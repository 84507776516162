import { createFileRoute } from '@tanstack/react-router';

import { AdvancedSearch } from '../../modules/ComponentsLibrary/AdvancedSearch';
import { type Kind } from '../../modules/ComponentsLibrary/AdvancedSearch/constants';
import { propertiesSearchScheme } from '../../modules/ComponentsLibrary/AdvancedSearch/PropertiesSearch/utils';

const properties = ['properties'] satisfies Kind[];

export const Route = createFileRoute('/properties/')({
  component: RouteComponent,
  validateSearch: propertiesSearchScheme,
});

function RouteComponent() {
  return <AdvancedSearch title="Property Search" kinds={properties} />;
}
