import { createFileRoute } from '@tanstack/react-router';

import ToolFundDashboard from '../modules/ToolFundDashboard/main';

export const Route = createFileRoute('/toolFundDashboard')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Tool Fund Dashboard' }],
    };
  },
});

function RouteComponent() {
  return <ToolFundDashboard />;
}
