import { TimesheetDepartment } from '@kalos/kalos-rpc';
import { type FC, useCallback, useEffect, useRef, useState } from 'react';

import { debounce, TimesheetDepartmentClientService } from '../../../tools/helpers';
import { PlainForm, type Schema } from '../PlainForm';

// Extended interface to add department filtering
export interface EnhancedFilterProps {
  onSearch: (params: { description: string; departments?: number[] }) => void;
  initialFilters?: {
    description: string;
    departments: number[];
  };
}

// Define the form data structure
interface FilterData {
  description: string;
  department: number[];
}

export const EnhancedFilter: FC<EnhancedFilterProps> = ({ onSearch, initialFilters }) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<TimesheetDepartment[]>([]);
  const [filterData, setFilterData] = useState<FilterData>({
    description: initialFilters?.description || '',
    department: initialFilters?.departments || [],
  });

  // Store the debounced function in a ref to avoid recreation
  const debouncedSearchRef = useRef<any>(null);

  // Initialize the debounced search function when component mounts
  useEffect(() => {
    debouncedSearchRef.current = debounce((data: FilterData) => {
      onSearch({
        description: data.description,
        departments: data.department,
      });
      setIsSearching(false);
    }, 300);

    // Clean up on unmount
    return () => {
      if (debouncedSearchRef.current && debouncedSearchRef.current.cancel) {
        debouncedSearchRef.current.cancel();
      }
    };
  }, [onSearch]);

  // Apply initial filters when they change
  useEffect(() => {
    if (initialFilters) {
      setFilterData({
        description: initialFilters.description,
        department: initialFilters.departments,
      });

      // Notify parent component of initial filter state
      onSearch({
        description: initialFilters.description,
        departments: initialFilters.departments,
      });
    }
  }, [initialFilters, onSearch]);

  // Load departments when component mounts
  useEffect(() => {
    const loadDepartments = async () => {
      try {
        const dep = TimesheetDepartment.create();
        dep.isActive = 1;
        const result = await TimesheetDepartmentClientService.BatchGet(dep);
        if (result && result.results) {
          setDepartmentList(result.results);
        }
      } catch (err) {
        console.error('Unable to Retrieve Department List in QuoteSelector', err);
      }
    };

    loadDepartments();
  }, []);

  // Define the schema for the combined filter form
  const FILTER_SCHEMA: Schema<FilterData> = [
    [
      {
        name: 'description',
        label: 'Description',
        type: 'text',
      },
      {
        name: 'department',
        label: 'Department(s)',
        type: 'multiselect',
        options: departmentList.map((dl) => ({
          value: dl.id,
          label: `${dl.value} - ${dl.description}`,
        })),
      },
    ],
  ];

  // Handle combined filter changes
  const handleFilterChange = useCallback((data: FilterData) => {
    setFilterData(data);
    setIsSearching(true);

    if (debouncedSearchRef.current) {
      debouncedSearchRef.current(data);
    }
  }, []);

  return (
    <div className="enhanced-filter" style={{ padding: '10px 16px' }}>
      {isSearching && (
        <div
          style={{
            position: 'absolute',
            top: '5px',
            right: '20px',
            fontSize: '12px',
            color: '#666',
          }}
        >
          Searching...
        </div>
      )}

      <PlainForm
        schema={FILTER_SCHEMA}
        data={filterData}
        onChange={handleFilterChange}
        fullWidth={true}
      />
    </div>
  );
};
