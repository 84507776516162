import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { lazy, Suspense, useEffect, useState } from 'react';

export const TanStackQueryDevtools = import.meta.env.PROD
  ? ProductionQueryDevtools // Render nothing in production
  : ReactQueryDevtools;

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

// eslint-disable-next-line react-refresh/only-export-components
function ProductionQueryDevtools() {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-expect-error this is a global function for devtools in production
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    showDevtools && (
      <Suspense fallback={null}>
        <ReactQueryDevtoolsProduction />
      </Suspense>
    )
  );
}
