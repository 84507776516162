import { zodResolver } from '@hookform/resolvers/zod';
import { type EmployeeItem } from '@kalos/kalos-rpc';
import {
  Button,
  Combobox,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Textarea,
} from '@kalos/ui';
import { Link } from '@tanstack/react-router';
import { ExternalLink } from 'lucide-react';
import { useMemo } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useAuth } from '../../context/AuthContext';
import { useEmployeeItemOptionsBatchGetQuery } from '../../hooks/react-query/employeeItems';
import { type DirtyModelFields } from '../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../tools/typeguargs';
import { employeesItemOptionsRoute } from '../SideMenu/constants';
import { getHasAccessToEmployeeItemOptions } from './EmployeeItemOptions/utils';

type EmployeeItemsFormFields = Pick<EmployeeItem, 'name' | 'date' | 'notes'>;
const employeeItemsFormSchema = z.object({
  name: z.string().min(1, 'Please select item'),
  date: z.date(),
  notes: z.string().min(1, 'Please enter notes'),
}) satisfies ZodObjectWithModelKeys<EmployeeItemsFormFields>;
type EmployeeItemsFormSchema = z.infer<typeof employeeItemsFormSchema>;
const getDefaultEmployeeItemFormValues = (
  data?: Partial<EmployeeItemsFormSchema>,
): EmployeeItemsFormSchema => ({
  date: new Date(),
  notes: '',
  name: '',
  ...data,
});

type EmployeeItemFormProps = {
  defaultValues: EmployeeItemsFormSchema;
  onSave: (data: {
    data: EmployeeItemsFormSchema;
    dirtyFields: DirtyModelFields<EmployeeItemsFormSchema>;
  }) => void;
  isLoading?: boolean;
  disabled?: boolean;
};
export const EmployeeItemForm = ({
  defaultValues,
  onSave,
  disabled,
  isLoading,
}: EmployeeItemFormProps) => {
  const form = useForm<EmployeeItemsFormSchema>({
    resolver: zodResolver(employeeItemsFormSchema),
    defaultValues,
    disabled,
  });
  const handleFormSubmit: SubmitHandler<EmployeeItemsFormSchema> = (data) => {
    onSave({
      data,
      dirtyFields: form.formState.dirtyFields,
    });
  };

  const loggedInUser = useAuth().user;
  const isAbleToManageEmployeeOptionItems = useMemo(
    () => getHasAccessToEmployeeItemOptions(loggedInUser),
    [loggedInUser],
  );

  const employeeItemsOptionsQuery = useEmployeeItemOptionsBatchGetQuery({
    filter: {
      isActive: true,
      withoutLimit: true,
    },
    select(data) {
      return data.results.map((item) => ({
        label: item.name,
        value: item.name,
      }));
    },
  });

  return (
    <form
      className="flex max-h-[calc(100vh-100px)] flex-col gap-4 overflow-y-auto p-4"
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      <Form {...form}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center gap-2">
                <FormLabel>Item</FormLabel>
                {isAbleToManageEmployeeOptionItems && (
                  <Button size="sm" asChild>
                    <Link className="flex items-center gap-1" to={`/${employeesItemOptionsRoute}`}>
                      <span>Manage Item Options</span>
                      <ExternalLink size={15} />
                    </Link>
                  </Button>
                )}
              </div>

              <FormMessage />
              <FormControl>
                <Combobox
                  emptyLabel="No matching option"
                  emptySearch="No result"
                  values={employeeItemsOptionsQuery.data ?? []}
                  disabled={employeeItemsOptionsQuery.isPending}
                  placeholder="Select item option"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Notes</FormLabel>
              <FormMessage />
              <FormControl>
                <Textarea {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Issued Date</FormLabel>
              <FormMessage />
              <FormControl>
                <DateInput
                  onChange={field.onChange}
                  disabled={field.disabled}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button
          className="mt-4"
          disabled={disabled || !form.formState.isDirty}
          isLoading={isLoading}
        >
          Save
        </Button>
      </Form>
    </form>
  );
};

EmployeeItemForm.getDefaultValues = getDefaultEmployeeItemFormValues;
