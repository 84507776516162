// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "lodging.proto" (syntax proto3)
// tslint:disable
import { LodgingRequestService } from "./lodging";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service LodgingRequestService
 */
export class LodgingRequestServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = LodgingRequestService.typeName;
        this.methods = LodgingRequestService.methods;
        this.options = LodgingRequestService.options;
    }
    /**
     * @generated from protobuf rpc: GetLodgingRequestStatus(LodgingRequestStatus) returns (LodgingRequestStatus);
     */
    getLodgingRequestStatus(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllLodgingRequestStatus(Empty) returns (LodgingRequestStatusList);
     */
    getAllLodgingRequestStatus(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLodgingRequest(LodgingRequest) returns (LodgingRequest);
     */
    createLodgingRequest(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLodgingRequest(LodgingRequest) returns (LodgingRequest);
     */
    getLodgingRequest(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLodgingRequest(LodgingRequest) returns (LodgingRequestList);
     */
    batchGetLodgingRequest(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLodgingRequest(LodgingRequest) returns (LodgingRequest);
     */
    updateLodgingRequest(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteLodgingRequest(LodgingRequest) returns (Empty);
     */
    deleteLodgingRequest(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLodgingAssignment(LodgingRequestAssignment) returns (LodgingRequestAssignment);
     */
    createLodgingAssignment(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLodgingAssignment(LodgingRequestAssignment) returns (LodgingRequestAssignment);
     */
    getLodgingAssignment(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLodgingAssignment(LodgingRequestAssignment) returns (LodgingRequestAssignmentList);
     */
    batchGetLodgingAssignment(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLodgingAssignment(LodgingRequestAssignment) returns (LodgingRequestAssignment);
     */
    updateLodgingAssignment(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteLodgingAssignment(LodgingRequestAssignment) returns (Empty);
     */
    deleteLodgingAssignment(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLodgingDocument(LodgingRequestDocument) returns (LodgingRequestDocument);
     */
    createLodgingDocument(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLodgingDocument(LodgingRequestDocument) returns (LodgingRequestDocument);
     */
    getLodgingDocument(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLodgingDocument(LodgingRequestDocument) returns (LodgingRequestDocumentList);
     */
    batchGetLodgingDocument(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLodgingDocument(LodgingRequestDocument) returns (LodgingRequestDocument);
     */
    updateLodgingDocument(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteLodgingDocument(LodgingRequestDocument) returns (Empty);
     */
    deleteLodgingDocument(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAssignmentDetails(AssignmentDetails) returns (AssignmentDetails);
     */
    getAssignmentDetails(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetAssignmentDetails(AssignmentDetails) returns (AssignmentDetailsList);
     */
    batchGetAssignmentDetails(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOverlapAssignments(LodgingRequest) returns (AssignmentDetailsList);
     */
    getOverlapAssignments(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLodgingRequestByUsersAssignment(Int32List) returns (LodgingRequestList);
     */
    batchGetLodgingRequestByUsersAssignment(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendAssignmentNotification(LodgingRequestAssignment) returns (Empty);
     */
    sendAssignmentNotification(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddOrUpdatePerdiems(IDInt32) returns (Empty);
     */
    addOrUpdatePerdiems(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AutoUpdateRequestStatus(Empty) returns (Empty);
     */
    autoUpdateRequestStatus(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
