'use client';

import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { Button } from '../components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../components/command';
import { Popover, PopoverContent, PopoverTrigger } from '../components/popover';
import { ScrollArea } from '../components/scrollArea';
import { cn } from '../utils';

export function Combobox({
  value: selectedValue,
  values,
  onChange,
  disabled = false,
  emptyLabel = null,
  emptySearch = '',
  placeholder,
  id,
  triggerClassName,
  side,
  isLoading = false,
}: {
  values: Array<{
    // label is used for the search and displayed if displayLabel is not passed
    label: string;
    displayLabel?: React.ReactNode;
    value: string;
  }>;
  onChange: (newValue: string) => void;
  value: string;
  emptySearch?: string;
  disabled?: boolean;
  id?: string;
  emptyLabel?: React.ReactNode;
  triggerClassName?: string;
  isLoading?: boolean;
} & Pick<React.ComponentProps<typeof CommandInput>, 'placeholder'> &
  Pick<React.ComponentProps<typeof PopoverContent>, 'side'>) {
  const [open, setOpen] = React.useState(false);

  const selectedValueLabel = React.useMemo(() => {
    const value = values.find(({ value }) => value === selectedValue);
    return value?.displayLabel || value?.label;
  }, [selectedValue, values]);

  const handleTriggerKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
      setOpen(!open);
    }
  };

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          id={id}
          tabIndex={0}
          variant="outline"
          role="combobox"
          disabled={disabled || isLoading}
          isLoading={isLoading}
          aria-expanded={open}
          onKeyDown={handleTriggerKeyDown}
          className={cn(triggerClassName, 'h-min w-full justify-between ')}
        >
          <span className={cn(!selectedValueLabel && 'font-normal opacity-60', 'truncate')}>
            {selectedValueLabel ?? emptyLabel}
          </span>
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent side={side} className="w-56 p-0">
        <Command
          filter={(value, search) => {
            return values
              .find(({ value: v }) => value === v)
              ?.label.toLowerCase()
              .includes(search.toLowerCase())
              ? 1
              : 0;
          }}
        >
          <CommandInput placeholder={placeholder} className="h-9" />
          {emptySearch && <CommandEmpty>{emptySearch}</CommandEmpty>}
          <CommandList>
            <ScrollArea type="always" className="h-[300px] [&>div>div]:!block">
              <CommandGroup>
                {values.map(({ value, label, displayLabel }) => (
                  <CommandItem
                    key={value}
                    value={value}
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                      if (event.key === 'Enter') {
                        onChange(value);
                        setOpen(false);
                      }
                    }}
                    onSelect={(changedValue) => {
                      onChange(changedValue === selectedValue ? '' : changedValue);
                      setOpen(false);
                    }}
                  >
                    {displayLabel || label}
                    <CheckIcon
                      className={cn(
                        'ml-auto h-4 w-4',
                        value === selectedValue ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
