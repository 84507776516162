import { createFileRoute } from '@tanstack/react-router';

import { AdvancedSearch } from '../../modules/ComponentsLibrary/AdvancedSearch';
import { serviceCallsSearchScheme } from '../../modules/ComponentsLibrary/AdvancedSearch/ServiceCallsSearch/utils';

export const Route = createFileRoute('/jobs/')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Jobs' }],
    };
  },
  validateSearch: serviceCallsSearchScheme,
});

function RouteComponent() {
  return (
    <AdvancedSearch
      title="Jobs"
      kinds={['serviceCalls']}
      deletableEvents
      eventsWithAccounting
      eventsWithAdd
    />
  );
}
