import { createFileRoute } from '@tanstack/react-router';

import { NewHireCreate } from '../../modules/NewHire/components/NewHireCreate';

export const Route = createFileRoute('/hrDashboard/newHire/create')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'HR Dashboard - New Hire' }],
    };
  },
});

function RouteComponent() {
  return <NewHireCreate />;
}
