import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { OAuthConsent } from '../../modules/Softphone/OAuthConsent';

export const Route = createFileRoute('/softphone/oauth-callback')({
  component: RouteComponent,
  head: () => {
    return {
      meta: [{ title: 'Softphone Authorization' }],
    };
  },
  validateSearch: z.object({
    code: z.string().optional(),
  }),
});

function RouteComponent() {
  return <OAuthConsent />;
}
