'use no memo';
import { Skeleton } from '@kalos/ui';
import { type ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

export const getLoadingColumns: <T>(cols: ColumnDef<T>[]) => ColumnDef<T>[] = (cols) => {
  return cols.map((col) => ({
    ...col,
    cell(props) {
      return <Skeleton className={props.column.columnDef.meta?.className} />;
    },
  }));
};

export const useGetLoadingColumns = <T,>(cols: ColumnDef<T>[]) => {
  return useMemo(() => getLoadingColumns(cols), [cols]);
};
