import { type User } from '@kalos/kalos-rpc';
import { Button, Card, CardContent, CardHeader, CardTitle, Toaster } from '@kalos/ui';
import { type CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { Navigate, useLocation, useNavigate, useRouter, useSearch } from '@tanstack/react-router';
import type { JSX } from 'react';

import { useAuth } from './context/AuthContext';
import { ErrorBoundary } from './modules/ComponentsLibrary/ErrorBoundary';
import { StyledPage } from './modules/PageWrapper/styled';
import SideMenu from './modules/SideMenu/main';
import { Softphone } from './modules/Softphone';
import { SoftPhoneStoreProvider } from './modules/Softphone/context/SoftPhoneStoreProvider';

export const RequireAuth = ({
  children,
  requiredPermissions,
  userCheck,
}: {
  children: JSX.Element;
  requiredPermissions?: number[];
  userCheck?: (user: User) => boolean;
}) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authChecked) {
    return null; // or some loading spinner
  }

  if (auth.user.id === 0) {
    return (
      <Navigate
        to="/"
        search={{
          from: location.pathname,
        }}
        replace
      />
    );
  }

  if (userCheck) {
    const isAllowed = userCheck(auth.user);
    if (!isAllowed) {
      return "You don't have permission to view this page";
    }
  }

  if (requiredPermissions) {
    const userPermissions = auth.user.permissionGroups.map((p) => p.id);
    const hasAllPermissions = requiredPermissions.every((perm) => userPermissions.includes(perm));

    if (!hasAllPermissions) {
      return null;
    }
  }

  return children;
};
/*
const client = new UserClient(ENDPOINT);
//We should return the old login here

export const LegacyLoginPage = () => {
  const credentials = { userName: '', password: '' };
  async function getLegacyUser(userName: string, password: string) {
    const userReq = User.create();
    userReq.pwd = password;
    userReq.login = userName;
    const legacyToken = await client.GetToken_DEPRECATED(userName, password);
    console.log(legacyToken);
  }
  return (
    <div className="flex min-h-[80vh] flex-col items-center justify-center">
      <Card>
        <CardHeader>
          <CardTitle className="text-center">Welcome To Kalos Services Inc.</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <Form
            className="main_box"
            schema={[
              [
                {
                  label: 'User Name',
                  name: 'userName',

                  required: true,
                },
              ],
              [
                {
                  label: 'Password',
                  name: 'password',
                  required: true,
                },
              ],
            ]}
            onSave={(data) => getLegacyUser(data.userName, data.password)}
            data={credentials}
            onClose={() => console.log('we do closing logic, if any')}
          />
          <p>{'If you cannot log in, please contact Webtech for assistance'}</p>
        </CardContent>
      </Card>
    </div>
  );
};
*/
export const authFlow = 'auth-code';
export const authScope = 'https://www.googleapis.com/auth/chat.memberships.app';
//Render the logic to allow a login selection

export const SelectLoginPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { from } = useSearch({ from: '/' });

  const handleSubmit = async function handleSubmit(credentialResponse: CodeResponse) {
    if (credentialResponse.code) {
      console.log({ credentialResponse });
      await auth.signin(credentialResponse.code);
      navigate({ to: from || '/home' });
    } else {
      console.log(credentialResponse);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleSubmit(tokenResponse),
    onError: (response) => {
      console.log('Login Failed');
      console.log({ response });
    },
    flow: authFlow,
    redirect_uri: window.location.origin,
    scope: authScope,
  });

  return (
    <div className="flex min-h-[80vh] flex-col items-center justify-center">
      <Card>
        <CardHeader className="pb-16">
          <CardTitle className="text-center">Welcome To Kalos Services Inc.</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <Button className="flex-1" type="submit" onClick={login}>
            Employee Sign In
          </Button>
          <p>If you cannot log in, please contact Webtech for assistance</p>
        </CardContent>
      </Card>
    </div>
  );
};

export const Logout = () => {
  const auth = useAuth();
  const router = useRouter();
  auth.signout(() => {
    router.navigate({ to: '/' });
    router.invalidate();
  });
  return null;
};

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const hasSoftphoneAccess = user.permissionGroups.some((group) => group.name === 'GoToUser');

  return (
    <StyledPage>
      <ErrorBoundary>
        <Toaster />
        <div className="PageWrapper">
          <div className="PageWrapperMenu flex">
            <SideMenu />
          </div>
          <div className="PageWrapperContent">
            <SoftPhoneStoreProvider>
              {children}
              {hasSoftphoneAccess && <Softphone />}
            </SoftPhoneStoreProvider>
          </div>
        </div>
      </ErrorBoundary>
    </StyledPage>
  );
};
