import './Tooltip.module.less';

import Fade from '@mui/material/Fade';
import TooltipUI, { type TooltipProps } from '@mui/material/Tooltip';
import { type FC, type ReactElement } from 'react';

type Style = {
  controlled?: boolean;
  maxWidth?: number;
  noPadding?: boolean;
};
type Props = Style &
  Pick<TooltipProps, 'placement' | 'children' | 'open'> & {
    content: string | ReactElement<any>;
    open?: boolean;
    disabled?: boolean;
  };

export const Tooltip: FC<Props> = ({
  content,
  placement = 'left',
  children,
  maxWidth = 220,
  open = false,
  controlled = false,
  noPadding = false,
  disabled,
}) => (
  <TooltipUI // TODO: make it clickable for mobile devices
    title={content}
    placement={placement}
    classes={{
      tooltip: `Tooltip ${noPadding ? 'NoPadding' : ''}`,
      popper: controlled ? 'TooltipPopover' : '',
    }}
    disableHoverListener={disabled}
    disableFocusListener={disabled}
    disableInteractive={disabled}
    enterTouchDelay={50}
    leaveTouchDelay={60000}
    style={{ maxWidth }}
    open={controlled ? open : undefined}
    slots={{
      transition: Fade,
    }}
  >
    {children}
  </TooltipUI>
);
