import { createFileRoute } from '@tanstack/react-router';

import { AdvancedSearch } from '../../modules/ComponentsLibrary/AdvancedSearch';
import { type Kind } from '../../modules/ComponentsLibrary/AdvancedSearch/constants';
import { employeesSearchScheme } from '../../modules/ComponentsLibrary/AdvancedSearch/EmployeesSearch/utils';

export const Route = createFileRoute('/employees/')({
  component: RouteComponent,
  validateSearch: employeesSearchScheme,
  head(ctx) {
    return {
      meta: [{ title: 'Employee Directory' }],
    };
  },
});

const onlyEmployeesKind: Kind[] = ['employees'];
function RouteComponent() {
  return <AdvancedSearch title="Employee Directory" kinds={onlyEmployeesKind} printableEmployees />;
}
