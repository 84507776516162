// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "projects_core.proto" (syntax proto3)
// tslint:disable
import { ProjectsCoreService } from "./projects_core";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ProjectsCoreService
 */
export class ProjectsCoreServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ProjectsCoreService.typeName;
        this.methods = ProjectsCoreService.methods;
        this.options = ProjectsCoreService.options;
    }
    /**
     * @generated from protobuf rpc: CreateArea(Area) returns (Area);
     */
    createArea(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetArea(Area) returns (Area);
     */
    getArea(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetArea(Area) returns (AreaList);
     */
    batchGetArea(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateArea(Area) returns (Area);
     */
    updateArea(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateBillingType(BillingType) returns (BillingType);
     */
    createBillingType(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBillingType(BillingType) returns (BillingType);
     */
    getBillingType(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetBillingType(BillingType) returns (BillingTypeList);
     */
    batchGetBillingType(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateBillingType(BillingType) returns (BillingType);
     */
    updateBillingType(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePosition(Position) returns (Position);
     */
    createPosition(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPosition(Position) returns (Position);
     */
    getPosition(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPosition(Position) returns (PositionList);
     */
    batchGetPosition(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePosition(Position) returns (Position);
     */
    updatePosition(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProjectAttribute(ProjectAttribute) returns (ProjectAttribute);
     */
    createProjectAttribute(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectAttribute(ProjectAttribute) returns (ProjectAttribute);
     */
    getProjectAttribute(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProjectAttribute(ProjectAttribute) returns (ProjectAttributeList);
     */
    batchGetProjectAttribute(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProjectAttribute(ProjectAttribute) returns (ProjectAttribute);
     */
    updateProjectAttribute(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProductionKPI(ProductionKPI) returns (ProductionKPI);
     */
    createProductionKPI(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProductionKPI(ProductionKPI) returns (ProductionKPI);
     */
    getProductionKPI(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProductionKPI(ProductionKPI) returns (ProductionKPIList);
     */
    batchGetProductionKPI(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProductionKPI(ProductionKPI) returns (ProductionKPI);
     */
    updateProductionKPI(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProjectStatus(ProjectStatus) returns (ProjectStatus);
     */
    createProjectStatus(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectStatus(ProjectStatus) returns (ProjectStatus);
     */
    getProjectStatus(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProjectStatus(ProjectStatus) returns (ProjectStatusList);
     */
    batchGetProjectStatus(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProjectStatus(ProjectStatus) returns (ProjectStatus);
     */
    updateProjectStatus(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProjectEmployeeHours(ProjectEmployeeHours) returns (ProjectEmployeeHours);
     */
    createProjectEmployeeHours(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectEmployeeHours(ProjectEmployeeHours) returns (ProjectEmployeeHours);
     */
    getProjectEmployeeHours(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProjectEmployeeHours(ProjectEmployeeHours) returns (ProjectEmployeeHoursList);
     */
    batchGetProjectEmployeeHours(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProjectEmployeeHours(ProjectEmployeeHours) returns (ProjectEmployeeHours);
     */
    updateProjectEmployeeHours(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProject(Project) returns (Project);
     */
    createProject(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProject(Project) returns (Project);
     */
    getProject(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProject(Project) returns (ProjectList);
     */
    batchGetProject(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProject(Project) returns (Project);
     */
    updateProject(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProjectType(ProjectType) returns (ProjectType);
     */
    createProjectType(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectType(ProjectType) returns (ProjectType);
     */
    getProjectType(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProjectType(ProjectType) returns (ProjectTypeList);
     */
    batchGetProjectType(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProjectType(ProjectType) returns (ProjectType);
     */
    updateProjectType(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateChangeOrder(ChangeOrder) returns (ChangeOrder);
     */
    createChangeOrder(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetChangeOrder(ChangeOrder) returns (ChangeOrder);
     */
    getChangeOrder(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetChangeOrder(ChangeOrder) returns (ChangeOrderList);
     */
    batchGetChangeOrder(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateChangeOrder(ChangeOrder) returns (ChangeOrder);
     */
    updateChangeOrder(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateCustomer(Customer) returns (Customer);
     */
    createCustomer(input, options) {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomer(Customer) returns (Customer);
     */
    getCustomer(input, options) {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetCustomer(Customer) returns (CustomerList);
     */
    batchGetCustomer(input, options) {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCustomer(Customer) returns (Customer);
     */
    updateCustomer(input, options) {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateExpended(Expended) returns (Expended);
     */
    createExpended(input, options) {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetExpended(Expended) returns (Expended);
     */
    getExpended(input, options) {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetExpended(Expended) returns (ExpendedList);
     */
    batchGetExpended(input, options) {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateExpended(Expended) returns (Expended);
     */
    updateExpended(input, options) {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSchedule(Schedule) returns (Schedule);
     */
    createSchedule(input, options) {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSchedule(Schedule) returns (Schedule);
     */
    getSchedule(input, options) {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetSchedule(Schedule) returns (ScheduleList);
     */
    batchGetSchedule(input, options) {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSchedule(Schedule) returns (Schedule);
     */
    updateSchedule(input, options) {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateScopeSheet(ScopeSheet) returns (ScopeSheet);
     */
    createScopeSheet(input, options) {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScopeSheet(ScopeSheet) returns (ScopeSheet);
     */
    getScopeSheet(input, options) {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetScopeSheet(ScopeSheet) returns (ScopeSheetList);
     */
    batchGetScopeSheet(input, options) {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateScopeSheet(ScopeSheet) returns (ScopeSheet);
     */
    updateScopeSheet(input, options) {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateScopeTable(ScopeTable) returns (ScopeTable);
     */
    createScopeTable(input, options) {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScopeTable(ScopeTable) returns (ScopeTable);
     */
    getScopeTable(input, options) {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetScopeTable(ScopeTable) returns (ScopeTableList);
     */
    batchGetScopeTable(input, options) {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateScopeTable(ScopeTable) returns (ScopeTable);
     */
    updateScopeTable(input, options) {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSheet(Sheet) returns (Sheet);
     */
    createSheet(input, options) {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSheet(Sheet) returns (Sheet);
     */
    getSheet(input, options) {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetSheet(Sheet) returns (SheetList);
     */
    batchGetSheet(input, options) {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSheet(Sheet) returns (Sheet);
     */
    updateSheet(input, options) {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     *
     * rpc CreateStatus(Status) returns (Status) {}
     * rpc GetStatus(Status) returns (Status) {}
     * rpc BatchGetStatus(Status) returns (ProjectStatusList) {}
     * rpc UpdateStatus(Status) returns (Status) {}
     *
     * @generated from protobuf rpc: CreateSystem(System) returns (System);
     */
    createSystem(input, options) {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSystem(System) returns (System);
     */
    getSystem(input, options) {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetSystem(System) returns (SystemList);
     */
    batchGetSystem(input, options) {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSystem(System) returns (System);
     */
    updateSystem(input, options) {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTrelloCard(TrelloCard) returns (TrelloCard);
     */
    createTrelloCard(input, options) {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTrelloCard(TrelloCard) returns (TrelloCard);
     */
    getTrelloCard(input, options) {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTrelloCard(TrelloCard) returns (TrelloCardList);
     */
    batchGetTrelloCard(input, options) {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTrelloCard(TrelloCard) returns (TrelloCard);
     */
    updateTrelloCard(input, options) {
        const method = this.methods[71], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEstimate(Estimate) returns (Estimate);
     */
    createEstimate(input, options) {
        const method = this.methods[72], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEstimate(Estimate) returns (Estimate);
     */
    getEstimate(input, options) {
        const method = this.methods[73], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetEstimate(Estimate) returns (EstimateList);
     */
    batchGetEstimate(input, options) {
        const method = this.methods[74], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateEstimate(Estimate) returns (Estimate);
     */
    updateEstimate(input, options) {
        const method = this.methods[75], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteEstimate(Estimate) returns (Estimate);
     */
    deleteEstimate(input, options) {
        const method = this.methods[76], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
