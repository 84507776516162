import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { LodgingAssignmentDetails } from '../modules/Lodging/LodgingAssignmentDetails';

export const Route = createFileRoute('/lodgingAssignment/$lodgingAssignmentId')({
  component: RouteComponent,
  params: z.object({
    lodgingAssignmentId: z.coerce.number().positive(),
  }),
});

function RouteComponent() {
  const { lodgingAssignmentId } = Route.useParams();
  return <LodgingAssignmentDetails assignmentId={lodgingAssignmentId} />;
}
