import { createFileRoute } from '@tanstack/react-router';

import { SoftPhoneStoreProvider } from '../../modules/Softphone/context/SoftPhoneStoreProvider';
import { SoftphonePopup } from '../../modules/Softphone/popup';

export const Route = createFileRoute('/softphone/popup')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <SoftPhoneStoreProvider>
      <SoftphonePopup />
    </SoftPhoneStoreProvider>
  );
}
