import { createFileRoute } from '@tanstack/react-router';

import NitrogenCalculator from '../tools/Nitrogen';

export const Route = createFileRoute('/tools/nitrogen')({
  component: NitrogenCalculator,
  head(ctx) {
    return {
      meta: [{ title: 'Nitrogen Calculator' }],
    };
  },
});
