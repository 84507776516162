import { type File } from '@kalos/kalos-rpc';
import { Button, Card, CardFooter, CardHeader, CardTitle, DataTablePagination } from '@kalos/ui';
import { DownloadIcon } from '@radix-ui/react-icons';
import { useEffect, useMemo, useState } from 'react';

import { useS3FileUrlQuery } from '../../../../hooks/react-query/useS3';
import { convertHeicToJpeg, getMimeType, S3ClientService } from '../../../../tools/helpers';
import { RotateZoomImage } from '../../../AltGallery/main';
import { Loader } from '../../../Loader/main';

type ImageGalleryProps<T extends Pick<File, 'name' | 'bucket' | 'mimeType'>> = {
  title?: string;
  files: T[];
  actions?: (props: { original: T }) => React.ReactNode;
};

export const ImageGallery = <T extends Pick<File, 'name' | 'bucket' | 'mimeType'>>({
  title,
  files,
  actions,
}: ImageGalleryProps<T>) => {
  const [activeImageIdx, setActiveImageIdx] = useState(0);
  const [convertedImageUrl, setConvertedImageUrl] = useState<string | null>(null);
  const [isConverting, setIsConverting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setActiveImageIdx(0);
    setConvertedImageUrl(null);
  }, [files.length]);

  const currentFile = useMemo(() => files[activeImageIdx], [files, activeImageIdx]);

  const fetchImageUrl = useS3FileUrlQuery({
    filter: {
      fileName: currentFile?.name ?? '',
      bucket: currentFile?.bucket ?? '',
    },
    enabled: files.length > 0 && !!currentFile,
  });

  const { mimeType } = useMemo(
    () => ({
      mimeType:
        files.at(activeImageIdx)?.mimeType != ''
          ? files.at(activeImageIdx)?.mimeType
          : getMimeType(files?.at(activeImageIdx)?.name ?? ''),
    }),
    [activeImageIdx, files],
  );

  // Handle HEIC conversion when URL is available
  useEffect(() => {
    let currentUrl: string | null = null;

    if (!fetchImageUrl.data || !currentFile) return;

    const isHeic =
      currentFile.name.toLowerCase().endsWith('.heic') ||
      currentFile.mimeType?.toLowerCase().includes('image/heic');

    if (!isHeic) {
      setConvertedImageUrl(null);
      return;
    }

    const convertImage = async () => {
      setIsConverting(true);
      try {
        const response = await fetch(fetchImageUrl.data);
        const arrayBuffer = await response.arrayBuffer();
        const blob = await convertHeicToJpeg(arrayBuffer, currentFile.name, currentFile.mimeType);
        currentUrl = URL.createObjectURL(blob);
        setConvertedImageUrl(currentUrl);
      } catch (error) {
        console.error('Error converting HEIC image:', error);
        setConvertedImageUrl(null);
      } finally {
        setIsConverting(false);
      }
    };

    convertImage();

    return () => {
      if (currentUrl) {
        URL.revokeObjectURL(currentUrl);
      }
    };
  }, [fetchImageUrl.data, currentFile]);

  const downloadFile = async () => {
    setIsDownloading(true);
    try {
      const currentFile = files[activeImageIdx];

      // Check if file is HEIC format
      const isHeic =
        currentFile.name.toLowerCase().endsWith('.heic') ||
        currentFile.mimeType?.toLowerCase().includes('image/heic');

      // Get S3 download URL
      const url = await S3ClientService.getFileS3BucketUrl(currentFile.name, currentFile.bucket);
      if (!url) {
        console.error('Failed to get download URL');
        return;
      }

      // Get the file's mime type
      const mimeType = currentFile.mimeType || getMimeType(currentFile.name);

      // Get base filename
      const baseName = currentFile.name.includes('.')
        ? currentFile.name.substring(0, currentFile.name.lastIndexOf('.'))
        : currentFile.name;

      // Determine download filename and mime type
      let downloadName = currentFile.name;
      let downloadMimeType = mimeType;

      if (mimeType && mimeType.includes('/')) {
        let extension = mimeType.split('/')[1].replace('jpeg', 'jpg');

        // For HEIC files, always convert to JPG for compatibility
        if (isHeic) {
          extension = 'jpg';
          downloadMimeType = 'image/jpeg';
        }

        downloadName = `${baseName}.${extension}`;
      }

      // Fetch the file
      const response = await fetch(url);
      const fileData = await response.arrayBuffer();

      let blob;
      // Convert HEIC to JPEG if needed
      if (isHeic) {
        blob = await convertHeicToJpeg(fileData, currentFile.name, currentFile.mimeType);
      } else {
        blob = new Blob([fileData], { type: downloadMimeType });
      }

      // Create download link
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const displayUrl = convertedImageUrl || fetchImageUrl.data;

  return (
    <Card className="relative flex h-full w-full flex-1 flex-col overflow-hidden">
      {!!title && (
        <CardHeader>
          <div>
            <CardTitle>{title}</CardTitle>
          </div>
        </CardHeader>
      )}

      <div
        className="flex h-[calc(100%_-138px)] w-full items-center justify-center"
        style={{ transform: 'scale(0.8)' }}
      >
        {fetchImageUrl.isLoading || isConverting ? (
          <Loader className="AltGalleryModal-Loader" />
        ) : files?.length ? (
          mimeType === 'application/pdf' ? (
            <iframe title="preview" src={fetchImageUrl.data} className="h-full w-full" />
          ) : (
            <RotateZoomImage url={displayUrl ?? ''} />
          )
        ) : (
          <div className="absolute flex h-full w-full items-center justify-center">
            <span className="text-xl">No files found.</span>
          </div>
        )}
      </div>

      <CardFooter className="flex justify-center gap-2 p-2">
        {fetchImageUrl.isSuccess && actions?.({ original: files[activeImageIdx] })}
        <Button
          size="icon"
          onClick={downloadFile}
          isLoading={isDownloading}
          disabled={fetchImageUrl.isFetching || !fetchImageUrl.data || isDownloading}
        >
          <DownloadIcon />
        </Button>
        <DataTablePagination
          currentPage={activeImageIdx}
          pageCount={files.length ?? 0}
          setPage={setActiveImageIdx}
          disabled={fetchImageUrl.isLoading || isConverting}
        />
      </CardFooter>
    </Card>
  );
};
