import { createFileRoute } from '@tanstack/react-router';

import { ServiceCallsPending } from '../modules/PendingBilling/components/ServiceCallsPending';

export const Route = createFileRoute('/callsPending')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Pending Calls' }],
    };
  },
});

function RouteComponent() {
  return <ServiceCallsPending />;
}
