import React, { Suspense, useEffect, useState } from 'react';

import { type DevtoolsProps, DevToolsWrapper } from './DevToolsWrapper';

export const DevelopmentRouterDevtools = (props: DevtoolsProps) => {
  const [isOpen, setIsOpen] = useState(props.initialIsOpen || false);

  // Add keyboard shortcut (Ctrl+Shift+R) to toggle devtools
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'R') {
        event.preventDefault();
        setIsOpen((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    // @ts-expect-error Global helper
    window.toggleRouterDevtools = () => setIsOpen((prev) => !prev);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // If hideToggleButton is true, don't render the toggle button,
  // but still respond to keyboard shortcut
  if (props.hideToggleButton === true) {
    return isOpen ? (
      <Suspense fallback={null}>
        <DevToolsWrapper {...props} initialIsOpen={true} />
      </Suspense>
    ) : null;
  }

  // Otherwise, return the devtools with props forwarded
  return (
    <Suspense fallback={null}>
      <DevToolsWrapper {...props} initialIsOpen={isOpen} />
    </Suspense>
  );
};
