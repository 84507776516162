import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { TimeOffPageByUser } from '../modules/ComponentsLibrary/TimeOff/UserTimeOffPage';
export const Route = createFileRoute('/user/time-off/$userId')({
  component: RouteComponent,
  params: z.object({
    userId: z.coerce.number().positive(),
  }),
  validateSearch: z.object({
    fromWeek: z.string().date().optional(),
  }),
});

function RouteComponent() {
  return <TimeOffPageByUser />;
}
