import { createFileRoute } from '@tanstack/react-router';

import { ToolFundCatalog } from '../../modules/ToolFundDashboard/components/ToolFundCatalog';

export const Route = createFileRoute('/toolFundCatalog/')({
  component: RouteComponent,
  head: () => {
    return {
      meta: [{ title: 'Tool Fund Catalog' }],
    };
  },
});

function RouteComponent() {
  return <ToolFundCatalog />;
}
