import { ToolFundSettings, User } from '@kalos/kalos-rpc';
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SimpleSelect,
  toast,
} from '@kalos/ui';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { type SubmitHandler, useWatch } from 'react-hook-form';

import { useAuth } from '../../../context/AuthContext';
import {
  useGetToolFundSettings,
  useToolFundCreateMutation,
  useToolFundSettingsUpdateMutation,
  useUserToolFundBalanceQuery,
} from '../../../hooks/react-query/useToolFundSettingsQuery';
import { useBatchGetTradeTypes } from '../../../hooks/react-query/useTradeTypeQuery';
import { getFieldMaskFromDirtyField } from '../../ComponentsLibrary/EmployeeDepartments/utils';
import { TechniciansComboboxPicker } from '../../ComponentsLibrary/Pickers/newPickers/TechnicianComboboxPicker';
import {
  type ToolFundSettingsFormType,
  useToolFundSettingsForm,
} from './ToolFundSettingsForm.hooks';

interface ToolFundSettingsFormProps {
  userId: number;
  isAdmin: boolean;
}

export const ToolFundSettingsForm: React.FC<ToolFundSettingsFormProps> = ({ userId, isAdmin }) => {
  const { user: loggedUser } = useAuth();
  const { data: settings, isLoading } = useGetToolFundSettings(ToolFundSettings.create({ userId }));
  const tradeTypesQuery = useBatchGetTradeTypes({
    select(data) {
      return {
        trades: data.results || [],
        selectOptions:
          data.results?.map((trade) => ({
            value: trade.id.toString(),
            label: trade.name,
          })) || [],
      };
    },
  });

  const { mutateAsync: updateSettings, isPending: isSaving } = useToolFundSettingsUpdateMutation();
  const { mutate: createSettings, isPending: isCreating } = useToolFundCreateMutation();
  const { data: balance } = useUserToolFundBalanceQuery(User.create({ id: userId }));

  const isNewRecord = useMemo(() => !settings || settings.id === 0, [settings]);

  const defaultFormValues = useMemo<ToolFundSettingsFormType>(() => {
    if (!isNewRecord && settings) {
      return {
        ...settings,
        tradeTypeId: Number(settings.tradeTypeId || 0),
      };
    }
    return {
      id: 0,
      userId,
      isEligible: false,
      monthlyAllowance: 0,
      lastUpdated: new Date().toISOString(),
      updatedBy: 0,
      tradeTypeId: 0,
    };
  }, [isNewRecord, settings, userId]);

  const form = useToolFundSettingsForm({
    defaultValues: defaultFormValues,
    disabled: isSaving,
  });

  // Add useEffect to update form when settings data is loaded
  useEffect(() => {
    if (settings && !isNewRecord) {
      form.reset({
        ...settings,
        tradeTypeId: Number(settings.tradeTypeId || 0),
      });
    }
  }, [settings, isNewRecord, form]);

  const isEligible = useWatch({ control: form.control, name: 'isEligible' });

  const isSubmitting = isSaving || isCreating;
  const isDirty = form.formState.isDirty;

  const canSubmit = isDirty && !isSubmitting;

  const onSaveCreate: SubmitHandler<ToolFundSettingsFormType> = useCallback(
    async (data) => {
      const selectedTrade = tradeTypesQuery.data?.trades?.find(
        (trade) => trade.id === data.tradeTypeId,
      );
      const isDefaultAmount = selectedTrade?.defaultAllotment === data.monthlyAllowance;

      const settingsToCreate = ToolFundSettings.create({
        ...data,
        userId,
        updatedBy: loggedUser.id,
        lastUpdated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        monthlyAllowance: isDefaultAmount ? 0 : data.monthlyAllowance,
      });

      try {
        await createSettings(settingsToCreate);
        toast({
          title: 'Success',
          description: 'Tool fund settings created successfully',
          variant: 'success',
        });
      } catch {
        toast({
          title: 'Error',
          description: 'Failed to create tool fund settings',
          variant: 'destructive',
        });
      }
    },
    [tradeTypesQuery.data?.trades, userId, loggedUser.id, createSettings],
  );

  const onSaveUpdate: SubmitHandler<ToolFundSettingsFormType> = useCallback(
    async (data) => {
      const dirtyFields = form.formState.dirtyFields;
      const fieldMask = getFieldMaskFromDirtyField(dirtyFields);

      if (fieldMask.length === 0) {
        toast({
          title: 'No changes',
          description: 'No changes to save',
          variant: 'default',
        });
        return;
      }

      const selectedTrade = tradeTypesQuery.data?.trades?.find(
        (trade) => trade.id === data.tradeTypeId,
      );
      const isDefaultAmount = selectedTrade?.defaultAllotment === data.monthlyAllowance;

      fieldMask.push('UpdatedBy', 'LastUpdated');

      const settingsToUpdate = ToolFundSettings.create({
        ...data,
        userId,
        updatedBy: loggedUser.id,
        lastUpdated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        monthlyAllowance: isDefaultAmount ? 0 : data.monthlyAllowance,
        fieldMask,
      });

      try {
        await updateSettings(settingsToUpdate);
        toast({
          title: 'Success',
          description: 'Tool fund settings updated successfully',
          variant: 'success',
        });
      } catch {
        toast({
          title: 'Error',
          description: 'Failed to update tool fund settings',
          variant: 'destructive',
        });
      }
    },
    [
      form.formState.dirtyFields,
      tradeTypesQuery.data?.trades,
      userId,
      loggedUser.id,
      updateSettings,
    ],
  );

  const onSubmit = isNewRecord ? onSaveCreate : onSaveUpdate;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {isAdmin && (
          <div className="flex justify-end space-x-4 border-b pb-4">
            <Button type="submit" disabled={!canSubmit} isLoading={isSubmitting}>
              Save
            </Button>
          </div>
        )}

        <div>
          <h3 className="text-2xl font-semibold tracking-wide">Tool Fund Settings</h3>
          <div className="rounded-lg border bg-white p-6 shadow">
            <div className="mb-6">
              <FormItem>
                <FormLabel>Total Tool Fund Available</FormLabel>
                <FormControl>
                  <Input value={`$${balance?.currentBalance.toFixed(2) || '0.00'}`} disabled />
                </FormControl>
              </FormItem>
            </div>

            <div className="grid grid-cols-1 items-center gap-6 md:grid-cols-2 lg:grid-cols-3">
              <FormField
                control={form.control}
                name="isEligible"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-3 space-y-0">
                    <FormLabel className="mb-0">Eligible for Tool Fund</FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(value) => {
                          field.onChange(value);
                          // Only reset tradeTypeId if unchecking the box
                          if (value === false) {
                            form.resetField('tradeTypeId');
                          }
                        }}
                        disabled={!isAdmin || isSubmitting}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="monthlyAllowance"
                render={({ field: allowanceField }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Monthly Allowance</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...allowanceField}
                        disabled={!isAdmin || !form.getValues('isEligible') || isSubmitting}
                        onChange={(e) => allowanceField.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tradeTypeId"
                render={({ field: tradeField }) => (
                  <FormItem className="flex flex-1 flex-col justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <FormLabel>
                        Trade {isEligible && <span className="text-primary">*</span>}
                      </FormLabel>
                      <FormMessage />
                    </div>
                    <FormControl>
                      <SimpleSelect
                        onChange={(value) => {
                          tradeField.onChange(Number(value));
                          const selectedTrade = tradeTypesQuery.data?.trades?.find(
                            (trade) => trade.id === Number(value),
                          );
                          if (selectedTrade?.defaultAllotment) {
                            form.setValue('monthlyAllowance', selectedTrade.defaultAllotment, {
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                          }
                        }}
                        values={tradeTypesQuery.data?.selectOptions || []}
                        selectedValue={String(tradeField.value || settings?.tradeTypeId || '')}
                        placeholder="Select Trade"
                        disabled={!isAdmin || !isEligible || isSubmitting}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            {!isNewRecord && (
              <>
                <FormField
                  control={form.control}
                  name="updatedBy"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Updated By</FormLabel>
                      <TechniciansComboboxPicker
                        placeholder="Select"
                        disabled={true}
                        onSelect={() => {}} // No-op since it's disabled
                        selected={field.value.toString()}
                      />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastUpdated"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Updated</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          disabled={true}
                          value={new Date(field.value).toLocaleString()}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};
