import { createFileRoute } from '@tanstack/react-router';

import { AddEmployeeFunction } from '../modules/ComponentsLibrary/EmployeeDepartments/AddEmployeeFunction';

export const Route = createFileRoute('/departments/add')({
  component: RouteComponent,
});

function RouteComponent() {
  return <AddEmployeeFunction />;
}
