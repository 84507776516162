import './AddServiceCall.module.less';

import { type Property, User, type UsersFilter } from '@kalos/kalos-rpc';
import { useNavigate } from '@tanstack/react-router';
import { type FC, useCallback, useEffect, useState } from 'react';

import { ROWS_PER_PAGE } from '../../../constants';
import { makeFakeRows, UserClientService } from '../../../tools/helpers';
import { CustomerEdit } from '../../ComponentsLibrary/CustomerEdit';
import { InfoTable } from '../../ComponentsLibrary/InfoTable';
import { Modal } from '../../ComponentsLibrary/Modal';
import { PropertyEdit } from '../../ComponentsLibrary/PropertyEdit';
import { SectionBar } from '../../ComponentsLibrary/SectionBar';
import { ServiceCall } from '../../ComponentsLibrary/ServiceCall';
import { CustomerItem } from './CustomerItem';
import { SearchForm } from './SearchForm';

export type Props = {
  propertyId?: number;
  openServiceCall?: boolean;
  onClose?: () => void;
  onSave?: (jobId?: number) => void;
  asProject?: boolean;
  projectParentId?: number;
};

const title = 'New Job';

export const AddServiceCall: FC<Props> = (props) => {
  const { propertyId = 0, openServiceCall = false, onClose, onSave, asProject = false } = props;
  const [addCustomer, setAddCustomer] = useState<boolean>(false);
  const [propertyOpened, setPropertyOpened] = useState<User>();
  const [serviceCallOpened, setServiceCallOpened] = useState<Property>();
  const [defaultServiceCallOpen, setDefaultServiceCallOpen] = useState<boolean>(openServiceCall);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<UsersFilter>({});
  const [entries, setEntries] = useState<User[]>([]);
  const [isReseted, setIsReseted] = useState<boolean>(true);

  const load = useCallback(async (page: number, search: UsersFilter) => {
    setLoading(true);
    // ? Just a very stripped-down version of the loadUsersByFilter function that takes the parameters into account
    const req = User.create();
    if (search.firstname) req.firstname = `%${search.firstname}%`;
    if (search.lastname) req.lastname = `%${search.lastname}%`;
    if (search.businessname) req.businessname = `%${search.businessname}%`;
    if (search.email) req.email = `%${search.email}%`;
    if (search.phone) req.phone = `%${search.phone}%`;
    req.orderBy = 'user_firstname';
    req.orderDir = 'ASC';
    req.isEmployee = 0;
    req.isActive = 1;
    if (page === -1) {
      req.overrideLimit = true;
    } else {
      req.pageNumber = page;
    }
    req.withProperties = true;

    const data = await UserClientService.BatchGet(req);
    const results = {
      results: data.results.sort((a, b) => {
        const A = (a.orderBy || '').toString().toLowerCase();
        const B = (b.orderBy || '').toString().toLowerCase();
        if (A < B) return -1;
        if (A > B) return 1;
        return 0;
      }),
      totalCount: data.totalCount,
    };
    setEntries(results.results);
    setCount(data.totalCount);
    setLoading(false);
    setIsReseted(!!results.results.length);
  }, []);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      load(page, search);
    }
  }, [loaded, setLoaded, load, page, search]);

  const handleReset = useCallback(() => {
    setEntries([]);
    setCount(0);
    setPage(0);
    setIsReseted(true);
  }, [setEntries]);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
      setLoaded(false);
    },
    [setPage, setLoaded],
  );

  const handleSearch = useCallback(
    (search: UsersFilter) => {
      setPage(0);
      setSearch(search);
      setLoaded(false);
    },
    [setSearch, setLoaded],
  );

  const handleToggleAddCustomer = useCallback(
    (addCustomer: boolean) => () => setAddCustomer(addCustomer),
    [setAddCustomer],
  );

  const handlePropertyClose = useCallback(() => setPropertyOpened(undefined), [setPropertyOpened]);

  const handleServiceCallClose = useCallback(() => {
    setServiceCallOpened(undefined);
    setDefaultServiceCallOpen(false);
    if (onClose) {
      onClose();
    }
  }, [setServiceCallOpened, onClose]);

  const navigate = useNavigate();

  const onCustomerClick = useCallback(
    (customer: User) => {
      navigate({ to: '/customers/$customerId', params: { customerId: customer.id } });
    },
    [navigate],
  );

  const handleCustomerSave = useCallback(
    (data: User) => {
      setAddCustomer(false);
      setPropertyOpened(data);
    },
    [setAddCustomer, setPropertyOpened],
  );

  const handlePropertySave = useCallback(
    (data: Property) => {
      setPropertyOpened(undefined);
      setServiceCallOpened(data);
    },
    [setPropertyOpened, setServiceCallOpened],
  );

  const handleAddProperty = useCallback(
    (customer: User) => {
      setPropertyOpened(customer);
    },
    [setPropertyOpened],
  );

  return (
    <div>
      {!openServiceCall && (
        <>
          <SectionBar
            title={title}
            pagination={{
              count,
              rowsPerPage: ROWS_PER_PAGE,
              onPageChange: handlePageChange,
              page,
              disabled: loading,
            }}
            actions={onClose ? [{ label: 'Close', onClick: onClose }] : []}
            fixedActions
          />
          <SearchForm
            onClose={onClose}
            onSearch={handleSearch}
            onReset={handleReset}
            onAddCustomer={handleToggleAddCustomer(true)}
          />
          {loading ? (
            <InfoTable data={makeFakeRows()} loading />
          ) : entries.length ? (
            entries.map((entry) => (
              <CustomerItem
                key={entry.id}
                customer={entry}
                {...props}
                onAddServiceCall={setServiceCallOpened}
                onCustomerClick={onCustomerClick}
                onAddProperty={handleAddProperty}
              />
            ))
          ) : (
            !isReseted && (
              <div className="px-8">
                <span>No entries found.</span>
              </div>
            )
          )}
        </>
      )}
      {propertyOpened && (
        <Modal open onClose={handlePropertyClose} className="Modal-Remove-Scroll">
          <PropertyEdit
            userId={propertyOpened.id}
            onClose={handlePropertyClose}
            onSave={handlePropertySave}
          />
        </Modal>
      )}
      {(serviceCallOpened || defaultServiceCallOpen) && (
        <Modal
          open
          onClose={handleServiceCallClose}
          fullScreen
          className="Modal-Remove-Scroll AddServiceCall-Modal"
        >
          <div className="AddServiceCallWrapper">
            <div className="AddServiceCallHeader">
              <SectionBar
                title={title}
                actions={[{ label: 'Close', onClick: handleServiceCallClose }]}
                fixedActions
              />
            </div>
            <div className="AddServiceCallContent New-Job">
              <ServiceCall
                propertyId={serviceCallOpened ? serviceCallOpened.id : propertyId}
                serviceCallId={0}
                onSave={onSave}
                asProject={asProject}
                onClose={handleServiceCallClose}
                projectParentId={props.projectParentId}
              />
            </div>
          </div>
        </Modal>
      )}
      {addCustomer && (
        <Modal open onClose={handleToggleAddCustomer(false)} className="Modal-Remove-Scroll">
          <CustomerEdit onClose={handleToggleAddCustomer(false)} onSave={handleCustomerSave} />
        </Modal>
      )}
    </div>
  );
};
