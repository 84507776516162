// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "projects_core.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "projects_core.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TimesheetDepartment } from "./timesheet_department";
// @generated message type with reflection information, may provide speed optimized methods
class Position$Type extends MessageType {
    constructor() {
        super("Position", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Position
 */
export const Position = new Position$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PositionList$Type extends MessageType {
    constructor() {
        super("PositionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Position results */ 1:
                    message.results.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Position results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Position.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PositionList
 */
export const PositionList = new PositionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingType$Type extends MessageType {
    constructor() {
        super("BillingType", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "billing_type_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, billingTypeName: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string billing_type_name */ 2:
                    message.billingTypeName = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string billing_type_name = 2; */
        if (message.billingTypeName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.billingTypeName);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingType
 */
export const BillingType = new BillingType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingTypeList$Type extends MessageType {
    constructor() {
        super("BillingTypeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BillingType },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BillingType results */ 1:
                    message.results.push(BillingType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated BillingType results = 1; */
        for (let i = 0; i < message.results.length; i++)
            BillingType.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingTypeList
 */
export const BillingTypeList = new BillingTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Area$Type extends MessageType {
    constructor() {
        super("Area", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "areas", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, areas: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string areas */ 2:
                    message.areas = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string areas = 2; */
        if (message.areas !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.areas);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Area
 */
export const Area = new Area$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AreaList$Type extends MessageType {
    constructor() {
        super("AreaList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Area },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Area results */ 1:
                    message.results.push(Area.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Area results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Area.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AreaList
 */
export const AreaList = new AreaList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectAttribute$Type extends MessageType {
    constructor() {
        super("ProjectAttribute", [
            { no: 1, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "area_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "scope_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "due_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "last_update", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { projectId: 0, areaId: 0, scopeId: 0, dueDate: "", statusId: 0, lastUpdate: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 project_id */ 1:
                    message.projectId = reader.int32();
                    break;
                case /* int32 area_id */ 2:
                    message.areaId = reader.int32();
                    break;
                case /* int32 scope_id */ 3:
                    message.scopeId = reader.int32();
                    break;
                case /* string due_date */ 4:
                    message.dueDate = reader.string();
                    break;
                case /* int32 status_id */ 5:
                    message.statusId = reader.int32();
                    break;
                case /* string last_update */ 6:
                    message.lastUpdate = reader.string();
                    break;
                case /* bool without_limit */ 7:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 project_id = 1; */
        if (message.projectId !== 0)
            writer.tag(1, WireType.Varint).int32(message.projectId);
        /* int32 area_id = 2; */
        if (message.areaId !== 0)
            writer.tag(2, WireType.Varint).int32(message.areaId);
        /* int32 scope_id = 3; */
        if (message.scopeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.scopeId);
        /* string due_date = 4; */
        if (message.dueDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.dueDate);
        /* int32 status_id = 5; */
        if (message.statusId !== 0)
            writer.tag(5, WireType.Varint).int32(message.statusId);
        /* string last_update = 6; */
        if (message.lastUpdate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.lastUpdate);
        /* bool without_limit = 7; */
        if (message.withoutLimit !== false)
            writer.tag(7, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectAttribute
 */
export const ProjectAttribute = new ProjectAttribute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectAttributeList$Type extends MessageType {
    constructor() {
        super("ProjectAttributeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectAttribute },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProjectAttribute results */ 1:
                    message.results.push(ProjectAttribute.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProjectAttribute results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ProjectAttribute.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectAttributeList
 */
export const ProjectAttributeList = new ProjectAttributeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductionKPI$Type extends MessageType {
    constructor() {
        super("ProductionKPI", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "kalos_project_number_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "safety", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "travel_efficiency", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "quality", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "historical", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "early_completion", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 9, name: "customer_satisfaction", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 10, name: "communication", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 11, name: "combined_score", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 12, name: "bonus_pool", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 13, name: "spiffs_paid", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 14, name: "remaining_bonus_pool", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 15, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, projectId: 0, kalosProjectNumberId: 0, safety: 0, travelEfficiency: 0, quality: 0, historical: 0, earlyCompletion: 0, customerSatisfaction: 0, communication: 0, combinedScore: 0, bonusPool: 0, spiffsPaid: 0, remainingBonusPool: 0, notes: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 project_id */ 2:
                    message.projectId = reader.int32();
                    break;
                case /* int32 kalos_project_number_id */ 3:
                    message.kalosProjectNumberId = reader.int32();
                    break;
                case /* float safety */ 4:
                    message.safety = reader.float();
                    break;
                case /* float travel_efficiency */ 5:
                    message.travelEfficiency = reader.float();
                    break;
                case /* float quality */ 6:
                    message.quality = reader.float();
                    break;
                case /* float historical */ 7:
                    message.historical = reader.float();
                    break;
                case /* float early_completion */ 8:
                    message.earlyCompletion = reader.float();
                    break;
                case /* float customer_satisfaction */ 9:
                    message.customerSatisfaction = reader.float();
                    break;
                case /* float communication */ 10:
                    message.communication = reader.float();
                    break;
                case /* float combined_score */ 11:
                    message.combinedScore = reader.float();
                    break;
                case /* float bonus_pool */ 12:
                    message.bonusPool = reader.float();
                    break;
                case /* float spiffs_paid */ 13:
                    message.spiffsPaid = reader.float();
                    break;
                case /* float remaining_bonus_pool */ 14:
                    message.remainingBonusPool = reader.float();
                    break;
                case /* string notes */ 15:
                    message.notes = reader.string();
                    break;
                case /* bool without_limit */ 16:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 17:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 18:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 project_id = 2; */
        if (message.projectId !== 0)
            writer.tag(2, WireType.Varint).int32(message.projectId);
        /* int32 kalos_project_number_id = 3; */
        if (message.kalosProjectNumberId !== 0)
            writer.tag(3, WireType.Varint).int32(message.kalosProjectNumberId);
        /* float safety = 4; */
        if (message.safety !== 0)
            writer.tag(4, WireType.Bit32).float(message.safety);
        /* float travel_efficiency = 5; */
        if (message.travelEfficiency !== 0)
            writer.tag(5, WireType.Bit32).float(message.travelEfficiency);
        /* float quality = 6; */
        if (message.quality !== 0)
            writer.tag(6, WireType.Bit32).float(message.quality);
        /* float historical = 7; */
        if (message.historical !== 0)
            writer.tag(7, WireType.Bit32).float(message.historical);
        /* float early_completion = 8; */
        if (message.earlyCompletion !== 0)
            writer.tag(8, WireType.Bit32).float(message.earlyCompletion);
        /* float customer_satisfaction = 9; */
        if (message.customerSatisfaction !== 0)
            writer.tag(9, WireType.Bit32).float(message.customerSatisfaction);
        /* float communication = 10; */
        if (message.communication !== 0)
            writer.tag(10, WireType.Bit32).float(message.communication);
        /* float combined_score = 11; */
        if (message.combinedScore !== 0)
            writer.tag(11, WireType.Bit32).float(message.combinedScore);
        /* float bonus_pool = 12; */
        if (message.bonusPool !== 0)
            writer.tag(12, WireType.Bit32).float(message.bonusPool);
        /* float spiffs_paid = 13; */
        if (message.spiffsPaid !== 0)
            writer.tag(13, WireType.Bit32).float(message.spiffsPaid);
        /* float remaining_bonus_pool = 14; */
        if (message.remainingBonusPool !== 0)
            writer.tag(14, WireType.Bit32).float(message.remainingBonusPool);
        /* string notes = 15; */
        if (message.notes !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.notes);
        /* bool without_limit = 16; */
        if (message.withoutLimit !== false)
            writer.tag(16, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 17; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 18; */
        if (message.pageNumber !== 0)
            writer.tag(18, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProductionKPI
 */
export const ProductionKPI = new ProductionKPI$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductionKPIList$Type extends MessageType {
    constructor() {
        super("ProductionKPIList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProductionKPI },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProductionKPI results */ 1:
                    message.results.push(ProductionKPI.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProductionKPI results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ProductionKPI.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProductionKPIList
 */
export const ProductionKPIList = new ProductionKPIList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectStatus$Type extends MessageType {
    constructor() {
        super("ProjectStatus", [
            { no: 1, name: "project_status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "project_status_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { projectStatusId: 0, projectStatusName: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 project_status_id */ 1:
                    message.projectStatusId = reader.int32();
                    break;
                case /* string project_status_name */ 2:
                    message.projectStatusName = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 project_status_id = 1; */
        if (message.projectStatusId !== 0)
            writer.tag(1, WireType.Varint).int32(message.projectStatusId);
        /* string project_status_name = 2; */
        if (message.projectStatusName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectStatusName);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectStatus
 */
export const ProjectStatus = new ProjectStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectStatusList$Type extends MessageType {
    constructor() {
        super("ProjectStatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectStatus },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProjectStatus results */ 1:
                    message.results.push(ProjectStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProjectStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ProjectStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectStatusList
 */
export const ProjectStatusList = new ProjectStatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectEmployeeHours$Type extends MessageType {
    constructor() {
        super("ProjectEmployeeHours", [
            { no: 1, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "hours_worked", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "position_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { projectId: 0, employeeId: 0, hoursWorked: 0, positionId: 0, withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 project_id */ 1:
                    message.projectId = reader.int32();
                    break;
                case /* int32 employee_id */ 2:
                    message.employeeId = reader.int32();
                    break;
                case /* int32 hours_worked */ 3:
                    message.hoursWorked = reader.int32();
                    break;
                case /* int32 position_id */ 4:
                    message.positionId = reader.int32();
                    break;
                case /* bool without_limit */ 5:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 project_id = 1; */
        if (message.projectId !== 0)
            writer.tag(1, WireType.Varint).int32(message.projectId);
        /* int32 employee_id = 2; */
        if (message.employeeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.employeeId);
        /* int32 hours_worked = 3; */
        if (message.hoursWorked !== 0)
            writer.tag(3, WireType.Varint).int32(message.hoursWorked);
        /* int32 position_id = 4; */
        if (message.positionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.positionId);
        /* bool without_limit = 5; */
        if (message.withoutLimit !== false)
            writer.tag(5, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectEmployeeHours
 */
export const ProjectEmployeeHours = new ProjectEmployeeHours$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectEmployeeHoursList$Type extends MessageType {
    constructor() {
        super("ProjectEmployeeHoursList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectEmployeeHours },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProjectEmployeeHours results */ 1:
                    message.results.push(ProjectEmployeeHours.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProjectEmployeeHours results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ProjectEmployeeHours.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectEmployeeHoursList
 */
export const ProjectEmployeeHoursList = new ProjectEmployeeHoursList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Project$Type extends MessageType {
    constructor() {
        super("Project", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "kalos_project_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "project_store_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "customer_project_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "project_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "project_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "project_start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "project_end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "project_square_footage", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "project_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "project_status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "billing_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "project_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "project_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "billing_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "customer", kind: "message", T: () => Customer },
            { no: 22, name: "department", kind: "message", T: () => TimesheetDepartment }
        ]);
    }
    create(value) {
        const message = { id: 0, kalosProjectNumber: 0, customerId: 0, projectStoreNumber: 0, customerProjectNumber: 0, projectAddress: "", projectDescription: "", projectStartDate: "", projectEndDate: "", projectSquareFootage: 0, projectTypeId: 0, projectStatusId: 0, billingTypeId: 0, departmentId: 0, withoutLimit: false, fieldMask: [], pageNumber: 0, projectStatus: "", projectType: "", billingType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 kalos_project_number */ 2:
                    message.kalosProjectNumber = reader.int32();
                    break;
                case /* int32 customer_id */ 3:
                    message.customerId = reader.int32();
                    break;
                case /* int32 project_store_number */ 4:
                    message.projectStoreNumber = reader.int32();
                    break;
                case /* int32 customer_project_number */ 5:
                    message.customerProjectNumber = reader.int32();
                    break;
                case /* string project_address */ 6:
                    message.projectAddress = reader.string();
                    break;
                case /* string project_description */ 7:
                    message.projectDescription = reader.string();
                    break;
                case /* string project_start_date */ 8:
                    message.projectStartDate = reader.string();
                    break;
                case /* string project_end_date */ 9:
                    message.projectEndDate = reader.string();
                    break;
                case /* int32 project_square_footage */ 10:
                    message.projectSquareFootage = reader.int32();
                    break;
                case /* int32 project_type_id */ 11:
                    message.projectTypeId = reader.int32();
                    break;
                case /* int32 project_status_id */ 12:
                    message.projectStatusId = reader.int32();
                    break;
                case /* int32 billing_type_id */ 13:
                    message.billingTypeId = reader.int32();
                    break;
                case /* int32 department_id */ 14:
                    message.departmentId = reader.int32();
                    break;
                case /* bool without_limit */ 15:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 16:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                case /* string project_status */ 18:
                    message.projectStatus = reader.string();
                    break;
                case /* string project_type */ 19:
                    message.projectType = reader.string();
                    break;
                case /* string billing_type */ 20:
                    message.billingType = reader.string();
                    break;
                case /* Customer customer */ 21:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* TimesheetDepartment department */ 22:
                    message.department = TimesheetDepartment.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 kalos_project_number = 2; */
        if (message.kalosProjectNumber !== 0)
            writer.tag(2, WireType.Varint).int32(message.kalosProjectNumber);
        /* int32 customer_id = 3; */
        if (message.customerId !== 0)
            writer.tag(3, WireType.Varint).int32(message.customerId);
        /* int32 project_store_number = 4; */
        if (message.projectStoreNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.projectStoreNumber);
        /* int32 customer_project_number = 5; */
        if (message.customerProjectNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.customerProjectNumber);
        /* string project_address = 6; */
        if (message.projectAddress !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.projectAddress);
        /* string project_description = 7; */
        if (message.projectDescription !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.projectDescription);
        /* string project_start_date = 8; */
        if (message.projectStartDate !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.projectStartDate);
        /* string project_end_date = 9; */
        if (message.projectEndDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.projectEndDate);
        /* int32 project_square_footage = 10; */
        if (message.projectSquareFootage !== 0)
            writer.tag(10, WireType.Varint).int32(message.projectSquareFootage);
        /* int32 project_type_id = 11; */
        if (message.projectTypeId !== 0)
            writer.tag(11, WireType.Varint).int32(message.projectTypeId);
        /* int32 project_status_id = 12; */
        if (message.projectStatusId !== 0)
            writer.tag(12, WireType.Varint).int32(message.projectStatusId);
        /* int32 billing_type_id = 13; */
        if (message.billingTypeId !== 0)
            writer.tag(13, WireType.Varint).int32(message.billingTypeId);
        /* int32 department_id = 14; */
        if (message.departmentId !== 0)
            writer.tag(14, WireType.Varint).int32(message.departmentId);
        /* bool without_limit = 15; */
        if (message.withoutLimit !== false)
            writer.tag(15, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 16; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        /* string project_status = 18; */
        if (message.projectStatus !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.projectStatus);
        /* string project_type = 19; */
        if (message.projectType !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.projectType);
        /* string billing_type = 20; */
        if (message.billingType !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.billingType);
        /* Customer customer = 21; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* TimesheetDepartment department = 22; */
        if (message.department)
            TimesheetDepartment.internalBinaryWrite(message.department, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Project
 */
export const Project = new Project$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectList$Type extends MessageType {
    constructor() {
        super("ProjectList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Project },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Project results */ 1:
                    message.results.push(Project.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Project results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Project.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectList
 */
export const ProjectList = new ProjectList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectType$Type extends MessageType {
    constructor() {
        super("ProjectType", [
            { no: 1, name: "project_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "project_type_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { projectTypeId: 0, projectTypeName: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 project_type_id */ 1:
                    message.projectTypeId = reader.int32();
                    break;
                case /* string project_type_name */ 2:
                    message.projectTypeName = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 project_type_id = 1; */
        if (message.projectTypeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.projectTypeId);
        /* string project_type_name = 2; */
        if (message.projectTypeName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectTypeName);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectType
 */
export const ProjectType = new ProjectType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectTypeList$Type extends MessageType {
    constructor() {
        super("ProjectTypeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectType },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProjectType results */ 1:
                    message.results.push(ProjectType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProjectType results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ProjectType.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectTypeList
 */
export const ProjectTypeList = new ProjectTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeOrder$Type extends MessageType {
    constructor() {
        super("ChangeOrder", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "kalos_project_number_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "change_order_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "change_order_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "change_order_labor_hours", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "change_order_direct_labor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "change_order_indirect_labor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "change_order_general_material", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "change_order_quoted_material", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "change_order_general_expense", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "change_order_equipment", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "change_order_subcontractor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "change_order_selling_price", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, projectId: 0, kalosProjectNumberId: 0, changeOrderDate: "", changeOrderDescription: "", changeOrderLaborHours: 0, changeOrderDirectLabor: 0, changeOrderIndirectLabor: 0, changeOrderGeneralMaterial: 0, changeOrderQuotedMaterial: 0, changeOrderGeneralExpense: 0, changeOrderEquipment: 0, changeOrderSubcontractor: 0, changeOrderSellingPrice: 0, withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 project_id */ 2:
                    message.projectId = reader.int32();
                    break;
                case /* int32 kalos_project_number_id */ 3:
                    message.kalosProjectNumberId = reader.int32();
                    break;
                case /* string change_order_date */ 4:
                    message.changeOrderDate = reader.string();
                    break;
                case /* string change_order_description */ 5:
                    message.changeOrderDescription = reader.string();
                    break;
                case /* int32 change_order_labor_hours */ 6:
                    message.changeOrderLaborHours = reader.int32();
                    break;
                case /* int32 change_order_direct_labor */ 7:
                    message.changeOrderDirectLabor = reader.int32();
                    break;
                case /* int32 change_order_indirect_labor */ 8:
                    message.changeOrderIndirectLabor = reader.int32();
                    break;
                case /* int32 change_order_general_material */ 9:
                    message.changeOrderGeneralMaterial = reader.int32();
                    break;
                case /* int32 change_order_quoted_material */ 10:
                    message.changeOrderQuotedMaterial = reader.int32();
                    break;
                case /* int32 change_order_general_expense */ 11:
                    message.changeOrderGeneralExpense = reader.int32();
                    break;
                case /* int32 change_order_equipment */ 12:
                    message.changeOrderEquipment = reader.int32();
                    break;
                case /* int32 change_order_subcontractor */ 13:
                    message.changeOrderSubcontractor = reader.int32();
                    break;
                case /* int32 change_order_selling_price */ 14:
                    message.changeOrderSellingPrice = reader.int32();
                    break;
                case /* bool without_limit */ 15:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 16:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 project_id = 2; */
        if (message.projectId !== 0)
            writer.tag(2, WireType.Varint).int32(message.projectId);
        /* int32 kalos_project_number_id = 3; */
        if (message.kalosProjectNumberId !== 0)
            writer.tag(3, WireType.Varint).int32(message.kalosProjectNumberId);
        /* string change_order_date = 4; */
        if (message.changeOrderDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.changeOrderDate);
        /* string change_order_description = 5; */
        if (message.changeOrderDescription !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.changeOrderDescription);
        /* int32 change_order_labor_hours = 6; */
        if (message.changeOrderLaborHours !== 0)
            writer.tag(6, WireType.Varint).int32(message.changeOrderLaborHours);
        /* int32 change_order_direct_labor = 7; */
        if (message.changeOrderDirectLabor !== 0)
            writer.tag(7, WireType.Varint).int32(message.changeOrderDirectLabor);
        /* int32 change_order_indirect_labor = 8; */
        if (message.changeOrderIndirectLabor !== 0)
            writer.tag(8, WireType.Varint).int32(message.changeOrderIndirectLabor);
        /* int32 change_order_general_material = 9; */
        if (message.changeOrderGeneralMaterial !== 0)
            writer.tag(9, WireType.Varint).int32(message.changeOrderGeneralMaterial);
        /* int32 change_order_quoted_material = 10; */
        if (message.changeOrderQuotedMaterial !== 0)
            writer.tag(10, WireType.Varint).int32(message.changeOrderQuotedMaterial);
        /* int32 change_order_general_expense = 11; */
        if (message.changeOrderGeneralExpense !== 0)
            writer.tag(11, WireType.Varint).int32(message.changeOrderGeneralExpense);
        /* int32 change_order_equipment = 12; */
        if (message.changeOrderEquipment !== 0)
            writer.tag(12, WireType.Varint).int32(message.changeOrderEquipment);
        /* int32 change_order_subcontractor = 13; */
        if (message.changeOrderSubcontractor !== 0)
            writer.tag(13, WireType.Varint).int32(message.changeOrderSubcontractor);
        /* int32 change_order_selling_price = 14; */
        if (message.changeOrderSellingPrice !== 0)
            writer.tag(14, WireType.Varint).int32(message.changeOrderSellingPrice);
        /* bool without_limit = 15; */
        if (message.withoutLimit !== false)
            writer.tag(15, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 16; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChangeOrder
 */
export const ChangeOrder = new ChangeOrder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeOrderList$Type extends MessageType {
    constructor() {
        super("ChangeOrderList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChangeOrder },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ChangeOrder results */ 1:
                    message.results.push(ChangeOrder.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ChangeOrder results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ChangeOrder.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChangeOrderList
 */
export const ChangeOrderList = new ChangeOrderList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Customer$Type extends MessageType {
    constructor() {
        super("Customer", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, customerName: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string customer_name */ 2:
                    message.customerName = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string customer_name = 2; */
        if (message.customerName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerName);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Customer
 */
export const Customer = new Customer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerList$Type extends MessageType {
    constructor() {
        super("CustomerList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Customer },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Customer results */ 1:
                    message.results.push(Customer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Customer results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Customer.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerList
 */
export const CustomerList = new CustomerList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Expended$Type extends MessageType {
    constructor() {
        super("Expended", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "expended_labor_hours", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "expended_direct_labor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "expended_indirect_labor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "expended_general_material", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "expended_quoted_material", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "expended_general_expense", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "expended_equipment", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "expended_subcontractor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "expended_miles", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "expended_miles_cost", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "expended_meals", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "expended_lodging", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "expended_spiffs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "avg_weekly_hours", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "expended_recorded_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, projectId: 0, expendedLaborHours: 0, expendedDirectLabor: 0, expendedIndirectLabor: 0, expendedGeneralMaterial: 0, expendedQuotedMaterial: 0, expendedGeneralExpense: 0, expendedEquipment: 0, expendedSubcontractor: 0, expendedMiles: 0, expendedMilesCost: 0, expendedMeals: 0, expendedLodging: 0, expendedSpiffs: 0, avgWeeklyHours: 0, expendedRecordedDate: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 project_id */ 2:
                    message.projectId = reader.int32();
                    break;
                case /* int32 expended_labor_hours */ 3:
                    message.expendedLaborHours = reader.int32();
                    break;
                case /* int32 expended_direct_labor */ 4:
                    message.expendedDirectLabor = reader.int32();
                    break;
                case /* int32 expended_indirect_labor */ 5:
                    message.expendedIndirectLabor = reader.int32();
                    break;
                case /* int32 expended_general_material */ 6:
                    message.expendedGeneralMaterial = reader.int32();
                    break;
                case /* int32 expended_quoted_material */ 7:
                    message.expendedQuotedMaterial = reader.int32();
                    break;
                case /* int32 expended_general_expense */ 8:
                    message.expendedGeneralExpense = reader.int32();
                    break;
                case /* int32 expended_equipment */ 9:
                    message.expendedEquipment = reader.int32();
                    break;
                case /* int32 expended_subcontractor */ 10:
                    message.expendedSubcontractor = reader.int32();
                    break;
                case /* int32 expended_miles */ 11:
                    message.expendedMiles = reader.int32();
                    break;
                case /* int32 expended_miles_cost */ 12:
                    message.expendedMilesCost = reader.int32();
                    break;
                case /* int32 expended_meals */ 13:
                    message.expendedMeals = reader.int32();
                    break;
                case /* int32 expended_lodging */ 14:
                    message.expendedLodging = reader.int32();
                    break;
                case /* int32 expended_spiffs */ 15:
                    message.expendedSpiffs = reader.int32();
                    break;
                case /* int32 avg_weekly_hours */ 16:
                    message.avgWeeklyHours = reader.int32();
                    break;
                case /* string expended_recorded_date */ 17:
                    message.expendedRecordedDate = reader.string();
                    break;
                case /* bool without_limit */ 18:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 19:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 20:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 project_id = 2; */
        if (message.projectId !== 0)
            writer.tag(2, WireType.Varint).int32(message.projectId);
        /* int32 expended_labor_hours = 3; */
        if (message.expendedLaborHours !== 0)
            writer.tag(3, WireType.Varint).int32(message.expendedLaborHours);
        /* int32 expended_direct_labor = 4; */
        if (message.expendedDirectLabor !== 0)
            writer.tag(4, WireType.Varint).int32(message.expendedDirectLabor);
        /* int32 expended_indirect_labor = 5; */
        if (message.expendedIndirectLabor !== 0)
            writer.tag(5, WireType.Varint).int32(message.expendedIndirectLabor);
        /* int32 expended_general_material = 6; */
        if (message.expendedGeneralMaterial !== 0)
            writer.tag(6, WireType.Varint).int32(message.expendedGeneralMaterial);
        /* int32 expended_quoted_material = 7; */
        if (message.expendedQuotedMaterial !== 0)
            writer.tag(7, WireType.Varint).int32(message.expendedQuotedMaterial);
        /* int32 expended_general_expense = 8; */
        if (message.expendedGeneralExpense !== 0)
            writer.tag(8, WireType.Varint).int32(message.expendedGeneralExpense);
        /* int32 expended_equipment = 9; */
        if (message.expendedEquipment !== 0)
            writer.tag(9, WireType.Varint).int32(message.expendedEquipment);
        /* int32 expended_subcontractor = 10; */
        if (message.expendedSubcontractor !== 0)
            writer.tag(10, WireType.Varint).int32(message.expendedSubcontractor);
        /* int32 expended_miles = 11; */
        if (message.expendedMiles !== 0)
            writer.tag(11, WireType.Varint).int32(message.expendedMiles);
        /* int32 expended_miles_cost = 12; */
        if (message.expendedMilesCost !== 0)
            writer.tag(12, WireType.Varint).int32(message.expendedMilesCost);
        /* int32 expended_meals = 13; */
        if (message.expendedMeals !== 0)
            writer.tag(13, WireType.Varint).int32(message.expendedMeals);
        /* int32 expended_lodging = 14; */
        if (message.expendedLodging !== 0)
            writer.tag(14, WireType.Varint).int32(message.expendedLodging);
        /* int32 expended_spiffs = 15; */
        if (message.expendedSpiffs !== 0)
            writer.tag(15, WireType.Varint).int32(message.expendedSpiffs);
        /* int32 avg_weekly_hours = 16; */
        if (message.avgWeeklyHours !== 0)
            writer.tag(16, WireType.Varint).int32(message.avgWeeklyHours);
        /* string expended_recorded_date = 17; */
        if (message.expendedRecordedDate !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.expendedRecordedDate);
        /* bool without_limit = 18; */
        if (message.withoutLimit !== false)
            writer.tag(18, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 19; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 20; */
        if (message.pageNumber !== 0)
            writer.tag(20, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Expended
 */
export const Expended = new Expended$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpendedList$Type extends MessageType {
    constructor() {
        super("ExpendedList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Expended },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Expended results */ 1:
                    message.results.push(Expended.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Expended results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Expended.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExpendedList
 */
export const ExpendedList = new ExpendedList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Schedule$Type extends MessageType {
    constructor() {
        super("Schedule", [
            { no: 1, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "scheduled_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "scheduled_start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "scheduled_end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "scheduled_comments", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "scheduled_last_updated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { projectId: 0, employeeId: 0, scheduledDate: "", scheduledStartTime: "", scheduledEndTime: "", scheduledComments: "", scheduledLastUpdated: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 project_id */ 1:
                    message.projectId = reader.int32();
                    break;
                case /* int32 employee_id */ 2:
                    message.employeeId = reader.int32();
                    break;
                case /* string scheduled_date */ 3:
                    message.scheduledDate = reader.string();
                    break;
                case /* string scheduled_start_time */ 4:
                    message.scheduledStartTime = reader.string();
                    break;
                case /* string scheduled_end_time */ 5:
                    message.scheduledEndTime = reader.string();
                    break;
                case /* string scheduled_comments */ 6:
                    message.scheduledComments = reader.string();
                    break;
                case /* string scheduled_last_updated */ 7:
                    message.scheduledLastUpdated = reader.string();
                    break;
                case /* bool without_limit */ 8:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 9:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 10:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 project_id = 1; */
        if (message.projectId !== 0)
            writer.tag(1, WireType.Varint).int32(message.projectId);
        /* int32 employee_id = 2; */
        if (message.employeeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.employeeId);
        /* string scheduled_date = 3; */
        if (message.scheduledDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.scheduledDate);
        /* string scheduled_start_time = 4; */
        if (message.scheduledStartTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.scheduledStartTime);
        /* string scheduled_end_time = 5; */
        if (message.scheduledEndTime !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.scheduledEndTime);
        /* string scheduled_comments = 6; */
        if (message.scheduledComments !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.scheduledComments);
        /* string scheduled_last_updated = 7; */
        if (message.scheduledLastUpdated !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.scheduledLastUpdated);
        /* bool without_limit = 8; */
        if (message.withoutLimit !== false)
            writer.tag(8, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 9; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 10; */
        if (message.pageNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Schedule
 */
export const Schedule = new Schedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScheduleList$Type extends MessageType {
    constructor() {
        super("ScheduleList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Schedule },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Schedule results */ 1:
                    message.results.push(Schedule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Schedule results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Schedule.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScheduleList
 */
export const ScheduleList = new ScheduleList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScopeSheet$Type extends MessageType {
    constructor() {
        super("ScopeSheet", [
            { no: 1, name: "scope_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "sheet_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { scopeId: 0, sheetId: 0, projectId: 0, withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 scope_id */ 1:
                    message.scopeId = reader.int32();
                    break;
                case /* int32 sheet_id */ 2:
                    message.sheetId = reader.int32();
                    break;
                case /* int32 project_id */ 3:
                    message.projectId = reader.int32();
                    break;
                case /* bool without_limit */ 4:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 scope_id = 1; */
        if (message.scopeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.scopeId);
        /* int32 sheet_id = 2; */
        if (message.sheetId !== 0)
            writer.tag(2, WireType.Varint).int32(message.sheetId);
        /* int32 project_id = 3; */
        if (message.projectId !== 0)
            writer.tag(3, WireType.Varint).int32(message.projectId);
        /* bool without_limit = 4; */
        if (message.withoutLimit !== false)
            writer.tag(4, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScopeSheet
 */
export const ScopeSheet = new ScopeSheet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScopeSheetList$Type extends MessageType {
    constructor() {
        super("ScopeSheetList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ScopeSheet },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ScopeSheet results */ 1:
                    message.results.push(ScopeSheet.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ScopeSheet results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ScopeSheet.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScopeSheetList
 */
export const ScopeSheetList = new ScopeSheetList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScopeTable$Type extends MessageType {
    constructor() {
        super("ScopeTable", [
            { no: 1, name: "scope_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "scope", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "frequency", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "last_update", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { scopeId: 0, scope: "", frequency: 0, lastUpdate: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 scope_id */ 1:
                    message.scopeId = reader.int32();
                    break;
                case /* string scope */ 2:
                    message.scope = reader.string();
                    break;
                case /* int32 frequency */ 3:
                    message.frequency = reader.int32();
                    break;
                case /* string last_update */ 4:
                    message.lastUpdate = reader.string();
                    break;
                case /* bool without_limit */ 5:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 scope_id = 1; */
        if (message.scopeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.scopeId);
        /* string scope = 2; */
        if (message.scope !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scope);
        /* int32 frequency = 3; */
        if (message.frequency !== 0)
            writer.tag(3, WireType.Varint).int32(message.frequency);
        /* string last_update = 4; */
        if (message.lastUpdate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastUpdate);
        /* bool without_limit = 5; */
        if (message.withoutLimit !== false)
            writer.tag(5, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScopeTable
 */
export const ScopeTable = new ScopeTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScopeTableList$Type extends MessageType {
    constructor() {
        super("ScopeTableList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ScopeTable },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ScopeTable results */ 1:
                    message.results.push(ScopeTable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ScopeTable results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ScopeTable.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ScopeTableList
 */
export const ScopeTableList = new ScopeTableList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sheet$Type extends MessageType {
    constructor() {
        super("Sheet", [
            { no: 1, name: "sheet_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "sheet_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { sheetId: 0, sheetName: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sheet_id */ 1:
                    message.sheetId = reader.int32();
                    break;
                case /* string sheet_name */ 2:
                    message.sheetName = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 sheet_id = 1; */
        if (message.sheetId !== 0)
            writer.tag(1, WireType.Varint).int32(message.sheetId);
        /* string sheet_name = 2; */
        if (message.sheetName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sheetName);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Sheet
 */
export const Sheet = new Sheet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SheetList$Type extends MessageType {
    constructor() {
        super("SheetList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sheet },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Sheet results */ 1:
                    message.results.push(Sheet.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Sheet results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Sheet.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SheetList
 */
export const SheetList = new SheetList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class System$Type extends MessageType {
    constructor() {
        super("System", [
            { no: 1, name: "system_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "systems", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { systemId: 0, systems: "", withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 system_id */ 1:
                    message.systemId = reader.int32();
                    break;
                case /* string systems */ 2:
                    message.systems = reader.string();
                    break;
                case /* bool without_limit */ 3:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 system_id = 1; */
        if (message.systemId !== 0)
            writer.tag(1, WireType.Varint).int32(message.systemId);
        /* string systems = 2; */
        if (message.systems !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.systems);
        /* bool without_limit = 3; */
        if (message.withoutLimit !== false)
            writer.tag(3, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message System
 */
export const System = new System$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SystemList$Type extends MessageType {
    constructor() {
        super("SystemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => System },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated System results */ 1:
                    message.results.push(System.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated System results = 1; */
        for (let i = 0; i < message.results.length; i++)
            System.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SystemList
 */
export const SystemList = new SystemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrelloCard$Type extends MessageType {
    constructor() {
        super("TrelloCard", [
            { no: 1, name: "board_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "card_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "job_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "job_check_items", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "job_check_items_checked", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { boardId: "", cardId: "", jobName: "", jobNumber: "", projectId: 0, jobCheckItems: 0, jobCheckItemsChecked: 0, withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string board_id */ 1:
                    message.boardId = reader.string();
                    break;
                case /* string card_id */ 2:
                    message.cardId = reader.string();
                    break;
                case /* string job_name */ 3:
                    message.jobName = reader.string();
                    break;
                case /* string job_number */ 4:
                    message.jobNumber = reader.string();
                    break;
                case /* int32 project_id */ 5:
                    message.projectId = reader.int32();
                    break;
                case /* int32 job_check_items */ 6:
                    message.jobCheckItems = reader.int32();
                    break;
                case /* int32 job_check_items_checked */ 7:
                    message.jobCheckItemsChecked = reader.int32();
                    break;
                case /* bool without_limit */ 8:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 9:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 10:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string board_id = 1; */
        if (message.boardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.boardId);
        /* string card_id = 2; */
        if (message.cardId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cardId);
        /* string job_name = 3; */
        if (message.jobName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.jobName);
        /* string job_number = 4; */
        if (message.jobNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.jobNumber);
        /* int32 project_id = 5; */
        if (message.projectId !== 0)
            writer.tag(5, WireType.Varint).int32(message.projectId);
        /* int32 job_check_items = 6; */
        if (message.jobCheckItems !== 0)
            writer.tag(6, WireType.Varint).int32(message.jobCheckItems);
        /* int32 job_check_items_checked = 7; */
        if (message.jobCheckItemsChecked !== 0)
            writer.tag(7, WireType.Varint).int32(message.jobCheckItemsChecked);
        /* bool without_limit = 8; */
        if (message.withoutLimit !== false)
            writer.tag(8, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 9; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 10; */
        if (message.pageNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TrelloCard
 */
export const TrelloCard = new TrelloCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrelloCardList$Type extends MessageType {
    constructor() {
        super("TrelloCardList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TrelloCard },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TrelloCard results */ 1:
                    message.results.push(TrelloCard.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TrelloCard results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TrelloCard.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TrelloCardList
 */
export const TrelloCardList = new TrelloCardList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Estimate$Type extends MessageType {
    constructor() {
        super("Estimate", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "kalos_project_number_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "estimate_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "estimate_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "estimate_labor_hours", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "estimate_direct_labor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "estimate_indirect_labor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "estimate_general_material", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "estimate_quoted_material", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "estimate_general_expense", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "estimate_equipment", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "estimate_subcontractor", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "estimate_selling_price", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "lock_sell_price", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "project", kind: "message", T: () => Project }
        ]);
    }
    create(value) {
        const message = { id: 0, projectId: 0, kalosProjectNumberId: 0, estimateDate: "", estimateDescription: "", estimateLaborHours: 0, estimateDirectLabor: 0, estimateIndirectLabor: 0, estimateGeneralMaterial: 0, estimateQuotedMaterial: 0, estimateGeneralExpense: 0, estimateEquipment: 0, estimateSubcontractor: 0, estimateSellingPrice: 0, lockSellPrice: false, withoutLimit: false, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 project_id */ 2:
                    message.projectId = reader.int32();
                    break;
                case /* int32 kalos_project_number_id */ 3:
                    message.kalosProjectNumberId = reader.int32();
                    break;
                case /* string estimate_date */ 4:
                    message.estimateDate = reader.string();
                    break;
                case /* string estimate_description */ 5:
                    message.estimateDescription = reader.string();
                    break;
                case /* int32 estimate_labor_hours */ 6:
                    message.estimateLaborHours = reader.int32();
                    break;
                case /* int32 estimate_direct_labor */ 7:
                    message.estimateDirectLabor = reader.int32();
                    break;
                case /* int32 estimate_indirect_labor */ 8:
                    message.estimateIndirectLabor = reader.int32();
                    break;
                case /* int32 estimate_general_material */ 9:
                    message.estimateGeneralMaterial = reader.int32();
                    break;
                case /* int32 estimate_quoted_material */ 10:
                    message.estimateQuotedMaterial = reader.int32();
                    break;
                case /* int32 estimate_general_expense */ 11:
                    message.estimateGeneralExpense = reader.int32();
                    break;
                case /* int32 estimate_equipment */ 12:
                    message.estimateEquipment = reader.int32();
                    break;
                case /* int32 estimate_subcontractor */ 13:
                    message.estimateSubcontractor = reader.int32();
                    break;
                case /* int32 estimate_selling_price */ 14:
                    message.estimateSellingPrice = reader.int32();
                    break;
                case /* bool lock_sell_price */ 15:
                    message.lockSellPrice = reader.bool();
                    break;
                case /* bool without_limit */ 16:
                    message.withoutLimit = reader.bool();
                    break;
                case /* repeated string field_mask */ 17:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 18:
                    message.pageNumber = reader.int32();
                    break;
                case /* Project project */ 19:
                    message.project = Project.internalBinaryRead(reader, reader.uint32(), options, message.project);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 project_id = 2; */
        if (message.projectId !== 0)
            writer.tag(2, WireType.Varint).int32(message.projectId);
        /* int32 kalos_project_number_id = 3; */
        if (message.kalosProjectNumberId !== 0)
            writer.tag(3, WireType.Varint).int32(message.kalosProjectNumberId);
        /* string estimate_date = 4; */
        if (message.estimateDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.estimateDate);
        /* string estimate_description = 5; */
        if (message.estimateDescription !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.estimateDescription);
        /* int32 estimate_labor_hours = 6; */
        if (message.estimateLaborHours !== 0)
            writer.tag(6, WireType.Varint).int32(message.estimateLaborHours);
        /* int32 estimate_direct_labor = 7; */
        if (message.estimateDirectLabor !== 0)
            writer.tag(7, WireType.Varint).int32(message.estimateDirectLabor);
        /* int32 estimate_indirect_labor = 8; */
        if (message.estimateIndirectLabor !== 0)
            writer.tag(8, WireType.Varint).int32(message.estimateIndirectLabor);
        /* int32 estimate_general_material = 9; */
        if (message.estimateGeneralMaterial !== 0)
            writer.tag(9, WireType.Varint).int32(message.estimateGeneralMaterial);
        /* int32 estimate_quoted_material = 10; */
        if (message.estimateQuotedMaterial !== 0)
            writer.tag(10, WireType.Varint).int32(message.estimateQuotedMaterial);
        /* int32 estimate_general_expense = 11; */
        if (message.estimateGeneralExpense !== 0)
            writer.tag(11, WireType.Varint).int32(message.estimateGeneralExpense);
        /* int32 estimate_equipment = 12; */
        if (message.estimateEquipment !== 0)
            writer.tag(12, WireType.Varint).int32(message.estimateEquipment);
        /* int32 estimate_subcontractor = 13; */
        if (message.estimateSubcontractor !== 0)
            writer.tag(13, WireType.Varint).int32(message.estimateSubcontractor);
        /* int32 estimate_selling_price = 14; */
        if (message.estimateSellingPrice !== 0)
            writer.tag(14, WireType.Varint).int32(message.estimateSellingPrice);
        /* bool lock_sell_price = 15; */
        if (message.lockSellPrice !== false)
            writer.tag(15, WireType.Varint).bool(message.lockSellPrice);
        /* bool without_limit = 16; */
        if (message.withoutLimit !== false)
            writer.tag(16, WireType.Varint).bool(message.withoutLimit);
        /* repeated string field_mask = 17; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 18; */
        if (message.pageNumber !== 0)
            writer.tag(18, WireType.Varint).int32(message.pageNumber);
        /* Project project = 19; */
        if (message.project)
            Project.internalBinaryWrite(message.project, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Estimate
 */
export const Estimate = new Estimate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EstimateList$Type extends MessageType {
    constructor() {
        super("EstimateList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Estimate },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Estimate results */ 1:
                    message.results.push(Estimate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Estimate results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Estimate.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EstimateList
 */
export const EstimateList = new EstimateList$Type();
/**
 * @generated ServiceType for protobuf service ProjectsCoreService
 */
export const ProjectsCoreService = new ServiceType("ProjectsCoreService", [
    { name: "CreateArea", options: {}, I: Area, O: Area },
    { name: "GetArea", options: {}, I: Area, O: Area },
    { name: "BatchGetArea", options: {}, I: Area, O: AreaList },
    { name: "UpdateArea", options: {}, I: Area, O: Area },
    { name: "CreateBillingType", options: {}, I: BillingType, O: BillingType },
    { name: "GetBillingType", options: {}, I: BillingType, O: BillingType },
    { name: "BatchGetBillingType", options: {}, I: BillingType, O: BillingTypeList },
    { name: "UpdateBillingType", options: {}, I: BillingType, O: BillingType },
    { name: "CreatePosition", options: {}, I: Position, O: Position },
    { name: "GetPosition", options: {}, I: Position, O: Position },
    { name: "BatchGetPosition", options: {}, I: Position, O: PositionList },
    { name: "UpdatePosition", options: {}, I: Position, O: Position },
    { name: "CreateProjectAttribute", options: {}, I: ProjectAttribute, O: ProjectAttribute },
    { name: "GetProjectAttribute", options: {}, I: ProjectAttribute, O: ProjectAttribute },
    { name: "BatchGetProjectAttribute", options: {}, I: ProjectAttribute, O: ProjectAttributeList },
    { name: "UpdateProjectAttribute", options: {}, I: ProjectAttribute, O: ProjectAttribute },
    { name: "CreateProductionKPI", options: {}, I: ProductionKPI, O: ProductionKPI },
    { name: "GetProductionKPI", options: {}, I: ProductionKPI, O: ProductionKPI },
    { name: "BatchGetProductionKPI", options: {}, I: ProductionKPI, O: ProductionKPIList },
    { name: "UpdateProductionKPI", options: {}, I: ProductionKPI, O: ProductionKPI },
    { name: "CreateProjectStatus", options: {}, I: ProjectStatus, O: ProjectStatus },
    { name: "GetProjectStatus", options: {}, I: ProjectStatus, O: ProjectStatus },
    { name: "BatchGetProjectStatus", options: {}, I: ProjectStatus, O: ProjectStatusList },
    { name: "UpdateProjectStatus", options: {}, I: ProjectStatus, O: ProjectStatus },
    { name: "CreateProjectEmployeeHours", options: {}, I: ProjectEmployeeHours, O: ProjectEmployeeHours },
    { name: "GetProjectEmployeeHours", options: {}, I: ProjectEmployeeHours, O: ProjectEmployeeHours },
    { name: "BatchGetProjectEmployeeHours", options: {}, I: ProjectEmployeeHours, O: ProjectEmployeeHoursList },
    { name: "UpdateProjectEmployeeHours", options: {}, I: ProjectEmployeeHours, O: ProjectEmployeeHours },
    { name: "CreateProject", options: {}, I: Project, O: Project },
    { name: "GetProject", options: {}, I: Project, O: Project },
    { name: "BatchGetProject", options: {}, I: Project, O: ProjectList },
    { name: "UpdateProject", options: {}, I: Project, O: Project },
    { name: "CreateProjectType", options: {}, I: ProjectType, O: ProjectType },
    { name: "GetProjectType", options: {}, I: ProjectType, O: ProjectType },
    { name: "BatchGetProjectType", options: {}, I: ProjectType, O: ProjectTypeList },
    { name: "UpdateProjectType", options: {}, I: ProjectType, O: ProjectType },
    { name: "CreateChangeOrder", options: {}, I: ChangeOrder, O: ChangeOrder },
    { name: "GetChangeOrder", options: {}, I: ChangeOrder, O: ChangeOrder },
    { name: "BatchGetChangeOrder", options: {}, I: ChangeOrder, O: ChangeOrderList },
    { name: "UpdateChangeOrder", options: {}, I: ChangeOrder, O: ChangeOrder },
    { name: "CreateCustomer", options: {}, I: Customer, O: Customer },
    { name: "GetCustomer", options: {}, I: Customer, O: Customer },
    { name: "BatchGetCustomer", options: {}, I: Customer, O: CustomerList },
    { name: "UpdateCustomer", options: {}, I: Customer, O: Customer },
    { name: "CreateExpended", options: {}, I: Expended, O: Expended },
    { name: "GetExpended", options: {}, I: Expended, O: Expended },
    { name: "BatchGetExpended", options: {}, I: Expended, O: ExpendedList },
    { name: "UpdateExpended", options: {}, I: Expended, O: Expended },
    { name: "CreateSchedule", options: {}, I: Schedule, O: Schedule },
    { name: "GetSchedule", options: {}, I: Schedule, O: Schedule },
    { name: "BatchGetSchedule", options: {}, I: Schedule, O: ScheduleList },
    { name: "UpdateSchedule", options: {}, I: Schedule, O: Schedule },
    { name: "CreateScopeSheet", options: {}, I: ScopeSheet, O: ScopeSheet },
    { name: "GetScopeSheet", options: {}, I: ScopeSheet, O: ScopeSheet },
    { name: "BatchGetScopeSheet", options: {}, I: ScopeSheet, O: ScopeSheetList },
    { name: "UpdateScopeSheet", options: {}, I: ScopeSheet, O: ScopeSheet },
    { name: "CreateScopeTable", options: {}, I: ScopeTable, O: ScopeTable },
    { name: "GetScopeTable", options: {}, I: ScopeTable, O: ScopeTable },
    { name: "BatchGetScopeTable", options: {}, I: ScopeTable, O: ScopeTableList },
    { name: "UpdateScopeTable", options: {}, I: ScopeTable, O: ScopeTable },
    { name: "CreateSheet", options: {}, I: Sheet, O: Sheet },
    { name: "GetSheet", options: {}, I: Sheet, O: Sheet },
    { name: "BatchGetSheet", options: {}, I: Sheet, O: SheetList },
    { name: "UpdateSheet", options: {}, I: Sheet, O: Sheet },
    { name: "CreateSystem", options: {}, I: System, O: System },
    { name: "GetSystem", options: {}, I: System, O: System },
    { name: "BatchGetSystem", options: {}, I: System, O: SystemList },
    { name: "UpdateSystem", options: {}, I: System, O: System },
    { name: "CreateTrelloCard", options: {}, I: TrelloCard, O: TrelloCard },
    { name: "GetTrelloCard", options: {}, I: TrelloCard, O: TrelloCard },
    { name: "BatchGetTrelloCard", options: {}, I: TrelloCard, O: TrelloCardList },
    { name: "UpdateTrelloCard", options: {}, I: TrelloCard, O: TrelloCard },
    { name: "CreateEstimate", options: {}, I: Estimate, O: Estimate },
    { name: "GetEstimate", options: {}, I: Estimate, O: Estimate },
    { name: "BatchGetEstimate", options: {}, I: Estimate, O: EstimateList },
    { name: "UpdateEstimate", options: {}, I: Estimate, O: Estimate },
    { name: "DeleteEstimate", options: {}, I: Estimate, O: Estimate }
]);
