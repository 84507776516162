import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { PrintStripeInvoiceReceipt } from '../modules/PrintStripeInvoiceReceipt/main';

export const Route = createFileRoute('/stripe/success/$token')({
  component: RouteComponent,
  params: z.object({
    token: z.string(),
  }),
});

function RouteComponent() {
  const { token } = Route.useParams();
  return <PrintStripeInvoiceReceipt token={token} />;
}
