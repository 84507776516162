import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { EditEmployeeFunction } from '../modules/ComponentsLibrary/EmployeeDepartments/EditEmployeeFunction';

export const Route = createFileRoute('/departments/edit/$functionId')({
  component: RouteComponent,
  params: z.object({
    functionId: z.coerce.number().positive(),
  }),
});

function RouteComponent() {
  const { functionId } = Route.useParams();
  return <EditEmployeeFunction functionId={functionId} />;
}
