import { NULL_TIME, type Requisition } from '@kalos/kalos-rpc';
import { Button, Dialog, DialogContent, DialogTitle, ScrollArea, useAlert } from '@kalos/ui';
import { useState } from 'react';

import { useAuth } from '../../../context/AuthContext';
import { useBatchGetAssetInstanceQuery } from '../../../hooks/react-query/useAssetInstanceQuery';
import { useRequisitionCreateMutation } from '../../../hooks/react-query/useToolFundQuery';
import { RequisitionForm } from './RequisitionForm';

interface RequisitionFormDialogProps {
  userId: number;
}

export const RequisitionFormDialog = ({ userId }: RequisitionFormDialogProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const createRequisition = useRequisitionCreateMutation();
  const { user } = useAuth();
  const isManager = user.permissionGroups.some((group) => group.name === 'Manager');

  const handleSubmit = async (data: Requisition) => {
    try {
      await createRequisition.mutateAsync(data);
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error submitting requisition:', error);
    }
  };

  const userAssetQuery = useBatchGetAssetInstanceQuery({
    filters: {
      currentUserId: userId,
      acquisitionDate: NULL_TIME,
    },
    select(data) {
      return { doesUserHavePendingReceiveItems: data.totalCount > 0 };
    },
  });

  const alert = useAlert();

  const onTriggerClick = () => {
    if (!userAssetQuery.isSuccess) throw new Error('Flow should be impossible');
    if (userAssetQuery.data.doesUserHavePendingReceiveItems) {
      alert({
        title: 'You have not received tools that are approved',
        body: 'Please confirm that you have received all approved tools before requesting a new tool.',
        cancelButton: 'OK',
      });
      return;
    }
    setIsDialogOpen(true);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen} modal={true}>
      <Button
        disabled={!userAssetQuery.isSuccess}
        isLoading={userAssetQuery.isPending}
        onClick={onTriggerClick}
        size="sm"
      >
        New Tool Request
      </Button>
      <DialogContent
        className="h-svh max-w-[100vw] overflow-hidden p-0 md:h-[80svh] md:max-w-screen-sm"
        onPointerDownOutside={(e) => {
          e.preventDefault();
          if (
            e.target instanceof Element &&
            (e.target.closest('.pac-container') || e.target.closest('.pac-item'))
          ) {
            e.preventDefault();
          }
        }}
      >
        <ScrollArea className="h-full px-2 pb-4 md:px-4 [&>div]:block">
          <DialogTitle className="py-2 md:py-6">Request New Tool</DialogTitle>
          <RequisitionForm
            userId={userId}
            onSubmit={handleSubmit}
            isSubmitting={createRequisition.isPending}
            ignoreTradeRestriction={isManager}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
