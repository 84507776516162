/**
 * Helper functions for converting HEIC images to JPEG format
 * for use in the FileUploader component
 */

/**
 * Checks if a file is a HEIC format image
 */
export function isHeicImage(file: File): boolean {
  return (
    file.type === 'image/heic' ||
    file.type === 'image/heif' ||
    file.name.toLowerCase().endsWith('.heic') ||
    file.name.toLowerCase().endsWith('.heif')
  );
}

/**
 * Converts a HEIC file to JPEG format
 */
export async function convertHeicToJpegForPreview(
  file: File,
  options: {
    quality?: number;
    signal?: AbortSignal;
  } = {},
): Promise<File> {
  // If not a HEIC file, return the original file
  if (!isHeicImage(file)) {
    return file;
  }

  try {
    // Load the heic-convert library dynamically
    const heic2any = await import('heic2any').then((m) => m.default);

    // Convert the HEIC file to JPEG
    const jpegBlob = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: options.quality || 0.8,
      // @ts-expect-error - signal is not in the types but supported
      signal: options.signal,
    });

    // Create a new file with the converted blob
    const convertedFile = new File(
      [jpegBlob as Blob],
      file.name.replace(/\.(heic|heif)$/i, '.jpg'),
      { type: 'image/jpeg' },
    );

    return convertedFile;
  } catch (error) {
    // If conversion fails or is aborted, return the original file
    if (error instanceof Error && error.message === 'Conversion aborted') {
      throw error;
    }

    console.warn('HEIC conversion failed:', error);
    return file;
  }
}

/**
 * Creates an abortable HEIC conversion
 */
export function createAbortableHeicConversion() {
  const controller = new AbortController();
  return {
    convert: (file: File) => convertHeicToJpegForPreview(file, { signal: controller.signal }),
    abort: () => controller.abort(),
  };
}

/**
 * Handles file preview for the FileUploader component
 * This will convert HEIC images to JPEG for preview
 */
export async function appendHeicPreview(file: File): Promise<File & { preview: string }> {
  try {
    // If it's a HEIC file, convert it first
    const processedFile = isHeicImage(file) ? await convertHeicToJpegForPreview(file) : file;

    // Append the preview URL
    return Object.assign(processedFile, {
      preview: URL.createObjectURL(processedFile),
    });
  } catch (error) {
    console.error('Error creating preview for file:', error);

    // If there's an error, still try to create a preview from the original file
    return Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
  }
}
