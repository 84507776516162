import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../utils';

const cardVariants = cva('rounded-xl border shadow', {
  variants: {
    variant: {
      default: 'bg-card text-card-foreground ',
      light: 'bg-gray-50 text-card-foreground',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface CardProps extends React.ComponentProps<'div'>, VariantProps<typeof cardVariants> {
  asChild?: boolean;
}

const Card = ({ className, variant, ...props }: CardProps) => (
  <div className={cn(cardVariants({ variant, className }))} {...props} />
);
Card.displayName = 'Card';

const CardSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse">
      <Card className="h-48">
        <div className="mx-4 mt-4 h-6 rounded bg-gray-200"></div>
        <div className="mx-4 mt-4 h-6 rounded bg-gray-200"></div>
        <div className="mx-4 mt-4 h-6 rounded bg-gray-200"></div>
      </Card>
    </div>
  );
};

const CardHeader = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div className={cn('flex flex-col space-y-1.5 p-6', className)} {...props} />
);
CardHeader.displayName = 'CardHeader';

const CardTitle = ({ className, ...props }: React.ComponentProps<'h3'>) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3 className={cn('font-semibold leading-none tracking-tight', className)} {...props} />
);
CardTitle.displayName = 'CardTitle';

const CardDescription = ({ className, ...props }: React.ComponentProps<'p'>) => (
  <p className={cn('text-muted-foreground text-sm', className)} {...props} />
);
CardDescription.displayName = 'CardDescription';

const CardContent = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div className={cn('p-6 pt-0', className)} {...props} />
);
CardContent.displayName = 'CardContent';

const CardFooter = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div className={cn('flex items-center p-6 pt-0', className)} {...props} />
);
CardFooter.displayName = 'CardFooter';

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent, CardSkeleton };
