import {
  type ClientInfo,
  type ClientSession,
  type Notification as SoftphoneNotification,
  type SoftphoneClient,
  type SubscribeRequest,
} from '@kalos/kalos-rpc';
import { type ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import { createStore } from 'zustand';

import { softphoneLog } from '../SoftPhoneWidget/components/utils';
import { messageBus } from './SoftphoneMessageBus';

const NOTIFICATION_CHANNEL = 'kalos-softphone-notifications';

export type SoftPhoneStore = {
  client: SoftphoneClient;
  session: ClientSession | null;
  isSessionLoading: boolean;
  eventStream: ServerStreamingCall<SubscribeRequest, SoftphoneNotification> | null;
  notificationChannel: BroadcastChannel | null;
  createSession: (args: { userId: number; clientInfo: ClientInfo }) => Promise<ClientSession>;
  terminateSession: (args: { sessionId: string }) => Promise<void>;
  deactivateEventStream: () => Promise<void>;
  initializeNotificationChannel: () => void;
  cleanupNotificationChannel: () => void;
  setupEventStream: (sessionId: string) => void;
  cleanupEventStream: () => void;
  ensureEventStream: () => Promise<void>;
};

export const createSoftPhoneStore = (client: SoftphoneClient) =>
  createStore<SoftPhoneStore>()((set, get) => {
    // Subscribe to state updates from other tabs/windows
    const unsubscribe = messageBus.subscribe((message) => {
      if (message.type === 'STATE_UPDATE') {
        if ('requestState' in message.data) {
          // If this is the main window and we have a session, broadcast it
          if (window.name !== 'KalosSoftphone' && get().session) {
            messageBus.broadcast({
              type: 'STATE_UPDATE',
              data: { session: get().session },
            });
          }
        } else if ('session' in message.data) {
          set({ session: message.data.session });
        }
      }
    });

    // Cleanup subscription when store is destroyed
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', unsubscribe);
    }

    // Set up client event listeners
    client.on('message', (message) => {
      messageBus.broadcast({
        type: 'SOFTPHONE_EVENT',
        data: {
          type: message.type,
          metadata: message.metadata,
          callId: message.callId,
          fromNumber: message.fromNumber,
          callerName: message.callerName,
          timestamp: message.timestamp?.toString(),
        },
      });
    });

    client.on('streamFailed', ({ sessionId, error }) => {
      messageBus.broadcast({
        type: 'STREAM_ERROR',
        data: { error: error.message },
      });
    });

    return {
      client,
      session: null,
      isSessionLoading: false,
      eventStream: null,
      notificationChannel: null,

      initializeNotificationChannel: () => {
        try {
          const channel = new BroadcastChannel(NOTIFICATION_CHANNEL);
          set({ notificationChannel: channel });
          softphoneLog('Notification channel initialized');
        } catch (error) {
          softphoneLog('Failed to initialize notification channel:', error);
        }
      },

      cleanupNotificationChannel: () => {
        const channel = get().notificationChannel;
        if (channel) {
          channel.close();
          set({ notificationChannel: null });
          softphoneLog('Notification channel cleaned up');
        }
      },

      setupEventStream: (sessionId: string) => {
        // Only set up event stream in main window
        if (window.name === 'KalosSoftphone') {
          softphoneLog('Skipping event stream setup in popup window');
          return;
        }

        softphoneLog('Setting up event stream for session:', sessionId);
        const subscription = client.SubscribeToEvents(sessionId);
        set({ eventStream: subscription });
      },

      cleanupEventStream: () => {
        const state = get();
        if (state.eventStream) {
          softphoneLog('Cleaning up event stream');
          set({ eventStream: null });
        }
      },

      createSession: async ({ userId, clientInfo }) => {
        const state = get();
        // Clean up any existing session and stream
        state.cleanupEventStream();

        try {
          softphoneLog('Initializing client session:', { userId, clientInfo });
          const session = await client.InitializeClient(userId, clientInfo);

          if (!session || !session.sessionId) {
            throw new Error('Invalid session response');
          }

          // Update state and broadcast first
          set({ session, isSessionLoading: false });
          messageBus.broadcast({
            type: 'STATE_UPDATE',
            data: { session },
          });

          // Then set up event stream only in main window
          if (window.name !== 'KalosSoftphone') {
            softphoneLog('Setting up event stream for new session:', session.sessionId);
            state.setupEventStream(session.sessionId);
          }

          return session;
        } catch (error) {
          softphoneLog('Failed to create session:', error);
          set({ isSessionLoading: false, session: null });
          throw error;
        }
      },

      terminateSession: async ({ sessionId }) => {
        const state = get();
        if (!client) throw new Error('terminateSession: Client not initialized');

        softphoneLog('Terminating session:', sessionId);
        try {
          // Clean up event stream first
          state.cleanupEventStream();

          // Then terminate the session
          await client.TerminateClient(sessionId);
          set({ session: null });

          messageBus.broadcast({
            type: 'STATE_UPDATE',
            data: { session: null },
          });
        } catch (error) {
          softphoneLog('Error terminating session:', error);
          set({ session: null });
          throw error;
        }
      },

      deactivateEventStream: async () => {
        const state = get();
        softphoneLog('Deactivating session');
        state.cleanupEventStream();
      },

      ensureEventStream: async () => {
        const state = get();
        const session = state.session;
        if (!session) throw new Error('Cannot setup event stream: No active session');

        if (!state.eventStream) {
          softphoneLog('Setting up event stream for session:', session.sessionId);
          state.setupEventStream(session.sessionId);
        }
      },
    };
  });
