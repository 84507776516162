import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type Path, useForm } from 'react-hook-form';
import { type z, ZodEffects, ZodNumber, ZodOptional, ZodString } from 'zod';

import { FORM_SEARCH_PARAMS_SYNC_DEBOUNCE_TIME } from '../constants';
import { debounce } from '../tools/helpers';

type BaseZodType = z.ZodString | z.ZodNumber | z.ZodDate | z.ZodBoolean;
type SchemaEntryValidator =
  | BaseZodType
  | z.ZodOptional<BaseZodType>
  | z.ZodEffects<BaseZodType>
  | z.ZodEffects<z.ZodOptional<BaseZodType>, any, unknown>;

const narrowSchema = (
  schema: SchemaEntryValidator,
): { schema: BaseZodType; wasOptional: boolean; wasEffect: boolean } => {
  if (schema instanceof ZodOptional) {
    return { schema: schema._def.innerType, wasEffect: false, wasOptional: true };
  }

  if (schema instanceof ZodEffects) {
    const tempSchema = schema._def.schema;
    return {
      schema: tempSchema instanceof ZodOptional ? tempSchema._def.innerType : tempSchema,
      wasEffect: true,
      wasOptional: tempSchema instanceof ZodOptional ? true : false,
    };
  }

  return {
    schema,
    wasOptional: false,
    wasEffect: false,
  };
};

/**
 *
 * @param schema - will be used to parse the search params and generate default values form it
 * @param defaultValues - will be used as a base object. Please make sure to memoize it properly!!!
 */
export const useGetDefaultSearchFormValues = <
  SchemaType extends z.ZodObject<
    { [x: string]: SchemaEntryValidator },
    any,
    any,
    {
      [x: string]: any;
    },
    {
      [x: string]: any;
    }
  >,
>(
  schema: SchemaType,
  defaultValues?: z.infer<SchemaType>,
) => {
  const searchParams = useSearch({ strict: false });
  const [defaultValueFromSearchParams] = useState(() => {
    const parsedSchema: Partial<z.infer<SchemaType>> = {};
    Object.entries(schema.shape).forEach(([key, schema]) => {
      const value = searchParams[key as keyof typeof searchParams];
      if (typeof value === 'undefined') return;
      const parsedValue = value;
      Object.assign(parsedSchema, { [key]: parsedValue });
    });

    return parsedSchema;
  });

  return useMemo(
    () => ({ ...defaultValues, ...defaultValueFromSearchParams }),
    [defaultValueFromSearchParams, defaultValues],
  );
};

export const useSearchParamsForm = <
  SchemaType extends z.ZodObject<
    { [x: string]: SchemaEntryValidator },
    any,
    any,
    {
      [x: string]: any;
    },
    {
      [x: string]: any;
    }
  >,
>({
  schema,
  disableParamsSync = false,
  defaultResetValues,
  defaultValues,
  ...params
}: { schema: SchemaType; defaultResetValues?: Partial<z.infer<SchemaType>> } & Parameters<
  typeof useForm<z.infer<SchemaType>>
>['0'] & {
    disableParamsSync?: boolean;
  }) => {
  type SchemaObject = z.infer<SchemaType>;
  const form = useForm<SchemaObject>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'onChange',
    ...params,
  });

  const initializationRef = useRef(false);

  const searchParams = useSearch({ strict: false });
  const navigate = useNavigate();

  // sync form with search params
  useEffect(() => {
    const currentFormValues = form.getValues();
    const currentSearchParamsValues = searchParams;
    Object.entries(currentFormValues).forEach(([key, value]) => {
      const searchParamValue =
        currentSearchParamsValues[key as keyof typeof currentSearchParamsValues];
      const { schema: zodSchema } = narrowSchema(schema.shape[key]);
      if (searchParamValue === undefined) {
        if (typeof defaultValues === 'object') {
          if (
            (zodSchema instanceof ZodString && value !== '') ||
            (zodSchema instanceof ZodNumber && value !== 0)
          ) {
            const newValue = defaultValues?.[key] as SchemaObject[keyof SchemaObject];
            if (value === newValue) return;
            form.setValue(key as Path<SchemaObject>, newValue);
          }
        }

        return;
      }

      const parsedValue = searchParamValue;
      if (parsedValue === value) return;
      form.setValue(key as Path<SchemaObject>, parsedValue as SchemaObject[keyof SchemaObject]);
    });
  }, [defaultValues, form, schema.shape, searchParams]);

  // sync search params with form
  useEffect(() => {
    if (disableParamsSync) return;

    const subscription = form.watch(
      debounce((data: Partial<SchemaObject>) => {
        navigate({
          to: '.',
          search: {
            ...searchParams,
            ...data,
          },
        });
      }, FORM_SEARCH_PARAMS_SYNC_DEBOUNCE_TIME),
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [disableParamsSync, form, navigate, searchParams]);

  // initialize form with search params
  useEffect(() => {
    if (!initializationRef.current && !disableParamsSync) {
      const parsedSchema: Partial<z.infer<SchemaType>> = {};
      Object.entries(schema.shape).forEach(([key, schema]) => {
        const value = searchParams[key as keyof typeof searchParams];
        if (!value) return;
        const parsedValue = value;
        Object.assign(parsedSchema, { [key]: parsedValue });
      });
      form.reset(parsedSchema);
      initializationRef.current = true;
    }
  }, [disableParamsSync, form, schema.shape, searchParams]);

  const _reset = useMemo(() => {
    if (!defaultResetValues) return form.reset;

    return (
      values?: Parameters<typeof form.reset>['0'],
      keepStateOptions?: Parameters<typeof form.reset>['1'],
    ) => {
      form.reset({ ...defaultResetValues, ...values }, keepStateOptions);
    };
  }, [defaultResetValues, form]);

  return useMemo(() => ({ ...form, reset: _reset }), [_reset, form]);
};
