import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { HRDashBoard } from '../../modules/ComponentsLibrary/HRDashboard';
import { hrDashboardTabsIds } from '../../modules/ComponentsLibrary/HRDashboard/constants';

export const Route = createFileRoute('/hrDashboard/$prId')({
  component: RouteComponent,
  params: z.object({
    prId: z.coerce.number().positive(),
  }),
  validateSearch: z.object({
    defaultTabId: z.enum(hrDashboardTabsIds).optional(),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'HR Dashboard' }],
    };
  },
});

function RouteComponent() {
  const params = Route.useParams();
  const searchParams = Route.useSearch();
  return <HRDashBoard prId={params.prId} defaultTabId={searchParams.defaultTabId || '0'} />;
}
