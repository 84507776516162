import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';
import { z } from 'zod';

import { useAuth } from '../context/AuthContext';

export const Route = createFileRoute('/')({
  component: RouteComponent,
  validateSearch: z.object({
    from: z.string().optional(),
  }),
  loader(ctx) {
    let state: 'loading' | 'login' | 'authenticated' = 'loading';
    if (ctx.context.authContext.authChecked) {
      state = ctx.context.authContext.user.id ? 'authenticated' : 'login';
    }
    return { isLoggedIn: state };
  },
  head(ctx) {
    let title = 'Kalos Dashboard';
    if (ctx.loaderData.isLoggedIn === 'login') {
      title = 'Login';
    }
    if (ctx.loaderData.isLoggedIn === 'loading') {
      title = 'Loading...';
    }
    return {
      meta: [{ title }],
    };
  },
});

function RouteComponent() {
  const auth = useAuth();
  if (auth.authChecked && auth.user.id) {
    return <Navigate to="/home" />;
  }
  return <Outlet />;
}
