import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { RequestTimeOffForm } from '../modules/ComponentsLibrary/TimeOff';

export const Route = createFileRoute('/timeoff/$timeOffRequestId')({
  component: RouteComponent,
  params: z.object({
    timeOffRequestId: z.coerce.number().positive(),
  }),
});

function RouteComponent() {
  const { timeOffRequestId } = Route.useParams();
  return <RequestTimeOffForm requestOffId={timeOffRequestId} />;
}
