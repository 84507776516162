import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { PropertyInfo } from '../../modules/PropertyInformation/components/PropertyInfo';

export const Route = createFileRoute('/customers/$userId/properties/$propertyId')({
  component: RouteComponent,
  params: z.object({
    userId: z.coerce.number().positive(),
    propertyId: z.coerce.number().positive(),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'Customer Property View' }],
    };
  },
});

function RouteComponent() {
  const { userId, propertyId } = Route.useParams();
  return <PropertyInfo propertyId={propertyId} userId={userId} />;
}
