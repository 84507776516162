import { createFileRoute } from '@tanstack/react-router';

import { CustomerEdit } from '../../modules/ComponentsLibrary/CustomerEdit';

export const Route = createFileRoute('/customers/add')({
  component: CustomerEdit,
  head(ctx) {
    return {
      meta: [{ title: 'Add Customer' }],
    };
  },
});
