import { zodResolver } from '@hookform/resolvers/zod';
import { CatalogItem } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  SimpleSelect,
} from '@kalos/ui';
import { type ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import { useReactTable } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useGetLoadingColumns } from '../../../components/utils';
import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME, ROWS_PER_PAGE } from '../../../constants';
import { useToolFundCatalogItemCategoryQuery } from '../../../hooks/react-query/useToolFundCatalogItemCategoryQuery';
import {
  type CatalogItemFilter,
  useToolFundCatalogQuery,
} from '../../../hooks/react-query/useToolFundCatalogQuery';
import { useMediaQuery } from '../../../hooks/useMedia';

const searchSchema = z.object({
  name: z.string().optional().default(''),
  brand: z.string().optional().default(''),
  categoryId: z.number().optional(),
  externalLink: z.string().optional().default(''),
});

type SearchSchema = z.infer<typeof searchSchema>;

const staticCatalogItems = Array.from({ length: 5 }, (_, idx) => CatalogItem.create({ id: idx }));

export const ToolFundCatalog = ({ catalogItemId }: { catalogItemId?: number }) => {
  const isDetailView = catalogItemId && Number(catalogItemId) > 0;
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const form = useForm<SearchSchema>({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      name: '',
      brand: '',
      categoryId: undefined,
      externalLink: '',
    },
    mode: 'onChange',
  });

  const [searchValues, setSearchValues] = useState<SearchSchema>(form.getValues());

  useEffect(() => {
    const subscription = form.watch(
      debounce((value) => {
        setSearchValues((prev) => ({ ...prev, ...value }));
        setPage(0);
      }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME),
    );
    return () => subscription.unsubscribe();
  }, [form]);

  const { data: categories } = useToolFundCatalogItemCategoryQuery({ filter: { pageNumber: -1 } });

  const categoryOptions = useMemo(() => {
    return (
      categories?.results?.map((category) => ({
        label: category.name,
        value: category.id.toString(),
      })) ?? []
    );
  }, [categories]);

  const request = useMemo<CatalogItemFilter>(() => {
    const searchName = searchValues.name?.trim();
    const searchBrand = searchValues.brand?.trim();
    const searchLink = searchValues.externalLink?.trim();
    const categoryId = searchValues.categoryId;

    return {
      name: searchName ? `%${searchName}%` : undefined,
      brand: searchBrand ? `%${searchBrand}%` : undefined,
      categoryId: categoryId ? categoryId : undefined,
      id: catalogItemId ? Number(catalogItemId) : undefined,
      externalLink: searchLink ? `%${searchLink}%` : undefined,
      pageNumber: page,
    };
  }, [searchValues, page, catalogItemId]);

  const toolFundCatalogQuery = useToolFundCatalogQuery({
    filter: request,
  });

  useEffect(() => {
    if (toolFundCatalogQuery.isSuccess) {
      setTotalCount(toolFundCatalogQuery.data.totalCount);
    }
  }, [toolFundCatalogQuery.isSuccess, toolFundCatalogQuery.data]);

  const pageCount = totalCount ? Math.ceil(totalCount / ROWS_PER_PAGE) : 0;

  const isMobile = useMediaQuery('(max-width: 768px)');

  const columns: ColumnDef<CatalogItem>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        meta: {
          className: 'w-32 truncate',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className} title={row.original.name}>
            {row.original.name}
          </div>
        ),
      },
      {
        meta: {
          className: 'w-12',
        },
        accessorKey: 'externalLink',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Link" />,
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className}>
            {row.original.externalLink && (
              <Button className="px-0" variant="link" asChild>
                <a href={row.original.externalLink} target="_blank" rel="noopener noreferrer">
                  View
                </a>
              </Button>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'brand',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Brand" />,
        meta: {
          className: 'w-32 truncate',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className} title={row.original.brand}>
            {row.original.brand}
          </div>
        ),
      },
      {
        accessorKey: 'price',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Price" />,
        meta: {
          className: 'w-16',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className}>${row.original.cost.toFixed(2)}</div>
        ),
      },
      ...(!isMobile
        ? ([
            {
              accessorKey: 'description',
              meta: {
                className: 'w-32',
              },
              header: ({ column }) => <DataTableColumnHeader column={column} title="Description" />,
              cell: ({ row, column }) => (
                <div className={column.columnDef.meta?.className} title={row.original.description}>
                  {row.original.description}
                </div>
              ),
            },
            {
              meta: {
                className: 'w-32 line-clamp-2',
              },
              accessorKey: 'tradeTypeNameList',
              header: ({ column }) => (
                <DataTableColumnHeader className="w-32" column={column} title="Trades" />
              ),
              cell: ({ row, column }) => (
                <div
                  className={column.columnDef.meta?.className}
                  title={row.original.tradeTypeNameList}
                >
                  {row.original.tradeTypeNameList}
                </div>
              ),
            },
          ] satisfies ColumnDef<CatalogItem>[])
        : []),
    ],
    [isMobile],
  );

  const loadingColumns = useGetLoadingColumns(columns);

  const table = useReactTable<CatalogItem>({
    data: toolFundCatalogQuery.isPending
      ? staticCatalogItems
      : toolFundCatalogQuery.data?.results ?? [],
    columns: toolFundCatalogQuery.isPending ? loadingColumns : columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
    enableSorting: false,
  });

  return (
    <>
      <Form {...form}>
        <div className="space-y-4">
          {!isDetailView && (
            <form className="px-4">
              <div className="grid max-w-full grid-cols-2 gap-4 lg:grid-cols-3">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Search by name..."
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setPage(0);
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="externalLink"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Link</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Search by link..."
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setPage(0);
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="brand"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Brand</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Search by brand..."
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setPage(0);
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="categoryId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Category</FormLabel>
                      <SimpleSelect
                        onChange={(value) => {
                          field.onChange(value ? Number(value) : undefined);
                          setPage(0);
                        }}
                        values={categoryOptions}
                        selectedValue={field.value?.toString() ?? ''}
                        placeholder="Filter by category"
                      />
                    </FormItem>
                  )}
                />
                <Button
                  type="reset"
                  className="mt-auto max-w-max"
                  variant="outline"
                  onClick={() => {
                    form.reset(form.formState.defaultValues);
                    setPage(0);
                  }}
                >
                  Reset
                </Button>
              </div>
            </form>
          )}

          {pageCount > 1 && !isDetailView && (
            <div className="flex items-center justify-between px-4">
              <DataTablePagination
                className="max-w-full"
                currentPage={page}
                setPage={setPage}
                pageCount={pageCount}
              />
              {totalCount && <p className="text-sm">Total Entries: {totalCount}</p>}
            </div>
          )}
        </div>
      </Form>
      <div className="max-w-full overflow-x-auto rounded-md border">
        <DataTable table={table} />
      </div>
    </>
  );
};
