export function monitorAudioLevels(remoteStream: MediaStream): [Promise<void>, () => void] {
  let intervalId: NodeJS.Timeout | null = null;
  let audioContext: AudioContext | null = null;
  let source: MediaStreamAudioSourceNode | null = null;
  let analyser: AnalyserNode | null = null;

  const cleanupFn = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
    if (source) {
      source.disconnect();
      source = null;
    }
    if (analyser) {
      analyser.disconnect();
      analyser = null;
    }
    if (audioContext) {
      audioContext.close();
      audioContext = null;
    }
  };

  const promise = new Promise<void>((resolve, reject) => {
    try {
      // Create an AudioContext
      const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      audioContext = new AudioContext();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048; // Larger FFT size for more accurate analysis
      analyser.smoothingTimeConstant = 0.8; // Smooth out the readings

      // Create a MediaStreamSource from the remote audio stream
      source = audioContext.createMediaStreamSource(remoteStream);
      source.connect(analyser);

      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      let consecutiveDetections = 0;
      const REQUIRED_DETECTIONS = 3; // Require 3 consecutive detections
      const SOUND_THRESHOLD = 0.1; // Lower threshold for better sensitivity

      const checkAudio = () => {
        if (!analyser) return;
        analyser.getByteFrequencyData(dataArray);

        // Calculate the average volume level
        const sum = dataArray.reduce((acc, value) => acc + value, 0);
        const averageVolume = sum / dataArray.length / 255; // Normalize to 0-1 range

        if (averageVolume > SOUND_THRESHOLD) {
          consecutiveDetections++;
          softphoneLog('Audio level detected:', { averageVolume, consecutiveDetections });
          if (consecutiveDetections >= REQUIRED_DETECTIONS) {
            softphoneLog('Audio confirmed!', { averageVolume, consecutiveDetections });
            cleanupFn();
            resolve();
          }
        } else {
          consecutiveDetections = Math.max(0, consecutiveDetections - 1);
        }
      };

      // Start monitoring every 100ms
      intervalId = setInterval(checkAudio, 100);

      // Set a timeout to auto-resolve after 5 seconds if no audio detected
      setTimeout(() => {
        if (intervalId) {
          softphoneLog('Audio detection timeout reached');
          cleanupFn();
          resolve();
        }
      }, 5000);
    } catch (error) {
      softphoneLog('Error setting up audio monitoring:', error);
      cleanupFn();
      reject(error);
    }
  });

  return [promise, cleanupFn];
}

export const cleanupRTC = ({ peerConnection }: { peerConnection: RTCPeerConnection }) => {
  peerConnection.getSenders().forEach((sender) => {
    if (sender.track) {
      sender.track.stop();
    }
  });
  peerConnection.onicecandidate = null;
  peerConnection.ontrack = null;
  peerConnection.oniceconnectionstatechange = null;
  peerConnection.onsignalingstatechange = null;
  peerConnection.ondatachannel = null;

  peerConnection.close();
};

export const softphoneLog = (...args: any[]) => {
  console.log('%c[KalosSoftphone]', 'background: #dc2626; color: white;', ...args);
};
