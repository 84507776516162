import { QuoteLine, TimesheetDepartment, User } from '@kalos/kalos-rpc';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { default as IconButton } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { type FC, useCallback, useEffect, useReducer } from 'react';

import { useAuth } from '../../../context/AuthContext';
import {
  makeFakeRows,
  QuoteLineClientService,
  TimesheetDepartmentClientService,
  UserClientService,
} from '../../../tools/helpers';
import { PageWrapper } from '../../PageWrapper/main';
import { InfoTable } from '../InfoTable';
import { PlainForm, type Schema } from '../PlainForm';
import { SectionBar } from '../SectionBar';
import { FlatRateCreateForm } from './components/FlatRateCreateForm';

export interface Props {
  onClose?: () => void;
  initialDepartment?: number;
  readOnly?: boolean;
}

interface FormData {
  description: string;
  department: number[];
  cost: number;
}

interface State {
  user: User;
  canEditFlatRates: boolean;
  canEditDepartment: boolean;
  department: number;
  departmentList: TimesheetDepartment[];
  selectedRowId: number;
  flatRates: QuoteLine[];
  filteredFlatRates: QuoteLine[];
  edit: boolean;
  createNew: boolean;
  loaded: boolean;
  formData: FormData;
  pendingSave: FormData;
  searchParams: FormData;
  reload: boolean;
  saving: boolean;
  remove: boolean;
  resethFormKey: number;
  creatingQuote: boolean;
}

type Action =
  | { type: 'setFlatRates'; data: QuoteLine[] }
  | { type: 'setLoaded'; data: boolean }
  | {
      type: 'setData';
      data: {
        flatRates: QuoteLine[];
        filteredFlatRates: QuoteLine[];
        loaded: boolean;
      };
    }
  | {
      type: 'setEdit';
      data: {
        selectedRowId: number;
        edit: boolean;
        formData: FormData;
      };
    }
  | { type: 'setCreateNew'; data: boolean }
  | { type: 'setFormData'; data: FormData }
  | { type: 'setPendingSave'; data: FormData }
  | { type: 'cancel'; data: FormData }
  | { type: 'setDepartmentList'; data: TimesheetDepartment[] }
  | { type: 'setSearchParams'; data: FormData }
  | { type: 'setFilteredData'; data: QuoteLine[] }
  | { type: 'setSaving'; data: boolean }
  | { type: 'setResethFormKey'; data: number }
  | {
      type: 'setUser';
      data: {
        user: User;
        canEditFlatRate: boolean;
        canEditDepartment: boolean;
      };
    }
  | {
      type: 'setPendingDelete';
      data: {
        selectedRowId: number;
        remove: boolean;
      };
    }
  | { type: 'setCreatingQuote'; data: boolean };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setFlatRates':
      return {
        ...state,
        flatRates: action.data,
      };
    case 'setLoaded':
      return {
        ...state,
        loaded: action.data,
      };
    case 'setData':
      return {
        ...state,
        flatRates: action.data.flatRates,
        filteredFlatRates: action.data.filteredFlatRates,
        loaded: action.data.loaded,
        reload: false,
      };
    case 'setEdit':
      return {
        ...state,
        selectedRowId: action.data.selectedRowId,
        edit: action.data.edit,
        formData: action.data.formData,
        pendingSave: action.data.formData,
      };
    case 'setCreateNew':
      return {
        ...state,
        createNew: action.data,
      };
    case 'setFormData':
      return {
        ...state,
        formData: action.data,
      };
    case 'setPendingSave':
      return {
        ...state,
        pendingSave: action.data,
      };
    case 'cancel':
      return {
        ...state,
        edit: false,
        pendingSave: action.data,
        selectedRowId: 0,
      };
    case 'setDepartmentList':
      return {
        ...state,
        departmentList: action.data,
      };
    case 'setSearchParams':
      return {
        ...state,
        searchParams: action.data,
        reload: true,
      };
    case 'setFilteredData':
      return {
        ...state,
        filteredFlatRates: action.data,
      };
    case 'setSaving':
      return {
        ...state,
        saving: action.data,
      };
    case 'setUser':
      return {
        ...state,
        user: action.data.user,
        canEditFlatRates: action.data.canEditFlatRate,
        canEditDepartment: action.data.canEditDepartment,
      };
    case 'setPendingDelete':
      return {
        ...state,
        selectedRowId: action.data.selectedRowId,
        remove: action.data.remove,
      };
    case 'setResethFormKey':
      return {
        ...state,
        resethFormKey: state.resethFormKey + 1,
      };
    case 'setCreatingQuote':
      return {
        ...state,
        creatingQuote: action.data,
      };
    default:
      return state;
  }
};

const initialFormData: FormData = {
  description: '',
  department: [],
  cost: 0,
};

const initialState: State = {
  user: User.create(),
  canEditFlatRates: false,
  canEditDepartment: false,
  department: 0,
  departmentList: [],
  selectedRowId: 0,
  flatRates: [],
  filteredFlatRates: [],
  edit: false,
  loaded: false,
  createNew: false,
  formData: initialFormData,
  pendingSave: initialFormData,
  searchParams: {
    description: '',
    department: [],
    cost: 0,
  },
  reload: false,
  saving: false,
  remove: false,
  resethFormKey: 0,
  creatingQuote: false,
};

export const FlatRateSheet: FC<Props> = function FlatRateSheet({
  onClose,
  initialDepartment,
  readOnly = false,
}) {
  console.log('FlatRateSheet initialized with department:', initialDepartment);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    searchParams: {
      description: '',
      department: initialDepartment ? [initialDepartment] : [],
      cost: 0,
    },
  });

  const auth = useAuth();
  const loggedUserId = auth.user.id;

  const handleToggleEdit = (type: string, selectedRowId = 0) => {
    if (type === 'edit') {
      const flatRate = state.flatRates.find((fr) => fr.id === selectedRowId);
      if (flatRate) {
        dispatch({
          type: 'setEdit',
          data: {
            selectedRowId: selectedRowId,
            edit: true,
            formData: {
              description: flatRate.description,
              department: flatRate.departments.split(',').map(Number),
              cost: Number(flatRate.adjustment),
            },
          },
        });
      }
    } else if (type === 'cancel') {
      dispatch({
        type: 'cancel',
        data: { description: '', department: [], cost: 0 },
      });
    }
  };

  const handleTogglePendingDelete = (selectedRow: number, remove: boolean) => {
    dispatch({
      type: 'setPendingDelete',
      data: {
        selectedRowId: selectedRow,
        remove: remove,
      },
    });
  };

  const handleToggleAdd = () => {
    dispatch({ type: 'setCreateNew', data: !state.createNew });
  };

  const getUserData = useCallback(async () => {
    const userObj = User.create();
    userObj.id = loggedUserId || 0;
    const user = await UserClientService.Get(userObj);
    const canEditFlatRate = user.permissionGroups.find((p) => p.name === 'EditFlatRates');
    const canEditDepartments = user.permissionGroups.find(
      (p) => p.name === 'EditFlatRateDepartment',
    );
    dispatch({
      type: 'setUser',
      data: {
        user: user,
        canEditFlatRate: canEditFlatRate !== undefined,
        canEditDepartment: canEditDepartments !== undefined,
      },
    });
  }, [loggedUserId]);

  const handleSave = async (id: number, data?: any) => {
    const flatRate = QuoteLine.create();
    if (id === 0) {
      if (data && data['Department'][0] === '') {
        data['Department'][0] = '0';
      }
      const description = data['Description'] ? data['Description'] : '';
      const department = data['Department'] ? data['Department'].toString() : '0';
      const cost = data['Cost'] ? Number(data['Cost']) : 0;
      flatRate.description = description;
      flatRate.departments = department;
      flatRate.adjustment = cost.toString();
      flatRate.isActive = 1;
      flatRate.isFlatrate = '1';
      try {
        await QuoteLineClientService.Create(flatRate);
      } catch (err) {
        console.error('Failed to Create Flat Rate', err);
      }
      handleToggleAdd();
    } else {
      flatRate.id = id;
      flatRate.description = state.pendingSave.description;
      flatRate.departments = state.pendingSave.department.toString();
      flatRate.adjustment = (state.pendingSave.cost || 0).toString();
      try {
        flatRate.fieldMask = ['Description', 'Departments', 'Adjustment'];
        await QuoteLineClientService.Update(flatRate);
      } catch (err) {
        console.error('Failed to Save Flat Rate', err);
      }
      handleToggleEdit('cancel', 0);
    }
    dispatch({ type: 'setLoaded', data: false });
    dispatch({ type: 'setSaving', data: false });
  };

  const handleCreateFormSave = async ({
    data,
  }: {
    data: { description: string; departments: number[]; adjustment: number };
  }) => {
    const flatRate = QuoteLine.create();
    flatRate.description = data.description;
    flatRate.departments = data.departments.toString();
    flatRate.adjustment = data.adjustment.toString();
    flatRate.isActive = 1;
    flatRate.isFlatrate = '1';
    try {
      await QuoteLineClientService.Create(flatRate);
      handleToggleAdd();
    } catch (err) {
      console.error('Failed to Create Flat Rate', err);
    }
    dispatch({ type: 'setLoaded', data: false });
    dispatch({ type: 'setSaving', data: false });
  };

  const handleDelete = async (id: number) => {
    const quoteReq = QuoteLine.create();
    quoteReq.id = id;
    try {
      await QuoteLineClientService.Delete(quoteReq);
    } catch (err) {
      console.error('Failed to Update Flat Rate', err);
    }
    dispatch({ type: 'setLoaded', data: false });
  };

  const updateSearchParams = async (formData: FormData) => {
    const updatedForm: FormData = {
      description: formData.description,
      department: formData.department,
      cost: formData.cost,
    };
    console.log('Updating search params:', formData, 'to:', updatedForm);
    dispatch({ type: 'setSearchParams', data: updatedForm });
  };

  // Define filterFlatRates in its own useCallback hook to maintain stable reference
  const filterFlatRates = useCallback(
    (rates: QuoteLine[], searchParams: FormData) => {
      console.log(
        'Filtering flat rates. Search params:',
        searchParams,
        'Initial department:',
        initialDepartment,
      );

      return rates.filter((rate) => {
        // Filter by description if search term provided
        if (searchParams.description) {
          const searchTerm = searchParams.description.toLowerCase();
          const description = rate.description.toLowerCase();
          if (!description.includes(searchTerm)) {
            return false;
          }
        }

        // Filter by department if selected
        if (searchParams.department && searchParams.department.length > 0) {
          // Split the comma-separated departments from the rate
          const rateDepartments = rate.departments.split(',').map(Number);
          // Check if any of the selected departments match the rate departments
          // Always include department 0 (generic) if any department is selected
          const departmentMatch = searchParams.department.some(
            (depId) => rateDepartments.includes(depId) || rateDepartments.includes(0),
          );
          if (!departmentMatch) {
            return false;
          }
        }

        return true;
      });
    },
    [initialDepartment], // Only depends on initialDepartment, which is stable
  );

  // Now use filterFlatRates in useCallback with the proper dependency array
  // const _handleFilterChange = useCallback(
  //   async (searchParams: FormData) => {
  //     dispatch({ type: 'setSearchParams', data: searchParams });
  //     const filteredQLResults = filterFlatRates(state.flatRates, searchParams);
  //     dispatch({ type: 'setFilteredData', data: filteredQLResults });
  //   },
  //   [state.flatRates, filterFlatRates],
  // );

  const getFlatRates = useCallback<(refresh: boolean) => Promise<void>>(
    async (refresh: boolean) => {
      let qlResults: QuoteLine[] = [];
      let filteredQLResults: QuoteLine[] = [];
      const startingPage = 0;
      if (refresh) {
        const quoteReq = QuoteLine.create();
        quoteReq.isActive = 1;
        quoteReq.isFlatrate = '1';
        quoteReq.pageNumber = startingPage;
        quoteReq.withoutLimit = true;
        try {
          qlResults = (await QuoteLineClientService.BatchGet(quoteReq))!.results;
        } catch (e) {
          console.error('could not fetch results for flat rate', e);
        }
        qlResults = qlResults.sort((a, b) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return 0;
        });

        filteredQLResults = filterFlatRates(qlResults, state.searchParams);

        dispatch({
          type: 'setData',
          data: {
            flatRates: qlResults,
            filteredFlatRates: filteredQLResults,
            loaded: true,
          },
        });
      } else {
        filteredQLResults = filterFlatRates(state.flatRates, state.searchParams);
        dispatch({ type: 'setFilteredData', data: filteredQLResults });
      }
    },
    [state.searchParams, state.flatRates, filterFlatRates],
  );

  const getDepartments = useCallback(async () => {
    const dep = TimesheetDepartment.create();
    dep.isActive = 1;
    try {
      const departmentList = await TimesheetDepartmentClientService.BatchGet(dep);
      dispatch({
        type: 'setDepartmentList',
        data: departmentList!.results,
      });
    } catch (err) {
      console.error('Unable to Retrieve Department List', err);
    }
  }, []);

  useEffect(() => {
    if (!state.loaded) {
      if (state.user.id === 0) {
        getUserData();
      } else {
        getFlatRates(true);
        getDepartments();
      }
    }
    if (state.reload) {
      getFlatRates(false);
    }
  }, [getFlatRates, state.loaded, getDepartments, state.reload, getUserData, state.user]);

  // Essential hook to ensure department is always an array
  useEffect(() => {
    // Ensure department is always an array in searchParams
    if (
      state.searchParams &&
      state.searchParams.department &&
      !Array.isArray(state.searchParams.department)
    ) {
      const updatedParams = {
        ...state.searchParams,
        department: state.searchParams.department ? [state.searchParams.department] : [],
      };
      dispatch({ type: 'setSearchParams', data: updatedParams });
    }
  }, [state.searchParams]);

  const DESCRIPTION_SCHEMA: Schema<FormData> = [
    [
      {
        name: 'description',
        label: 'Description',
        type: 'text',
      },
    ],
  ];

  const DEPARTMENT_SCHEMA: Schema<FormData> = [
    [
      {
        name: 'department',
        label: 'Department(s)',
        type: 'multiselect',
        options: state.departmentList.map((dl) => ({
          value: dl.id,
          label: `${dl.value} - ${dl.description}`,
        })),
      },
    ],
  ];

  const COST_SCHEMA: Schema<FormData> = [
    [
      {
        name: 'cost',
        label: 'Cost',
        type: 'number',
        startAdornment: '$',
      },
    ],
  ];

  return (
    <PageWrapper>
      <SectionBar
        title="Flat Rates"
        actions={
          onClose
            ? [
                {
                  label: state.createNew ? 'Cancel Add' : 'Add New Flat Rate',
                  onClick: () => handleToggleAdd(),
                  style: { display: !readOnly && state.canEditFlatRates ? '' : 'none' },
                },
                {
                  label: 'Close',
                  onClick: onClose,
                },
              ]
            : [
                {
                  label: state.createNew ? 'Cancel Add' : 'Add New Flat Rate',
                  onClick: () => handleToggleAdd(),
                  style: { display: !readOnly && state.canEditFlatRates ? '' : 'none' },
                },
              ]
        }
        uncollapsable
      />
      {/* @ts-ignore*/}
      <Box
        sx={{
          width: '100%',
          position: 'sticky',
          top: { xs: '35px', md: '48px' },
          zIndex: 3,
          backgroundColor: 'white',
          borderTop: '0px',
          borderLeft: '0px',
          borderRight: '0px',
          border: 'solid',
          borderColor: 'black',
          padding: '10px',
        }}
      >
        <PlainForm
          schema={[
            [
              {
                name: 'description',
                label: 'Description',
                type: 'text',
              },
              {
                name: 'department',
                label: 'Department(s)',
                type: 'multiselect',
                options: state.departmentList.map((dl) => ({
                  value: dl.id,
                  label: `${dl.value} - ${dl.description}`,
                })),
              },
            ],
          ]}
          data={state.searchParams}
          onChange={debounce(updateSearchParams, 1000)}
          fullWidth={true}
        />
      </Box>
      {!state.loaded && <InfoTable loading data={makeFakeRows(3, 7)} />}
      {state.loaded && (
        <>
          {state.createNew && (
            <Box sx={{ p: 2 }}>
              <FlatRateCreateForm onSave={handleCreateFormSave} isLoading={state.saving} />
            </Box>
          )}
          <InfoTable
            className="Form"
            compact={!state.canEditFlatRates || readOnly}
            ignoreImage
            columns={[
              { name: 'Description', width: window.innerWidth * 0.4 },
              { name: 'Department', width: window.innerWidth * 0.3 },
              { name: 'Cost', width: window.innerWidth * 0.2 },
              {
                name: 'Actions',
                width: window.innerWidth * 0.1,
                invisible: readOnly || !state.canEditFlatRates,
              },
            ]}
            data={state.filteredFlatRates.map((value) => {
              return [
                {
                  value:
                    state.remove && state.selectedRowId === value.id ? (
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bolder',
                          fontSize: '20px',
                        }}
                      >
                        Are you sure you want to delete this record?
                      </Typography>
                    ) : state.edit && state.selectedRowId === value.id ? (
                      <PlainForm
                        onChange={debounce((result) => {
                          const pendingSave = state.pendingSave;
                          // eslint-disable-next-line react-compiler/react-compiler
                          pendingSave.description = result.description;
                          dispatch({ type: 'setPendingSave', data: pendingSave });
                        }, 500)}
                        schema={DESCRIPTION_SCHEMA}
                        data={state.formData}
                      />
                    ) : (
                      value.description
                    ),
                },
                {
                  value:
                    state.remove && state.selectedRowId === value.id ? (
                      <div></div>
                    ) : state.edit && state.selectedRowId === value.id ? (
                      <PlainForm
                        onChange={(result) => {
                          const pendingSave = state.pendingSave;
                          pendingSave.department = result.department;
                          dispatch({ type: 'setPendingSave', data: pendingSave });
                        }}
                        schema={DEPARTMENT_SCHEMA}
                        data={state.formData}
                      />
                    ) : value.departments === '0' ? (
                      'All Departments'
                    ) : (
                      state.departmentList
                        .filter((dep) => value.departments.split(',').includes(String(dep.id)))
                        .map((dep) => dep.value)
                        .toString()
                    ),
                },
                {
                  value:
                    state.remove && state.selectedRowId === value.id ? (
                      <div></div>
                    ) : state.edit && state.selectedRowId === value.id ? (
                      <PlainForm
                        onChange={(result) => {
                          const pendingSave = state.pendingSave;
                          pendingSave.cost = result.cost;
                          dispatch({ type: 'setPendingSave', data: pendingSave });
                        }}
                        schema={COST_SCHEMA}
                        data={state.formData}
                      />
                    ) : (
                      `$${parseFloat(value.adjustment).toFixed(2)}`
                    ),
                },
                {
                  value: (
                    <div>
                      {state.selectedRowId !== value.id && state.canEditFlatRates && (
                        <div>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleToggleEdit('edit', value.id)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleTogglePendingDelete(value.id, true)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                      {state.edit && state.selectedRowId === value.id && state.canEditFlatRates && (
                        <div>
                          <Tooltip title="Save">
                            <IconButton onClick={() => handleSave(value.id)}>
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Cancel">
                            <IconButton onClick={() => handleToggleEdit('cancel', 0)}>
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                      {state.remove &&
                        state.selectedRowId === value.id &&
                        state.canEditFlatRates && (
                          <div>
                            <Tooltip title="Confirm">
                              <IconButton onClick={() => handleDelete(value.id)}>
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                              <IconButton onClick={() => handleTogglePendingDelete(0, false)}>
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                    </div>
                  ),
                },
              ];
            })}
          />
        </>
      )}
    </PageWrapper>
  );
};
