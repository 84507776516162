import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ReimbursementComponent } from '../modules/ReimbursementLog/components/Reimbursement';

export const Route = createFileRoute('/reimbursements/$reimbursementOwnerId')({
  component: RouteComponent,
  params: z.object({
    reimbursementOwnerId: z.coerce.number().positive(),
  }),
});

function RouteComponent() {
  const { reimbursementOwnerId } = Route.useParams();
  return <ReimbursementComponent reimbursementOwnerId={reimbursementOwnerId} />;
}
