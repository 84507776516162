import { createFileRoute } from '@tanstack/react-router';

import { ToolFund } from '../modules/ComponentsLibrary/ToolFund';

export const Route = createFileRoute('/toolFundRequests')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Tool Fund Requests' }],
    };
  },
});

function RouteComponent() {
  return <ToolFund />;
}
