import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { type Kind } from '../modules/ComponentsLibrary/AdvancedSearch/constants';
import { contractsSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/ContractsSearch/utils';
import { customerSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/CustomerSearch/utils';
import { employeesSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/EmployeesSearch/utils';
import { propertiesSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/PropertiesSearch/utils';
import { serviceCallsSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/ServiceCallsSearch/utils';
import { Dashboard } from '../modules/Dashboard/main';

const advancedSearchWithKindsSchema = z
  .discriminatedUnion('kind', [
    z.object({
      kind: z.literal('contracts' satisfies Kind).catch('contracts'),
      ...contractsSearchScheme.shape,
    }),
    z.object({
      kind: z.literal('customers' satisfies Kind).catch('customers'),
      ...customerSearchScheme.shape,
    }),
    z.object({
      kind: z.literal('serviceCalls' satisfies Kind).catch('serviceCalls'),
      ...serviceCallsSearchScheme.shape,
    }),
    z.object({
      kind: z.literal('employees' satisfies Kind).catch('employees'),
      ...employeesSearchScheme.shape,
    }),
    z.object({
      kind: z.literal('properties' satisfies Kind).catch('properties'),
      ...propertiesSearchScheme.shape,
    }),
  ])
  .catch({ kind: 'contracts' });

const searchSchema = advancedSearchWithKindsSchema;
export const Route = createFileRoute('/home')({
  component: Dashboard,
  head: () => {
    return {
      meta: [
        {
          title: 'Dashboard',
        },
      ],
    };
  },
  validateSearch: searchSchema,
});
