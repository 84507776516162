import { InvoiceIdContext } from './InvoicePaymentLineContext.context';

export const withInvoiceIdProvider = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
  invoiceId: number,
) => {
  return (props: P) => {
    return (
      <InvoiceIdContext value={invoiceId}>
        <WrappedComponent {...props} />
      </InvoiceIdContext>
    );
  };
};
