import { LoadingIcon } from '@kalos/ui';
import { type FormEvent, useCallback, useEffect } from 'react';

import { useS3FileUrlQuery } from '../../../hooks/react-query/useS3';

export type RTUDrawing = {
  line1: number;
  line2: number;
  line3: number;
  line4: number;
  line5: number;
  line6: number;
  line7: number;
  line8: number;
  line9: number;
  line10: number;
  line11: number;
  line12: number;
};

interface Props {
  drawing: RTUDrawing;
  onDrawingChange: (drawing: RTUDrawing) => void;
  onBase64ImageChange?: (base64: string) => void;
}

const convertToBase64 = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting image to base64:', error);
    throw error;
  }
};

export const RTUDiagram: React.FC<Props> = ({ drawing, onDrawingChange, onBase64ImageChange }) => {
  const { data: RTU_img, isFetching } = useS3FileUrlQuery({
    filter: {
      bucket: 'testbuckethelios',
      fileName: 'RTU.png',
    },
  });

  useEffect(() => {
    if (RTU_img && onBase64ImageChange) {
      convertToBase64(RTU_img).then(onBase64ImageChange).catch(console.error);
    }
  }, [RTU_img, onBase64ImageChange]);

  const handleSetDrawing = useCallback(
    (key: keyof RTUDrawing) => (event: FormEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const value = parseInt(target.value);

      // Only update if it's a valid non-negative number
      if (!isNaN(value) && value >= 0) {
        onDrawingChange({
          ...drawing,
          [key]: value,
        });
      }
    },
    [drawing, onDrawingChange],
  );

  return (
    <div className="mx-auto mb-3 max-w-[85%]">
      {isFetching ? (
        <LoadingIcon />
      ) : (
        <div className="relative">
          <input
            value={drawing.line1}
            type="number"
            min="0"
            onInput={handleSetDrawing('line1')}
            className="absolute left-[2%] top-[33.5%] h-[4.5%] w-[7%] border-[1px] border-black"
          />
          <input
            value={drawing.line2}
            type="number"
            min="0"
            onInput={handleSetDrawing('line2')}
            className="absolute left-[2%] top-[58.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line3}
            type="number"
            min="0"
            onInput={handleSetDrawing('line3')}
            className="absolute left-[2%] top-[77%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line4}
            type="number"
            min="0"
            onInput={handleSetDrawing('line4')}
            className="absolute left-[25%] top-[77%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line5}
            type="number"
            min="0"
            onInput={handleSetDrawing('line5')}
            className="absolute left-[29.5%] top-[82.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line6}
            type="number"
            min="0"
            onInput={handleSetDrawing('line6')}
            className="absolute left-[40.5%] top-[89.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line7}
            type="number"
            min="0"
            onInput={handleSetDrawing('line7')}
            className="absolute left-[40.5%] top-[94.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line8}
            type="number"
            min="0"
            onInput={handleSetDrawing('line8')}
            className="absolute left-[58%] top-[82.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line9}
            type="number"
            min="0"
            onInput={handleSetDrawing('line9')}
            className="absolute right-[0.5%] top-[38%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line10}
            type="number"
            min="0"
            onInput={handleSetDrawing('line10')}
            className="absolute right-[8%] top-[38%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line11}
            type="number"
            min="0"
            onInput={handleSetDrawing('line11')}
            className="absolute right-[13%] top-[43.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          <input
            value={drawing.line12}
            type="number"
            min="0"
            onInput={handleSetDrawing('line12')}
            className="absolute right-[13%] top-[62.5%] h-[4.5%] w-[7%] border-[1px] border-black outline-none"
          />
          {RTU_img && <img src={RTU_img} alt="RTU Diagram" className="h-auto w-full" />}
        </div>
      )}
    </div>
  );
};
