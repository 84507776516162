'use no memo';
import { ToolFundTransaction } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MoneyInput,
  SimpleSelect,
  Textarea,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { Pencil1Icon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { format } from 'date-fns';
import { type ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { useGetLoadingColumns } from '../../../components/utils';
import { ROWS_PER_PAGE } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import {
  useBatchGetToolFundTransactionQuery,
  useBatchGetToolFundTransactionTypeQuery,
  useToolFundTransactionCreateMutation,
  useToolFundTransactionUpdateMutation,
} from '../../../hooks/react-query/useToolFundTransaction';
import { type FormProps } from '../../../tools/types';
import { getFieldMaskFromDirtyField } from '../../ComponentsLibrary/EmployeeDepartments/utils';
import { type TransactionFormData, useTransactionForm } from './ToolFundTransactions.hooks';

type TransactionFormProps = {
  isAdmin: boolean;
} & FormProps<TransactionFormData>;

const loadingToolFundTransactions = Array.from({ length: 3 }, (_, index) =>
  ToolFundTransaction.create(),
);

const TransactionForm = ({
  isAdmin,
  defaultValues,
  onSave,
  disabled,
  isLoading,
}: TransactionFormProps) => {
  const { data: transactionTypes = [] } = useBatchGetToolFundTransactionTypeQuery({
    select(data) {
      return data.results.map((type) => ({
        value: type.id.toString(),
        label: `${type.name} - ${type.description}`,
      }));
    },
  });

  const form = useTransactionForm({
    defaultValues,
    disabled,
  });

  const onSubmit = form.handleSubmit((data) => {
    onSave({ data, dirtyFields: form.formState.dirtyFields });
  });

  return (
    <Form {...form}>
      <form onSubmit={onSubmit} className="@container space-y-4">
        <div className="rounded-lg border bg-white p-4 shadow">
          <div className="@lg:grid-cols-4 grid grid-cols-1 gap-4">
            <FormField
              control={form.control}
              name="transactionTypeId"
              render={({ field: { ref, ...field } }) => (
                <FormItem className="">
                  <FormLabel>Transaction Type</FormLabel>
                  <FormControl>
                    <SimpleSelect
                      values={transactionTypes}
                      onChange={(value) => field.onChange(Number(value))}
                      selectedValue={field.value.toString()}
                      disabled={!isAdmin || field.disabled}
                      placeholder="Select Type"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <MoneyInput
                      type="number"
                      step="0.01"
                      {...field}
                      disabled={!isAdmin || field.disabled}
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem className="@lg:col-span-2 col-span-1">
                  <FormLabel>Notes</FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled={!isAdmin || field.disabled} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {isAdmin && (
            <div className="mt-4 flex justify-end">
              <Button
                type="submit"
                disabled={disabled || !form.formState.isDirty}
                isLoading={isLoading}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </form>
    </Form>
  );
};

interface ToolFundTransactionsProps {
  userId: number;
  isAdmin: boolean;
}

export const ToolFundTransactions: React.FC<ToolFundTransactionsProps> = ({ userId, isAdmin }) => {
  const [showNewForm, setShowNewForm] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const transactionsQuery = useBatchGetToolFundTransactionQuery({
    filters: {
      userId,
      pageNumber: page,
      isActive: true,
      orderBy: 'date_created',
      orderDir: 'DESC',
    },
    enabled: !!userId,
  });
  const { user: loggedUser } = useAuth();

  const createTransactionMutation = useToolFundTransactionCreateMutation();

  const createTransaction = useCallback<ComponentProps<typeof TransactionForm>['onSave']>(
    ({ data }) => {
      const transaction = ToolFundTransaction.create({
        ...data,
        userId,
        createdBy: loggedUser.id,
        isActive: true,
        dateCreated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      });

      createTransactionMutation.mutate(transaction, {
        onSuccess: () => {
          toast({
            variant: 'success',
            title: 'Transaction created successfully',
          });
          setShowNewForm(false);
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            title: 'Failed to create transaction',
            description: error.message,
          });
        },
      });
    },
    [createTransactionMutation, loggedUser.id, userId],
  );

  const transactionTypesQuery = useBatchGetToolFundTransactionTypeQuery();

  const tableColumns = useMemo<ColumnDef<ToolFundTransaction>[]>(
    () => [
      {
        accessorKey: 'transactionTypeId',
        header(props) {
          return <DataTableColumnHeader {...props} title="Transaction Type" />;
        },
        meta: {
          className: 'md:w-80 w-40',
        },
        cell(props) {
          const transactionType = transactionTypesQuery.data?.results.find(
            (type) => type.id === props.row.original.transactionTypeId,
          );
          return (
            <div
              className={props.column.columnDef.meta?.className}
            >{`${transactionType?.name} - ${transactionType?.description}`}</div>
          );
        },
      },
      {
        accessorKey: 'dateCreated',
        header(props) {
          return <DataTableColumnHeader {...props} title="Date Created" />;
        },
        meta: {
          className: 'w-44',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {format(new Date(props.row.original.dateCreated), 'PPp')}
            </div>
          );
        },
      },
      {
        accessorKey: 'amount',
        header(props) {
          return <DataTableColumnHeader {...props} title="Amount" />;
        },
        meta: {
          className: 'w-44',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              ${props.row.original.amount}
            </div>
          );
        },
      },
      {
        accessorKey: 'notes',
        header(props) {
          return <DataTableColumnHeader {...props} title="Notes" />;
        },
        meta: {
          className: 'w-44',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>{props.row.original.notes}</div>
          );
        },
      },
      {
        id: 'actions',
        meta: {
          className: 'w-16 flex gap-1 flex-wrap justify-end ml-auto',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <ToolFundTransactionTableActions isAdmin={isAdmin} original={props.row.original} />
            </div>
          );
        },
      },
    ],
    [isAdmin, transactionTypesQuery.data?.results],
  );

  const loadingColumns = useGetLoadingColumns(tableColumns);

  const tableData = useMemo(() => {
    return transactionsQuery.isPending
      ? loadingToolFundTransactions
      : transactionsQuery.data?.results || [];
  }, [transactionsQuery.isPending, transactionsQuery.data?.results]);

  const table = useReactTable({
    data: tableData,
    enableSorting: false,
    columns: transactionsQuery.isPending ? loadingColumns : tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (transactionsQuery.isSuccess) {
      setTotalCount(transactionsQuery.data?.totalCount || 0);
    }
  }, [transactionsQuery.isSuccess, transactionsQuery.data?.totalCount]);

  const newTransactionFormDefaultValues = useMemo<
    ComponentProps<typeof TransactionForm>['defaultValues']
  >(() => {
    return {
      amount: 0,
      notes: '',
      transactionTypeId: 0,
    };
  }, []);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-semibold tracking-wide">Transactions</h3>
        {isAdmin && (
          <Button
            onClick={() => setShowNewForm((prev) => !prev)}
            variant={showNewForm ? 'destructive' : 'default'}
          >
            {showNewForm ? 'Cancel' : 'Add Transaction'}
          </Button>
        )}
      </div>

      <div className="space-y-4">
        {showNewForm && (
          <div className="border-b pb-4">
            <h4 className="mb-2 text-lg font-medium">New Transaction</h4>
            <TransactionForm
              defaultValues={newTransactionFormDefaultValues}
              isAdmin={isAdmin}
              isLoading={createTransactionMutation.isPending}
              disabled={createTransactionMutation.isPending}
              onSave={createTransaction}
            />
          </div>
        )}

        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <DataTablePagination
              currentPage={page}
              pageCount={Math.ceil(totalCount / ROWS_PER_PAGE)}
              setPage={setPage}
              className="max-w-max"
            />
            <p>Total count: {totalCount}</p>
          </div>
          <div className="max-w-full overflow-x-auto">
            <DataTable table={table} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ToolFundTransactionTableActions = ({
  isAdmin,
  original,
}: {
  isAdmin: boolean;
  original: ToolFundTransaction;
}) => {
  const updateTransactionMutation = useToolFundTransactionUpdateMutation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const defaultUpdateFormValues = useMemo<
    ComponentProps<typeof TransactionForm>['defaultValues']
  >(() => {
    return {
      amount: original.amount,
      notes: original.notes,
      transactionTypeId: original.transactionTypeId,
    };
  }, [original]);

  const updateTransaction = useCallback<ComponentProps<typeof TransactionForm>['onSave']>(
    ({ data, dirtyFields }) => {
      const updateRequest = ToolFundTransaction.create({
        ...data,
        id: original.id,
        fieldMask: getFieldMaskFromDirtyField(dirtyFields),
      });

      updateTransactionMutation.mutate(updateRequest, {
        onSuccess: () => {
          toast({
            variant: 'success',
            title: 'Transaction updated successfully',
          });
          setIsEditDialogOpen(false);
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            title: 'Failed to update transaction',
            description: error.message,
          });
        },
      });
    },
    [original.id, updateTransactionMutation],
  );

  return (
    <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
      <TooltipProvider>
        <Tooltip>
          <DialogTrigger asChild>
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon">
                <Pencil1Icon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
          </DialogTrigger>
          <TooltipContent>Edit</TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Transaction</DialogTitle>
        </DialogHeader>
        <TransactionForm
          isAdmin={isAdmin}
          defaultValues={defaultUpdateFormValues}
          onSave={updateTransaction}
          disabled={updateTransactionMutation.isPending}
          isLoading={updateTransactionMutation.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
