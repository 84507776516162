import { type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../utils';
import { badgeVariants } from './badge.variants';

export type BadgeProps = React.ComponentProps<'div'> & VariantProps<typeof badgeVariants>;

export const Badge = ({ className, variant, ...props }: BadgeProps) => {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
};

Badge.displayName = 'Badge';
