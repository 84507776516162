import { type CallId } from '@kalos/kalos-rpc';
import { useMutation } from '@tanstack/react-query';
import { useStore } from 'zustand';

import { useProtectedSoftPhoneStore } from '../../context/SoftPhoneStore.context';
import { useCallStore } from './CallStore';
import { softphoneLog } from './utils';

export const useToggleMuteMutation = () => {
  const store = useProtectedSoftPhoneStore();
  const client = useStore(store, (store) => store.client);
  const setCurrentCall = useCallStore.use.setCurrentCall();

  const handleMuteLocalStream = useCallStore.use.handleMuteLocalStream();
  const handleUnmuteLocalStream = useCallStore.use.handleUnmuteLocalStream();

  return useMutation({
    mutationFn: async (args: CallId) => {
      return client.ToggleMute(args);
    },
    onMutate(variables) {
      const currentCall = useCallStore.getState().currentCall;
      if (!currentCall) throw new Error('No current call');
      if (currentCall.isMuted) {
        softphoneLog('unmute mutation triggered, unmuting');
        handleUnmuteLocalStream();
      } else {
        softphoneLog('mute mutation triggered, muting');
        handleMuteLocalStream();
      }
    },
    onSuccess(data, variables, context) {
      const currentCall = useCallStore.getState().currentCall;
      if (!currentCall) throw new Error('No current call');
      setCurrentCall({ ...currentCall, isMuted: !currentCall.isMuted });
    },
    onError(error, variables, context) {
      const currentCall = useCallStore.getState().currentCall;
      if (!currentCall) throw new Error('No current call');

      if (currentCall.isMuted) {
        softphoneLog('mute mutation failed, muting');
        handleMuteLocalStream();
      } else {
        softphoneLog('unmute mutation failed, unmuting');
        handleUnmuteLocalStream();
      }
    },
  });
};
