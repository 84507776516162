import { createFileRoute } from '@tanstack/react-router';

import { InternalDocuments } from '../modules/ComponentsLibrary/InternalDocuments';
import { internalResourceSearchScheme } from '../modules/ComponentsLibrary/InternalDocuments/InternalDocumentsSearchForm.hooks';

export const Route = createFileRoute('/documents')({
  component: RouteComponent,
  validateSearch: internalResourceSearchScheme,
  head(ctx) {
    return {
      meta: [{ title: 'Internal Resources' }],
    };
  },
});

function RouteComponent() {
  return <InternalDocuments />;
}
