import { type QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, HeadContent, Outlet, useMatch } from '@tanstack/react-router';

import { Layout, SelectLoginPage } from '../auth';
import { type AuthContextType, useAuth } from '../context/AuthContext';
import { Loader } from '../modules/Loader/main';

export interface RouterContext {
  queryClient: QueryClient;
  authContext: AuthContextType;
}
export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  const auth = useAuth();
  const isPopupRoute = useMatch({
    from: '/softphone/popup',
    shouldThrow: false,
  });
  const isStripeInvoiceRoute = useMatch({
    from: '/stripe/success/$token',
    shouldThrow: false,
  });

  if (!auth.authChecked) {
    return <Loader />;
  }

  if (!auth.user.id && !isStripeInvoiceRoute) {
    return <SelectLoginPage />;
  }

  return (
    <>
      <HeadContent />
      {isPopupRoute || isStripeInvoiceRoute ? (
        <Outlet />
      ) : (
        <Layout>
          <Outlet />
        </Layout>
      )}
    </>
  );
}
