'use client';

import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from '../utils';
import { Button } from './button';
import { SimpleSelect } from './select';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const Calendar = ({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) => {
  return (
    <DayPicker
      captionLayout="dropdown"
      showOutsideDays={showOutsideDays}
      className={cn('relative p-3 disabled:pointer-events-none', className)}
      classNames={{
        months: 'flex flex-col',
        month: 'w-full space-y-4',
        month_caption: 'flex gap-2',
        caption_label: 'text-sm font-medium text-center',
        nav: 'absolute top-[3.25rem] flex inset-x-0 items-center justify-between',
        button_previous:
          'h-7 w-7 flex items-center justify-center bg-transparent p-0 hover:bg-accent hover:text-accent-foreground disabled:hover:bg-transparent disabled:opacity-50 disabled:cursor-not-allowed rounded-md border border-input',
        button_next:
          'h-7 w-7 flex items-center justify-center bg-transparent p-0 hover:bg-accent hover:text-accent-foreground disabled:hover:bg-transparent disabled:opacity-50 disabled:cursor-not-allowed rounded-md border border-input',
        month_grid: 'w-full space-y-1 border-collapse',
        weekdays: cn('mt-4 grid grid-cols-7', props.showWeekNumber && 'grid-cols-8'),
        weekday: 'text-sm font-medium text-center text-muted-foreground',
        week: 'mt-2 grid grid-cols-7',
        day: 'relative h-9 w-9 p-0 font-normal rounded-md hover:bg-accent hover:text-accent-foreground focus-visible:bg-accent focus-visible:text-accent-foreground focus-visible:opacity-100 data-[selected]:bg-primary data-[selected]:text-primary-foreground disabled:opacity-50 hover:bg-transparent',
        day_button: 'h-9 w-9 p-0 font-normal',
        day_selected:
          '!bg-primary !text-primary-foreground hover:!bg-primary hover:!text-primary-foreground focus:!bg-primary focus:!text-primary-foreground',
        today: 'border border-dotted border-primary',
        outside: 'text-muted-foreground/50',
        disabled: 'text-muted-foreground/50 hover:bg-transparent',
        range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground rounded-none',
        range_end: 'rounded-l-none',
        range_start: 'rounded-r-none',
        dropdowns: 'flex justify-between px-10 flex-col-reverse gap-2 flex-1',
        hidden: 'invisible',
        ...classNames,
      }}
      components={{
        PreviousMonthButton: ({ ...props }) => (
          <Button {...props} variant="outline" size="icon">
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
        ),
        NextMonthButton: ({ ...props }) => (
          <Button {...props} variant="outline" size="icon">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        ),
        Dropdown: (props) => {
          const { options, value } = props;
          const _options =
            options?.map((option) => ({
              value: option.value.toString(),
              label: option.label,
            })) || [];
          return (
            <SimpleSelect
              triggerClassName="z-50 flex-1"
              values={_options}
              selectedValue={value?.toString() || ''}
              onChange={(newValue) => {
                // @ts-expect-error - requires native event, passing only necessary field instead
                props.onChange({ target: { value: newValue } });
              }}
            />
          );
        },
      }}
      {...props}
    />
  );
};
Calendar.displayName = 'Calendar';

export { Calendar };
