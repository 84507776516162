import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ToolFundCatalog } from '../../modules/ToolFundDashboard/components/ToolFundCatalog';
export const Route = createFileRoute('/toolFundCatalog/$catalogItemId')({
  component: RouteComponent,
  params: z.object({
    catalogItemId: z.coerce.number().positive(),
  }),
  head: () => {
    return {
      meta: [{ title: 'Tool Fund Catalog' }],
    };
  },
});

function RouteComponent() {
  const { catalogItemId } = Route.useParams();
  return <ToolFundCatalog catalogItemId={catalogItemId} />;
}
