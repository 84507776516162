import { createFileRoute } from '@tanstack/react-router';

import { NewHiresDashboard } from '../modules/NewHire/main';

export const Route = createFileRoute('/newHire')({
  component: RouteComponent,
});

function RouteComponent() {
  return <NewHiresDashboard />;
}
