/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as VehiclesImport } from './routes/vehicles';
import { Route as TransactionsImport } from './routes/transactions';
import { Route as TransactionAdminImport } from './routes/transactionAdmin';
import { Route as ToolsImport } from './routes/tools';
import { Route as ToolFundRequestsImport } from './routes/toolFundRequests';
import { Route as ToolFundDashboardImport } from './routes/toolFundDashboard';
import { Route as SearchImport } from './routes/search';
import { Route as ProjectResourcesImport } from './routes/projectResources';
import { Route as PermitAdminImport } from './routes/permitAdmin';
import { Route as PerdiemImport } from './routes/perdiem';
import { Route as NewHireImport } from './routes/newHire';
import { Route as LogoutImport } from './routes/logout';
import { Route as LodgingRequestsImport } from './routes/lodgingRequests';
import { Route as LodgingAdminImport } from './routes/lodgingAdmin';
import { Route as HomeImport } from './routes/home';
import { Route as FirstcallsImport } from './routes/firstcalls';
import { Route as DriverDashboardImport } from './routes/driverDashboard';
import { Route as DocumentsImport } from './routes/documents';
import { Route as DispatchImport } from './routes/dispatch';
import { Route as DepartmentsImport } from './routes/departments';
import { Route as CustomerServiceDashboardImport } from './routes/customer-service-dashboard';
import { Route as CreditCardsImport } from './routes/creditCards';
import { Route as CallsPendingImport } from './routes/callsPending';
import { Route as CallWindowImport } from './routes/callWindow';
import { Route as AccountsPayableImport } from './routes/accountsPayable';
import { Route as IndexImport } from './routes/index';
import { Route as ToolLogIndexImport } from './routes/toolLog/index';
import { Route as ToolFundCatalogIndexImport } from './routes/toolFundCatalog/index';
import { Route as SpiffLogIndexImport } from './routes/spiffLog/index';
import { Route as ReportsIndexImport } from './routes/reports/index';
import { Route as PropertiesIndexImport } from './routes/properties/index';
import { Route as PayrollIndexImport } from './routes/payroll/index';
import { Route as NotesIndexImport } from './routes/notes/index';
import { Route as JobsIndexImport } from './routes/jobs/index';
import { Route as HrDashboardIndexImport } from './routes/hrDashboard/index';
import { Route as EmployeesIndexImport } from './routes/employees/index';
import { Route as CustomersIndexImport } from './routes/customers/index';
import { Route as CalendarIndexImport } from './routes/calendar/index';
import { Route as ToolsNitrogenImport } from './routes/tools.nitrogen';
import { Route as ToolLogToolLogOwnerIdImport } from './routes/toolLog/$toolLogOwnerId';
import { Route as ToolFundCatalogCatalogItemIdImport } from './routes/toolFundCatalog/$catalogItemId';
import { Route as TimesheetTimesheetOwnerIdImport } from './routes/timesheet.$timesheetOwnerId';
import { Route as TimeoffTimeOffRequestIdImport } from './routes/timeoff.$timeOffRequestId';
import { Route as SpiffLogSpiffLogOwnerIdImport } from './routes/spiffLog/$spiffLogOwnerId';
import { Route as SoftphonePopupImport } from './routes/softphone/popup';
import { Route as SoftphoneOauthCallbackImport } from './routes/softphone/oauth-callback';
import { Route as SoftphoneNewJobImport } from './routes/softphone/new-job';
import { Route as ReportsTimeOffImport } from './routes/reports/time-off';
import { Route as ReportsCommDiffImport } from './routes/reports/comm-diff';
import { Route as ReimbursementsReimbursementOwnerIdImport } from './routes/reimbursements.$reimbursementOwnerId';
import { Route as PropertiesPropertyIdImport } from './routes/properties/$propertyId';
import { Route as ProjectsEstimatesImport } from './routes/projects.estimates';
import { Route as ProjectResourcesProjectIdImport } from './routes/projectResources.$projectId';
import { Route as PermitAdminPermitGroupIdImport } from './routes/permitAdmin.$permitGroupId';
import { Route as PayrollManagePerdiemImport } from './routes/payroll/manage-perdiem';
import { Route as NotesNotesIdsImport } from './routes/notes/$notesIds';
import { Route as MemoPreviewMemoIdImport } from './routes/memoPreview.$memoId';
import { Route as LodgingAssignmentLodgingAssignmentIdImport } from './routes/lodgingAssignment.$lodgingAssignmentId';
import { Route as LodgingAdminLodgingRequestIdImport } from './routes/lodgingAdmin.$lodgingRequestId';
import { Route as JobsJobIdImport } from './routes/jobs/$jobId';
import { Route as HrDashboardPrIdImport } from './routes/hrDashboard/$prId';
import { Route as DepartmentsAddImport } from './routes/departments.add';
import { Route as CustomersAddImport } from './routes/customers/add';
import { Route as CustomersCustomerIdImport } from './routes/customers/$customerId';
import { Route as CalendarInitialCustomerIdImport } from './routes/calendar/$initialCustomerId';
import { Route as UserTimeOffUserIdImport } from './routes/user.time-off.$userId';
import { Route as StripeSuccessTokenImport } from './routes/stripe.success.$token';
import { Route as HrDashboardNewHireCreateImport } from './routes/hrDashboard/newHire.create';
import { Route as EmployeesItemsOptionsImport } from './routes/employees/items.options';
import { Route as DocumentsInvoicesInvoiceIdImport } from './routes/documents.invoices.$invoiceId';
import { Route as DepartmentsEditFunctionIdImport } from './routes/departments.edit.$functionId';
import { Route as PermitAdminPermitGroupIdPermitsPermitIdImport } from './routes/permitAdmin.$permitGroupId.permits.$permitId';
import { Route as HrDashboardNewHireEditNewHireIdImport } from './routes/hrDashboard/newHire.edit.$newHireId';
import { Route as CustomersUserIdPropertiesPropertyIdImport } from './routes/customers/$userId.properties.$propertyId';

// Create/Update Routes

const VehiclesRoute = VehiclesImport.update({
  id: '/vehicles',
  path: '/vehicles',
  getParentRoute: () => rootRoute,
} as any);

const TransactionsRoute = TransactionsImport.update({
  id: '/transactions',
  path: '/transactions',
  getParentRoute: () => rootRoute,
} as any);

const TransactionAdminRoute = TransactionAdminImport.update({
  id: '/transactionAdmin',
  path: '/transactionAdmin',
  getParentRoute: () => rootRoute,
} as any);

const ToolsRoute = ToolsImport.update({
  id: '/tools',
  path: '/tools',
  getParentRoute: () => rootRoute,
} as any);

const ToolFundRequestsRoute = ToolFundRequestsImport.update({
  id: '/toolFundRequests',
  path: '/toolFundRequests',
  getParentRoute: () => rootRoute,
} as any);

const ToolFundDashboardRoute = ToolFundDashboardImport.update({
  id: '/toolFundDashboard',
  path: '/toolFundDashboard',
  getParentRoute: () => rootRoute,
} as any);

const SearchRoute = SearchImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any);

const ProjectResourcesRoute = ProjectResourcesImport.update({
  id: '/projectResources',
  path: '/projectResources',
  getParentRoute: () => rootRoute,
} as any);

const PermitAdminRoute = PermitAdminImport.update({
  id: '/permitAdmin',
  path: '/permitAdmin',
  getParentRoute: () => rootRoute,
} as any);

const PerdiemRoute = PerdiemImport.update({
  id: '/perdiem',
  path: '/perdiem',
  getParentRoute: () => rootRoute,
} as any);

const NewHireRoute = NewHireImport.update({
  id: '/newHire',
  path: '/newHire',
  getParentRoute: () => rootRoute,
} as any);

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any);

const LodgingRequestsRoute = LodgingRequestsImport.update({
  id: '/lodgingRequests',
  path: '/lodgingRequests',
  getParentRoute: () => rootRoute,
} as any);

const LodgingAdminRoute = LodgingAdminImport.update({
  id: '/lodgingAdmin',
  path: '/lodgingAdmin',
  getParentRoute: () => rootRoute,
} as any);

const HomeRoute = HomeImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => rootRoute,
} as any);

const FirstcallsRoute = FirstcallsImport.update({
  id: '/firstcalls',
  path: '/firstcalls',
  getParentRoute: () => rootRoute,
} as any);

const DriverDashboardRoute = DriverDashboardImport.update({
  id: '/driverDashboard',
  path: '/driverDashboard',
  getParentRoute: () => rootRoute,
} as any);

const DocumentsRoute = DocumentsImport.update({
  id: '/documents',
  path: '/documents',
  getParentRoute: () => rootRoute,
} as any);

const DispatchRoute = DispatchImport.update({
  id: '/dispatch',
  path: '/dispatch',
  getParentRoute: () => rootRoute,
} as any);

const DepartmentsRoute = DepartmentsImport.update({
  id: '/departments',
  path: '/departments',
  getParentRoute: () => rootRoute,
} as any);

const CustomerServiceDashboardRoute = CustomerServiceDashboardImport.update({
  id: '/customer-service-dashboard',
  path: '/customer-service-dashboard',
  getParentRoute: () => rootRoute,
} as any);

const CreditCardsRoute = CreditCardsImport.update({
  id: '/creditCards',
  path: '/creditCards',
  getParentRoute: () => rootRoute,
} as any);

const CallsPendingRoute = CallsPendingImport.update({
  id: '/callsPending',
  path: '/callsPending',
  getParentRoute: () => rootRoute,
} as any);

const CallWindowRoute = CallWindowImport.update({
  id: '/callWindow',
  path: '/callWindow',
  getParentRoute: () => rootRoute,
} as any);

const AccountsPayableRoute = AccountsPayableImport.update({
  id: '/accountsPayable',
  path: '/accountsPayable',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const ToolLogIndexRoute = ToolLogIndexImport.update({
  id: '/toolLog/',
  path: '/toolLog/',
  getParentRoute: () => rootRoute,
} as any);

const ToolFundCatalogIndexRoute = ToolFundCatalogIndexImport.update({
  id: '/toolFundCatalog/',
  path: '/toolFundCatalog/',
  getParentRoute: () => rootRoute,
} as any);

const SpiffLogIndexRoute = SpiffLogIndexImport.update({
  id: '/spiffLog/',
  path: '/spiffLog/',
  getParentRoute: () => rootRoute,
} as any);

const ReportsIndexRoute = ReportsIndexImport.update({
  id: '/reports/',
  path: '/reports/',
  getParentRoute: () => rootRoute,
} as any);

const PropertiesIndexRoute = PropertiesIndexImport.update({
  id: '/properties/',
  path: '/properties/',
  getParentRoute: () => rootRoute,
} as any);

const PayrollIndexRoute = PayrollIndexImport.update({
  id: '/payroll/',
  path: '/payroll/',
  getParentRoute: () => rootRoute,
} as any);

const NotesIndexRoute = NotesIndexImport.update({
  id: '/notes/',
  path: '/notes/',
  getParentRoute: () => rootRoute,
} as any);

const JobsIndexRoute = JobsIndexImport.update({
  id: '/jobs/',
  path: '/jobs/',
  getParentRoute: () => rootRoute,
} as any);

const HrDashboardIndexRoute = HrDashboardIndexImport.update({
  id: '/hrDashboard/',
  path: '/hrDashboard/',
  getParentRoute: () => rootRoute,
} as any);

const EmployeesIndexRoute = EmployeesIndexImport.update({
  id: '/employees/',
  path: '/employees/',
  getParentRoute: () => rootRoute,
} as any);

const CustomersIndexRoute = CustomersIndexImport.update({
  id: '/customers/',
  path: '/customers/',
  getParentRoute: () => rootRoute,
} as any);

const CalendarIndexRoute = CalendarIndexImport.update({
  id: '/calendar/',
  path: '/calendar/',
  getParentRoute: () => rootRoute,
} as any);

const ToolsNitrogenRoute = ToolsNitrogenImport.update({
  id: '/nitrogen',
  path: '/nitrogen',
  getParentRoute: () => ToolsRoute,
} as any);

const ToolLogToolLogOwnerIdRoute = ToolLogToolLogOwnerIdImport.update({
  id: '/toolLog/$toolLogOwnerId',
  path: '/toolLog/$toolLogOwnerId',
  getParentRoute: () => rootRoute,
} as any);

const ToolFundCatalogCatalogItemIdRoute = ToolFundCatalogCatalogItemIdImport.update({
  id: '/toolFundCatalog/$catalogItemId',
  path: '/toolFundCatalog/$catalogItemId',
  getParentRoute: () => rootRoute,
} as any);

const TimesheetTimesheetOwnerIdRoute = TimesheetTimesheetOwnerIdImport.update({
  id: '/timesheet/$timesheetOwnerId',
  path: '/timesheet/$timesheetOwnerId',
  getParentRoute: () => rootRoute,
} as any);

const TimeoffTimeOffRequestIdRoute = TimeoffTimeOffRequestIdImport.update({
  id: '/timeoff/$timeOffRequestId',
  path: '/timeoff/$timeOffRequestId',
  getParentRoute: () => rootRoute,
} as any);

const SpiffLogSpiffLogOwnerIdRoute = SpiffLogSpiffLogOwnerIdImport.update({
  id: '/spiffLog/$spiffLogOwnerId',
  path: '/spiffLog/$spiffLogOwnerId',
  getParentRoute: () => rootRoute,
} as any);

const SoftphonePopupRoute = SoftphonePopupImport.update({
  id: '/softphone/popup',
  path: '/softphone/popup',
  getParentRoute: () => rootRoute,
} as any);

const SoftphoneOauthCallbackRoute = SoftphoneOauthCallbackImport.update({
  id: '/softphone/oauth-callback',
  path: '/softphone/oauth-callback',
  getParentRoute: () => rootRoute,
} as any);

const SoftphoneNewJobRoute = SoftphoneNewJobImport.update({
  id: '/softphone/new-job',
  path: '/softphone/new-job',
  getParentRoute: () => rootRoute,
} as any);

const ReportsTimeOffRoute = ReportsTimeOffImport.update({
  id: '/reports/time-off',
  path: '/reports/time-off',
  getParentRoute: () => rootRoute,
} as any);

const ReportsCommDiffRoute = ReportsCommDiffImport.update({
  id: '/reports/comm-diff',
  path: '/reports/comm-diff',
  getParentRoute: () => rootRoute,
} as any);

const ReimbursementsReimbursementOwnerIdRoute = ReimbursementsReimbursementOwnerIdImport.update({
  id: '/reimbursements/$reimbursementOwnerId',
  path: '/reimbursements/$reimbursementOwnerId',
  getParentRoute: () => rootRoute,
} as any);

const PropertiesPropertyIdRoute = PropertiesPropertyIdImport.update({
  id: '/properties/$propertyId',
  path: '/properties/$propertyId',
  getParentRoute: () => rootRoute,
} as any);

const ProjectsEstimatesRoute = ProjectsEstimatesImport.update({
  id: '/projects/estimates',
  path: '/projects/estimates',
  getParentRoute: () => rootRoute,
} as any);

const ProjectResourcesProjectIdRoute = ProjectResourcesProjectIdImport.update({
  id: '/$projectId',
  path: '/$projectId',
  getParentRoute: () => ProjectResourcesRoute,
} as any);

const PermitAdminPermitGroupIdRoute = PermitAdminPermitGroupIdImport.update({
  id: '/$permitGroupId',
  path: '/$permitGroupId',
  getParentRoute: () => PermitAdminRoute,
} as any);

const PayrollManagePerdiemRoute = PayrollManagePerdiemImport.update({
  id: '/payroll/manage-perdiem',
  path: '/payroll/manage-perdiem',
  getParentRoute: () => rootRoute,
} as any);

const NotesNotesIdsRoute = NotesNotesIdsImport.update({
  id: '/notes/$notesIds',
  path: '/notes/$notesIds',
  getParentRoute: () => rootRoute,
} as any);

const MemoPreviewMemoIdRoute = MemoPreviewMemoIdImport.update({
  id: '/memoPreview/$memoId',
  path: '/memoPreview/$memoId',
  getParentRoute: () => rootRoute,
} as any);

const LodgingAssignmentLodgingAssignmentIdRoute = LodgingAssignmentLodgingAssignmentIdImport.update(
  {
    id: '/lodgingAssignment/$lodgingAssignmentId',
    path: '/lodgingAssignment/$lodgingAssignmentId',
    getParentRoute: () => rootRoute,
  } as any,
);

const LodgingAdminLodgingRequestIdRoute = LodgingAdminLodgingRequestIdImport.update({
  id: '/$lodgingRequestId',
  path: '/$lodgingRequestId',
  getParentRoute: () => LodgingAdminRoute,
} as any);

const JobsJobIdRoute = JobsJobIdImport.update({
  id: '/jobs/$jobId',
  path: '/jobs/$jobId',
  getParentRoute: () => rootRoute,
} as any);

const HrDashboardPrIdRoute = HrDashboardPrIdImport.update({
  id: '/hrDashboard/$prId',
  path: '/hrDashboard/$prId',
  getParentRoute: () => rootRoute,
} as any);

const DepartmentsAddRoute = DepartmentsAddImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => DepartmentsRoute,
} as any);

const CustomersAddRoute = CustomersAddImport.update({
  id: '/customers/add',
  path: '/customers/add',
  getParentRoute: () => rootRoute,
} as any);

const CustomersCustomerIdRoute = CustomersCustomerIdImport.update({
  id: '/customers/$customerId',
  path: '/customers/$customerId',
  getParentRoute: () => rootRoute,
} as any);

const CalendarInitialCustomerIdRoute = CalendarInitialCustomerIdImport.update({
  id: '/calendar/$initialCustomerId',
  path: '/calendar/$initialCustomerId',
  getParentRoute: () => rootRoute,
} as any);

const UserTimeOffUserIdRoute = UserTimeOffUserIdImport.update({
  id: '/user/time-off/$userId',
  path: '/user/time-off/$userId',
  getParentRoute: () => rootRoute,
} as any);

const StripeSuccessTokenRoute = StripeSuccessTokenImport.update({
  id: '/stripe/success/$token',
  path: '/stripe/success/$token',
  getParentRoute: () => rootRoute,
} as any);

const HrDashboardNewHireCreateRoute = HrDashboardNewHireCreateImport.update({
  id: '/hrDashboard/newHire/create',
  path: '/hrDashboard/newHire/create',
  getParentRoute: () => rootRoute,
} as any);

const EmployeesItemsOptionsRoute = EmployeesItemsOptionsImport.update({
  id: '/employees/items/options',
  path: '/employees/items/options',
  getParentRoute: () => rootRoute,
} as any);

const DocumentsInvoicesInvoiceIdRoute = DocumentsInvoicesInvoiceIdImport.update({
  id: '/invoices/$invoiceId',
  path: '/invoices/$invoiceId',
  getParentRoute: () => DocumentsRoute,
} as any);

const DepartmentsEditFunctionIdRoute = DepartmentsEditFunctionIdImport.update({
  id: '/edit/$functionId',
  path: '/edit/$functionId',
  getParentRoute: () => DepartmentsRoute,
} as any);

const PermitAdminPermitGroupIdPermitsPermitIdRoute =
  PermitAdminPermitGroupIdPermitsPermitIdImport.update({
    id: '/permits/$permitId',
    path: '/permits/$permitId',
    getParentRoute: () => PermitAdminPermitGroupIdRoute,
  } as any);

const HrDashboardNewHireEditNewHireIdRoute = HrDashboardNewHireEditNewHireIdImport.update({
  id: '/hrDashboard/newHire/edit/$newHireId',
  path: '/hrDashboard/newHire/edit/$newHireId',
  getParentRoute: () => rootRoute,
} as any);

const CustomersUserIdPropertiesPropertyIdRoute = CustomersUserIdPropertiesPropertyIdImport.update({
  id: '/customers/$userId/properties/$propertyId',
  path: '/customers/$userId/properties/$propertyId',
  getParentRoute: () => rootRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/accountsPayable': {
      id: '/accountsPayable';
      path: '/accountsPayable';
      fullPath: '/accountsPayable';
      preLoaderRoute: typeof AccountsPayableImport;
      parentRoute: typeof rootRoute;
    };
    '/callWindow': {
      id: '/callWindow';
      path: '/callWindow';
      fullPath: '/callWindow';
      preLoaderRoute: typeof CallWindowImport;
      parentRoute: typeof rootRoute;
    };
    '/callsPending': {
      id: '/callsPending';
      path: '/callsPending';
      fullPath: '/callsPending';
      preLoaderRoute: typeof CallsPendingImport;
      parentRoute: typeof rootRoute;
    };
    '/creditCards': {
      id: '/creditCards';
      path: '/creditCards';
      fullPath: '/creditCards';
      preLoaderRoute: typeof CreditCardsImport;
      parentRoute: typeof rootRoute;
    };
    '/customer-service-dashboard': {
      id: '/customer-service-dashboard';
      path: '/customer-service-dashboard';
      fullPath: '/customer-service-dashboard';
      preLoaderRoute: typeof CustomerServiceDashboardImport;
      parentRoute: typeof rootRoute;
    };
    '/departments': {
      id: '/departments';
      path: '/departments';
      fullPath: '/departments';
      preLoaderRoute: typeof DepartmentsImport;
      parentRoute: typeof rootRoute;
    };
    '/dispatch': {
      id: '/dispatch';
      path: '/dispatch';
      fullPath: '/dispatch';
      preLoaderRoute: typeof DispatchImport;
      parentRoute: typeof rootRoute;
    };
    '/documents': {
      id: '/documents';
      path: '/documents';
      fullPath: '/documents';
      preLoaderRoute: typeof DocumentsImport;
      parentRoute: typeof rootRoute;
    };
    '/driverDashboard': {
      id: '/driverDashboard';
      path: '/driverDashboard';
      fullPath: '/driverDashboard';
      preLoaderRoute: typeof DriverDashboardImport;
      parentRoute: typeof rootRoute;
    };
    '/firstcalls': {
      id: '/firstcalls';
      path: '/firstcalls';
      fullPath: '/firstcalls';
      preLoaderRoute: typeof FirstcallsImport;
      parentRoute: typeof rootRoute;
    };
    '/home': {
      id: '/home';
      path: '/home';
      fullPath: '/home';
      preLoaderRoute: typeof HomeImport;
      parentRoute: typeof rootRoute;
    };
    '/lodgingAdmin': {
      id: '/lodgingAdmin';
      path: '/lodgingAdmin';
      fullPath: '/lodgingAdmin';
      preLoaderRoute: typeof LodgingAdminImport;
      parentRoute: typeof rootRoute;
    };
    '/lodgingRequests': {
      id: '/lodgingRequests';
      path: '/lodgingRequests';
      fullPath: '/lodgingRequests';
      preLoaderRoute: typeof LodgingRequestsImport;
      parentRoute: typeof rootRoute;
    };
    '/logout': {
      id: '/logout';
      path: '/logout';
      fullPath: '/logout';
      preLoaderRoute: typeof LogoutImport;
      parentRoute: typeof rootRoute;
    };
    '/newHire': {
      id: '/newHire';
      path: '/newHire';
      fullPath: '/newHire';
      preLoaderRoute: typeof NewHireImport;
      parentRoute: typeof rootRoute;
    };
    '/perdiem': {
      id: '/perdiem';
      path: '/perdiem';
      fullPath: '/perdiem';
      preLoaderRoute: typeof PerdiemImport;
      parentRoute: typeof rootRoute;
    };
    '/permitAdmin': {
      id: '/permitAdmin';
      path: '/permitAdmin';
      fullPath: '/permitAdmin';
      preLoaderRoute: typeof PermitAdminImport;
      parentRoute: typeof rootRoute;
    };
    '/projectResources': {
      id: '/projectResources';
      path: '/projectResources';
      fullPath: '/projectResources';
      preLoaderRoute: typeof ProjectResourcesImport;
      parentRoute: typeof rootRoute;
    };
    '/search': {
      id: '/search';
      path: '/search';
      fullPath: '/search';
      preLoaderRoute: typeof SearchImport;
      parentRoute: typeof rootRoute;
    };
    '/toolFundDashboard': {
      id: '/toolFundDashboard';
      path: '/toolFundDashboard';
      fullPath: '/toolFundDashboard';
      preLoaderRoute: typeof ToolFundDashboardImport;
      parentRoute: typeof rootRoute;
    };
    '/toolFundRequests': {
      id: '/toolFundRequests';
      path: '/toolFundRequests';
      fullPath: '/toolFundRequests';
      preLoaderRoute: typeof ToolFundRequestsImport;
      parentRoute: typeof rootRoute;
    };
    '/tools': {
      id: '/tools';
      path: '/tools';
      fullPath: '/tools';
      preLoaderRoute: typeof ToolsImport;
      parentRoute: typeof rootRoute;
    };
    '/transactionAdmin': {
      id: '/transactionAdmin';
      path: '/transactionAdmin';
      fullPath: '/transactionAdmin';
      preLoaderRoute: typeof TransactionAdminImport;
      parentRoute: typeof rootRoute;
    };
    '/transactions': {
      id: '/transactions';
      path: '/transactions';
      fullPath: '/transactions';
      preLoaderRoute: typeof TransactionsImport;
      parentRoute: typeof rootRoute;
    };
    '/vehicles': {
      id: '/vehicles';
      path: '/vehicles';
      fullPath: '/vehicles';
      preLoaderRoute: typeof VehiclesImport;
      parentRoute: typeof rootRoute;
    };
    '/calendar/$initialCustomerId': {
      id: '/calendar/$initialCustomerId';
      path: '/calendar/$initialCustomerId';
      fullPath: '/calendar/$initialCustomerId';
      preLoaderRoute: typeof CalendarInitialCustomerIdImport;
      parentRoute: typeof rootRoute;
    };
    '/customers/$customerId': {
      id: '/customers/$customerId';
      path: '/customers/$customerId';
      fullPath: '/customers/$customerId';
      preLoaderRoute: typeof CustomersCustomerIdImport;
      parentRoute: typeof rootRoute;
    };
    '/customers/add': {
      id: '/customers/add';
      path: '/customers/add';
      fullPath: '/customers/add';
      preLoaderRoute: typeof CustomersAddImport;
      parentRoute: typeof rootRoute;
    };
    '/departments/add': {
      id: '/departments/add';
      path: '/add';
      fullPath: '/departments/add';
      preLoaderRoute: typeof DepartmentsAddImport;
      parentRoute: typeof DepartmentsImport;
    };
    '/hrDashboard/$prId': {
      id: '/hrDashboard/$prId';
      path: '/hrDashboard/$prId';
      fullPath: '/hrDashboard/$prId';
      preLoaderRoute: typeof HrDashboardPrIdImport;
      parentRoute: typeof rootRoute;
    };
    '/jobs/$jobId': {
      id: '/jobs/$jobId';
      path: '/jobs/$jobId';
      fullPath: '/jobs/$jobId';
      preLoaderRoute: typeof JobsJobIdImport;
      parentRoute: typeof rootRoute;
    };
    '/lodgingAdmin/$lodgingRequestId': {
      id: '/lodgingAdmin/$lodgingRequestId';
      path: '/$lodgingRequestId';
      fullPath: '/lodgingAdmin/$lodgingRequestId';
      preLoaderRoute: typeof LodgingAdminLodgingRequestIdImport;
      parentRoute: typeof LodgingAdminImport;
    };
    '/lodgingAssignment/$lodgingAssignmentId': {
      id: '/lodgingAssignment/$lodgingAssignmentId';
      path: '/lodgingAssignment/$lodgingAssignmentId';
      fullPath: '/lodgingAssignment/$lodgingAssignmentId';
      preLoaderRoute: typeof LodgingAssignmentLodgingAssignmentIdImport;
      parentRoute: typeof rootRoute;
    };
    '/memoPreview/$memoId': {
      id: '/memoPreview/$memoId';
      path: '/memoPreview/$memoId';
      fullPath: '/memoPreview/$memoId';
      preLoaderRoute: typeof MemoPreviewMemoIdImport;
      parentRoute: typeof rootRoute;
    };
    '/notes/$notesIds': {
      id: '/notes/$notesIds';
      path: '/notes/$notesIds';
      fullPath: '/notes/$notesIds';
      preLoaderRoute: typeof NotesNotesIdsImport;
      parentRoute: typeof rootRoute;
    };
    '/payroll/manage-perdiem': {
      id: '/payroll/manage-perdiem';
      path: '/payroll/manage-perdiem';
      fullPath: '/payroll/manage-perdiem';
      preLoaderRoute: typeof PayrollManagePerdiemImport;
      parentRoute: typeof rootRoute;
    };
    '/permitAdmin/$permitGroupId': {
      id: '/permitAdmin/$permitGroupId';
      path: '/$permitGroupId';
      fullPath: '/permitAdmin/$permitGroupId';
      preLoaderRoute: typeof PermitAdminPermitGroupIdImport;
      parentRoute: typeof PermitAdminImport;
    };
    '/projectResources/$projectId': {
      id: '/projectResources/$projectId';
      path: '/$projectId';
      fullPath: '/projectResources/$projectId';
      preLoaderRoute: typeof ProjectResourcesProjectIdImport;
      parentRoute: typeof ProjectResourcesImport;
    };
    '/projects/estimates': {
      id: '/projects/estimates';
      path: '/projects/estimates';
      fullPath: '/projects/estimates';
      preLoaderRoute: typeof ProjectsEstimatesImport;
      parentRoute: typeof rootRoute;
    };
    '/properties/$propertyId': {
      id: '/properties/$propertyId';
      path: '/properties/$propertyId';
      fullPath: '/properties/$propertyId';
      preLoaderRoute: typeof PropertiesPropertyIdImport;
      parentRoute: typeof rootRoute;
    };
    '/reimbursements/$reimbursementOwnerId': {
      id: '/reimbursements/$reimbursementOwnerId';
      path: '/reimbursements/$reimbursementOwnerId';
      fullPath: '/reimbursements/$reimbursementOwnerId';
      preLoaderRoute: typeof ReimbursementsReimbursementOwnerIdImport;
      parentRoute: typeof rootRoute;
    };
    '/reports/comm-diff': {
      id: '/reports/comm-diff';
      path: '/reports/comm-diff';
      fullPath: '/reports/comm-diff';
      preLoaderRoute: typeof ReportsCommDiffImport;
      parentRoute: typeof rootRoute;
    };
    '/reports/time-off': {
      id: '/reports/time-off';
      path: '/reports/time-off';
      fullPath: '/reports/time-off';
      preLoaderRoute: typeof ReportsTimeOffImport;
      parentRoute: typeof rootRoute;
    };
    '/softphone/new-job': {
      id: '/softphone/new-job';
      path: '/softphone/new-job';
      fullPath: '/softphone/new-job';
      preLoaderRoute: typeof SoftphoneNewJobImport;
      parentRoute: typeof rootRoute;
    };
    '/softphone/oauth-callback': {
      id: '/softphone/oauth-callback';
      path: '/softphone/oauth-callback';
      fullPath: '/softphone/oauth-callback';
      preLoaderRoute: typeof SoftphoneOauthCallbackImport;
      parentRoute: typeof rootRoute;
    };
    '/softphone/popup': {
      id: '/softphone/popup';
      path: '/softphone/popup';
      fullPath: '/softphone/popup';
      preLoaderRoute: typeof SoftphonePopupImport;
      parentRoute: typeof rootRoute;
    };
    '/spiffLog/$spiffLogOwnerId': {
      id: '/spiffLog/$spiffLogOwnerId';
      path: '/spiffLog/$spiffLogOwnerId';
      fullPath: '/spiffLog/$spiffLogOwnerId';
      preLoaderRoute: typeof SpiffLogSpiffLogOwnerIdImport;
      parentRoute: typeof rootRoute;
    };
    '/timeoff/$timeOffRequestId': {
      id: '/timeoff/$timeOffRequestId';
      path: '/timeoff/$timeOffRequestId';
      fullPath: '/timeoff/$timeOffRequestId';
      preLoaderRoute: typeof TimeoffTimeOffRequestIdImport;
      parentRoute: typeof rootRoute;
    };
    '/timesheet/$timesheetOwnerId': {
      id: '/timesheet/$timesheetOwnerId';
      path: '/timesheet/$timesheetOwnerId';
      fullPath: '/timesheet/$timesheetOwnerId';
      preLoaderRoute: typeof TimesheetTimesheetOwnerIdImport;
      parentRoute: typeof rootRoute;
    };
    '/toolFundCatalog/$catalogItemId': {
      id: '/toolFundCatalog/$catalogItemId';
      path: '/toolFundCatalog/$catalogItemId';
      fullPath: '/toolFundCatalog/$catalogItemId';
      preLoaderRoute: typeof ToolFundCatalogCatalogItemIdImport;
      parentRoute: typeof rootRoute;
    };
    '/toolLog/$toolLogOwnerId': {
      id: '/toolLog/$toolLogOwnerId';
      path: '/toolLog/$toolLogOwnerId';
      fullPath: '/toolLog/$toolLogOwnerId';
      preLoaderRoute: typeof ToolLogToolLogOwnerIdImport;
      parentRoute: typeof rootRoute;
    };
    '/tools/nitrogen': {
      id: '/tools/nitrogen';
      path: '/nitrogen';
      fullPath: '/tools/nitrogen';
      preLoaderRoute: typeof ToolsNitrogenImport;
      parentRoute: typeof ToolsImport;
    };
    '/calendar/': {
      id: '/calendar/';
      path: '/calendar';
      fullPath: '/calendar';
      preLoaderRoute: typeof CalendarIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/customers/': {
      id: '/customers/';
      path: '/customers';
      fullPath: '/customers';
      preLoaderRoute: typeof CustomersIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/employees/': {
      id: '/employees/';
      path: '/employees';
      fullPath: '/employees';
      preLoaderRoute: typeof EmployeesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/hrDashboard/': {
      id: '/hrDashboard/';
      path: '/hrDashboard';
      fullPath: '/hrDashboard';
      preLoaderRoute: typeof HrDashboardIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/jobs/': {
      id: '/jobs/';
      path: '/jobs';
      fullPath: '/jobs';
      preLoaderRoute: typeof JobsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/notes/': {
      id: '/notes/';
      path: '/notes';
      fullPath: '/notes';
      preLoaderRoute: typeof NotesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/payroll/': {
      id: '/payroll/';
      path: '/payroll';
      fullPath: '/payroll';
      preLoaderRoute: typeof PayrollIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/properties/': {
      id: '/properties/';
      path: '/properties';
      fullPath: '/properties';
      preLoaderRoute: typeof PropertiesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/reports/': {
      id: '/reports/';
      path: '/reports';
      fullPath: '/reports';
      preLoaderRoute: typeof ReportsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/spiffLog/': {
      id: '/spiffLog/';
      path: '/spiffLog';
      fullPath: '/spiffLog';
      preLoaderRoute: typeof SpiffLogIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/toolFundCatalog/': {
      id: '/toolFundCatalog/';
      path: '/toolFundCatalog';
      fullPath: '/toolFundCatalog';
      preLoaderRoute: typeof ToolFundCatalogIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/toolLog/': {
      id: '/toolLog/';
      path: '/toolLog';
      fullPath: '/toolLog';
      preLoaderRoute: typeof ToolLogIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/departments/edit/$functionId': {
      id: '/departments/edit/$functionId';
      path: '/edit/$functionId';
      fullPath: '/departments/edit/$functionId';
      preLoaderRoute: typeof DepartmentsEditFunctionIdImport;
      parentRoute: typeof DepartmentsImport;
    };
    '/documents/invoices/$invoiceId': {
      id: '/documents/invoices/$invoiceId';
      path: '/invoices/$invoiceId';
      fullPath: '/documents/invoices/$invoiceId';
      preLoaderRoute: typeof DocumentsInvoicesInvoiceIdImport;
      parentRoute: typeof DocumentsImport;
    };
    '/employees/items/options': {
      id: '/employees/items/options';
      path: '/employees/items/options';
      fullPath: '/employees/items/options';
      preLoaderRoute: typeof EmployeesItemsOptionsImport;
      parentRoute: typeof rootRoute;
    };
    '/hrDashboard/newHire/create': {
      id: '/hrDashboard/newHire/create';
      path: '/hrDashboard/newHire/create';
      fullPath: '/hrDashboard/newHire/create';
      preLoaderRoute: typeof HrDashboardNewHireCreateImport;
      parentRoute: typeof rootRoute;
    };
    '/stripe/success/$token': {
      id: '/stripe/success/$token';
      path: '/stripe/success/$token';
      fullPath: '/stripe/success/$token';
      preLoaderRoute: typeof StripeSuccessTokenImport;
      parentRoute: typeof rootRoute;
    };
    '/user/time-off/$userId': {
      id: '/user/time-off/$userId';
      path: '/user/time-off/$userId';
      fullPath: '/user/time-off/$userId';
      preLoaderRoute: typeof UserTimeOffUserIdImport;
      parentRoute: typeof rootRoute;
    };
    '/customers/$userId/properties/$propertyId': {
      id: '/customers/$userId/properties/$propertyId';
      path: '/customers/$userId/properties/$propertyId';
      fullPath: '/customers/$userId/properties/$propertyId';
      preLoaderRoute: typeof CustomersUserIdPropertiesPropertyIdImport;
      parentRoute: typeof rootRoute;
    };
    '/hrDashboard/newHire/edit/$newHireId': {
      id: '/hrDashboard/newHire/edit/$newHireId';
      path: '/hrDashboard/newHire/edit/$newHireId';
      fullPath: '/hrDashboard/newHire/edit/$newHireId';
      preLoaderRoute: typeof HrDashboardNewHireEditNewHireIdImport;
      parentRoute: typeof rootRoute;
    };
    '/permitAdmin/$permitGroupId/permits/$permitId': {
      id: '/permitAdmin/$permitGroupId/permits/$permitId';
      path: '/permits/$permitId';
      fullPath: '/permitAdmin/$permitGroupId/permits/$permitId';
      preLoaderRoute: typeof PermitAdminPermitGroupIdPermitsPermitIdImport;
      parentRoute: typeof PermitAdminPermitGroupIdImport;
    };
  }
}

// Create and export the route tree

interface DepartmentsRouteChildren {
  DepartmentsAddRoute: typeof DepartmentsAddRoute;
  DepartmentsEditFunctionIdRoute: typeof DepartmentsEditFunctionIdRoute;
}

const DepartmentsRouteChildren: DepartmentsRouteChildren = {
  DepartmentsAddRoute: DepartmentsAddRoute,
  DepartmentsEditFunctionIdRoute: DepartmentsEditFunctionIdRoute,
};

const DepartmentsRouteWithChildren = DepartmentsRoute._addFileChildren(DepartmentsRouteChildren);

interface DocumentsRouteChildren {
  DocumentsInvoicesInvoiceIdRoute: typeof DocumentsInvoicesInvoiceIdRoute;
}

const DocumentsRouteChildren: DocumentsRouteChildren = {
  DocumentsInvoicesInvoiceIdRoute: DocumentsInvoicesInvoiceIdRoute,
};

const DocumentsRouteWithChildren = DocumentsRoute._addFileChildren(DocumentsRouteChildren);

interface LodgingAdminRouteChildren {
  LodgingAdminLodgingRequestIdRoute: typeof LodgingAdminLodgingRequestIdRoute;
}

const LodgingAdminRouteChildren: LodgingAdminRouteChildren = {
  LodgingAdminLodgingRequestIdRoute: LodgingAdminLodgingRequestIdRoute,
};

const LodgingAdminRouteWithChildren = LodgingAdminRoute._addFileChildren(LodgingAdminRouteChildren);

interface PermitAdminPermitGroupIdRouteChildren {
  PermitAdminPermitGroupIdPermitsPermitIdRoute: typeof PermitAdminPermitGroupIdPermitsPermitIdRoute;
}

const PermitAdminPermitGroupIdRouteChildren: PermitAdminPermitGroupIdRouteChildren = {
  PermitAdminPermitGroupIdPermitsPermitIdRoute: PermitAdminPermitGroupIdPermitsPermitIdRoute,
};

const PermitAdminPermitGroupIdRouteWithChildren = PermitAdminPermitGroupIdRoute._addFileChildren(
  PermitAdminPermitGroupIdRouteChildren,
);

interface PermitAdminRouteChildren {
  PermitAdminPermitGroupIdRoute: typeof PermitAdminPermitGroupIdRouteWithChildren;
}

const PermitAdminRouteChildren: PermitAdminRouteChildren = {
  PermitAdminPermitGroupIdRoute: PermitAdminPermitGroupIdRouteWithChildren,
};

const PermitAdminRouteWithChildren = PermitAdminRoute._addFileChildren(PermitAdminRouteChildren);

interface ProjectResourcesRouteChildren {
  ProjectResourcesProjectIdRoute: typeof ProjectResourcesProjectIdRoute;
}

const ProjectResourcesRouteChildren: ProjectResourcesRouteChildren = {
  ProjectResourcesProjectIdRoute: ProjectResourcesProjectIdRoute,
};

const ProjectResourcesRouteWithChildren = ProjectResourcesRoute._addFileChildren(
  ProjectResourcesRouteChildren,
);

interface ToolsRouteChildren {
  ToolsNitrogenRoute: typeof ToolsNitrogenRoute;
}

const ToolsRouteChildren: ToolsRouteChildren = {
  ToolsNitrogenRoute: ToolsNitrogenRoute,
};

const ToolsRouteWithChildren = ToolsRoute._addFileChildren(ToolsRouteChildren);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/accountsPayable': typeof AccountsPayableRoute;
  '/callWindow': typeof CallWindowRoute;
  '/callsPending': typeof CallsPendingRoute;
  '/creditCards': typeof CreditCardsRoute;
  '/customer-service-dashboard': typeof CustomerServiceDashboardRoute;
  '/departments': typeof DepartmentsRouteWithChildren;
  '/dispatch': typeof DispatchRoute;
  '/documents': typeof DocumentsRouteWithChildren;
  '/driverDashboard': typeof DriverDashboardRoute;
  '/firstcalls': typeof FirstcallsRoute;
  '/home': typeof HomeRoute;
  '/lodgingAdmin': typeof LodgingAdminRouteWithChildren;
  '/lodgingRequests': typeof LodgingRequestsRoute;
  '/logout': typeof LogoutRoute;
  '/newHire': typeof NewHireRoute;
  '/perdiem': typeof PerdiemRoute;
  '/permitAdmin': typeof PermitAdminRouteWithChildren;
  '/projectResources': typeof ProjectResourcesRouteWithChildren;
  '/search': typeof SearchRoute;
  '/toolFundDashboard': typeof ToolFundDashboardRoute;
  '/toolFundRequests': typeof ToolFundRequestsRoute;
  '/tools': typeof ToolsRouteWithChildren;
  '/transactionAdmin': typeof TransactionAdminRoute;
  '/transactions': typeof TransactionsRoute;
  '/vehicles': typeof VehiclesRoute;
  '/calendar/$initialCustomerId': typeof CalendarInitialCustomerIdRoute;
  '/customers/$customerId': typeof CustomersCustomerIdRoute;
  '/customers/add': typeof CustomersAddRoute;
  '/departments/add': typeof DepartmentsAddRoute;
  '/hrDashboard/$prId': typeof HrDashboardPrIdRoute;
  '/jobs/$jobId': typeof JobsJobIdRoute;
  '/lodgingAdmin/$lodgingRequestId': typeof LodgingAdminLodgingRequestIdRoute;
  '/lodgingAssignment/$lodgingAssignmentId': typeof LodgingAssignmentLodgingAssignmentIdRoute;
  '/memoPreview/$memoId': typeof MemoPreviewMemoIdRoute;
  '/notes/$notesIds': typeof NotesNotesIdsRoute;
  '/payroll/manage-perdiem': typeof PayrollManagePerdiemRoute;
  '/permitAdmin/$permitGroupId': typeof PermitAdminPermitGroupIdRouteWithChildren;
  '/projectResources/$projectId': typeof ProjectResourcesProjectIdRoute;
  '/projects/estimates': typeof ProjectsEstimatesRoute;
  '/properties/$propertyId': typeof PropertiesPropertyIdRoute;
  '/reimbursements/$reimbursementOwnerId': typeof ReimbursementsReimbursementOwnerIdRoute;
  '/reports/comm-diff': typeof ReportsCommDiffRoute;
  '/reports/time-off': typeof ReportsTimeOffRoute;
  '/softphone/new-job': typeof SoftphoneNewJobRoute;
  '/softphone/oauth-callback': typeof SoftphoneOauthCallbackRoute;
  '/softphone/popup': typeof SoftphonePopupRoute;
  '/spiffLog/$spiffLogOwnerId': typeof SpiffLogSpiffLogOwnerIdRoute;
  '/timeoff/$timeOffRequestId': typeof TimeoffTimeOffRequestIdRoute;
  '/timesheet/$timesheetOwnerId': typeof TimesheetTimesheetOwnerIdRoute;
  '/toolFundCatalog/$catalogItemId': typeof ToolFundCatalogCatalogItemIdRoute;
  '/toolLog/$toolLogOwnerId': typeof ToolLogToolLogOwnerIdRoute;
  '/tools/nitrogen': typeof ToolsNitrogenRoute;
  '/calendar': typeof CalendarIndexRoute;
  '/customers': typeof CustomersIndexRoute;
  '/employees': typeof EmployeesIndexRoute;
  '/hrDashboard': typeof HrDashboardIndexRoute;
  '/jobs': typeof JobsIndexRoute;
  '/notes': typeof NotesIndexRoute;
  '/payroll': typeof PayrollIndexRoute;
  '/properties': typeof PropertiesIndexRoute;
  '/reports': typeof ReportsIndexRoute;
  '/spiffLog': typeof SpiffLogIndexRoute;
  '/toolFundCatalog': typeof ToolFundCatalogIndexRoute;
  '/toolLog': typeof ToolLogIndexRoute;
  '/departments/edit/$functionId': typeof DepartmentsEditFunctionIdRoute;
  '/documents/invoices/$invoiceId': typeof DocumentsInvoicesInvoiceIdRoute;
  '/employees/items/options': typeof EmployeesItemsOptionsRoute;
  '/hrDashboard/newHire/create': typeof HrDashboardNewHireCreateRoute;
  '/stripe/success/$token': typeof StripeSuccessTokenRoute;
  '/user/time-off/$userId': typeof UserTimeOffUserIdRoute;
  '/customers/$userId/properties/$propertyId': typeof CustomersUserIdPropertiesPropertyIdRoute;
  '/hrDashboard/newHire/edit/$newHireId': typeof HrDashboardNewHireEditNewHireIdRoute;
  '/permitAdmin/$permitGroupId/permits/$permitId': typeof PermitAdminPermitGroupIdPermitsPermitIdRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/accountsPayable': typeof AccountsPayableRoute;
  '/callWindow': typeof CallWindowRoute;
  '/callsPending': typeof CallsPendingRoute;
  '/creditCards': typeof CreditCardsRoute;
  '/customer-service-dashboard': typeof CustomerServiceDashboardRoute;
  '/departments': typeof DepartmentsRouteWithChildren;
  '/dispatch': typeof DispatchRoute;
  '/documents': typeof DocumentsRouteWithChildren;
  '/driverDashboard': typeof DriverDashboardRoute;
  '/firstcalls': typeof FirstcallsRoute;
  '/home': typeof HomeRoute;
  '/lodgingAdmin': typeof LodgingAdminRouteWithChildren;
  '/lodgingRequests': typeof LodgingRequestsRoute;
  '/logout': typeof LogoutRoute;
  '/newHire': typeof NewHireRoute;
  '/perdiem': typeof PerdiemRoute;
  '/permitAdmin': typeof PermitAdminRouteWithChildren;
  '/projectResources': typeof ProjectResourcesRouteWithChildren;
  '/search': typeof SearchRoute;
  '/toolFundDashboard': typeof ToolFundDashboardRoute;
  '/toolFundRequests': typeof ToolFundRequestsRoute;
  '/tools': typeof ToolsRouteWithChildren;
  '/transactionAdmin': typeof TransactionAdminRoute;
  '/transactions': typeof TransactionsRoute;
  '/vehicles': typeof VehiclesRoute;
  '/calendar/$initialCustomerId': typeof CalendarInitialCustomerIdRoute;
  '/customers/$customerId': typeof CustomersCustomerIdRoute;
  '/customers/add': typeof CustomersAddRoute;
  '/departments/add': typeof DepartmentsAddRoute;
  '/hrDashboard/$prId': typeof HrDashboardPrIdRoute;
  '/jobs/$jobId': typeof JobsJobIdRoute;
  '/lodgingAdmin/$lodgingRequestId': typeof LodgingAdminLodgingRequestIdRoute;
  '/lodgingAssignment/$lodgingAssignmentId': typeof LodgingAssignmentLodgingAssignmentIdRoute;
  '/memoPreview/$memoId': typeof MemoPreviewMemoIdRoute;
  '/notes/$notesIds': typeof NotesNotesIdsRoute;
  '/payroll/manage-perdiem': typeof PayrollManagePerdiemRoute;
  '/permitAdmin/$permitGroupId': typeof PermitAdminPermitGroupIdRouteWithChildren;
  '/projectResources/$projectId': typeof ProjectResourcesProjectIdRoute;
  '/projects/estimates': typeof ProjectsEstimatesRoute;
  '/properties/$propertyId': typeof PropertiesPropertyIdRoute;
  '/reimbursements/$reimbursementOwnerId': typeof ReimbursementsReimbursementOwnerIdRoute;
  '/reports/comm-diff': typeof ReportsCommDiffRoute;
  '/reports/time-off': typeof ReportsTimeOffRoute;
  '/softphone/new-job': typeof SoftphoneNewJobRoute;
  '/softphone/oauth-callback': typeof SoftphoneOauthCallbackRoute;
  '/softphone/popup': typeof SoftphonePopupRoute;
  '/spiffLog/$spiffLogOwnerId': typeof SpiffLogSpiffLogOwnerIdRoute;
  '/timeoff/$timeOffRequestId': typeof TimeoffTimeOffRequestIdRoute;
  '/timesheet/$timesheetOwnerId': typeof TimesheetTimesheetOwnerIdRoute;
  '/toolFundCatalog/$catalogItemId': typeof ToolFundCatalogCatalogItemIdRoute;
  '/toolLog/$toolLogOwnerId': typeof ToolLogToolLogOwnerIdRoute;
  '/tools/nitrogen': typeof ToolsNitrogenRoute;
  '/calendar': typeof CalendarIndexRoute;
  '/customers': typeof CustomersIndexRoute;
  '/employees': typeof EmployeesIndexRoute;
  '/hrDashboard': typeof HrDashboardIndexRoute;
  '/jobs': typeof JobsIndexRoute;
  '/notes': typeof NotesIndexRoute;
  '/payroll': typeof PayrollIndexRoute;
  '/properties': typeof PropertiesIndexRoute;
  '/reports': typeof ReportsIndexRoute;
  '/spiffLog': typeof SpiffLogIndexRoute;
  '/toolFundCatalog': typeof ToolFundCatalogIndexRoute;
  '/toolLog': typeof ToolLogIndexRoute;
  '/departments/edit/$functionId': typeof DepartmentsEditFunctionIdRoute;
  '/documents/invoices/$invoiceId': typeof DocumentsInvoicesInvoiceIdRoute;
  '/employees/items/options': typeof EmployeesItemsOptionsRoute;
  '/hrDashboard/newHire/create': typeof HrDashboardNewHireCreateRoute;
  '/stripe/success/$token': typeof StripeSuccessTokenRoute;
  '/user/time-off/$userId': typeof UserTimeOffUserIdRoute;
  '/customers/$userId/properties/$propertyId': typeof CustomersUserIdPropertiesPropertyIdRoute;
  '/hrDashboard/newHire/edit/$newHireId': typeof HrDashboardNewHireEditNewHireIdRoute;
  '/permitAdmin/$permitGroupId/permits/$permitId': typeof PermitAdminPermitGroupIdPermitsPermitIdRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/accountsPayable': typeof AccountsPayableRoute;
  '/callWindow': typeof CallWindowRoute;
  '/callsPending': typeof CallsPendingRoute;
  '/creditCards': typeof CreditCardsRoute;
  '/customer-service-dashboard': typeof CustomerServiceDashboardRoute;
  '/departments': typeof DepartmentsRouteWithChildren;
  '/dispatch': typeof DispatchRoute;
  '/documents': typeof DocumentsRouteWithChildren;
  '/driverDashboard': typeof DriverDashboardRoute;
  '/firstcalls': typeof FirstcallsRoute;
  '/home': typeof HomeRoute;
  '/lodgingAdmin': typeof LodgingAdminRouteWithChildren;
  '/lodgingRequests': typeof LodgingRequestsRoute;
  '/logout': typeof LogoutRoute;
  '/newHire': typeof NewHireRoute;
  '/perdiem': typeof PerdiemRoute;
  '/permitAdmin': typeof PermitAdminRouteWithChildren;
  '/projectResources': typeof ProjectResourcesRouteWithChildren;
  '/search': typeof SearchRoute;
  '/toolFundDashboard': typeof ToolFundDashboardRoute;
  '/toolFundRequests': typeof ToolFundRequestsRoute;
  '/tools': typeof ToolsRouteWithChildren;
  '/transactionAdmin': typeof TransactionAdminRoute;
  '/transactions': typeof TransactionsRoute;
  '/vehicles': typeof VehiclesRoute;
  '/calendar/$initialCustomerId': typeof CalendarInitialCustomerIdRoute;
  '/customers/$customerId': typeof CustomersCustomerIdRoute;
  '/customers/add': typeof CustomersAddRoute;
  '/departments/add': typeof DepartmentsAddRoute;
  '/hrDashboard/$prId': typeof HrDashboardPrIdRoute;
  '/jobs/$jobId': typeof JobsJobIdRoute;
  '/lodgingAdmin/$lodgingRequestId': typeof LodgingAdminLodgingRequestIdRoute;
  '/lodgingAssignment/$lodgingAssignmentId': typeof LodgingAssignmentLodgingAssignmentIdRoute;
  '/memoPreview/$memoId': typeof MemoPreviewMemoIdRoute;
  '/notes/$notesIds': typeof NotesNotesIdsRoute;
  '/payroll/manage-perdiem': typeof PayrollManagePerdiemRoute;
  '/permitAdmin/$permitGroupId': typeof PermitAdminPermitGroupIdRouteWithChildren;
  '/projectResources/$projectId': typeof ProjectResourcesProjectIdRoute;
  '/projects/estimates': typeof ProjectsEstimatesRoute;
  '/properties/$propertyId': typeof PropertiesPropertyIdRoute;
  '/reimbursements/$reimbursementOwnerId': typeof ReimbursementsReimbursementOwnerIdRoute;
  '/reports/comm-diff': typeof ReportsCommDiffRoute;
  '/reports/time-off': typeof ReportsTimeOffRoute;
  '/softphone/new-job': typeof SoftphoneNewJobRoute;
  '/softphone/oauth-callback': typeof SoftphoneOauthCallbackRoute;
  '/softphone/popup': typeof SoftphonePopupRoute;
  '/spiffLog/$spiffLogOwnerId': typeof SpiffLogSpiffLogOwnerIdRoute;
  '/timeoff/$timeOffRequestId': typeof TimeoffTimeOffRequestIdRoute;
  '/timesheet/$timesheetOwnerId': typeof TimesheetTimesheetOwnerIdRoute;
  '/toolFundCatalog/$catalogItemId': typeof ToolFundCatalogCatalogItemIdRoute;
  '/toolLog/$toolLogOwnerId': typeof ToolLogToolLogOwnerIdRoute;
  '/tools/nitrogen': typeof ToolsNitrogenRoute;
  '/calendar/': typeof CalendarIndexRoute;
  '/customers/': typeof CustomersIndexRoute;
  '/employees/': typeof EmployeesIndexRoute;
  '/hrDashboard/': typeof HrDashboardIndexRoute;
  '/jobs/': typeof JobsIndexRoute;
  '/notes/': typeof NotesIndexRoute;
  '/payroll/': typeof PayrollIndexRoute;
  '/properties/': typeof PropertiesIndexRoute;
  '/reports/': typeof ReportsIndexRoute;
  '/spiffLog/': typeof SpiffLogIndexRoute;
  '/toolFundCatalog/': typeof ToolFundCatalogIndexRoute;
  '/toolLog/': typeof ToolLogIndexRoute;
  '/departments/edit/$functionId': typeof DepartmentsEditFunctionIdRoute;
  '/documents/invoices/$invoiceId': typeof DocumentsInvoicesInvoiceIdRoute;
  '/employees/items/options': typeof EmployeesItemsOptionsRoute;
  '/hrDashboard/newHire/create': typeof HrDashboardNewHireCreateRoute;
  '/stripe/success/$token': typeof StripeSuccessTokenRoute;
  '/user/time-off/$userId': typeof UserTimeOffUserIdRoute;
  '/customers/$userId/properties/$propertyId': typeof CustomersUserIdPropertiesPropertyIdRoute;
  '/hrDashboard/newHire/edit/$newHireId': typeof HrDashboardNewHireEditNewHireIdRoute;
  '/permitAdmin/$permitGroupId/permits/$permitId': typeof PermitAdminPermitGroupIdPermitsPermitIdRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/accountsPayable'
    | '/callWindow'
    | '/callsPending'
    | '/creditCards'
    | '/customer-service-dashboard'
    | '/departments'
    | '/dispatch'
    | '/documents'
    | '/driverDashboard'
    | '/firstcalls'
    | '/home'
    | '/lodgingAdmin'
    | '/lodgingRequests'
    | '/logout'
    | '/newHire'
    | '/perdiem'
    | '/permitAdmin'
    | '/projectResources'
    | '/search'
    | '/toolFundDashboard'
    | '/toolFundRequests'
    | '/tools'
    | '/transactionAdmin'
    | '/transactions'
    | '/vehicles'
    | '/calendar/$initialCustomerId'
    | '/customers/$customerId'
    | '/customers/add'
    | '/departments/add'
    | '/hrDashboard/$prId'
    | '/jobs/$jobId'
    | '/lodgingAdmin/$lodgingRequestId'
    | '/lodgingAssignment/$lodgingAssignmentId'
    | '/memoPreview/$memoId'
    | '/notes/$notesIds'
    | '/payroll/manage-perdiem'
    | '/permitAdmin/$permitGroupId'
    | '/projectResources/$projectId'
    | '/projects/estimates'
    | '/properties/$propertyId'
    | '/reimbursements/$reimbursementOwnerId'
    | '/reports/comm-diff'
    | '/reports/time-off'
    | '/softphone/new-job'
    | '/softphone/oauth-callback'
    | '/softphone/popup'
    | '/spiffLog/$spiffLogOwnerId'
    | '/timeoff/$timeOffRequestId'
    | '/timesheet/$timesheetOwnerId'
    | '/toolFundCatalog/$catalogItemId'
    | '/toolLog/$toolLogOwnerId'
    | '/tools/nitrogen'
    | '/calendar'
    | '/customers'
    | '/employees'
    | '/hrDashboard'
    | '/jobs'
    | '/notes'
    | '/payroll'
    | '/properties'
    | '/reports'
    | '/spiffLog'
    | '/toolFundCatalog'
    | '/toolLog'
    | '/departments/edit/$functionId'
    | '/documents/invoices/$invoiceId'
    | '/employees/items/options'
    | '/hrDashboard/newHire/create'
    | '/stripe/success/$token'
    | '/user/time-off/$userId'
    | '/customers/$userId/properties/$propertyId'
    | '/hrDashboard/newHire/edit/$newHireId'
    | '/permitAdmin/$permitGroupId/permits/$permitId';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/accountsPayable'
    | '/callWindow'
    | '/callsPending'
    | '/creditCards'
    | '/customer-service-dashboard'
    | '/departments'
    | '/dispatch'
    | '/documents'
    | '/driverDashboard'
    | '/firstcalls'
    | '/home'
    | '/lodgingAdmin'
    | '/lodgingRequests'
    | '/logout'
    | '/newHire'
    | '/perdiem'
    | '/permitAdmin'
    | '/projectResources'
    | '/search'
    | '/toolFundDashboard'
    | '/toolFundRequests'
    | '/tools'
    | '/transactionAdmin'
    | '/transactions'
    | '/vehicles'
    | '/calendar/$initialCustomerId'
    | '/customers/$customerId'
    | '/customers/add'
    | '/departments/add'
    | '/hrDashboard/$prId'
    | '/jobs/$jobId'
    | '/lodgingAdmin/$lodgingRequestId'
    | '/lodgingAssignment/$lodgingAssignmentId'
    | '/memoPreview/$memoId'
    | '/notes/$notesIds'
    | '/payroll/manage-perdiem'
    | '/permitAdmin/$permitGroupId'
    | '/projectResources/$projectId'
    | '/projects/estimates'
    | '/properties/$propertyId'
    | '/reimbursements/$reimbursementOwnerId'
    | '/reports/comm-diff'
    | '/reports/time-off'
    | '/softphone/new-job'
    | '/softphone/oauth-callback'
    | '/softphone/popup'
    | '/spiffLog/$spiffLogOwnerId'
    | '/timeoff/$timeOffRequestId'
    | '/timesheet/$timesheetOwnerId'
    | '/toolFundCatalog/$catalogItemId'
    | '/toolLog/$toolLogOwnerId'
    | '/tools/nitrogen'
    | '/calendar'
    | '/customers'
    | '/employees'
    | '/hrDashboard'
    | '/jobs'
    | '/notes'
    | '/payroll'
    | '/properties'
    | '/reports'
    | '/spiffLog'
    | '/toolFundCatalog'
    | '/toolLog'
    | '/departments/edit/$functionId'
    | '/documents/invoices/$invoiceId'
    | '/employees/items/options'
    | '/hrDashboard/newHire/create'
    | '/stripe/success/$token'
    | '/user/time-off/$userId'
    | '/customers/$userId/properties/$propertyId'
    | '/hrDashboard/newHire/edit/$newHireId'
    | '/permitAdmin/$permitGroupId/permits/$permitId';
  id:
    | '__root__'
    | '/'
    | '/accountsPayable'
    | '/callWindow'
    | '/callsPending'
    | '/creditCards'
    | '/customer-service-dashboard'
    | '/departments'
    | '/dispatch'
    | '/documents'
    | '/driverDashboard'
    | '/firstcalls'
    | '/home'
    | '/lodgingAdmin'
    | '/lodgingRequests'
    | '/logout'
    | '/newHire'
    | '/perdiem'
    | '/permitAdmin'
    | '/projectResources'
    | '/search'
    | '/toolFundDashboard'
    | '/toolFundRequests'
    | '/tools'
    | '/transactionAdmin'
    | '/transactions'
    | '/vehicles'
    | '/calendar/$initialCustomerId'
    | '/customers/$customerId'
    | '/customers/add'
    | '/departments/add'
    | '/hrDashboard/$prId'
    | '/jobs/$jobId'
    | '/lodgingAdmin/$lodgingRequestId'
    | '/lodgingAssignment/$lodgingAssignmentId'
    | '/memoPreview/$memoId'
    | '/notes/$notesIds'
    | '/payroll/manage-perdiem'
    | '/permitAdmin/$permitGroupId'
    | '/projectResources/$projectId'
    | '/projects/estimates'
    | '/properties/$propertyId'
    | '/reimbursements/$reimbursementOwnerId'
    | '/reports/comm-diff'
    | '/reports/time-off'
    | '/softphone/new-job'
    | '/softphone/oauth-callback'
    | '/softphone/popup'
    | '/spiffLog/$spiffLogOwnerId'
    | '/timeoff/$timeOffRequestId'
    | '/timesheet/$timesheetOwnerId'
    | '/toolFundCatalog/$catalogItemId'
    | '/toolLog/$toolLogOwnerId'
    | '/tools/nitrogen'
    | '/calendar/'
    | '/customers/'
    | '/employees/'
    | '/hrDashboard/'
    | '/jobs/'
    | '/notes/'
    | '/payroll/'
    | '/properties/'
    | '/reports/'
    | '/spiffLog/'
    | '/toolFundCatalog/'
    | '/toolLog/'
    | '/departments/edit/$functionId'
    | '/documents/invoices/$invoiceId'
    | '/employees/items/options'
    | '/hrDashboard/newHire/create'
    | '/stripe/success/$token'
    | '/user/time-off/$userId'
    | '/customers/$userId/properties/$propertyId'
    | '/hrDashboard/newHire/edit/$newHireId'
    | '/permitAdmin/$permitGroupId/permits/$permitId';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AccountsPayableRoute: typeof AccountsPayableRoute;
  CallWindowRoute: typeof CallWindowRoute;
  CallsPendingRoute: typeof CallsPendingRoute;
  CreditCardsRoute: typeof CreditCardsRoute;
  CustomerServiceDashboardRoute: typeof CustomerServiceDashboardRoute;
  DepartmentsRoute: typeof DepartmentsRouteWithChildren;
  DispatchRoute: typeof DispatchRoute;
  DocumentsRoute: typeof DocumentsRouteWithChildren;
  DriverDashboardRoute: typeof DriverDashboardRoute;
  FirstcallsRoute: typeof FirstcallsRoute;
  HomeRoute: typeof HomeRoute;
  LodgingAdminRoute: typeof LodgingAdminRouteWithChildren;
  LodgingRequestsRoute: typeof LodgingRequestsRoute;
  LogoutRoute: typeof LogoutRoute;
  NewHireRoute: typeof NewHireRoute;
  PerdiemRoute: typeof PerdiemRoute;
  PermitAdminRoute: typeof PermitAdminRouteWithChildren;
  ProjectResourcesRoute: typeof ProjectResourcesRouteWithChildren;
  SearchRoute: typeof SearchRoute;
  ToolFundDashboardRoute: typeof ToolFundDashboardRoute;
  ToolFundRequestsRoute: typeof ToolFundRequestsRoute;
  ToolsRoute: typeof ToolsRouteWithChildren;
  TransactionAdminRoute: typeof TransactionAdminRoute;
  TransactionsRoute: typeof TransactionsRoute;
  VehiclesRoute: typeof VehiclesRoute;
  CalendarInitialCustomerIdRoute: typeof CalendarInitialCustomerIdRoute;
  CustomersCustomerIdRoute: typeof CustomersCustomerIdRoute;
  CustomersAddRoute: typeof CustomersAddRoute;
  HrDashboardPrIdRoute: typeof HrDashboardPrIdRoute;
  JobsJobIdRoute: typeof JobsJobIdRoute;
  LodgingAssignmentLodgingAssignmentIdRoute: typeof LodgingAssignmentLodgingAssignmentIdRoute;
  MemoPreviewMemoIdRoute: typeof MemoPreviewMemoIdRoute;
  NotesNotesIdsRoute: typeof NotesNotesIdsRoute;
  PayrollManagePerdiemRoute: typeof PayrollManagePerdiemRoute;
  ProjectsEstimatesRoute: typeof ProjectsEstimatesRoute;
  PropertiesPropertyIdRoute: typeof PropertiesPropertyIdRoute;
  ReimbursementsReimbursementOwnerIdRoute: typeof ReimbursementsReimbursementOwnerIdRoute;
  ReportsCommDiffRoute: typeof ReportsCommDiffRoute;
  ReportsTimeOffRoute: typeof ReportsTimeOffRoute;
  SoftphoneNewJobRoute: typeof SoftphoneNewJobRoute;
  SoftphoneOauthCallbackRoute: typeof SoftphoneOauthCallbackRoute;
  SoftphonePopupRoute: typeof SoftphonePopupRoute;
  SpiffLogSpiffLogOwnerIdRoute: typeof SpiffLogSpiffLogOwnerIdRoute;
  TimeoffTimeOffRequestIdRoute: typeof TimeoffTimeOffRequestIdRoute;
  TimesheetTimesheetOwnerIdRoute: typeof TimesheetTimesheetOwnerIdRoute;
  ToolFundCatalogCatalogItemIdRoute: typeof ToolFundCatalogCatalogItemIdRoute;
  ToolLogToolLogOwnerIdRoute: typeof ToolLogToolLogOwnerIdRoute;
  CalendarIndexRoute: typeof CalendarIndexRoute;
  CustomersIndexRoute: typeof CustomersIndexRoute;
  EmployeesIndexRoute: typeof EmployeesIndexRoute;
  HrDashboardIndexRoute: typeof HrDashboardIndexRoute;
  JobsIndexRoute: typeof JobsIndexRoute;
  NotesIndexRoute: typeof NotesIndexRoute;
  PayrollIndexRoute: typeof PayrollIndexRoute;
  PropertiesIndexRoute: typeof PropertiesIndexRoute;
  ReportsIndexRoute: typeof ReportsIndexRoute;
  SpiffLogIndexRoute: typeof SpiffLogIndexRoute;
  ToolFundCatalogIndexRoute: typeof ToolFundCatalogIndexRoute;
  ToolLogIndexRoute: typeof ToolLogIndexRoute;
  EmployeesItemsOptionsRoute: typeof EmployeesItemsOptionsRoute;
  HrDashboardNewHireCreateRoute: typeof HrDashboardNewHireCreateRoute;
  StripeSuccessTokenRoute: typeof StripeSuccessTokenRoute;
  UserTimeOffUserIdRoute: typeof UserTimeOffUserIdRoute;
  CustomersUserIdPropertiesPropertyIdRoute: typeof CustomersUserIdPropertiesPropertyIdRoute;
  HrDashboardNewHireEditNewHireIdRoute: typeof HrDashboardNewHireEditNewHireIdRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AccountsPayableRoute: AccountsPayableRoute,
  CallWindowRoute: CallWindowRoute,
  CallsPendingRoute: CallsPendingRoute,
  CreditCardsRoute: CreditCardsRoute,
  CustomerServiceDashboardRoute: CustomerServiceDashboardRoute,
  DepartmentsRoute: DepartmentsRouteWithChildren,
  DispatchRoute: DispatchRoute,
  DocumentsRoute: DocumentsRouteWithChildren,
  DriverDashboardRoute: DriverDashboardRoute,
  FirstcallsRoute: FirstcallsRoute,
  HomeRoute: HomeRoute,
  LodgingAdminRoute: LodgingAdminRouteWithChildren,
  LodgingRequestsRoute: LodgingRequestsRoute,
  LogoutRoute: LogoutRoute,
  NewHireRoute: NewHireRoute,
  PerdiemRoute: PerdiemRoute,
  PermitAdminRoute: PermitAdminRouteWithChildren,
  ProjectResourcesRoute: ProjectResourcesRouteWithChildren,
  SearchRoute: SearchRoute,
  ToolFundDashboardRoute: ToolFundDashboardRoute,
  ToolFundRequestsRoute: ToolFundRequestsRoute,
  ToolsRoute: ToolsRouteWithChildren,
  TransactionAdminRoute: TransactionAdminRoute,
  TransactionsRoute: TransactionsRoute,
  VehiclesRoute: VehiclesRoute,
  CalendarInitialCustomerIdRoute: CalendarInitialCustomerIdRoute,
  CustomersCustomerIdRoute: CustomersCustomerIdRoute,
  CustomersAddRoute: CustomersAddRoute,
  HrDashboardPrIdRoute: HrDashboardPrIdRoute,
  JobsJobIdRoute: JobsJobIdRoute,
  LodgingAssignmentLodgingAssignmentIdRoute: LodgingAssignmentLodgingAssignmentIdRoute,
  MemoPreviewMemoIdRoute: MemoPreviewMemoIdRoute,
  NotesNotesIdsRoute: NotesNotesIdsRoute,
  PayrollManagePerdiemRoute: PayrollManagePerdiemRoute,
  ProjectsEstimatesRoute: ProjectsEstimatesRoute,
  PropertiesPropertyIdRoute: PropertiesPropertyIdRoute,
  ReimbursementsReimbursementOwnerIdRoute: ReimbursementsReimbursementOwnerIdRoute,
  ReportsCommDiffRoute: ReportsCommDiffRoute,
  ReportsTimeOffRoute: ReportsTimeOffRoute,
  SoftphoneNewJobRoute: SoftphoneNewJobRoute,
  SoftphoneOauthCallbackRoute: SoftphoneOauthCallbackRoute,
  SoftphonePopupRoute: SoftphonePopupRoute,
  SpiffLogSpiffLogOwnerIdRoute: SpiffLogSpiffLogOwnerIdRoute,
  TimeoffTimeOffRequestIdRoute: TimeoffTimeOffRequestIdRoute,
  TimesheetTimesheetOwnerIdRoute: TimesheetTimesheetOwnerIdRoute,
  ToolFundCatalogCatalogItemIdRoute: ToolFundCatalogCatalogItemIdRoute,
  ToolLogToolLogOwnerIdRoute: ToolLogToolLogOwnerIdRoute,
  CalendarIndexRoute: CalendarIndexRoute,
  CustomersIndexRoute: CustomersIndexRoute,
  EmployeesIndexRoute: EmployeesIndexRoute,
  HrDashboardIndexRoute: HrDashboardIndexRoute,
  JobsIndexRoute: JobsIndexRoute,
  NotesIndexRoute: NotesIndexRoute,
  PayrollIndexRoute: PayrollIndexRoute,
  PropertiesIndexRoute: PropertiesIndexRoute,
  ReportsIndexRoute: ReportsIndexRoute,
  SpiffLogIndexRoute: SpiffLogIndexRoute,
  ToolFundCatalogIndexRoute: ToolFundCatalogIndexRoute,
  ToolLogIndexRoute: ToolLogIndexRoute,
  EmployeesItemsOptionsRoute: EmployeesItemsOptionsRoute,
  HrDashboardNewHireCreateRoute: HrDashboardNewHireCreateRoute,
  StripeSuccessTokenRoute: StripeSuccessTokenRoute,
  UserTimeOffUserIdRoute: UserTimeOffUserIdRoute,
  CustomersUserIdPropertiesPropertyIdRoute: CustomersUserIdPropertiesPropertyIdRoute,
  HrDashboardNewHireEditNewHireIdRoute: HrDashboardNewHireEditNewHireIdRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/accountsPayable",
        "/callWindow",
        "/callsPending",
        "/creditCards",
        "/customer-service-dashboard",
        "/departments",
        "/dispatch",
        "/documents",
        "/driverDashboard",
        "/firstcalls",
        "/home",
        "/lodgingAdmin",
        "/lodgingRequests",
        "/logout",
        "/newHire",
        "/perdiem",
        "/permitAdmin",
        "/projectResources",
        "/search",
        "/toolFundDashboard",
        "/toolFundRequests",
        "/tools",
        "/transactionAdmin",
        "/transactions",
        "/vehicles",
        "/calendar/$initialCustomerId",
        "/customers/$customerId",
        "/customers/add",
        "/hrDashboard/$prId",
        "/jobs/$jobId",
        "/lodgingAssignment/$lodgingAssignmentId",
        "/memoPreview/$memoId",
        "/notes/$notesIds",
        "/payroll/manage-perdiem",
        "/projects/estimates",
        "/properties/$propertyId",
        "/reimbursements/$reimbursementOwnerId",
        "/reports/comm-diff",
        "/reports/time-off",
        "/softphone/new-job",
        "/softphone/oauth-callback",
        "/softphone/popup",
        "/spiffLog/$spiffLogOwnerId",
        "/timeoff/$timeOffRequestId",
        "/timesheet/$timesheetOwnerId",
        "/toolFundCatalog/$catalogItemId",
        "/toolLog/$toolLogOwnerId",
        "/calendar/",
        "/customers/",
        "/employees/",
        "/hrDashboard/",
        "/jobs/",
        "/notes/",
        "/payroll/",
        "/properties/",
        "/reports/",
        "/spiffLog/",
        "/toolFundCatalog/",
        "/toolLog/",
        "/employees/items/options",
        "/hrDashboard/newHire/create",
        "/stripe/success/$token",
        "/user/time-off/$userId",
        "/customers/$userId/properties/$propertyId",
        "/hrDashboard/newHire/edit/$newHireId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/accountsPayable": {
      "filePath": "accountsPayable.tsx"
    },
    "/callWindow": {
      "filePath": "callWindow.tsx"
    },
    "/callsPending": {
      "filePath": "callsPending.tsx"
    },
    "/creditCards": {
      "filePath": "creditCards.tsx"
    },
    "/customer-service-dashboard": {
      "filePath": "customer-service-dashboard.tsx"
    },
    "/departments": {
      "filePath": "departments.tsx",
      "children": [
        "/departments/add",
        "/departments/edit/$functionId"
      ]
    },
    "/dispatch": {
      "filePath": "dispatch.tsx"
    },
    "/documents": {
      "filePath": "documents.tsx",
      "children": [
        "/documents/invoices/$invoiceId"
      ]
    },
    "/driverDashboard": {
      "filePath": "driverDashboard.tsx"
    },
    "/firstcalls": {
      "filePath": "firstcalls.tsx"
    },
    "/home": {
      "filePath": "home.tsx"
    },
    "/lodgingAdmin": {
      "filePath": "lodgingAdmin.tsx",
      "children": [
        "/lodgingAdmin/$lodgingRequestId"
      ]
    },
    "/lodgingRequests": {
      "filePath": "lodgingRequests.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/newHire": {
      "filePath": "newHire.tsx"
    },
    "/perdiem": {
      "filePath": "perdiem.tsx"
    },
    "/permitAdmin": {
      "filePath": "permitAdmin.tsx",
      "children": [
        "/permitAdmin/$permitGroupId"
      ]
    },
    "/projectResources": {
      "filePath": "projectResources.tsx",
      "children": [
        "/projectResources/$projectId"
      ]
    },
    "/search": {
      "filePath": "search.tsx"
    },
    "/toolFundDashboard": {
      "filePath": "toolFundDashboard.tsx"
    },
    "/toolFundRequests": {
      "filePath": "toolFundRequests.tsx"
    },
    "/tools": {
      "filePath": "tools.tsx",
      "children": [
        "/tools/nitrogen"
      ]
    },
    "/transactionAdmin": {
      "filePath": "transactionAdmin.tsx"
    },
    "/transactions": {
      "filePath": "transactions.tsx"
    },
    "/vehicles": {
      "filePath": "vehicles.tsx"
    },
    "/calendar/$initialCustomerId": {
      "filePath": "calendar/$initialCustomerId.tsx"
    },
    "/customers/$customerId": {
      "filePath": "customers/$customerId.tsx"
    },
    "/customers/add": {
      "filePath": "customers/add.tsx"
    },
    "/departments/add": {
      "filePath": "departments.add.tsx",
      "parent": "/departments"
    },
    "/hrDashboard/$prId": {
      "filePath": "hrDashboard/$prId.tsx"
    },
    "/jobs/$jobId": {
      "filePath": "jobs/$jobId.tsx"
    },
    "/lodgingAdmin/$lodgingRequestId": {
      "filePath": "lodgingAdmin.$lodgingRequestId.tsx",
      "parent": "/lodgingAdmin"
    },
    "/lodgingAssignment/$lodgingAssignmentId": {
      "filePath": "lodgingAssignment.$lodgingAssignmentId.tsx"
    },
    "/memoPreview/$memoId": {
      "filePath": "memoPreview.$memoId.tsx"
    },
    "/notes/$notesIds": {
      "filePath": "notes/$notesIds.tsx"
    },
    "/payroll/manage-perdiem": {
      "filePath": "payroll/manage-perdiem.tsx"
    },
    "/permitAdmin/$permitGroupId": {
      "filePath": "permitAdmin.$permitGroupId.tsx",
      "parent": "/permitAdmin",
      "children": [
        "/permitAdmin/$permitGroupId/permits/$permitId"
      ]
    },
    "/projectResources/$projectId": {
      "filePath": "projectResources.$projectId.tsx",
      "parent": "/projectResources"
    },
    "/projects/estimates": {
      "filePath": "projects.estimates.tsx"
    },
    "/properties/$propertyId": {
      "filePath": "properties/$propertyId.tsx"
    },
    "/reimbursements/$reimbursementOwnerId": {
      "filePath": "reimbursements.$reimbursementOwnerId.tsx"
    },
    "/reports/comm-diff": {
      "filePath": "reports/comm-diff.tsx"
    },
    "/reports/time-off": {
      "filePath": "reports/time-off.tsx"
    },
    "/softphone/new-job": {
      "filePath": "softphone/new-job.tsx"
    },
    "/softphone/oauth-callback": {
      "filePath": "softphone/oauth-callback.tsx"
    },
    "/softphone/popup": {
      "filePath": "softphone/popup.tsx"
    },
    "/spiffLog/$spiffLogOwnerId": {
      "filePath": "spiffLog/$spiffLogOwnerId.tsx"
    },
    "/timeoff/$timeOffRequestId": {
      "filePath": "timeoff.$timeOffRequestId.tsx"
    },
    "/timesheet/$timesheetOwnerId": {
      "filePath": "timesheet.$timesheetOwnerId.tsx"
    },
    "/toolFundCatalog/$catalogItemId": {
      "filePath": "toolFundCatalog/$catalogItemId.tsx"
    },
    "/toolLog/$toolLogOwnerId": {
      "filePath": "toolLog/$toolLogOwnerId.tsx"
    },
    "/tools/nitrogen": {
      "filePath": "tools.nitrogen.tsx",
      "parent": "/tools"
    },
    "/calendar/": {
      "filePath": "calendar/index.tsx"
    },
    "/customers/": {
      "filePath": "customers/index.tsx"
    },
    "/employees/": {
      "filePath": "employees/index.tsx"
    },
    "/hrDashboard/": {
      "filePath": "hrDashboard/index.tsx"
    },
    "/jobs/": {
      "filePath": "jobs/index.tsx"
    },
    "/notes/": {
      "filePath": "notes/index.tsx"
    },
    "/payroll/": {
      "filePath": "payroll/index.tsx"
    },
    "/properties/": {
      "filePath": "properties/index.tsx"
    },
    "/reports/": {
      "filePath": "reports/index.tsx"
    },
    "/spiffLog/": {
      "filePath": "spiffLog/index.tsx"
    },
    "/toolFundCatalog/": {
      "filePath": "toolFundCatalog/index.tsx"
    },
    "/toolLog/": {
      "filePath": "toolLog/index.tsx"
    },
    "/departments/edit/$functionId": {
      "filePath": "departments.edit.$functionId.tsx",
      "parent": "/departments"
    },
    "/documents/invoices/$invoiceId": {
      "filePath": "documents.invoices.$invoiceId.tsx",
      "parent": "/documents"
    },
    "/employees/items/options": {
      "filePath": "employees/items.options.tsx"
    },
    "/hrDashboard/newHire/create": {
      "filePath": "hrDashboard/newHire.create.tsx"
    },
    "/stripe/success/$token": {
      "filePath": "stripe.success.$token.tsx"
    },
    "/user/time-off/$userId": {
      "filePath": "user.time-off.$userId.tsx"
    },
    "/customers/$userId/properties/$propertyId": {
      "filePath": "customers/$userId.properties.$propertyId.tsx"
    },
    "/hrDashboard/newHire/edit/$newHireId": {
      "filePath": "hrDashboard/newHire.edit.$newHireId.tsx"
    },
    "/permitAdmin/$permitGroupId/permits/$permitId": {
      "filePath": "permitAdmin.$permitGroupId.permits.$permitId.tsx",
      "parent": "/permitAdmin/$permitGroupId"
    }
  }
}
ROUTE_MANIFEST_END */
