import { createFileRoute } from '@tanstack/react-router';

import Transaction from '../modules/TransactionUser/main';

export const Route = createFileRoute('/transactions')({
  component: Transaction,
  head() {
    return {
      meta: [{ title: 'User Transactions' }],
    };
  },
});
