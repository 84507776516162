import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { Timesheet } from '../modules/ComponentsLibrary/Timesheet';

export const Route = createFileRoute('/timesheet/$timesheetOwnerId')({
  component: RouteComponent,
  params: z.object({
    timesheetOwnerId: z.coerce.number().positive(),
  }),
  validateSearch: z.object({
    startOnWeek: z.boolean().optional(),
    week: z.string().date().optional(),
  }),
});

function RouteComponent() {
  const { timesheetOwnerId } = Route.useParams();
  const { startOnWeek, week } = Route.useSearch();
  return <Timesheet timesheetOwnerId={timesheetOwnerId} startOnWeek={startOnWeek} week={week} />;
}
