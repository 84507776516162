import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { getProjectEventQueryConfig } from '../hooks/react-query/useProjectEventsQuery';
import ProjectPage from '../modules/Projects/ProjectPage';

export const Route = createFileRoute('/projectResources/$projectId')({
  component: ProjectPage,
  params: z.object({
    projectId: z.coerce.number().positive(),
  }),
  async loader(ctx) {
    const project = await ctx.context.queryClient.ensureQueryData(
      getProjectEventQueryConfig({ id: ctx.params.projectId }),
    );
    return {
      projectId: ctx.params.projectId,
      project,
    };
  },
  head(ctx) {
    return {
      meta: [{ title: `Project | ${ctx.loaderData.project.name}` }],
    };
  },
});
