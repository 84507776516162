import { createFileRoute } from '@tanstack/react-router';

import { DispatchDashboard } from '../modules/ComponentsLibrary/Dispatch';

export const Route = createFileRoute('/dispatch')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Dispatch' }],
    };
  },
});

function RouteComponent() {
  return <DispatchDashboard />;
}
