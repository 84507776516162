import { toast } from '@kalos/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { PermitsAdminPage } from '../modules/Permits/PermitsAdminPage';
import { permitAdminCheck } from '../tools/permissionChecks';

export const Route = createFileRoute('/permitAdmin')({
  component: PermitsAdminPage,
  loader(ctx) {
    const permissionCheck = permitAdminCheck(ctx.context.authContext.user);
    if (!permissionCheck) {
      toast({
        title: 'You do not have permission to view this page',
        description: 'Please contact your administrator if you believe this is an error',
        variant: 'destructive',
      });
      throw redirect({ to: '/' });
    }
  },
  head() {
    return {
      meta: [{ title: 'Permits Admin' }],
    };
  },
});
