import { createFileRoute, redirect } from '@tanstack/react-router';

import { LodgingAdminPage } from '../modules/Lodging/LodgingAdminPage';
import { lodgingAdminCheck } from '../tools/permissionChecks';

export const Route = createFileRoute('/lodgingAdmin')({
  component: LodgingAdminPage,
  loader(ctx) {
    const permissionCheck = lodgingAdminCheck(ctx.context.authContext.user);
    if (!permissionCheck) {
      throw redirect({ to: '/' });
    }
  },
  head(ctx) {
    return {
      meta: [{ title: 'Lodging Admin' }],
    };
  },
});
