import { toast } from '@kalos/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { getBatchUserQueryConfig } from '../../hooks/react-query/useUserQuery';
import { techniciansUserFilter } from '../../modules/ComponentsLibrary/Pickers/newPickers/utils';
import { SpiffTool } from '../../modules/SpiffToolLogs/components/SpiffTool';

export const Route = createFileRoute('/toolLog/$toolLogOwnerId')({
  component: RouteComponent,
  params: z.object({
    toolLogOwnerId: z.coerce.number().positive(),
  }),
  async loader(ctx) {
    const employees = await ctx.context.queryClient.ensureQueryData(
      getBatchUserQueryConfig(techniciansUserFilter),
    );
    const employee = employees?.results.find((el) => el.id === ctx.params.toolLogOwnerId);
    if (!employee) {
      toast({
        variant: 'destructive',
        title: `Employee not found for tool log (ID: ${ctx.params.toolLogOwnerId})`,
      });
      throw redirect({ to: '/home' });
    }
    return { employee };
  },
  head(ctx) {
    return {
      meta: [
        {
          title: `Tool Log - ${ctx.loaderData.employee.firstname} ${ctx.loaderData.employee.lastname}`,
        },
      ],
    };
  },
});

function RouteComponent() {
  const { toolLogOwnerId } = Route.useParams();
  return <SpiffTool type="Tool" disableActions ownerId={toolLogOwnerId} />;
}
