import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../utils';

const alertVariants = cva(
  'relative w-full rounded-lg border px-4 py-3 text-sm [&>svg]:text-foreground',
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',
        destructive:
          'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
        success:
          'border-green-500/50 bg-green-500 text-white dark:border-green-700 dark:bg-green-700 [&>svg]:text-white',
        info: 'border-sky-300 bg-sky-200 text-foreground dark:border-sky-700 dark:bg-sky-700 [&>svg]:text-sky-500',
        warning:
          'border-yellow-300 bg-yellow-200 text-foreground dark:border-yellow-700 dark:bg-yellow-700 [&>svg]:text-yellow-500',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type AlertProps = React.ComponentProps<'div'> & VariantProps<typeof alertVariants>;

const Alert = ({ className, variant, ...props }: AlertProps) => (
  <div role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
);
Alert.displayName = 'Alert';

type AlertTitleProps = React.ComponentProps<'h5'>;

const AlertTitle = ({ className, children, ...props }: AlertTitleProps) => (
  <h5 className={cn('mb-1 font-medium leading-none tracking-tight', className)} {...props}>
    {children}
  </h5>
);
AlertTitle.displayName = 'AlertTitle';

type AlertDescriptionProps = React.ComponentProps<'div'>;

const AlertDescription = ({ className, ...props }: AlertDescriptionProps) => (
  <div className={cn('text-sm [&_p]:leading-relaxed', className)} {...props} />
);
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
