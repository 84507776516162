'use no memo';

import { type InvoicePaymentLine } from '@kalos/kalos-rpc';
import { Button, DataTable, DataTableColumnHeader, TooltipProvider } from '@kalos/ui';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';

import { TechnicianPreview } from '../../../../../components/UserPreview';
import {
  AmountCell,
  DeleteInvoicePaymentLineAction,
  EditSaveButton,
  InvoiceActions,
  InvoicePaymentLineAmountColumnHeader,
} from './InvoicePaymentLineTableComponents';

export const InvoicePaymentLineTable = ({ data }: { data: InvoicePaymentLine[] }) => {
  const columns = useMemo<ColumnDef<InvoicePaymentLine>[]>(() => {
    return [
      {
        accessorKey: 'invoiceId',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Invoice ID" />;
        },
        meta: {
          className: 'w-32',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.invoiceId}
            </div>
          );
        },
      },
      {
        accessorKey: 'amount',
        header(props) {
          return (
            <div className="flex flex-col items-center gap-1">
              <DataTableColumnHeader column={props.column} className="font-bold" title="Amount" />
              <InvoicePaymentLineAmountColumnHeader />
            </div>
          );
        },
        meta: {
          className: 'w-32 mx-auto text-center',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <AmountCell original={props.row.original} />
            </div>
          );
        },
      },
      {
        accessorKey: 'creatorId',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Creator ID" />;
        },
        meta: {
          className: 'w-32',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <TechnicianPreview userId={props.row.original.creatorId}>
                {({ user }) => (
                  <Button variant="outline" size="sm">
                    {user.firstname} {user.lastname} (ID: {user.id})
                  </Button>
                )}
              </TechnicianPreview>
            </div>
          );
        },
      },
      {
        id: 'actions',
        header(props) {
          return (
            <div className="ml-auto flex items-center justify-end">
              <EditSaveButton />
            </div>
          );
        },
        meta: {
          className: 'w-40 ml-auto flex gap-1 justify-end items-center',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <TooltipProvider>
                <DeleteInvoicePaymentLineAction original={props.row.original} />
                <InvoiceActions original={props.row.original} />
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
  }, []);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
    getRowId: (row, idx) => (row.id ? row.id.toString() : idx.toString()),
  });

  return <DataTable table={table} />;
};
