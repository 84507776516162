import { createFileRoute } from '@tanstack/react-router';

import { TransactionTable } from '../modules/ComponentsLibrary/TransactionTable';
import { transactionSearchScheme } from '../modules/ComponentsLibrary/TransactionTable/components/transactionSearchForm/utils';

export const Route = createFileRoute('/accountsPayable')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Accounts Payable' }],
    };
  },
  validateSearch: transactionSearchScheme,
});

function RouteComponent() {
  return <TransactionTable />;
}
