import { createFileRoute } from '@tanstack/react-router';

import { CustomerServiceDashboardWrapper } from '../modules/CustomerServiceDashboard/components/CustomerServiceDashboardWrapper';

export const Route = createFileRoute('/customer-service-dashboard')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Customer Service Dashboard' }],
    };
  },
});

function RouteComponent() {
  return <CustomerServiceDashboardWrapper />;
}
