import { type ClientSession } from '@kalos/kalos-rpc';

const CHANNEL_NAME = 'kalos-softphone';

type MessageType =
  | 'INCOMING_CALL'
  | 'CALL_ENDED'
  | 'WINDOW_FOCUS_REQUEST'
  | 'TAB_COORDINATION'
  | 'STATE_UPDATE'
  | 'CALL_TRANSFERRED'
  | 'SOFTPHONE_EVENT'
  | 'STREAM_ERROR';

interface BaseMessage {
  type: MessageType;
  timestamp: number;
  tabId: string;
}

interface IncomingCallMessage extends BaseMessage {
  type: 'INCOMING_CALL';
  callerId: string;
  callId: string;
  sessionId: string;
}

interface CallEndedMessage extends BaseMessage {
  type: 'CALL_ENDED';
}

interface WindowFocusRequestMessage extends BaseMessage {
  type: 'WINDOW_FOCUS_REQUEST';
}

interface TabCoordinationMessage extends BaseMessage {
  type: 'TAB_COORDINATION';
  action: 'ping' | 'pong' | 'primary-changed' | 'request-primary';
}

interface StateUpdateMessage extends BaseMessage {
  type: 'STATE_UPDATE';
  data:
    | {
        session: ClientSession | null;
      }
    | {
        requestState: true;
      };
}

interface CallTransferredMessage extends BaseMessage {
  type: 'CALL_TRANSFERRED';
}

interface SoftphoneEventMessage extends BaseMessage {
  type: 'SOFTPHONE_EVENT';
  data: {
    type: number;
    metadata?: Record<string, string>;
    callId?: string;
    fromNumber?: string;
    callerName?: string;
    timestamp?: string;
  };
}

interface StreamErrorMessage extends BaseMessage {
  type: 'STREAM_ERROR';
  data: {
    error: string;
  };
}

export type SoftphoneMessage =
  | IncomingCallMessage
  | CallEndedMessage
  | WindowFocusRequestMessage
  | TabCoordinationMessage
  | StateUpdateMessage
  | CallTransferredMessage
  | SoftphoneEventMessage
  | StreamErrorMessage;

// Helper type for broadcasting messages without tabId and timestamp
type BroadcastMessage =
  | Omit<IncomingCallMessage, 'tabId' | 'timestamp'>
  | Omit<CallEndedMessage, 'tabId' | 'timestamp'>
  | Omit<WindowFocusRequestMessage, 'tabId' | 'timestamp'>
  | Omit<TabCoordinationMessage, 'tabId' | 'timestamp'>
  | Omit<StateUpdateMessage, 'tabId' | 'timestamp'>
  | Omit<CallTransferredMessage, 'tabId' | 'timestamp'>
  | Omit<SoftphoneEventMessage, 'tabId' | 'timestamp'>
  | Omit<StreamErrorMessage, 'tabId' | 'timestamp'>;

type MessageHandler = (message: SoftphoneMessage) => void;

export class SoftphoneMessageBus {
  private static instance: SoftphoneMessageBus;
  private channel: BroadcastChannel;
  private handlers: Set<MessageHandler> = new Set();
  private tabId: string;
  private popupWindow: Window | null = null;

  private constructor() {
    this.tabId = Math.random().toString(36).substring(7);
    this.channel = new BroadcastChannel(CHANNEL_NAME);
    this.setupMessageHandling();
  }

  public static getInstance(): SoftphoneMessageBus {
    if (!SoftphoneMessageBus.instance) {
      SoftphoneMessageBus.instance = new SoftphoneMessageBus();
    }
    return SoftphoneMessageBus.instance;
  }

  private setupMessageHandling() {
    this.channel.onmessage = (event) => {
      const message = event.data as SoftphoneMessage;
      if (message.tabId === this.tabId) return; // Ignore own messages

      this.handlers.forEach((handler) => handler(message));
    };
  }

  public subscribe(handler: MessageHandler) {
    this.handlers.add(handler);
    return () => this.handlers.delete(handler);
  }

  public broadcast(message: BroadcastMessage) {
    this.channel.postMessage({
      ...message,
      tabId: this.tabId,
      timestamp: Date.now(),
    });
  }

  public setPopupWindow(window: Window | null) {
    this.popupWindow = window;
  }

  public getPopupWindow(): Window | null {
    return this.popupWindow;
  }

  public focusPopup() {
    if (this.popupWindow && !this.popupWindow.closed) {
      this.popupWindow.focus();
    }
  }

  public cleanup() {
    this.channel.close();
    this.handlers.clear();
  }

  public getTabId() {
    return this.tabId;
  }
}

export const messageBus = SoftphoneMessageBus.getInstance();
