import { TradeType, type TradeTypeList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TradeTypeFilter = EntityFilter<TradeType>;
export const useBatchGetTradeTypes = <TSelectData = TradeTypeList,>({
  filter = {},
  select,
}: { filter?: TradeTypeFilter; select?: (data: TradeTypeList) => TSelectData } = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.tradeType.root,
      queryKeys.tradeType.list,
      queryKeys.tradeType.getHash(filter),
    ],
    queryFn: async () => {
      const response = await InventoryClientService.BatchGetToolFundTrade(TradeType.create(filter));
      return response;
    },
    select,
  });
};

export const useTradeTypeUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (tradeType: TradeType) => {
      return await InventoryClientService.UpdateTradeType(tradeType);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tradeType.root],
      });
    },
  });
};
