'use client';
import * as React from 'react';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/alertDialog';
import { Button } from '../../components/button';
import { Input } from '../../components/Input';
import { type AlertAction, AlertDialogContext } from './AlertDialogProvider.hooks';

interface AlertDialogState {
  open: boolean;
  title: string;
  body: React.ReactNode;
  type: 'alert' | 'confirm' | 'prompt';
  cancelButton: string;
  actionButton: string;
  defaultValue?: string;
  inputProps?: React.PropsWithoutRef<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  >;
}

function alertDialogReducer(state: AlertDialogState, action: AlertAction): AlertDialogState {
  switch (action.type) {
    case 'close':
      return { ...state, open: false };
    case 'alert':
    case 'confirm':
    case 'prompt':
      return {
        ...state,
        open: true,
        ...action,
        // if they are not passed - we should not keep old values
        body: action.body || '',
        title: action.title || '',
        cancelButton: action.cancelButton || (action.type === 'alert' ? 'Okay' : 'Cancel'),
        actionButton: ('actionButton' in action && action.actionButton) || 'Okay',
      };
    default:
      return state;
  }
}

export function AlertDialogProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(alertDialogReducer, {
    open: false,
    title: '',
    body: '',
    type: 'alert',
    cancelButton: 'Cancel',
    actionButton: 'Okay',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resolveRef = React.useRef<(tf: any) => void>(undefined);

  function close() {
    dispatch({ type: 'close' });
    resolveRef.current?.(false);
  }

  function confirm(value?: string) {
    dispatch({ type: 'close' });
    resolveRef.current?.(value ?? true);
  }

  const dialog = React.useCallback(async <T extends AlertAction>(params: T) => {
    dispatch(params);

    return new Promise<T['type'] extends 'alert' | 'confirm' ? boolean : null | string>(
      (resolve) => {
        resolveRef.current = resolve;
      },
    );
  }, []);

  return (
    <AlertDialogContext value={dialog}>
      {children}
      <AlertDialog
        open={state.open}
        onOpenChange={(open) => {
          if (!open) close();
          return;
        }}
      >
        <AlertDialogContent className="z-[99999]" asChild>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              confirm(event.currentTarget.prompt?.value);
            }}
          >
            <AlertDialogHeader>
              <AlertDialogTitle>{state.title}</AlertDialogTitle>
              {state.body ? <AlertDialogDescription>{state.body}</AlertDialogDescription> : null}
            </AlertDialogHeader>
            {state.type === 'prompt' && (
              <Input name="prompt" defaultValue={state.defaultValue} {...state.inputProps} />
            )}
            <AlertDialogFooter className="gap-2">
              <Button variant="secondary" type="button" onClick={close}>
                {state.cancelButton}
              </Button>
              {state.type === 'alert' ? null : <Button type="submit">{state.actionButton}</Button>}
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </AlertDialogContext>
  );
}
