'use client';
import * as React from 'react';

import { useScroll } from '../hooks/ScrollStoreContext.hooks';
import { cn } from '../utils';

export type InputProps = React.ComponentProps<'input'>;

const Input = ({ className, type, ...props }: InputProps) => {
  const isScrolling = useScroll();
  return (
    <input
      type={type}
      className={cn(
        'border-input placeholder:text-muted-foreground focus-visible:ring-ring flex h-9 w-full rounded-md border bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      disabled={props.disabled || isScrolling}
      {...props}
    />
  );
};

const MoneyInput = ({ className, type = 'number', ...props }: InputProps) => {
  const isScrolling = useScroll();
  return (
    <div className="relative">
      <div className="text-foreground absolute left-2 top-1/2 -translate-y-1/2 font-medium">$</div>
      <input
        disabled={props.disabled || isScrolling}
        type={type}
        className={cn(
          'border-input placeholder:text-muted-foreground focus-visible:ring-ring flex h-9 w-full rounded-md border bg-transparent px-3 py-1 pl-6 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        step={0.01}
        {...props}
      />
    </div>
  );
};

export { Input, MoneyInput };
