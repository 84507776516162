import { createFileRoute } from '@tanstack/react-router';

import { useAuth } from '../../context/AuthContext';
import { EmployeeToolLog } from '../../modules/ToolFundDashboard/components/EmployeeToolLog';

export const Route = createFileRoute('/toolLog/')({
  component: RouteComponent,
});

function RouteComponent() {
  const loggedInUserId = useAuth().user.id;
  return <EmployeeToolLog userId={loggedInUserId} />;
}
