import { createFileRoute } from '@tanstack/react-router';

import { useAuth } from '../../context/AuthContext';
import { SpiffTool } from '../../modules/SpiffToolLogs/components/SpiffTool';

export const Route = createFileRoute('/spiffLog/')({
  component: RouteComponent,
  loader(ctx) {
    const userFullName =
      ctx.context.authContext.user.firstname + ' ' + ctx.context.authContext.user.lastname;
    return {
      userFullName,
    };
  },
  head(ctx) {
    return {
      meta: [{ title: `Spiff Log - ${ctx.loaderData.userFullName}` }],
    };
  },
});

function RouteComponent() {
  const auth = useAuth();
  const loggedUserId = auth.user.id;
  return <SpiffTool type="Spiff" ownerId={loggedUserId} />;
}
