import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { LodgingDetails } from '../modules/Lodging/LodgingAdminPage/LodgingRequestDetails/LodgingRequestDetails';

export const Route = createFileRoute('/lodgingAdmin/$lodgingRequestId')({
  component: LodgingDetails,
  params: z.object({
    lodgingRequestId: z.coerce.number().positive(),
  }),
});
