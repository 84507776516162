import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { CustomerInformationWithPropertyPage } from '../../modules/ComponentsLibrary/CustomerInformation/CustomerInformationWithPropertyPage';
import { PropertyInfo } from '../../modules/PropertyInformation/components/PropertyInfo';

export const Route = createFileRoute('/properties/$propertyId')({
  component: RouteComponent,
  params: z.object({
    propertyId: z.coerce.number().positive(),
  }),
});

function RouteComponent() {
  const { propertyId } = Route.useParams();
  return (
    <>
      <CustomerInformationWithPropertyPage />
      <PropertyInfo propertyId={propertyId} />
    </>
  );
}
