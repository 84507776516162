import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  Button,
  Card,
  CardDescription,
  CardTitle,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  MultipleSelect,
  Separator,
  useConfirm,
} from '@kalos/ui';
import { useCallback, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useAuth } from '../../../../context/AuthContext';
import { useBatchUserQuery } from '../../../../hooks/react-query/useUserQuery';
import { techniciansUserFilter } from '../../Pickers/newPickers/utils';

type ProposalReviewAndSendProps = {
  entries: Array<{ description: string; price: number }>;
  onSubmit: (data: ProposalReviewAndSendFormData) => void;
  onCancel: () => void;
  loading?: boolean;
  proposalFileName: string;
  disabled?: boolean;
};

// Define form schema
const formSchema = z.object({
  selfCC: z.boolean(),
  employeesCC: z.array(z.number()),
});

export type ProposalReviewAndSendFormData = z.infer<typeof formSchema>;

const defaultValues: ProposalReviewAndSendFormData = {
  selfCC: false,
  employeesCC: [],
};

export const ProposalReviewAndSend = ({
  entries,
  onSubmit,
  onCancel,
  loading = false,
  proposalFileName,
  disabled = false,
}: ProposalReviewAndSendProps) => {
  const form = useForm<ProposalReviewAndSendFormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
    disabled,
  });

  const loggedInUser = useAuth().user;

  const techniciansQuery = useBatchUserQuery({
    filters: techniciansUserFilter,
  });

  const techniciansSelectOptions = useMemo(() => {
    if (!techniciansQuery.isSuccess) return [];
    return techniciansQuery.data?.results
      .filter((user) => user.id !== loggedInUser.id)
      .map((technician) => ({
        label: `${technician.firstname} ${technician.lastname}`,
        value: technician.id.toString(),
      }));
  }, [loggedInUser.id, techniciansQuery.data?.results, techniciansQuery.isSuccess]);

  const confirm = useConfirm();
  const handleSubmit = useCallback(
    async (data: ProposalReviewAndSendFormData) => {
      const ok = await confirm({
        title: 'Are you sure you want to send this proposal?',
        body: 'This action cannot be undone.',
        actionButton: 'Send',
        cancelButton: 'Cancel',
      });
      if (ok) {
        onSubmit(data);
      }
    },
    [onSubmit, confirm],
  );

  const entriesTable =
    entries.length > 0 ? (
      <ul className="flex flex-col gap-2 text-sm">
        {entries.map((entry) => (
          <li className="grid grid-cols-[4fr_20px_1fr] gap-2" key={entry.description}>
            <span>{entry.description}</span>
            <span>|</span>
            <span>{`$${entry.price}`}</span>
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-sm">No entries found</p>
    );

  const formId = useId();

  return (
    <div className="space-y-5">
      <Card>
        <CardTitle className="p-4 text-center">Please Review Your Proposal Carefully</CardTitle>
      </Card>

      <h3>Sender Email</h3>
      <Card className="px-4 py-1.5">
        <CardDescription className="text-sm">office@kalosflorida.com</CardDescription>
      </Card>

      <Form {...form}>
        <form id={formId} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
          <FormField
            control={form.control}
            name="selfCC"
            render={({ field }) => (
              <FormItem className="flex items-center space-x-2 space-y-0">
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
                <FormLabel className="cursor-pointer">CC yourself on this proposal</FormLabel>
              </FormItem>
            )}
          />
        </form>

        <FormField
          control={form.control}
          name="employeesCC"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Add Employees to CC</FormLabel>
              <MultipleSelect
                placeholder="Search employee name"
                options={techniciansSelectOptions}
                loading={techniciansQuery.isLoading}
                selected={field.value.map(String)}
                onChange={(val) => {
                  field.onChange(val.map(Number));
                }}
              />
            </FormItem>
          )}
        />
        <Separator className="bg-gray-300" decorative />
      </Form>

      <h3 className="font-medium">Description | Price</h3>
      {entriesTable}
      <Separator decorative className="bg-gray-300" />

      <Card className="p-4">
        <CardTitle>File</CardTitle>
        <CardDescription>{proposalFileName}</CardDescription>
      </Card>

      <Alert variant="warning" className="p-4 text-center">
        Submitting this proposal will send it to the customer. Only send the proposal if you are
        certain it is correct. If you need to make changes, click cancel to go back.
      </Alert>

      <div className="flex justify-end gap-2.5">
        <Button variant="destructive" onClick={onCancel} disabled={disabled}>
          Cancel
        </Button>
        <Button variant="success" isLoading={loading} form={formId} disabled={disabled}>
          Send To Customer
        </Button>
      </div>
    </div>
  );
};
