import { Slot } from '@radix-ui/react-slot';
import { type VariantProps } from 'class-variance-authority';
import React from 'react';

import { cn } from '../../utils';
import { LoadingIcon } from '../loadingIcon';
import { buttonVariants } from './button.variants';

export interface ButtonProps
  extends React.ComponentProps<'button'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

export const Button = ({
  className,
  variant,
  size,
  asChild = false,
  children: childrenProp,
  isLoading,
  ...props
}: ButtonProps) => {
  const Comp = asChild ? Slot : 'button';
  const children = isLoading ? (
    <LoadingIcon className={cn(size !== 'icon-sm' ? 'mr-1' : 'size-3.5')} />
  ) : (
    childrenProp
  );
  return (
    <Comp className={cn(buttonVariants({ variant, size, className }))} {...props}>
      {children}
    </Comp>
  );
};
Button.displayName = 'Button';
