// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "event.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "event.proto" (syntax proto3)
// tslint:disable
import { PropertyDocumentRequest } from "./email";
import { Empty } from "./common";
import { IDInt32 } from "./common";
import { InvoiceBodyReturn } from "./email";
import { InvoiceBodyRequest } from "./email";
import { Bool } from "./common";
import { Double } from "./common";
import { Int32 } from "./common";
import { ProjectTaskList } from "./task";
import { ProjectTask } from "./task";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ChangeOrder } from "./projects_core";
import { Expended } from "./projects_core";
import { Estimate } from "./projects_core";
import { Int32List } from "./common";
import { Transaction } from "./transaction";
import { Trip } from "./perdiem";
import { Task } from "./task";
import { PerDiem } from "./perdiem";
import { TimesheetLine } from "./timesheet_line";
import { TripList } from "./perdiem";
import { TaskList } from "./task";
import { PerDiemList } from "./perdiem";
import { TimesheetLineList } from "./timesheet_line";
import { TransactionList } from "./transaction";
import { TimeoffRequest } from "./timesheet_line";
import { LatLng } from "./common";
import { JobTypeSubtype } from "./job_type";
import { JobSubtype } from "./job_type";
import { JobType } from "./job_type";
import { User } from "./user";
import { Property } from "./property";
import { Document } from "./s3";
import { SQSEmail } from "./email";
// @generated message type with reflection information, may provide speed optimized methods
class SubAccountEventRequest$Type extends MessageType {
    constructor() {
        super("SubAccountEventRequest", [
            { no: 1, name: "sub_account_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event", kind: "message", T: () => Event }
        ]);
    }
    create(value) {
        const message = { subAccountId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sub_account_id */ 1:
                    message.subAccountId = reader.int32();
                    break;
                case /* Event event */ 2:
                    message.event = Event.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 sub_account_id = 1; */
        if (message.subAccountId !== 0)
            writer.tag(1, WireType.Varint).int32(message.subAccountId);
        /* Event event = 2; */
        if (message.event)
            Event.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubAccountEventRequest
 */
export const SubAccountEventRequest = new SubAccountEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MostLikelyJobRequest$Type extends MessageType {
    constructor() {
        super("MostLikelyJobRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "target_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "include_full_event_data", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, targetDate: "", includeFullEventData: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string target_date */ 2:
                    message.targetDate = reader.string();
                    break;
                case /* bool include_full_event_data */ 3:
                    message.includeFullEventData = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string target_date = 2; */
        if (message.targetDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.targetDate);
        /* bool include_full_event_data = 3; */
        if (message.includeFullEventData !== false)
            writer.tag(3, WireType.Varint).bool(message.includeFullEventData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MostLikelyJobRequest
 */
export const MostLikelyJobRequest = new MostLikelyJobRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateStripePaymentLinkRequest$Type extends MessageType {
    constructor() {
        super("GenerateStripePaymentLinkRequest", [
            { no: 1, name: "invoice_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "invoice_job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customer_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value) {
        const message = { invoiceId: 0n, invoiceJobNumber: "", amount: 0, description: "", customerId: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 invoice_id */ 1:
                    message.invoiceId = reader.int64().toBigInt();
                    break;
                case /* string invoice_job_number */ 2:
                    message.invoiceJobNumber = reader.string();
                    break;
                case /* double amount */ 3:
                    message.amount = reader.double();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* int64 customer_id */ 5:
                    message.customerId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 invoice_id = 1; */
        if (message.invoiceId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.invoiceId);
        /* string invoice_job_number = 2; */
        if (message.invoiceJobNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.invoiceJobNumber);
        /* double amount = 3; */
        if (message.amount !== 0)
            writer.tag(3, WireType.Bit64).double(message.amount);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* int64 customer_id = 5; */
        if (message.customerId !== 0n)
            writer.tag(5, WireType.Varint).int64(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GenerateStripePaymentLinkRequest
 */
export const GenerateStripePaymentLinkRequest = new GenerateStripePaymentLinkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateStripePaymentLinkResponse$Type extends MessageType {
    constructor() {
        super("GenerateStripePaymentLinkResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GenerateStripePaymentLinkResponse
 */
export const GenerateStripePaymentLinkResponse = new GenerateStripePaymentLinkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServicesRendered$Type extends MessageType {
    constructor() {
        super("ServicesRendered", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "technician_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "materials_used", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "service_rendered", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "tech_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "time_finished", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "hide_from_timesheet", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "signature_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "signature_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "id_event_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, technicianUserId: 0, name: "", materialsUsed: "", serviceRendered: "", techNotes: "", status: "", datetime: "", timeStarted: "", timeFinished: "", isActive: 0, hideFromTimesheet: 0, signatureId: 0, signatureData: "", fieldMask: [], pageNumber: 0, dateRange: [], dateTarget: [], groupBy: "", withoutLimit: false, idEventList: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 technician_user_id */ 3:
                    message.technicianUserId = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string materials_used */ 5:
                    message.materialsUsed = reader.string();
                    break;
                case /* string service_rendered */ 6:
                    message.serviceRendered = reader.string();
                    break;
                case /* string tech_notes */ 7:
                    message.techNotes = reader.string();
                    break;
                case /* string status */ 8:
                    message.status = reader.string();
                    break;
                case /* string datetime */ 9:
                    message.datetime = reader.string();
                    break;
                case /* string time_started */ 10:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_finished */ 11:
                    message.timeFinished = reader.string();
                    break;
                case /* int32 is_active */ 12:
                    message.isActive = reader.int32();
                    break;
                case /* int32 hide_from_timesheet */ 13:
                    message.hideFromTimesheet = reader.int32();
                    break;
                case /* int32 signature_id */ 14:
                    message.signatureId = reader.int32();
                    break;
                case /* string signature_data */ 15:
                    message.signatureData = reader.string();
                    break;
                case /* repeated string field_mask */ 16:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string date_range */ 18:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 19:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string group_by */ 20:
                    message.groupBy = reader.string();
                    break;
                case /* bool without_limit */ 21:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string id_event_list */ 22:
                    message.idEventList = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 technician_user_id = 3; */
        if (message.technicianUserId !== 0)
            writer.tag(3, WireType.Varint).int32(message.technicianUserId);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string materials_used = 5; */
        if (message.materialsUsed !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.materialsUsed);
        /* string service_rendered = 6; */
        if (message.serviceRendered !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.serviceRendered);
        /* string tech_notes = 7; */
        if (message.techNotes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.techNotes);
        /* string status = 8; */
        if (message.status !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.status);
        /* string datetime = 9; */
        if (message.datetime !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.datetime);
        /* string time_started = 10; */
        if (message.timeStarted !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_finished = 11; */
        if (message.timeFinished !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.timeFinished);
        /* int32 is_active = 12; */
        if (message.isActive !== 0)
            writer.tag(12, WireType.Varint).int32(message.isActive);
        /* int32 hide_from_timesheet = 13; */
        if (message.hideFromTimesheet !== 0)
            writer.tag(13, WireType.Varint).int32(message.hideFromTimesheet);
        /* int32 signature_id = 14; */
        if (message.signatureId !== 0)
            writer.tag(14, WireType.Varint).int32(message.signatureId);
        /* string signature_data = 15; */
        if (message.signatureData !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.signatureData);
        /* repeated string field_mask = 16; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        /* repeated string date_range = 18; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(18, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 19; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string group_by = 20; */
        if (message.groupBy !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.groupBy);
        /* bool without_limit = 21; */
        if (message.withoutLimit !== false)
            writer.tag(21, WireType.Varint).bool(message.withoutLimit);
        /* string id_event_list = 22; */
        if (message.idEventList !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.idEventList);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServicesRendered
 */
export const ServicesRendered = new ServicesRendered$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServicesRenderedList$Type extends MessageType {
    constructor() {
        super("ServicesRenderedList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServicesRendered },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ServicesRendered results */ 1:
                    message.results.push(ServicesRendered.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ServicesRendered results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ServicesRendered.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServicesRenderedList
 */
export const ServicesRenderedList = new ServicesRenderedList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Payment$Type extends MessageType {
    constructor() {
        super("Payment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "collected", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "payee_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "amount_collected", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "dl_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "auth_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "cheque_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "is_invoice_data", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, servicesRenderedId: 0, collected: 0, type: "", payeeUserId: 0, amountCollected: 0, dlNumber: "", authKey: "", chequeNumber: "", isInvoiceData: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 services_rendered_id */ 2:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* int32 collected */ 3:
                    message.collected = reader.int32();
                    break;
                case /* string type */ 4:
                    message.type = reader.string();
                    break;
                case /* int32 payee_user_id */ 5:
                    message.payeeUserId = reader.int32();
                    break;
                case /* double amount_collected */ 6:
                    message.amountCollected = reader.double();
                    break;
                case /* string dl_number */ 7:
                    message.dlNumber = reader.string();
                    break;
                case /* string auth_key */ 8:
                    message.authKey = reader.string();
                    break;
                case /* string cheque_number */ 9:
                    message.chequeNumber = reader.string();
                    break;
                case /* int32 is_invoice_data */ 10:
                    message.isInvoiceData = reader.int32();
                    break;
                case /* repeated string field_mask */ 11:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 12:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 services_rendered_id = 2; */
        if (message.servicesRenderedId !== 0)
            writer.tag(2, WireType.Varint).int32(message.servicesRenderedId);
        /* int32 collected = 3; */
        if (message.collected !== 0)
            writer.tag(3, WireType.Varint).int32(message.collected);
        /* string type = 4; */
        if (message.type !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.type);
        /* int32 payee_user_id = 5; */
        if (message.payeeUserId !== 0)
            writer.tag(5, WireType.Varint).int32(message.payeeUserId);
        /* double amount_collected = 6; */
        if (message.amountCollected !== 0)
            writer.tag(6, WireType.Bit64).double(message.amountCollected);
        /* string dl_number = 7; */
        if (message.dlNumber !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dlNumber);
        /* string auth_key = 8; */
        if (message.authKey !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.authKey);
        /* string cheque_number = 9; */
        if (message.chequeNumber !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.chequeNumber);
        /* int32 is_invoice_data = 10; */
        if (message.isInvoiceData !== 0)
            writer.tag(10, WireType.Varint).int32(message.isInvoiceData);
        /* repeated string field_mask = 11; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 12; */
        if (message.pageNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Payment
 */
export const Payment = new Payment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentList$Type extends MessageType {
    constructor() {
        super("PaymentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Payment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Payment results */ 1:
                    message.results.push(Payment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Payment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Payment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaymentList
 */
export const PaymentList = new PaymentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MaintenanceQuestion$Type extends MessageType {
    constructor() {
        super("MaintenanceQuestion", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "tstat_brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "thermostat", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "plateform", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "float_switch", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "evaporator_coil", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "condenser_coil", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "hurricane_pad", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "lineset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "drain_line", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "gas_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "burner", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "heat_exchanger", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "condition_notes1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "condition_notes2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "condition_notes3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "condition_rating1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "condition_rating2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "condition_rating3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "reading_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, tstatBrand: "", thermostat: 0, plateform: 0, floatSwitch: 0, evaporatorCoil: 0, condenserCoil: 0, hurricanePad: 0, lineset: 0, drainLine: 0, gasType: 0, burner: 0, heatExchanger: 0, conditionNotes1: "", conditionNotes2: "", conditionNotes3: "", conditionRating1: "", conditionRating2: "", conditionRating3: "", readingId: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string tstat_brand */ 2:
                    message.tstatBrand = reader.string();
                    break;
                case /* int32 thermostat */ 3:
                    message.thermostat = reader.int32();
                    break;
                case /* int32 plateform */ 4:
                    message.plateform = reader.int32();
                    break;
                case /* int32 float_switch */ 5:
                    message.floatSwitch = reader.int32();
                    break;
                case /* int32 evaporator_coil */ 6:
                    message.evaporatorCoil = reader.int32();
                    break;
                case /* int32 condenser_coil */ 7:
                    message.condenserCoil = reader.int32();
                    break;
                case /* int32 hurricane_pad */ 8:
                    message.hurricanePad = reader.int32();
                    break;
                case /* int32 lineset */ 9:
                    message.lineset = reader.int32();
                    break;
                case /* int32 drain_line */ 10:
                    message.drainLine = reader.int32();
                    break;
                case /* int32 gas_type */ 11:
                    message.gasType = reader.int32();
                    break;
                case /* int32 burner */ 12:
                    message.burner = reader.int32();
                    break;
                case /* int32 heat_exchanger */ 13:
                    message.heatExchanger = reader.int32();
                    break;
                case /* string condition_notes1 */ 14:
                    message.conditionNotes1 = reader.string();
                    break;
                case /* string condition_notes2 */ 15:
                    message.conditionNotes2 = reader.string();
                    break;
                case /* string condition_notes3 */ 16:
                    message.conditionNotes3 = reader.string();
                    break;
                case /* string condition_rating1 */ 17:
                    message.conditionRating1 = reader.string();
                    break;
                case /* string condition_rating2 */ 18:
                    message.conditionRating2 = reader.string();
                    break;
                case /* string condition_rating3 */ 19:
                    message.conditionRating3 = reader.string();
                    break;
                case /* int32 reading_id */ 20:
                    message.readingId = reader.int32();
                    break;
                case /* repeated string field_mask */ 21:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 22:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string tstat_brand = 2; */
        if (message.tstatBrand !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tstatBrand);
        /* int32 thermostat = 3; */
        if (message.thermostat !== 0)
            writer.tag(3, WireType.Varint).int32(message.thermostat);
        /* int32 plateform = 4; */
        if (message.plateform !== 0)
            writer.tag(4, WireType.Varint).int32(message.plateform);
        /* int32 float_switch = 5; */
        if (message.floatSwitch !== 0)
            writer.tag(5, WireType.Varint).int32(message.floatSwitch);
        /* int32 evaporator_coil = 6; */
        if (message.evaporatorCoil !== 0)
            writer.tag(6, WireType.Varint).int32(message.evaporatorCoil);
        /* int32 condenser_coil = 7; */
        if (message.condenserCoil !== 0)
            writer.tag(7, WireType.Varint).int32(message.condenserCoil);
        /* int32 hurricane_pad = 8; */
        if (message.hurricanePad !== 0)
            writer.tag(8, WireType.Varint).int32(message.hurricanePad);
        /* int32 lineset = 9; */
        if (message.lineset !== 0)
            writer.tag(9, WireType.Varint).int32(message.lineset);
        /* int32 drain_line = 10; */
        if (message.drainLine !== 0)
            writer.tag(10, WireType.Varint).int32(message.drainLine);
        /* int32 gas_type = 11; */
        if (message.gasType !== 0)
            writer.tag(11, WireType.Varint).int32(message.gasType);
        /* int32 burner = 12; */
        if (message.burner !== 0)
            writer.tag(12, WireType.Varint).int32(message.burner);
        /* int32 heat_exchanger = 13; */
        if (message.heatExchanger !== 0)
            writer.tag(13, WireType.Varint).int32(message.heatExchanger);
        /* string condition_notes1 = 14; */
        if (message.conditionNotes1 !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.conditionNotes1);
        /* string condition_notes2 = 15; */
        if (message.conditionNotes2 !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.conditionNotes2);
        /* string condition_notes3 = 16; */
        if (message.conditionNotes3 !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.conditionNotes3);
        /* string condition_rating1 = 17; */
        if (message.conditionRating1 !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.conditionRating1);
        /* string condition_rating2 = 18; */
        if (message.conditionRating2 !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.conditionRating2);
        /* string condition_rating3 = 19; */
        if (message.conditionRating3 !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.conditionRating3);
        /* int32 reading_id = 20; */
        if (message.readingId !== 0)
            writer.tag(20, WireType.Varint).int32(message.readingId);
        /* repeated string field_mask = 21; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 22; */
        if (message.pageNumber !== 0)
            writer.tag(22, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MaintenanceQuestion
 */
export const MaintenanceQuestion = new MaintenanceQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MaintenanceQuestionList$Type extends MessageType {
    constructor() {
        super("MaintenanceQuestionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MaintenanceQuestion },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MaintenanceQuestion results */ 1:
                    message.results.push(MaintenanceQuestion.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated MaintenanceQuestion results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MaintenanceQuestion.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MaintenanceQuestionList
 */
export const MaintenanceQuestionList = new MaintenanceQuestionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SQSEmailAndDocument$Type extends MessageType {
    constructor() {
        super("SQSEmailAndDocument", [
            { no: 1, name: "email", kind: "message", T: () => SQSEmail },
            { no: 2, name: "document", kind: "message", T: () => Document }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SQSEmail email */ 1:
                    message.email = SQSEmail.internalBinaryRead(reader, reader.uint32(), options, message.email);
                    break;
                case /* Document document */ 2:
                    message.document = Document.internalBinaryRead(reader, reader.uint32(), options, message.document);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* SQSEmail email = 1; */
        if (message.email)
            SQSEmail.internalBinaryWrite(message.email, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Document document = 2; */
        if (message.document)
            Document.internalBinaryWrite(message.document, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SQSEmailAndDocument
 */
export const SQSEmailAndDocument = new SQSEmailAndDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Invoice$Type extends MessageType {
    constructor() {
        super("Invoice", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "system_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "system_type_2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "compressor_amps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "condensing_fan_amps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "serial", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "suction_pressure", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "head_pressure", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "model_2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "brand_2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "return_temperature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "serial_2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "start_date_2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "supply_temperature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "tstat_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "tstat_brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "subcool", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "filter_sizes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "superheat", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "properties", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "servicesperformedrow1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "totalamountrow1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "servicesperformedrow2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "totalamountrow2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "servicesperformedrow3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "totalamountrow3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "servicesperformedrow4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "totalamountrow4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "discount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 39, name: "discountcost", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "totalamounttotal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "credit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 42, name: "cash", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 43, name: "by_check", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 44, name: "billing", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 45, name: "payment_yes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 46, name: "payment_no", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 47, name: "service_item", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "log_payment_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 49, name: "log_payment_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 50, name: "property_billing", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 51, name: "material_used", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 52, name: "material_total", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 53, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 54, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, contractId: 0, userId: 0, propertyId: 0, systemType: "", systemType2: "", compressorAmps: "", model: "", brand: "", condensingFanAmps: "", serial: "", startDate: "", suctionPressure: "", headPressure: "", model2: "", brand2: "", returnTemperature: "", serial2: "", startDate2: "", supplyTemperature: "", tstatType: "", tstatBrand: "", subcool: "", filterSizes: "", superheat: "", notes: "", properties: "", terms: "", servicesperformedrow1: "", totalamountrow1: "", servicesperformedrow2: "", totalamountrow2: "", servicesperformedrow3: "", totalamountrow3: "", servicesperformedrow4: "", totalamountrow4: "", discount: "", discountcost: "", totalamounttotal: "", credit: 0, cash: 0, byCheck: 0, billing: 0, paymentYes: 0, paymentNo: 0, serviceItem: "", logPaymentType: "", logPaymentStatus: "", propertyBilling: 0, materialUsed: "", materialTotal: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 contract_id */ 3:
                    message.contractId = reader.int32();
                    break;
                case /* int32 user_id */ 4:
                    message.userId = reader.int32();
                    break;
                case /* int32 property_id */ 5:
                    message.propertyId = reader.int32();
                    break;
                case /* string system_type */ 6:
                    message.systemType = reader.string();
                    break;
                case /* string system_type_2 */ 7:
                    message.systemType2 = reader.string();
                    break;
                case /* string compressor_amps */ 8:
                    message.compressorAmps = reader.string();
                    break;
                case /* string model */ 9:
                    message.model = reader.string();
                    break;
                case /* string brand */ 10:
                    message.brand = reader.string();
                    break;
                case /* string condensing_fan_amps */ 11:
                    message.condensingFanAmps = reader.string();
                    break;
                case /* string serial */ 12:
                    message.serial = reader.string();
                    break;
                case /* string start_date */ 13:
                    message.startDate = reader.string();
                    break;
                case /* string suction_pressure */ 14:
                    message.suctionPressure = reader.string();
                    break;
                case /* string head_pressure */ 15:
                    message.headPressure = reader.string();
                    break;
                case /* string model_2 */ 16:
                    message.model2 = reader.string();
                    break;
                case /* string brand_2 */ 17:
                    message.brand2 = reader.string();
                    break;
                case /* string return_temperature */ 18:
                    message.returnTemperature = reader.string();
                    break;
                case /* string serial_2 */ 19:
                    message.serial2 = reader.string();
                    break;
                case /* string start_date_2 */ 20:
                    message.startDate2 = reader.string();
                    break;
                case /* string supply_temperature */ 21:
                    message.supplyTemperature = reader.string();
                    break;
                case /* string tstat_type */ 22:
                    message.tstatType = reader.string();
                    break;
                case /* string tstat_brand */ 23:
                    message.tstatBrand = reader.string();
                    break;
                case /* string subcool */ 24:
                    message.subcool = reader.string();
                    break;
                case /* string filter_sizes */ 25:
                    message.filterSizes = reader.string();
                    break;
                case /* string superheat */ 26:
                    message.superheat = reader.string();
                    break;
                case /* string notes */ 27:
                    message.notes = reader.string();
                    break;
                case /* string properties */ 28:
                    message.properties = reader.string();
                    break;
                case /* string terms */ 29:
                    message.terms = reader.string();
                    break;
                case /* string servicesperformedrow1 */ 30:
                    message.servicesperformedrow1 = reader.string();
                    break;
                case /* string totalamountrow1 */ 31:
                    message.totalamountrow1 = reader.string();
                    break;
                case /* string servicesperformedrow2 */ 32:
                    message.servicesperformedrow2 = reader.string();
                    break;
                case /* string totalamountrow2 */ 33:
                    message.totalamountrow2 = reader.string();
                    break;
                case /* string servicesperformedrow3 */ 34:
                    message.servicesperformedrow3 = reader.string();
                    break;
                case /* string totalamountrow3 */ 35:
                    message.totalamountrow3 = reader.string();
                    break;
                case /* string servicesperformedrow4 */ 36:
                    message.servicesperformedrow4 = reader.string();
                    break;
                case /* string totalamountrow4 */ 37:
                    message.totalamountrow4 = reader.string();
                    break;
                case /* string discount */ 38:
                    message.discount = reader.string();
                    break;
                case /* string discountcost */ 39:
                    message.discountcost = reader.string();
                    break;
                case /* string totalamounttotal */ 40:
                    message.totalamounttotal = reader.string();
                    break;
                case /* int32 credit */ 41:
                    message.credit = reader.int32();
                    break;
                case /* int32 cash */ 42:
                    message.cash = reader.int32();
                    break;
                case /* int32 by_check */ 43:
                    message.byCheck = reader.int32();
                    break;
                case /* int32 billing */ 44:
                    message.billing = reader.int32();
                    break;
                case /* int32 payment_yes */ 45:
                    message.paymentYes = reader.int32();
                    break;
                case /* int32 payment_no */ 46:
                    message.paymentNo = reader.int32();
                    break;
                case /* string service_item */ 47:
                    message.serviceItem = reader.string();
                    break;
                case /* string log_payment_type */ 48:
                    message.logPaymentType = reader.string();
                    break;
                case /* string log_payment_status */ 49:
                    message.logPaymentStatus = reader.string();
                    break;
                case /* int32 property_billing */ 50:
                    message.propertyBilling = reader.int32();
                    break;
                case /* string material_used */ 51:
                    message.materialUsed = reader.string();
                    break;
                case /* string material_total */ 52:
                    message.materialTotal = reader.string();
                    break;
                case /* repeated string field_mask */ 53:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 54:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 contract_id = 3; */
        if (message.contractId !== 0)
            writer.tag(3, WireType.Varint).int32(message.contractId);
        /* int32 user_id = 4; */
        if (message.userId !== 0)
            writer.tag(4, WireType.Varint).int32(message.userId);
        /* int32 property_id = 5; */
        if (message.propertyId !== 0)
            writer.tag(5, WireType.Varint).int32(message.propertyId);
        /* string system_type = 6; */
        if (message.systemType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.systemType);
        /* string system_type_2 = 7; */
        if (message.systemType2 !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.systemType2);
        /* string compressor_amps = 8; */
        if (message.compressorAmps !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.compressorAmps);
        /* string model = 9; */
        if (message.model !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.model);
        /* string brand = 10; */
        if (message.brand !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.brand);
        /* string condensing_fan_amps = 11; */
        if (message.condensingFanAmps !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.condensingFanAmps);
        /* string serial = 12; */
        if (message.serial !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.serial);
        /* string start_date = 13; */
        if (message.startDate !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.startDate);
        /* string suction_pressure = 14; */
        if (message.suctionPressure !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.suctionPressure);
        /* string head_pressure = 15; */
        if (message.headPressure !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.headPressure);
        /* string model_2 = 16; */
        if (message.model2 !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.model2);
        /* string brand_2 = 17; */
        if (message.brand2 !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.brand2);
        /* string return_temperature = 18; */
        if (message.returnTemperature !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.returnTemperature);
        /* string serial_2 = 19; */
        if (message.serial2 !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.serial2);
        /* string start_date_2 = 20; */
        if (message.startDate2 !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.startDate2);
        /* string supply_temperature = 21; */
        if (message.supplyTemperature !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.supplyTemperature);
        /* string tstat_type = 22; */
        if (message.tstatType !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.tstatType);
        /* string tstat_brand = 23; */
        if (message.tstatBrand !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.tstatBrand);
        /* string subcool = 24; */
        if (message.subcool !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.subcool);
        /* string filter_sizes = 25; */
        if (message.filterSizes !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.filterSizes);
        /* string superheat = 26; */
        if (message.superheat !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.superheat);
        /* string notes = 27; */
        if (message.notes !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.notes);
        /* string properties = 28; */
        if (message.properties !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.properties);
        /* string terms = 29; */
        if (message.terms !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.terms);
        /* string servicesperformedrow1 = 30; */
        if (message.servicesperformedrow1 !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.servicesperformedrow1);
        /* string totalamountrow1 = 31; */
        if (message.totalamountrow1 !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.totalamountrow1);
        /* string servicesperformedrow2 = 32; */
        if (message.servicesperformedrow2 !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.servicesperformedrow2);
        /* string totalamountrow2 = 33; */
        if (message.totalamountrow2 !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.totalamountrow2);
        /* string servicesperformedrow3 = 34; */
        if (message.servicesperformedrow3 !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.servicesperformedrow3);
        /* string totalamountrow3 = 35; */
        if (message.totalamountrow3 !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.totalamountrow3);
        /* string servicesperformedrow4 = 36; */
        if (message.servicesperformedrow4 !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.servicesperformedrow4);
        /* string totalamountrow4 = 37; */
        if (message.totalamountrow4 !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.totalamountrow4);
        /* string discount = 38; */
        if (message.discount !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.discount);
        /* string discountcost = 39; */
        if (message.discountcost !== "")
            writer.tag(39, WireType.LengthDelimited).string(message.discountcost);
        /* string totalamounttotal = 40; */
        if (message.totalamounttotal !== "")
            writer.tag(40, WireType.LengthDelimited).string(message.totalamounttotal);
        /* int32 credit = 41; */
        if (message.credit !== 0)
            writer.tag(41, WireType.Varint).int32(message.credit);
        /* int32 cash = 42; */
        if (message.cash !== 0)
            writer.tag(42, WireType.Varint).int32(message.cash);
        /* int32 by_check = 43; */
        if (message.byCheck !== 0)
            writer.tag(43, WireType.Varint).int32(message.byCheck);
        /* int32 billing = 44; */
        if (message.billing !== 0)
            writer.tag(44, WireType.Varint).int32(message.billing);
        /* int32 payment_yes = 45; */
        if (message.paymentYes !== 0)
            writer.tag(45, WireType.Varint).int32(message.paymentYes);
        /* int32 payment_no = 46; */
        if (message.paymentNo !== 0)
            writer.tag(46, WireType.Varint).int32(message.paymentNo);
        /* string service_item = 47; */
        if (message.serviceItem !== "")
            writer.tag(47, WireType.LengthDelimited).string(message.serviceItem);
        /* string log_payment_type = 48; */
        if (message.logPaymentType !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.logPaymentType);
        /* string log_payment_status = 49; */
        if (message.logPaymentStatus !== "")
            writer.tag(49, WireType.LengthDelimited).string(message.logPaymentStatus);
        /* int32 property_billing = 50; */
        if (message.propertyBilling !== 0)
            writer.tag(50, WireType.Varint).int32(message.propertyBilling);
        /* string material_used = 51; */
        if (message.materialUsed !== "")
            writer.tag(51, WireType.LengthDelimited).string(message.materialUsed);
        /* string material_total = 52; */
        if (message.materialTotal !== "")
            writer.tag(52, WireType.LengthDelimited).string(message.materialTotal);
        /* repeated string field_mask = 53; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(53, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 54; */
        if (message.pageNumber !== 0)
            writer.tag(54, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Invoice
 */
export const Invoice = new Invoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceList$Type extends MessageType {
    constructor() {
        super("InvoiceList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Invoice },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Invoice results */ 1:
                    message.results.push(Invoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Invoice results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Invoice.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoiceList
 */
export const InvoiceList = new InvoiceList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SiLink$Type extends MessageType {
    constructor() {
        super("SiLink", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "service_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, serviceItemId: 0, url: "", description: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 service_item_id */ 2:
                    message.serviceItemId = reader.int32();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 service_item_id = 2; */
        if (message.serviceItemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.serviceItemId);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SiLink
 */
export const SiLink = new SiLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SiLinkList$Type extends MessageType {
    constructor() {
        super("SiLinkList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SiLink },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SiLink results */ 1:
                    message.results.push(SiLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SiLink results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SiLink.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SiLinkList
 */
export const SiLinkList = new SiLinkList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractFrequency$Type extends MessageType {
    constructor() {
        super("ContractFrequency", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "interval", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "maintenance_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", interval: 0, maintenanceCount: 0, fieldMask: [], pageNumber: 0, isActive: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 interval */ 3:
                    message.interval = reader.int32();
                    break;
                case /* int32 maintenance_count */ 4:
                    message.maintenanceCount = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 is_active */ 7:
                    message.isActive = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 interval = 3; */
        if (message.interval !== 0)
            writer.tag(3, WireType.Varint).int32(message.interval);
        /* int32 maintenance_count = 4; */
        if (message.maintenanceCount !== 0)
            writer.tag(4, WireType.Varint).int32(message.maintenanceCount);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        /* int32 is_active = 7; */
        if (message.isActive !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContractFrequency
 */
export const ContractFrequency = new ContractFrequency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractFrequencyList$Type extends MessageType {
    constructor() {
        super("ContractFrequencyList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ContractFrequency },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ContractFrequency results */ 1:
                    message.results.push(ContractFrequency.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ContractFrequency results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ContractFrequency.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContractFrequencyList
 */
export const ContractFrequencyList = new ContractFrequencyList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobNumberRequest$Type extends MessageType {
    constructor() {
        super("JobNumberRequest", [
            { no: 1, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { jobNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 job_number */ 1:
                    message.jobNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 job_number = 1; */
        if (message.jobNumber !== 0)
            writer.tag(1, WireType.Varint).int32(message.jobNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobNumberRequest
 */
export const JobNumberRequest = new JobNumberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventDetails$Type extends MessageType {
    constructor() {
        super("EventDetails", [
            { no: 1, name: "event", kind: "message", T: () => Event },
            { no: 2, name: "property", kind: "message", T: () => Property },
            { no: 3, name: "customer", kind: "message", T: () => User },
            { no: 4, name: "job_types", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobType },
            { no: 5, name: "job_subtypes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobSubtype },
            { no: 6, name: "job_types_subtypes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobTypeSubtype },
            { no: 7, name: "job_admins", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobAdmin },
            { no: 8, name: "services_rendered", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServicesRendered },
            { no: 9, name: "invoice", kind: "message", T: () => Invoice },
            { no: 10, name: "payment", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Payment },
            { no: 11, name: "property_event", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value) {
        const message = { jobTypes: [], jobSubtypes: [], jobTypesSubtypes: [], jobAdmins: [], servicesRendered: [], payment: [], propertyEvent: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Event event */ 1:
                    message.event = Event.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                case /* Property property */ 2:
                    message.property = Property.internalBinaryRead(reader, reader.uint32(), options, message.property);
                    break;
                case /* User customer */ 3:
                    message.customer = User.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* repeated JobType job_types */ 4:
                    message.jobTypes.push(JobType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated JobSubtype job_subtypes */ 5:
                    message.jobSubtypes.push(JobSubtype.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated JobTypeSubtype job_types_subtypes */ 6:
                    message.jobTypesSubtypes.push(JobTypeSubtype.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated JobAdmin job_admins */ 7:
                    message.jobAdmins.push(JobAdmin.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ServicesRendered services_rendered */ 8:
                    message.servicesRendered.push(ServicesRendered.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Invoice invoice */ 9:
                    message.invoice = Invoice.internalBinaryRead(reader, reader.uint32(), options, message.invoice);
                    break;
                case /* repeated Payment payment */ 10:
                    message.payment.push(Payment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Event property_event */ 11:
                    message.propertyEvent.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* Event event = 1; */
        if (message.event)
            Event.internalBinaryWrite(message.event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Property property = 2; */
        if (message.property)
            Property.internalBinaryWrite(message.property, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* User customer = 3; */
        if (message.customer)
            User.internalBinaryWrite(message.customer, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated JobType job_types = 4; */
        for (let i = 0; i < message.jobTypes.length; i++)
            JobType.internalBinaryWrite(message.jobTypes[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated JobSubtype job_subtypes = 5; */
        for (let i = 0; i < message.jobSubtypes.length; i++)
            JobSubtype.internalBinaryWrite(message.jobSubtypes[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated JobTypeSubtype job_types_subtypes = 6; */
        for (let i = 0; i < message.jobTypesSubtypes.length; i++)
            JobTypeSubtype.internalBinaryWrite(message.jobTypesSubtypes[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated JobAdmin job_admins = 7; */
        for (let i = 0; i < message.jobAdmins.length; i++)
            JobAdmin.internalBinaryWrite(message.jobAdmins[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated ServicesRendered services_rendered = 8; */
        for (let i = 0; i < message.servicesRendered.length; i++)
            ServicesRendered.internalBinaryWrite(message.servicesRendered[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* Invoice invoice = 9; */
        if (message.invoice)
            Invoice.internalBinaryWrite(message.invoice, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated Payment payment = 10; */
        for (let i = 0; i < message.payment.length; i++)
            Payment.internalBinaryWrite(message.payment[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated Event property_event = 11; */
        for (let i = 0; i < message.propertyEvent.length; i++)
            Event.internalBinaryWrite(message.propertyEvent[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EventDetails
 */
export const EventDetails = new EventDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contract$Type extends MessageType {
    constructor() {
        super("Contract", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "properties", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "date_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "date_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "frequency", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "group_billing", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "payment_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "payment_terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "payment_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "time_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, number: "", properties: "", dateCreated: "", dateStarted: "", dateEnded: "", frequency: 0, groupBilling: 0, paymentStatus: "", paymentTerms: "", notes: "", paymentType: "", isActive: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", dateTarget: [], dateRange: [], lastName: "", businessName: "", departmentId: 0, timeStarted: "", timeEnded: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* string number */ 3:
                    message.number = reader.string();
                    break;
                case /* string properties */ 4:
                    message.properties = reader.string();
                    break;
                case /* string date_created */ 5:
                    message.dateCreated = reader.string();
                    break;
                case /* string date_started */ 6:
                    message.dateStarted = reader.string();
                    break;
                case /* string date_ended */ 7:
                    message.dateEnded = reader.string();
                    break;
                case /* int32 frequency */ 8:
                    message.frequency = reader.int32();
                    break;
                case /* int32 group_billing */ 9:
                    message.groupBilling = reader.int32();
                    break;
                case /* string payment_status */ 10:
                    message.paymentStatus = reader.string();
                    break;
                case /* string payment_terms */ 11:
                    message.paymentTerms = reader.string();
                    break;
                case /* string notes */ 12:
                    message.notes = reader.string();
                    break;
                case /* string payment_type */ 13:
                    message.paymentType = reader.string();
                    break;
                case /* int32 is_active */ 14:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 15:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 16:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 17:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 18:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_target */ 19:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string date_range */ 20:
                    message.dateRange.push(reader.string());
                    break;
                case /* string last_name */ 21:
                    message.lastName = reader.string();
                    break;
                case /* string business_name */ 22:
                    message.businessName = reader.string();
                    break;
                case /* int32 department_id */ 23:
                    message.departmentId = reader.int32();
                    break;
                case /* string time_started */ 24:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_ended */ 25:
                    message.timeEnded = reader.string();
                    break;
                case /* bool without_limit */ 26:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* string number = 3; */
        if (message.number !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.number);
        /* string properties = 4; */
        if (message.properties !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.properties);
        /* string date_created = 5; */
        if (message.dateCreated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateCreated);
        /* string date_started = 6; */
        if (message.dateStarted !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.dateStarted);
        /* string date_ended = 7; */
        if (message.dateEnded !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dateEnded);
        /* int32 frequency = 8; */
        if (message.frequency !== 0)
            writer.tag(8, WireType.Varint).int32(message.frequency);
        /* int32 group_billing = 9; */
        if (message.groupBilling !== 0)
            writer.tag(9, WireType.Varint).int32(message.groupBilling);
        /* string payment_status = 10; */
        if (message.paymentStatus !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.paymentStatus);
        /* string payment_terms = 11; */
        if (message.paymentTerms !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.paymentTerms);
        /* string notes = 12; */
        if (message.notes !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.notes);
        /* string payment_type = 13; */
        if (message.paymentType !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.paymentType);
        /* int32 is_active = 14; */
        if (message.isActive !== 0)
            writer.tag(14, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 15; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 16; */
        if (message.pageNumber !== 0)
            writer.tag(16, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 17; */
        if (message.orderBy !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 18; */
        if (message.orderDir !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_target = 19; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string date_range = 20; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.dateRange[i]);
        /* string last_name = 21; */
        if (message.lastName !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.lastName);
        /* string business_name = 22; */
        if (message.businessName !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.businessName);
        /* int32 department_id = 23; */
        if (message.departmentId !== 0)
            writer.tag(23, WireType.Varint).int32(message.departmentId);
        /* string time_started = 24; */
        if (message.timeStarted !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_ended = 25; */
        if (message.timeEnded !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.timeEnded);
        /* bool without_limit = 26; */
        if (message.withoutLimit !== false)
            writer.tag(26, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Contract
 */
export const Contract = new Contract$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractMaterial$Type extends MessageType {
    constructor() {
        super("ContractMaterial", [
            { no: 1, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "property_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "property_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "property_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "serviceItem_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "material_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "material_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "material_partNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "material_vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "material_quantity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { contractId: 0, propertyAddress: "", propertyCity: "", propertyState: "", serviceItemId: 0, materialId: 0, materialName: "", materialPartNumber: "", materialVendor: "", materialQuantity: "", propertyId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 contract_id */ 1:
                    message.contractId = reader.int32();
                    break;
                case /* string property_address */ 2:
                    message.propertyAddress = reader.string();
                    break;
                case /* string property_city */ 3:
                    message.propertyCity = reader.string();
                    break;
                case /* string property_state */ 4:
                    message.propertyState = reader.string();
                    break;
                case /* int32 serviceItem_id */ 5:
                    message.serviceItemId = reader.int32();
                    break;
                case /* int32 material_id */ 6:
                    message.materialId = reader.int32();
                    break;
                case /* string material_name */ 7:
                    message.materialName = reader.string();
                    break;
                case /* string material_partNumber */ 8:
                    message.materialPartNumber = reader.string();
                    break;
                case /* string material_vendor */ 9:
                    message.materialVendor = reader.string();
                    break;
                case /* string material_quantity */ 10:
                    message.materialQuantity = reader.string();
                    break;
                case /* int32 property_id */ 11:
                    message.propertyId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 contract_id = 1; */
        if (message.contractId !== 0)
            writer.tag(1, WireType.Varint).int32(message.contractId);
        /* string property_address = 2; */
        if (message.propertyAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.propertyAddress);
        /* string property_city = 3; */
        if (message.propertyCity !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.propertyCity);
        /* string property_state = 4; */
        if (message.propertyState !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.propertyState);
        /* int32 serviceItem_id = 5; */
        if (message.serviceItemId !== 0)
            writer.tag(5, WireType.Varint).int32(message.serviceItemId);
        /* int32 material_id = 6; */
        if (message.materialId !== 0)
            writer.tag(6, WireType.Varint).int32(message.materialId);
        /* string material_name = 7; */
        if (message.materialName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.materialName);
        /* string material_partNumber = 8; */
        if (message.materialPartNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.materialPartNumber);
        /* string material_vendor = 9; */
        if (message.materialVendor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.materialVendor);
        /* string material_quantity = 10; */
        if (message.materialQuantity !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.materialQuantity);
        /* int32 property_id = 11; */
        if (message.propertyId !== 0)
            writer.tag(11, WireType.Varint).int32(message.propertyId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContractMaterial
 */
export const ContractMaterial = new ContractMaterial$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractMaterialList$Type extends MessageType {
    constructor() {
        super("ContractMaterialList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ContractMaterial },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ContractMaterial results */ 1:
                    message.results.push(ContractMaterial.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ContractMaterial results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ContractMaterial.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContractMaterialList
 */
export const ContractMaterialList = new ContractMaterialList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContractList$Type extends MessageType {
    constructor() {
        super("ContractList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Contract },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Contract results */ 1:
                    message.results.push(Contract.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Contract results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Contract.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ContractList
 */
export const ContractList = new ContractList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClosestPropertyRequest$Type extends MessageType {
    constructor() {
        super("ClosestPropertyRequest", [
            { no: 1, name: "coords", kind: "message", T: () => LatLng },
            { no: 2, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { timestamp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* LatLng coords */ 1:
                    message.coords = LatLng.internalBinaryRead(reader, reader.uint32(), options, message.coords);
                    break;
                case /* string timestamp */ 2:
                    message.timestamp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* LatLng coords = 1; */
        if (message.coords)
            LatLng.internalBinaryWrite(message.coords, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string timestamp = 2; */
        if (message.timestamp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ClosestPropertyRequest
 */
export const ClosestPropertyRequest = new ClosestPropertyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClosestPropertyResult$Type extends MessageType {
    constructor() {
        super("ClosestPropertyResult", [
            { no: 1, name: "property", kind: "message", T: () => Property },
            { no: 2, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value) {
        const message = { events: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Property property */ 1:
                    message.property = Property.internalBinaryRead(reader, reader.uint32(), options, message.property);
                    break;
                case /* repeated Event events */ 2:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* Property property = 1; */
        if (message.property)
            Property.internalBinaryWrite(message.property, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Event events = 2; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ClosestPropertyResult
 */
export const ClosestPropertyResult = new ClosestPropertyResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Event$Type extends MessageType {
    constructor() {
        super("Event", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "date_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "time_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_all_day", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "repeat_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "date_updated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "contract_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "log_job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "log_job_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "log_po", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "log_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "log_technician_assigned", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "log_date_completed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "log_materials_used", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "log_service_rendered", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "log_tech_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "log_billing_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "log_amount_charged", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "log_payment_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "log_payment_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "log_time_in", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "log_time_out", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "log_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "log_contract_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "invoice_service_item", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "tstat_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "tstat_brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "compressor_amps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "condensing_fan_amps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "suction_pressure", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 39, name: "head_pressure", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "return_temperature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "supply_temperature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 42, name: "subcool", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 43, name: "superheat", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "services", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 46, name: "servicesperformedrow1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 47, name: "totalamountrow1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "servicesperformedrow2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 49, name: "totalamountrow2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 50, name: "servicesperformedrow3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 51, name: "totalamountrow3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 52, name: "servicesperformedrow4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 53, name: "totalamountrow4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 54, name: "discount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 55, name: "discountcost", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 56, name: "log_notification", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 57, name: "diagnostic_quoted", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 58, name: "amount_quoted", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 59, name: "property_billing", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 60, name: "is_callback", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 61, name: "log_version", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 62, name: "job_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 63, name: "job_subtype_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 64, name: "callback_original_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 65, name: "callback_disposition", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 66, name: "callback_comments", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 67, name: "callback_technician", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 68, name: "callback_approval_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 69, name: "callback_comment_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 70, name: "document_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 71, name: "material_used", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 72, name: "material_total", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 73, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 74, name: "parent_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 75, name: "is_lmpc", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 76, name: "high_priority", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 77, name: "is_residential", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 79, name: "job_subtype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 80, name: "customer", kind: "message", T: () => User },
            { no: 81, name: "property", kind: "message", T: () => Property },
            { no: 82, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 83, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 84, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 85, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 89, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 86, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 87, name: "count_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 88, name: "quote_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Quotable },
            { no: 90, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 91, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 92, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 93, name: "is_generated_invoice", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 94, name: "only_events", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 95, name: "job_admin", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobAdmin },
            { no: 96, name: "job_admin_search", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 97, name: "callback_user_ids", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 98, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 99, name: "sold_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 100, name: "lead_generated_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 101, name: "quote_expire_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", description: "", dateStarted: "", dateEnded: "", timeStarted: "", timeEnded: "", isAllDay: 0, repeatType: 0, color: "", dateUpdated: "", dateCreated: "", propertyId: 0, contractId: 0, contractNumber: "", logJobNumber: "", logJobStatus: "", logPo: "", logNotes: "", logTechnicianAssigned: "", logDateCompleted: "", logMaterialsUsed: "", logServiceRendered: "", logTechNotes: "", logBillingDate: "", logAmountCharged: "", logPaymentType: "", logPaymentStatus: "", logTimeIn: "", logTimeOut: "", logType: "", logContractNotes: "", invoiceServiceItem: "", tstatType: "", tstatBrand: "", compressorAmps: "", condensingFanAmps: "", suctionPressure: "", headPressure: "", returnTemperature: "", supplyTemperature: "", subcool: "", superheat: "", notes: "", services: "", servicesperformedrow1: "", totalamountrow1: "", servicesperformedrow2: "", totalamountrow2: "", servicesperformedrow3: "", totalamountrow3: "", servicesperformedrow4: "", totalamountrow4: "", discount: "", discountcost: "", logNotification: "", diagnosticQuoted: 0, amountQuoted: "", propertyBilling: 0, isCallback: 0, logVersion: 0, jobTypeId: 0, jobSubtypeId: 0, callbackOriginalId: 0, callbackDisposition: 0, callbackComments: "", callbackTechnician: 0, callbackApprovalTimestamp: "", callbackCommentBy: 0, documentId: 0, materialUsed: "", materialTotal: 0, isActive: 0, parentId: 0, isLmpc: 0, highPriority: 0, isResidential: 0, jobType: "", jobSubtype: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", dateTarget: [], dateRange: [], countOnly: false, quoteData: [], departmentId: 0, notEquals: [], withoutLimit: false, isGeneratedInvoice: false, onlyEvents: false, jobAdmin: [], jobAdminSearch: 0, callbackUserIds: "", idList: "", soldBy: 0, leadGeneratedBy: 0, quoteExpireDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string date_started */ 4:
                    message.dateStarted = reader.string();
                    break;
                case /* string date_ended */ 5:
                    message.dateEnded = reader.string();
                    break;
                case /* string time_started */ 6:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_ended */ 7:
                    message.timeEnded = reader.string();
                    break;
                case /* int32 is_all_day */ 8:
                    message.isAllDay = reader.int32();
                    break;
                case /* int32 repeat_type */ 9:
                    message.repeatType = reader.int32();
                    break;
                case /* string color */ 10:
                    message.color = reader.string();
                    break;
                case /* string date_updated */ 11:
                    message.dateUpdated = reader.string();
                    break;
                case /* string date_created */ 12:
                    message.dateCreated = reader.string();
                    break;
                case /* int32 property_id */ 13:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 contract_id */ 14:
                    message.contractId = reader.int32();
                    break;
                case /* string contract_number */ 15:
                    message.contractNumber = reader.string();
                    break;
                case /* string log_job_number */ 16:
                    message.logJobNumber = reader.string();
                    break;
                case /* string log_job_status */ 17:
                    message.logJobStatus = reader.string();
                    break;
                case /* string log_po */ 18:
                    message.logPo = reader.string();
                    break;
                case /* string log_notes */ 19:
                    message.logNotes = reader.string();
                    break;
                case /* string log_technician_assigned */ 20:
                    message.logTechnicianAssigned = reader.string();
                    break;
                case /* string log_date_completed */ 21:
                    message.logDateCompleted = reader.string();
                    break;
                case /* string log_materials_used */ 22:
                    message.logMaterialsUsed = reader.string();
                    break;
                case /* string log_service_rendered */ 23:
                    message.logServiceRendered = reader.string();
                    break;
                case /* string log_tech_notes */ 24:
                    message.logTechNotes = reader.string();
                    break;
                case /* string log_billing_date */ 25:
                    message.logBillingDate = reader.string();
                    break;
                case /* string log_amount_charged */ 26:
                    message.logAmountCharged = reader.string();
                    break;
                case /* string log_payment_type */ 27:
                    message.logPaymentType = reader.string();
                    break;
                case /* string log_payment_status */ 28:
                    message.logPaymentStatus = reader.string();
                    break;
                case /* string log_time_in */ 29:
                    message.logTimeIn = reader.string();
                    break;
                case /* string log_time_out */ 30:
                    message.logTimeOut = reader.string();
                    break;
                case /* string log_type */ 31:
                    message.logType = reader.string();
                    break;
                case /* string log_contract_notes */ 32:
                    message.logContractNotes = reader.string();
                    break;
                case /* string invoice_service_item */ 33:
                    message.invoiceServiceItem = reader.string();
                    break;
                case /* string tstat_type */ 34:
                    message.tstatType = reader.string();
                    break;
                case /* string tstat_brand */ 35:
                    message.tstatBrand = reader.string();
                    break;
                case /* string compressor_amps */ 36:
                    message.compressorAmps = reader.string();
                    break;
                case /* string condensing_fan_amps */ 37:
                    message.condensingFanAmps = reader.string();
                    break;
                case /* string suction_pressure */ 38:
                    message.suctionPressure = reader.string();
                    break;
                case /* string head_pressure */ 39:
                    message.headPressure = reader.string();
                    break;
                case /* string return_temperature */ 40:
                    message.returnTemperature = reader.string();
                    break;
                case /* string supply_temperature */ 41:
                    message.supplyTemperature = reader.string();
                    break;
                case /* string subcool */ 42:
                    message.subcool = reader.string();
                    break;
                case /* string superheat */ 43:
                    message.superheat = reader.string();
                    break;
                case /* string notes */ 44:
                    message.notes = reader.string();
                    break;
                case /* string services */ 45:
                    message.services = reader.string();
                    break;
                case /* string servicesperformedrow1 */ 46:
                    message.servicesperformedrow1 = reader.string();
                    break;
                case /* string totalamountrow1 */ 47:
                    message.totalamountrow1 = reader.string();
                    break;
                case /* string servicesperformedrow2 */ 48:
                    message.servicesperformedrow2 = reader.string();
                    break;
                case /* string totalamountrow2 */ 49:
                    message.totalamountrow2 = reader.string();
                    break;
                case /* string servicesperformedrow3 */ 50:
                    message.servicesperformedrow3 = reader.string();
                    break;
                case /* string totalamountrow3 */ 51:
                    message.totalamountrow3 = reader.string();
                    break;
                case /* string servicesperformedrow4 */ 52:
                    message.servicesperformedrow4 = reader.string();
                    break;
                case /* string totalamountrow4 */ 53:
                    message.totalamountrow4 = reader.string();
                    break;
                case /* string discount */ 54:
                    message.discount = reader.string();
                    break;
                case /* string discountcost */ 55:
                    message.discountcost = reader.string();
                    break;
                case /* string log_notification */ 56:
                    message.logNotification = reader.string();
                    break;
                case /* int32 diagnostic_quoted */ 57:
                    message.diagnosticQuoted = reader.int32();
                    break;
                case /* string amount_quoted */ 58:
                    message.amountQuoted = reader.string();
                    break;
                case /* int32 property_billing */ 59:
                    message.propertyBilling = reader.int32();
                    break;
                case /* int32 is_callback */ 60:
                    message.isCallback = reader.int32();
                    break;
                case /* int32 log_version */ 61:
                    message.logVersion = reader.int32();
                    break;
                case /* int32 job_type_id */ 62:
                    message.jobTypeId = reader.int32();
                    break;
                case /* int32 job_subtype_id */ 63:
                    message.jobSubtypeId = reader.int32();
                    break;
                case /* int32 callback_original_id */ 64:
                    message.callbackOriginalId = reader.int32();
                    break;
                case /* int32 callback_disposition */ 65:
                    message.callbackDisposition = reader.int32();
                    break;
                case /* string callback_comments */ 66:
                    message.callbackComments = reader.string();
                    break;
                case /* int32 callback_technician */ 67:
                    message.callbackTechnician = reader.int32();
                    break;
                case /* string callback_approval_timestamp */ 68:
                    message.callbackApprovalTimestamp = reader.string();
                    break;
                case /* int32 callback_comment_by */ 69:
                    message.callbackCommentBy = reader.int32();
                    break;
                case /* int32 document_id */ 70:
                    message.documentId = reader.int32();
                    break;
                case /* string material_used */ 71:
                    message.materialUsed = reader.string();
                    break;
                case /* double material_total */ 72:
                    message.materialTotal = reader.double();
                    break;
                case /* int32 is_active */ 73:
                    message.isActive = reader.int32();
                    break;
                case /* int32 parent_id */ 74:
                    message.parentId = reader.int32();
                    break;
                case /* int32 is_lmpc */ 75:
                    message.isLmpc = reader.int32();
                    break;
                case /* int32 high_priority */ 76:
                    message.highPriority = reader.int32();
                    break;
                case /* int32 is_residential */ 77:
                    message.isResidential = reader.int32();
                    break;
                case /* string job_type */ 78:
                    message.jobType = reader.string();
                    break;
                case /* string job_subtype */ 79:
                    message.jobSubtype = reader.string();
                    break;
                case /* User customer */ 80:
                    message.customer = User.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* Property property */ 81:
                    message.property = Property.internalBinaryRead(reader, reader.uint32(), options, message.property);
                    break;
                case /* repeated string field_mask */ 82:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 83:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 84:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 85:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_target */ 89:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string date_range */ 86:
                    message.dateRange.push(reader.string());
                    break;
                case /* bool count_only */ 87:
                    message.countOnly = reader.bool();
                    break;
                case /* repeated Quotable quote_data */ 88:
                    message.quoteData.push(Quotable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 department_id */ 90:
                    message.departmentId = reader.int32();
                    break;
                case /* repeated string not_equals */ 91:
                    message.notEquals.push(reader.string());
                    break;
                case /* bool without_limit */ 92:
                    message.withoutLimit = reader.bool();
                    break;
                case /* bool is_generated_invoice */ 93:
                    message.isGeneratedInvoice = reader.bool();
                    break;
                case /* bool only_events */ 94:
                    message.onlyEvents = reader.bool();
                    break;
                case /* repeated JobAdmin job_admin */ 95:
                    message.jobAdmin.push(JobAdmin.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 job_admin_search */ 96:
                    message.jobAdminSearch = reader.int32();
                    break;
                case /* string callback_user_ids */ 97:
                    message.callbackUserIds = reader.string();
                    break;
                case /* string id_list */ 98:
                    message.idList = reader.string();
                    break;
                case /* int32 sold_by */ 99:
                    message.soldBy = reader.int32();
                    break;
                case /* int32 lead_generated_by */ 100:
                    message.leadGeneratedBy = reader.int32();
                    break;
                case /* string quote_expire_date */ 101:
                    message.quoteExpireDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string date_started = 4; */
        if (message.dateStarted !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.dateStarted);
        /* string date_ended = 5; */
        if (message.dateEnded !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateEnded);
        /* string time_started = 6; */
        if (message.timeStarted !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_ended = 7; */
        if (message.timeEnded !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timeEnded);
        /* int32 is_all_day = 8; */
        if (message.isAllDay !== 0)
            writer.tag(8, WireType.Varint).int32(message.isAllDay);
        /* int32 repeat_type = 9; */
        if (message.repeatType !== 0)
            writer.tag(9, WireType.Varint).int32(message.repeatType);
        /* string color = 10; */
        if (message.color !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.color);
        /* string date_updated = 11; */
        if (message.dateUpdated !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.dateUpdated);
        /* string date_created = 12; */
        if (message.dateCreated !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.dateCreated);
        /* int32 property_id = 13; */
        if (message.propertyId !== 0)
            writer.tag(13, WireType.Varint).int32(message.propertyId);
        /* int32 contract_id = 14; */
        if (message.contractId !== 0)
            writer.tag(14, WireType.Varint).int32(message.contractId);
        /* string contract_number = 15; */
        if (message.contractNumber !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.contractNumber);
        /* string log_job_number = 16; */
        if (message.logJobNumber !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.logJobNumber);
        /* string log_job_status = 17; */
        if (message.logJobStatus !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.logJobStatus);
        /* string log_po = 18; */
        if (message.logPo !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.logPo);
        /* string log_notes = 19; */
        if (message.logNotes !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.logNotes);
        /* string log_technician_assigned = 20; */
        if (message.logTechnicianAssigned !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.logTechnicianAssigned);
        /* string log_date_completed = 21; */
        if (message.logDateCompleted !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.logDateCompleted);
        /* string log_materials_used = 22; */
        if (message.logMaterialsUsed !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.logMaterialsUsed);
        /* string log_service_rendered = 23; */
        if (message.logServiceRendered !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.logServiceRendered);
        /* string log_tech_notes = 24; */
        if (message.logTechNotes !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.logTechNotes);
        /* string log_billing_date = 25; */
        if (message.logBillingDate !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.logBillingDate);
        /* string log_amount_charged = 26; */
        if (message.logAmountCharged !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.logAmountCharged);
        /* string log_payment_type = 27; */
        if (message.logPaymentType !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.logPaymentType);
        /* string log_payment_status = 28; */
        if (message.logPaymentStatus !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.logPaymentStatus);
        /* string log_time_in = 29; */
        if (message.logTimeIn !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.logTimeIn);
        /* string log_time_out = 30; */
        if (message.logTimeOut !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.logTimeOut);
        /* string log_type = 31; */
        if (message.logType !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.logType);
        /* string log_contract_notes = 32; */
        if (message.logContractNotes !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.logContractNotes);
        /* string invoice_service_item = 33; */
        if (message.invoiceServiceItem !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.invoiceServiceItem);
        /* string tstat_type = 34; */
        if (message.tstatType !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.tstatType);
        /* string tstat_brand = 35; */
        if (message.tstatBrand !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.tstatBrand);
        /* string compressor_amps = 36; */
        if (message.compressorAmps !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.compressorAmps);
        /* string condensing_fan_amps = 37; */
        if (message.condensingFanAmps !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.condensingFanAmps);
        /* string suction_pressure = 38; */
        if (message.suctionPressure !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.suctionPressure);
        /* string head_pressure = 39; */
        if (message.headPressure !== "")
            writer.tag(39, WireType.LengthDelimited).string(message.headPressure);
        /* string return_temperature = 40; */
        if (message.returnTemperature !== "")
            writer.tag(40, WireType.LengthDelimited).string(message.returnTemperature);
        /* string supply_temperature = 41; */
        if (message.supplyTemperature !== "")
            writer.tag(41, WireType.LengthDelimited).string(message.supplyTemperature);
        /* string subcool = 42; */
        if (message.subcool !== "")
            writer.tag(42, WireType.LengthDelimited).string(message.subcool);
        /* string superheat = 43; */
        if (message.superheat !== "")
            writer.tag(43, WireType.LengthDelimited).string(message.superheat);
        /* string notes = 44; */
        if (message.notes !== "")
            writer.tag(44, WireType.LengthDelimited).string(message.notes);
        /* string services = 45; */
        if (message.services !== "")
            writer.tag(45, WireType.LengthDelimited).string(message.services);
        /* string servicesperformedrow1 = 46; */
        if (message.servicesperformedrow1 !== "")
            writer.tag(46, WireType.LengthDelimited).string(message.servicesperformedrow1);
        /* string totalamountrow1 = 47; */
        if (message.totalamountrow1 !== "")
            writer.tag(47, WireType.LengthDelimited).string(message.totalamountrow1);
        /* string servicesperformedrow2 = 48; */
        if (message.servicesperformedrow2 !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.servicesperformedrow2);
        /* string totalamountrow2 = 49; */
        if (message.totalamountrow2 !== "")
            writer.tag(49, WireType.LengthDelimited).string(message.totalamountrow2);
        /* string servicesperformedrow3 = 50; */
        if (message.servicesperformedrow3 !== "")
            writer.tag(50, WireType.LengthDelimited).string(message.servicesperformedrow3);
        /* string totalamountrow3 = 51; */
        if (message.totalamountrow3 !== "")
            writer.tag(51, WireType.LengthDelimited).string(message.totalamountrow3);
        /* string servicesperformedrow4 = 52; */
        if (message.servicesperformedrow4 !== "")
            writer.tag(52, WireType.LengthDelimited).string(message.servicesperformedrow4);
        /* string totalamountrow4 = 53; */
        if (message.totalamountrow4 !== "")
            writer.tag(53, WireType.LengthDelimited).string(message.totalamountrow4);
        /* string discount = 54; */
        if (message.discount !== "")
            writer.tag(54, WireType.LengthDelimited).string(message.discount);
        /* string discountcost = 55; */
        if (message.discountcost !== "")
            writer.tag(55, WireType.LengthDelimited).string(message.discountcost);
        /* string log_notification = 56; */
        if (message.logNotification !== "")
            writer.tag(56, WireType.LengthDelimited).string(message.logNotification);
        /* int32 diagnostic_quoted = 57; */
        if (message.diagnosticQuoted !== 0)
            writer.tag(57, WireType.Varint).int32(message.diagnosticQuoted);
        /* string amount_quoted = 58; */
        if (message.amountQuoted !== "")
            writer.tag(58, WireType.LengthDelimited).string(message.amountQuoted);
        /* int32 property_billing = 59; */
        if (message.propertyBilling !== 0)
            writer.tag(59, WireType.Varint).int32(message.propertyBilling);
        /* int32 is_callback = 60; */
        if (message.isCallback !== 0)
            writer.tag(60, WireType.Varint).int32(message.isCallback);
        /* int32 log_version = 61; */
        if (message.logVersion !== 0)
            writer.tag(61, WireType.Varint).int32(message.logVersion);
        /* int32 job_type_id = 62; */
        if (message.jobTypeId !== 0)
            writer.tag(62, WireType.Varint).int32(message.jobTypeId);
        /* int32 job_subtype_id = 63; */
        if (message.jobSubtypeId !== 0)
            writer.tag(63, WireType.Varint).int32(message.jobSubtypeId);
        /* int32 callback_original_id = 64; */
        if (message.callbackOriginalId !== 0)
            writer.tag(64, WireType.Varint).int32(message.callbackOriginalId);
        /* int32 callback_disposition = 65; */
        if (message.callbackDisposition !== 0)
            writer.tag(65, WireType.Varint).int32(message.callbackDisposition);
        /* string callback_comments = 66; */
        if (message.callbackComments !== "")
            writer.tag(66, WireType.LengthDelimited).string(message.callbackComments);
        /* int32 callback_technician = 67; */
        if (message.callbackTechnician !== 0)
            writer.tag(67, WireType.Varint).int32(message.callbackTechnician);
        /* string callback_approval_timestamp = 68; */
        if (message.callbackApprovalTimestamp !== "")
            writer.tag(68, WireType.LengthDelimited).string(message.callbackApprovalTimestamp);
        /* int32 callback_comment_by = 69; */
        if (message.callbackCommentBy !== 0)
            writer.tag(69, WireType.Varint).int32(message.callbackCommentBy);
        /* int32 document_id = 70; */
        if (message.documentId !== 0)
            writer.tag(70, WireType.Varint).int32(message.documentId);
        /* string material_used = 71; */
        if (message.materialUsed !== "")
            writer.tag(71, WireType.LengthDelimited).string(message.materialUsed);
        /* double material_total = 72; */
        if (message.materialTotal !== 0)
            writer.tag(72, WireType.Bit64).double(message.materialTotal);
        /* int32 is_active = 73; */
        if (message.isActive !== 0)
            writer.tag(73, WireType.Varint).int32(message.isActive);
        /* int32 parent_id = 74; */
        if (message.parentId !== 0)
            writer.tag(74, WireType.Varint).int32(message.parentId);
        /* int32 is_lmpc = 75; */
        if (message.isLmpc !== 0)
            writer.tag(75, WireType.Varint).int32(message.isLmpc);
        /* int32 high_priority = 76; */
        if (message.highPriority !== 0)
            writer.tag(76, WireType.Varint).int32(message.highPriority);
        /* int32 is_residential = 77; */
        if (message.isResidential !== 0)
            writer.tag(77, WireType.Varint).int32(message.isResidential);
        /* string job_type = 78; */
        if (message.jobType !== "")
            writer.tag(78, WireType.LengthDelimited).string(message.jobType);
        /* string job_subtype = 79; */
        if (message.jobSubtype !== "")
            writer.tag(79, WireType.LengthDelimited).string(message.jobSubtype);
        /* User customer = 80; */
        if (message.customer)
            User.internalBinaryWrite(message.customer, writer.tag(80, WireType.LengthDelimited).fork(), options).join();
        /* Property property = 81; */
        if (message.property)
            Property.internalBinaryWrite(message.property, writer.tag(81, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 82; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(82, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 83; */
        if (message.pageNumber !== 0)
            writer.tag(83, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 84; */
        if (message.orderBy !== "")
            writer.tag(84, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 85; */
        if (message.orderDir !== "")
            writer.tag(85, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_target = 89; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(89, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string date_range = 86; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(86, WireType.LengthDelimited).string(message.dateRange[i]);
        /* bool count_only = 87; */
        if (message.countOnly !== false)
            writer.tag(87, WireType.Varint).bool(message.countOnly);
        /* repeated Quotable quote_data = 88; */
        for (let i = 0; i < message.quoteData.length; i++)
            Quotable.internalBinaryWrite(message.quoteData[i], writer.tag(88, WireType.LengthDelimited).fork(), options).join();
        /* int32 department_id = 90; */
        if (message.departmentId !== 0)
            writer.tag(90, WireType.Varint).int32(message.departmentId);
        /* repeated string not_equals = 91; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(91, WireType.LengthDelimited).string(message.notEquals[i]);
        /* bool without_limit = 92; */
        if (message.withoutLimit !== false)
            writer.tag(92, WireType.Varint).bool(message.withoutLimit);
        /* bool is_generated_invoice = 93; */
        if (message.isGeneratedInvoice !== false)
            writer.tag(93, WireType.Varint).bool(message.isGeneratedInvoice);
        /* bool only_events = 94; */
        if (message.onlyEvents !== false)
            writer.tag(94, WireType.Varint).bool(message.onlyEvents);
        /* repeated JobAdmin job_admin = 95; */
        for (let i = 0; i < message.jobAdmin.length; i++)
            JobAdmin.internalBinaryWrite(message.jobAdmin[i], writer.tag(95, WireType.LengthDelimited).fork(), options).join();
        /* int32 job_admin_search = 96; */
        if (message.jobAdminSearch !== 0)
            writer.tag(96, WireType.Varint).int32(message.jobAdminSearch);
        /* string callback_user_ids = 97; */
        if (message.callbackUserIds !== "")
            writer.tag(97, WireType.LengthDelimited).string(message.callbackUserIds);
        /* string id_list = 98; */
        if (message.idList !== "")
            writer.tag(98, WireType.LengthDelimited).string(message.idList);
        /* int32 sold_by = 99; */
        if (message.soldBy !== 0)
            writer.tag(99, WireType.Varint).int32(message.soldBy);
        /* int32 lead_generated_by = 100; */
        if (message.leadGeneratedBy !== 0)
            writer.tag(100, WireType.Varint).int32(message.leadGeneratedBy);
        /* string quote_expire_date = 101; */
        if (message.quoteExpireDate !== "")
            writer.tag(101, WireType.LengthDelimited).string(message.quoteExpireDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Event
 */
export const Event = new Event$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventList$Type extends MessageType {
    constructor() {
        super("EventList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Event results */ 1:
                    message.results.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Event results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Event.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EventList
 */
export const EventList = new EventList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarDay$Type extends MessageType {
    constructor() {
        super("CalendarDay", [
            { no: 1, name: "service_calls", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 2, name: "completed_service_calls", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 3, name: "reminders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 4, name: "timeoff_requests", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeoffRequest }
        ]);
    }
    create(value) {
        const message = { serviceCalls: [], completedServiceCalls: [], reminders: [], timeoffRequests: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Event service_calls */ 1:
                    message.serviceCalls.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Event completed_service_calls */ 2:
                    message.completedServiceCalls.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Event reminders */ 3:
                    message.reminders.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated TimeoffRequest timeoff_requests */ 4:
                    message.timeoffRequests.push(TimeoffRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Event service_calls = 1; */
        for (let i = 0; i < message.serviceCalls.length; i++)
            Event.internalBinaryWrite(message.serviceCalls[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Event completed_service_calls = 2; */
        for (let i = 0; i < message.completedServiceCalls.length; i++)
            Event.internalBinaryWrite(message.completedServiceCalls[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated Event reminders = 3; */
        for (let i = 0; i < message.reminders.length; i++)
            Event.internalBinaryWrite(message.reminders[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated TimeoffRequest timeoff_requests = 4; */
        for (let i = 0; i < message.timeoffRequests.length; i++)
            TimeoffRequest.internalBinaryWrite(message.timeoffRequests[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarDay
 */
export const CalendarDay = new CalendarDay$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarData$Type extends MessageType {
    constructor() {
        super("CalendarData", [
            { no: 1, name: "dates", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => CalendarDay } },
            { no: 2, name: "customers", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 3, name: "zip_codes", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "states", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value) {
        const message = { dates: {}, customers: {}, zipCodes: {}, states: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, CalendarDay> dates */ 1:
                    this.binaryReadMap1(message.dates, reader, options);
                    break;
                case /* map<int32, string> customers */ 2:
                    this.binaryReadMap2(message.customers, reader, options);
                    break;
                case /* map<string, string> zip_codes */ 3:
                    this.binaryReadMap3(message.zipCodes, reader, options);
                    break;
                case /* map<string, string> states */ 4:
                    this.binaryReadMap4(message.states, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap1(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = CalendarDay.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field CalendarData.dates");
            }
        }
        map[key ?? ""] = val ?? CalendarDay.create();
    }
    binaryReadMap2(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field CalendarData.customers");
            }
        }
        map[key ?? 0] = val ?? "";
    }
    binaryReadMap3(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field CalendarData.zip_codes");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    binaryReadMap4(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field CalendarData.states");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message, writer, options) {
        /* map<string, CalendarDay> dates = 1; */
        for (let k of Object.keys(message.dates)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            CalendarDay.internalBinaryWrite(message.dates[k], writer, options);
            writer.join().join();
        }
        /* map<int32, string> customers = 2; */
        for (let k of Object.keys(message.customers))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.LengthDelimited).string(message.customers[k]).join();
        /* map<string, string> zip_codes = 3; */
        for (let k of Object.keys(message.zipCodes))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.zipCodes[k]).join();
        /* map<string, string> states = 4; */
        for (let k of Object.keys(message.states))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.states[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarData
 */
export const CalendarData = new CalendarData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Quotable$Type extends MessageType {
    constructor() {
        super("Quotable", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quote_used_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "quote_line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "quoted_price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "quantity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "is_billable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_lmpc", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "is_flatrate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "is_complex", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, quoteUsedId: 0, servicesRenderedId: 0, quoteLineId: 0, description: "", quotedPrice: 0, quantity: 0, isBillable: false, isLmpc: false, isFlatrate: false, isComplex: false, isActive: false, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 quote_used_id */ 3:
                    message.quoteUsedId = reader.int32();
                    break;
                case /* int32 services_rendered_id */ 4:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* int32 quote_line_id */ 5:
                    message.quoteLineId = reader.int32();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* double quoted_price */ 7:
                    message.quotedPrice = reader.double();
                    break;
                case /* double quantity */ 8:
                    message.quantity = reader.double();
                    break;
                case /* bool is_billable */ 9:
                    message.isBillable = reader.bool();
                    break;
                case /* bool is_lmpc */ 10:
                    message.isLmpc = reader.bool();
                    break;
                case /* bool is_flatrate */ 11:
                    message.isFlatrate = reader.bool();
                    break;
                case /* bool is_complex */ 12:
                    message.isComplex = reader.bool();
                    break;
                case /* bool is_active */ 13:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 14:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 quote_used_id = 3; */
        if (message.quoteUsedId !== 0)
            writer.tag(3, WireType.Varint).int32(message.quoteUsedId);
        /* int32 services_rendered_id = 4; */
        if (message.servicesRenderedId !== 0)
            writer.tag(4, WireType.Varint).int32(message.servicesRenderedId);
        /* int32 quote_line_id = 5; */
        if (message.quoteLineId !== 0)
            writer.tag(5, WireType.Varint).int32(message.quoteLineId);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* double quoted_price = 7; */
        if (message.quotedPrice !== 0)
            writer.tag(7, WireType.Bit64).double(message.quotedPrice);
        /* double quantity = 8; */
        if (message.quantity !== 0)
            writer.tag(8, WireType.Bit64).double(message.quantity);
        /* bool is_billable = 9; */
        if (message.isBillable !== false)
            writer.tag(9, WireType.Varint).bool(message.isBillable);
        /* bool is_lmpc = 10; */
        if (message.isLmpc !== false)
            writer.tag(10, WireType.Varint).bool(message.isLmpc);
        /* bool is_flatrate = 11; */
        if (message.isFlatrate !== false)
            writer.tag(11, WireType.Varint).bool(message.isFlatrate);
        /* bool is_complex = 12; */
        if (message.isComplex !== false)
            writer.tag(12, WireType.Varint).bool(message.isComplex);
        /* bool is_active = 13; */
        if (message.isActive !== false)
            writer.tag(13, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 14; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Quotable
 */
export const Quotable = new Quotable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotableRead$Type extends MessageType {
    constructor() {
        super("QuotableRead", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quote_used_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "quote_line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "quoted_price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "quantity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "is_billable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_lmpc", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "is_flatrate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "is_complex", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, quoteUsedId: 0, servicesRenderedId: 0, quoteLineId: 0, description: "", quotedPrice: 0, quantity: 0, isBillable: false, isLmpc: false, isFlatrate: false, isComplex: false, isActive: false, fieldMask: [], notEquals: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 quote_used_id */ 3:
                    message.quoteUsedId = reader.int32();
                    break;
                case /* int32 services_rendered_id */ 4:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* int32 quote_line_id */ 5:
                    message.quoteLineId = reader.int32();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* double quoted_price */ 7:
                    message.quotedPrice = reader.double();
                    break;
                case /* double quantity */ 8:
                    message.quantity = reader.double();
                    break;
                case /* bool is_billable */ 9:
                    message.isBillable = reader.bool();
                    break;
                case /* bool is_lmpc */ 10:
                    message.isLmpc = reader.bool();
                    break;
                case /* bool is_flatrate */ 11:
                    message.isFlatrate = reader.bool();
                    break;
                case /* bool is_complex */ 12:
                    message.isComplex = reader.bool();
                    break;
                case /* bool is_active */ 13:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 14:
                    message.fieldMask.push(reader.string());
                    break;
                case /* repeated string not_equals */ 15:
                    message.notEquals.push(reader.string());
                    break;
                case /* int32 page_number */ 16:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 17:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 18:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 quote_used_id = 3; */
        if (message.quoteUsedId !== 0)
            writer.tag(3, WireType.Varint).int32(message.quoteUsedId);
        /* int32 services_rendered_id = 4; */
        if (message.servicesRenderedId !== 0)
            writer.tag(4, WireType.Varint).int32(message.servicesRenderedId);
        /* int32 quote_line_id = 5; */
        if (message.quoteLineId !== 0)
            writer.tag(5, WireType.Varint).int32(message.quoteLineId);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* double quoted_price = 7; */
        if (message.quotedPrice !== 0)
            writer.tag(7, WireType.Bit64).double(message.quotedPrice);
        /* double quantity = 8; */
        if (message.quantity !== 0)
            writer.tag(8, WireType.Bit64).double(message.quantity);
        /* bool is_billable = 9; */
        if (message.isBillable !== false)
            writer.tag(9, WireType.Varint).bool(message.isBillable);
        /* bool is_lmpc = 10; */
        if (message.isLmpc !== false)
            writer.tag(10, WireType.Varint).bool(message.isLmpc);
        /* bool is_flatrate = 11; */
        if (message.isFlatrate !== false)
            writer.tag(11, WireType.Varint).bool(message.isFlatrate);
        /* bool is_complex = 12; */
        if (message.isComplex !== false)
            writer.tag(12, WireType.Varint).bool(message.isComplex);
        /* bool is_active = 13; */
        if (message.isActive !== false)
            writer.tag(13, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 14; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* repeated string not_equals = 15; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.notEquals[i]);
        /* int32 page_number = 16; */
        if (message.pageNumber !== 0)
            writer.tag(16, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 17; */
        if (message.orderBy !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 18; */
        if (message.orderDir !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuotableRead
 */
export const QuotableRead = new QuotableRead$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotableList$Type extends MessageType {
    constructor() {
        super("QuotableList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Quotable },
            { no: 19, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Quotable data */ 1:
                    message.data.push(Quotable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 19:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Quotable data = 1; */
        for (let i = 0; i < message.data.length; i++)
            Quotable.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 19; */
        if (message.totalCount !== 0)
            writer.tag(19, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuotableList
 */
export const QuotableList = new QuotableList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CostReportData$Type extends MessageType {
    constructor() {
        super("CostReportData", [
            { no: 1, name: "transaction_results", kind: "message", T: () => TransactionList },
            { no: 2, name: "timesheet_results", kind: "message", T: () => TimesheetLineList },
            { no: 3, name: "per_diem_results", kind: "message", T: () => PerDiemList },
            { no: 4, name: "task_results", kind: "message", T: () => TaskList },
            { no: 5, name: "trip_results", kind: "message", T: () => TripList },
            { no: 6, name: "event_result", kind: "message", T: () => Event }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* TransactionList transaction_results */ 1:
                    message.transactionResults = TransactionList.internalBinaryRead(reader, reader.uint32(), options, message.transactionResults);
                    break;
                case /* TimesheetLineList timesheet_results */ 2:
                    message.timesheetResults = TimesheetLineList.internalBinaryRead(reader, reader.uint32(), options, message.timesheetResults);
                    break;
                case /* PerDiemList per_diem_results */ 3:
                    message.perDiemResults = PerDiemList.internalBinaryRead(reader, reader.uint32(), options, message.perDiemResults);
                    break;
                case /* TaskList task_results */ 4:
                    message.taskResults = TaskList.internalBinaryRead(reader, reader.uint32(), options, message.taskResults);
                    break;
                case /* TripList trip_results */ 5:
                    message.tripResults = TripList.internalBinaryRead(reader, reader.uint32(), options, message.tripResults);
                    break;
                case /* Event event_result */ 6:
                    message.eventResult = Event.internalBinaryRead(reader, reader.uint32(), options, message.eventResult);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* TransactionList transaction_results = 1; */
        if (message.transactionResults)
            TransactionList.internalBinaryWrite(message.transactionResults, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* TimesheetLineList timesheet_results = 2; */
        if (message.timesheetResults)
            TimesheetLineList.internalBinaryWrite(message.timesheetResults, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* PerDiemList per_diem_results = 3; */
        if (message.perDiemResults)
            PerDiemList.internalBinaryWrite(message.perDiemResults, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* TaskList task_results = 4; */
        if (message.taskResults)
            TaskList.internalBinaryWrite(message.taskResults, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* TripList trip_results = 5; */
        if (message.tripResults)
            TripList.internalBinaryWrite(message.tripResults, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Event event_result = 6; */
        if (message.eventResult)
            Event.internalBinaryWrite(message.eventResult, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CostReportData
 */
export const CostReportData = new CostReportData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CostReportReq$Type extends MessageType {
    constructor() {
        super("CostReportReq", [
            { no: 1, name: "timesheet_req", kind: "message", T: () => TimesheetLine },
            { no: 2, name: "perDiem_req", kind: "message", T: () => PerDiem },
            { no: 3, name: "task_req", kind: "message", T: () => Task },
            { no: 4, name: "trip_req", kind: "message", T: () => Trip },
            { no: 5, name: "transaction_req", kind: "message", T: () => Transaction }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* TimesheetLine timesheet_req */ 1:
                    message.timesheetReq = TimesheetLine.internalBinaryRead(reader, reader.uint32(), options, message.timesheetReq);
                    break;
                case /* PerDiem perDiem_req */ 2:
                    message.perDiemReq = PerDiem.internalBinaryRead(reader, reader.uint32(), options, message.perDiemReq);
                    break;
                case /* Task task_req */ 3:
                    message.taskReq = Task.internalBinaryRead(reader, reader.uint32(), options, message.taskReq);
                    break;
                case /* Trip trip_req */ 4:
                    message.tripReq = Trip.internalBinaryRead(reader, reader.uint32(), options, message.tripReq);
                    break;
                case /* Transaction transaction_req */ 5:
                    message.transactionReq = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transactionReq);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* TimesheetLine timesheet_req = 1; */
        if (message.timesheetReq)
            TimesheetLine.internalBinaryWrite(message.timesheetReq, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* PerDiem perDiem_req = 2; */
        if (message.perDiemReq)
            PerDiem.internalBinaryWrite(message.perDiemReq, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Task task_req = 3; */
        if (message.taskReq)
            Task.internalBinaryWrite(message.taskReq, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Trip trip_req = 4; */
        if (message.tripReq)
            Trip.internalBinaryWrite(message.tripReq, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Transaction transaction_req = 5; */
        if (message.transactionReq)
            Transaction.internalBinaryWrite(message.transactionReq, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CostReportReq
 */
export const CostReportReq = new CostReportReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobAdmin$Type extends MessageType {
    constructor() {
        super("JobAdmin", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, jobNumber: 0, userId: 0, title: "", isActive: false, pageNumber: 0, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 job_number */ 2:
                    message.jobNumber = reader.int32();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* bool is_active */ 5:
                    message.isActive = reader.bool();
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 job_number = 2; */
        if (message.jobNumber !== 0)
            writer.tag(2, WireType.Varint).int32(message.jobNumber);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* bool is_active = 5; */
        if (message.isActive !== false)
            writer.tag(5, WireType.Varint).bool(message.isActive);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobAdmin
 */
export const JobAdmin = new JobAdmin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobAdminList$Type extends MessageType {
    constructor() {
        super("JobAdminList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobAdmin },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated JobAdmin results */ 1:
                    message.results.push(JobAdmin.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated JobAdmin results = 1; */
        for (let i = 0; i < message.results.length; i++)
            JobAdmin.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobAdminList
 */
export const JobAdminList = new JobAdminList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventAssignment$Type extends MessageType {
    constructor() {
        super("EventAssignment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, eventId: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 event_id */ 3:
                    message.eventId = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 event_id = 3; */
        if (message.eventId !== 0)
            writer.tag(3, WireType.Varint).int32(message.eventId);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EventAssignment
 */
export const EventAssignment = new EventAssignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventAssignmentList$Type extends MessageType {
    constructor() {
        super("EventAssignmentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventAssignment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EventAssignment results */ 1:
                    message.results.push(EventAssignment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated EventAssignment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            EventAssignment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EventAssignmentList
 */
export const EventAssignmentList = new EventAssignmentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuickBooksTrackingCode$Type extends MessageType {
    constructor() {
        super("QuickBooksTrackingCode", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "tracking_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, trackingCode: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* string tracking_code */ 3:
                    message.trackingCode = reader.string();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* string tracking_code = 3; */
        if (message.trackingCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.trackingCode);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuickBooksTrackingCode
 */
export const QuickBooksTrackingCode = new QuickBooksTrackingCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuickBooksTrackingCodeList$Type extends MessageType {
    constructor() {
        super("QuickBooksTrackingCodeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuickBooksTrackingCode },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated QuickBooksTrackingCode results */ 1:
                    message.results.push(QuickBooksTrackingCode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated QuickBooksTrackingCode results = 1; */
        for (let i = 0; i < message.results.length; i++)
            QuickBooksTrackingCode.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuickBooksTrackingCodeList
 */
export const QuickBooksTrackingCodeList = new QuickBooksTrackingCodeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PendingBilling$Type extends MessageType {
    constructor() {
        super("PendingBilling", [
            { no: 1, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "date_completed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { eventId: 0, propertyId: 0, userId: 0, customerName: "", businessName: "", dateCompleted: "", address: "", pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 event_id */ 1:
                    message.eventId = reader.int32();
                    break;
                case /* int32 property_id */ 2:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* string customer_name */ 4:
                    message.customerName = reader.string();
                    break;
                case /* string business_name */ 5:
                    message.businessName = reader.string();
                    break;
                case /* string date_completed */ 6:
                    message.dateCompleted = reader.string();
                    break;
                case /* string address */ 7:
                    message.address = reader.string();
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 9:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 10:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 event_id = 1; */
        if (message.eventId !== 0)
            writer.tag(1, WireType.Varint).int32(message.eventId);
        /* int32 property_id = 2; */
        if (message.propertyId !== 0)
            writer.tag(2, WireType.Varint).int32(message.propertyId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* string customer_name = 4; */
        if (message.customerName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerName);
        /* string business_name = 5; */
        if (message.businessName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.businessName);
        /* string date_completed = 6; */
        if (message.dateCompleted !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.dateCompleted);
        /* string address = 7; */
        if (message.address !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.address);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 9; */
        if (message.orderBy !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 10; */
        if (message.orderDir !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PendingBilling
 */
export const PendingBilling = new PendingBilling$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PendingBillingList$Type extends MessageType {
    constructor() {
        super("PendingBillingList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PendingBilling },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PendingBilling results */ 1:
                    message.results.push(PendingBilling.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PendingBilling results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PendingBilling.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PendingBillingList
 */
export const PendingBillingList = new PendingBillingList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeolocationTimesheetData$Type extends MessageType {
    constructor() {
        super("GeolocationTimesheetData", [
            { no: 1, name: "timesheet_data", kind: "message", T: () => TimesheetLineList },
            { no: 2, name: "geolocation_id_list", kind: "message", T: () => Int32List }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* TimesheetLineList timesheet_data */ 1:
                    message.timesheetData = TimesheetLineList.internalBinaryRead(reader, reader.uint32(), options, message.timesheetData);
                    break;
                case /* Int32List geolocation_id_list */ 2:
                    message.geolocationIdList = Int32List.internalBinaryRead(reader, reader.uint32(), options, message.geolocationIdList);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* TimesheetLineList timesheet_data = 1; */
        if (message.timesheetData)
            TimesheetLineList.internalBinaryWrite(message.timesheetData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Int32List geolocation_id_list = 2; */
        if (message.geolocationIdList)
            Int32List.internalBinaryWrite(message.geolocationIdList, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeolocationTimesheetData
 */
export const GeolocationTimesheetData = new GeolocationTimesheetData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceLink$Type extends MessageType {
    constructor() {
        super("InvoiceLink", [
            { no: 1, name: "invoice_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "invoice_job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { invoiceId: 0n, invoiceJobNumber: "", customerId: 0n, amount: 0, description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 invoice_id */ 1:
                    message.invoiceId = reader.int64().toBigInt();
                    break;
                case /* string invoice_job_number */ 2:
                    message.invoiceJobNumber = reader.string();
                    break;
                case /* int64 customer_id */ 3:
                    message.customerId = reader.int64().toBigInt();
                    break;
                case /* double amount */ 4:
                    message.amount = reader.double();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 invoice_id = 1; */
        if (message.invoiceId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.invoiceId);
        /* string invoice_job_number = 2; */
        if (message.invoiceJobNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.invoiceJobNumber);
        /* int64 customer_id = 3; */
        if (message.customerId !== 0n)
            writer.tag(3, WireType.Varint).int64(message.customerId);
        /* double amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Bit64).double(message.amount);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoiceLink
 */
export const InvoiceLink = new InvoiceLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrder$Type extends MessageType {
    constructor() {
        super("PurchaseOrder", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "purchase_order_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "job_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updated_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "requisition_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseOrderRequisitionItem }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, purchaseOrderNumber: 0, jobId: 0, createdDate: "", updatedDate: "", requisitionItems: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 purchase_order_number */ 3:
                    message.purchaseOrderNumber = reader.int32();
                    break;
                case /* int32 job_id */ 4:
                    message.jobId = reader.int32();
                    break;
                case /* string created_date */ 5:
                    message.createdDate = reader.string();
                    break;
                case /* string updated_date */ 6:
                    message.updatedDate = reader.string();
                    break;
                case /* repeated PurchaseOrderRequisitionItem requisition_items */ 10:
                    message.requisitionItems.push(PurchaseOrderRequisitionItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 purchase_order_number = 3; */
        if (message.purchaseOrderNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.purchaseOrderNumber);
        /* int32 job_id = 4; */
        if (message.jobId !== 0)
            writer.tag(4, WireType.Varint).int32(message.jobId);
        /* string created_date = 5; */
        if (message.createdDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdDate);
        /* string updated_date = 6; */
        if (message.updatedDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.updatedDate);
        /* repeated PurchaseOrderRequisitionItem requisition_items = 10; */
        for (let i = 0; i < message.requisitionItems.length; i++)
            PurchaseOrderRequisitionItem.internalBinaryWrite(message.requisitionItems[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrder
 */
export const PurchaseOrder = new PurchaseOrder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrderBatchGetRequest$Type extends MessageType {
    constructor() {
        super("PurchaseOrderBatchGetRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "purchase_order_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "job_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, purchaseOrderNumber: 0, jobId: 0, fieldMask: [], pageNumber: 0, pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* int32 purchase_order_number */ 2:
                    message.purchaseOrderNumber = reader.int32();
                    break;
                case /* int32 job_id */ 3:
                    message.jobId = reader.int32();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 page_size */ 12:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* int32 purchase_order_number = 2; */
        if (message.purchaseOrderNumber !== 0)
            writer.tag(2, WireType.Varint).int32(message.purchaseOrderNumber);
        /* int32 job_id = 3; */
        if (message.jobId !== 0)
            writer.tag(3, WireType.Varint).int32(message.jobId);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* int32 page_size = 12; */
        if (message.pageSize !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrderBatchGetRequest
 */
export const PurchaseOrderBatchGetRequest = new PurchaseOrderBatchGetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrderUpdateRequest$Type extends MessageType {
    constructor() {
        super("PurchaseOrderUpdateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "purchase_order_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "job_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "requisition_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseOrderRequisitionItem },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, purchaseOrderNumber: 0, jobId: 0, requisitionItems: [], fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 purchase_order_number */ 3:
                    message.purchaseOrderNumber = reader.int32();
                    break;
                case /* int32 job_id */ 4:
                    message.jobId = reader.int32();
                    break;
                case /* repeated PurchaseOrderRequisitionItem requisition_items */ 5:
                    message.requisitionItems.push(PurchaseOrderRequisitionItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 purchase_order_number = 3; */
        if (message.purchaseOrderNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.purchaseOrderNumber);
        /* int32 job_id = 4; */
        if (message.jobId !== 0)
            writer.tag(4, WireType.Varint).int32(message.jobId);
        /* repeated PurchaseOrderRequisitionItem requisition_items = 5; */
        for (let i = 0; i < message.requisitionItems.length; i++)
            PurchaseOrderRequisitionItem.internalBinaryWrite(message.requisitionItems[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrderUpdateRequest
 */
export const PurchaseOrderUpdateRequest = new PurchaseOrderUpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrderList$Type extends MessageType {
    constructor() {
        super("PurchaseOrderList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseOrder },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PurchaseOrder results */ 1:
                    message.results.push(PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PurchaseOrder results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PurchaseOrder.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrderList
 */
export const PurchaseOrderList = new PurchaseOrderList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrderRequisitionItem$Type extends MessageType {
    constructor() {
        super("PurchaseOrderRequisitionItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "purchase_order_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "item_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updated_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, purchaseOrderId: 0, itemName: "", quantity: 0, createdDate: "", updatedDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 purchase_order_id */ 2:
                    message.purchaseOrderId = reader.int32();
                    break;
                case /* string item_name */ 3:
                    message.itemName = reader.string();
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* string created_date */ 5:
                    message.createdDate = reader.string();
                    break;
                case /* string updated_date */ 6:
                    message.updatedDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 purchase_order_id = 2; */
        if (message.purchaseOrderId !== 0)
            writer.tag(2, WireType.Varint).int32(message.purchaseOrderId);
        /* string item_name = 3; */
        if (message.itemName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.itemName);
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* string created_date = 5; */
        if (message.createdDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdDate);
        /* string updated_date = 6; */
        if (message.updatedDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.updatedDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrderRequisitionItem
 */
export const PurchaseOrderRequisitionItem = new PurchaseOrderRequisitionItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrderRequisitionItemBatchGetRequest$Type extends MessageType {
    constructor() {
        super("PurchaseOrderRequisitionItemBatchGetRequest", [
            { no: 1, name: "purchase_order_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "item_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { purchaseOrderId: 0, itemName: "", quantity: 0, fieldMask: [], pageNumber: 0, pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 purchase_order_id */ 1:
                    message.purchaseOrderId = reader.int32();
                    break;
                case /* string item_name */ 2:
                    message.itemName = reader.string();
                    break;
                case /* int32 quantity */ 3:
                    message.quantity = reader.int32();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 page_size */ 12:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 purchase_order_id = 1; */
        if (message.purchaseOrderId !== 0)
            writer.tag(1, WireType.Varint).int32(message.purchaseOrderId);
        /* string item_name = 2; */
        if (message.itemName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.itemName);
        /* int32 quantity = 3; */
        if (message.quantity !== 0)
            writer.tag(3, WireType.Varint).int32(message.quantity);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* int32 page_size = 12; */
        if (message.pageSize !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrderRequisitionItemBatchGetRequest
 */
export const PurchaseOrderRequisitionItemBatchGetRequest = new PurchaseOrderRequisitionItemBatchGetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrderRequisitionItemUpdateRequest$Type extends MessageType {
    constructor() {
        super("PurchaseOrderRequisitionItemUpdateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "purchase_order_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "item_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, purchaseOrderId: 0, itemName: "", quantity: 0, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 purchase_order_id */ 2:
                    message.purchaseOrderId = reader.int32();
                    break;
                case /* string item_name */ 3:
                    message.itemName = reader.string();
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 purchase_order_id = 2; */
        if (message.purchaseOrderId !== 0)
            writer.tag(2, WireType.Varint).int32(message.purchaseOrderId);
        /* string item_name = 3; */
        if (message.itemName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.itemName);
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrderRequisitionItemUpdateRequest
 */
export const PurchaseOrderRequisitionItemUpdateRequest = new PurchaseOrderRequisitionItemUpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseOrderRequisitionItemList$Type extends MessageType {
    constructor() {
        super("PurchaseOrderRequisitionItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseOrderRequisitionItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PurchaseOrderRequisitionItem results */ 1:
                    message.results.push(PurchaseOrderRequisitionItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PurchaseOrderRequisitionItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PurchaseOrderRequisitionItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PurchaseOrderRequisitionItemList
 */
export const PurchaseOrderRequisitionItemList = new PurchaseOrderRequisitionItemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeolocationCheckoutRequest$Type extends MessageType {
    constructor() {
        super("GeolocationCheckoutRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "startTimestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endTimestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, startTimestamp: "", endTimestamp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string startTimestamp */ 2:
                    message.startTimestamp = reader.string();
                    break;
                case /* string endTimestamp */ 3:
                    message.endTimestamp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string startTimestamp = 2; */
        if (message.startTimestamp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTimestamp);
        /* string endTimestamp = 3; */
        if (message.endTimestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endTimestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeolocationCheckoutRequest
 */
export const GeolocationCheckoutRequest = new GeolocationCheckoutRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectEvent$Type extends MessageType {
    constructor() {
        super("ProjectEvent", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "property_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "searchJobNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "primary_event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", propertyId: 0, isActive: false, fieldMask: [], pageNumber: 0, userId: 0, customerName: "", propertyAddress: "", searchJobNumber: 0, primaryEventId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 property_id */ 3:
                    message.propertyId = reader.int32();
                    break;
                case /* bool is_active */ 4:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 user_id */ 7:
                    message.userId = reader.int32();
                    break;
                case /* string customer_name */ 8:
                    message.customerName = reader.string();
                    break;
                case /* string property_address */ 9:
                    message.propertyAddress = reader.string();
                    break;
                case /* int32 searchJobNumber */ 10:
                    message.searchJobNumber = reader.int32();
                    break;
                case /* int32 primary_event_id */ 11:
                    message.primaryEventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 property_id = 3; */
        if (message.propertyId !== 0)
            writer.tag(3, WireType.Varint).int32(message.propertyId);
        /* bool is_active = 4; */
        if (message.isActive !== false)
            writer.tag(4, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        /* int32 user_id = 7; */
        if (message.userId !== 0)
            writer.tag(7, WireType.Varint).int32(message.userId);
        /* string customer_name = 8; */
        if (message.customerName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerName);
        /* string property_address = 9; */
        if (message.propertyAddress !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.propertyAddress);
        /* int32 searchJobNumber = 10; */
        if (message.searchJobNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.searchJobNumber);
        /* int32 primary_event_id = 11; */
        if (message.primaryEventId !== 0)
            writer.tag(11, WireType.Varint).int32(message.primaryEventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectEvent
 */
export const ProjectEvent = new ProjectEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectEventList$Type extends MessageType {
    constructor() {
        super("ProjectEventList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectEvent },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProjectEvent results */ 1:
                    message.results.push(ProjectEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProjectEvent results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ProjectEvent.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProjectEventList
 */
export const ProjectEventList = new ProjectEventList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoicePayment$Type extends MessageType {
    constructor() {
        super("InvoicePayment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "payment_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "payment_type_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "customer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "creator_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "include_payment_line", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "payment_line", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvoicePaymentLine }
        ]);
    }
    create(value) {
        const message = { id: 0, amount: 0, createdDate: "", paymentDate: "", paymentTypeId: "", customerId: 0, creatorId: 0, isActive: false, fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, includePaymentLine: false, paymentLine: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double amount */ 2:
                    message.amount = reader.double();
                    break;
                case /* string created_date */ 3:
                    message.createdDate = reader.string();
                    break;
                case /* string payment_date */ 4:
                    message.paymentDate = reader.string();
                    break;
                case /* string payment_type_id */ 5:
                    message.paymentTypeId = reader.string();
                    break;
                case /* int32 customer_id */ 6:
                    message.customerId = reader.int32();
                    break;
                case /* int32 creator_id */ 7:
                    message.creatorId = reader.int32();
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 9:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 10:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 11:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 12:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool include_payment_line */ 13:
                    message.includePaymentLine = reader.bool();
                    break;
                case /* repeated InvoicePaymentLine payment_line */ 14:
                    message.paymentLine.push(InvoicePaymentLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Bit64).double(message.amount);
        /* string created_date = 3; */
        if (message.createdDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.createdDate);
        /* string payment_date = 4; */
        if (message.paymentDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.paymentDate);
        /* string payment_type_id = 5; */
        if (message.paymentTypeId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.paymentTypeId);
        /* int32 customer_id = 6; */
        if (message.customerId !== 0)
            writer.tag(6, WireType.Varint).int32(message.customerId);
        /* int32 creator_id = 7; */
        if (message.creatorId !== 0)
            writer.tag(7, WireType.Varint).int32(message.creatorId);
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 9; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 10; */
        if (message.orderBy !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 11; */
        if (message.orderDir !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 12; */
        if (message.pageNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageNumber);
        /* bool include_payment_line = 13; */
        if (message.includePaymentLine !== false)
            writer.tag(13, WireType.Varint).bool(message.includePaymentLine);
        /* repeated InvoicePaymentLine payment_line = 14; */
        for (let i = 0; i < message.paymentLine.length; i++)
            InvoicePaymentLine.internalBinaryWrite(message.paymentLine[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoicePayment
 */
export const InvoicePayment = new InvoicePayment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoicePaymentList$Type extends MessageType {
    constructor() {
        super("InvoicePaymentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvoicePayment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated InvoicePayment results */ 1:
                    message.results.push(InvoicePayment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated InvoicePayment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            InvoicePayment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoicePaymentList
 */
export const InvoicePaymentList = new InvoicePaymentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoicePaymentLine$Type extends MessageType {
    constructor() {
        super("InvoicePaymentLine", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "invoice_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "payment_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "creator_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, invoiceId: 0, paymentId: 0, amount: 0, createdDate: "", isActive: false, creatorId: 0, fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, eventId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 invoice_id */ 2:
                    message.invoiceId = reader.int32();
                    break;
                case /* int32 payment_id */ 3:
                    message.paymentId = reader.int32();
                    break;
                case /* double amount */ 4:
                    message.amount = reader.double();
                    break;
                case /* string created_date */ 5:
                    message.createdDate = reader.string();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                case /* int32 creator_id */ 7:
                    message.creatorId = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 9:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 10:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 event_id */ 12:
                    message.eventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 invoice_id = 2; */
        if (message.invoiceId !== 0)
            writer.tag(2, WireType.Varint).int32(message.invoiceId);
        /* int32 payment_id = 3; */
        if (message.paymentId !== 0)
            writer.tag(3, WireType.Varint).int32(message.paymentId);
        /* double amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Bit64).double(message.amount);
        /* string created_date = 5; */
        if (message.createdDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdDate);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        /* int32 creator_id = 7; */
        if (message.creatorId !== 0)
            writer.tag(7, WireType.Varint).int32(message.creatorId);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 9; */
        if (message.orderBy !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 10; */
        if (message.orderDir !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* int32 event_id = 12; */
        if (message.eventId !== 0)
            writer.tag(12, WireType.Varint).int32(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoicePaymentLine
 */
export const InvoicePaymentLine = new InvoicePaymentLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoicePaymentLineList$Type extends MessageType {
    constructor() {
        super("InvoicePaymentLineList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvoicePaymentLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated InvoicePaymentLine results */ 1:
                    message.results.push(InvoicePaymentLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated InvoicePaymentLine results = 1; */
        for (let i = 0; i < message.results.length; i++)
            InvoicePaymentLine.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoicePaymentLineList
 */
export const InvoicePaymentLineList = new InvoicePaymentLineList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallCountResponse$Type extends MessageType {
    constructor() {
        super("CallCountResponse", [
            { no: 1, name: "call_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "jobs_in_window", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value) {
        const message = { callCount: 0, jobsInWindow: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 call_count */ 1:
                    message.callCount = reader.int32();
                    break;
                case /* repeated Event jobs_in_window */ 2:
                    message.jobsInWindow.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 call_count = 1; */
        if (message.callCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.callCount);
        /* repeated Event jobs_in_window = 2; */
        for (let i = 0; i < message.jobsInWindow.length; i++)
            Event.internalBinaryWrite(message.jobsInWindow[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallCountResponse
 */
export const CallCountResponse = new CallCountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallCountRequest$Type extends MessageType {
    constructor() {
        super("CallCountRequest", [
            { no: 1, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { startTime: "", endTime: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string start_time */ 1:
                    message.startTime = reader.string();
                    break;
                case /* string end_time */ 2:
                    message.endTime = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string start_time = 1; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string end_time = 2; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallCountRequest
 */
export const CallCountRequest = new CallCountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchUserByPhoneRequest$Type extends MessageType {
    constructor() {
        super("SearchUserByPhoneRequest", [
            { no: 1, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "date_target", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "interval_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { phoneNumber: "", dateTarget: "", intervalDays: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string phone_number */ 1:
                    message.phoneNumber = reader.string();
                    break;
                case /* string date_target */ 2:
                    message.dateTarget = reader.string();
                    break;
                case /* int32 interval_days */ 3:
                    message.intervalDays = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string phone_number = 1; */
        if (message.phoneNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.phoneNumber);
        /* string date_target = 2; */
        if (message.dateTarget !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dateTarget);
        /* int32 interval_days = 3; */
        if (message.intervalDays !== 0)
            writer.tag(3, WireType.Varint).int32(message.intervalDays);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SearchUserByPhoneRequest
 */
export const SearchUserByPhoneRequest = new SearchUserByPhoneRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchUserByPhoneResponse$Type extends MessageType {
    constructor() {
        super("SearchUserByPhoneResponse", [
            { no: 1, name: "user_activities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserRelevantData },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value) {
        const message = { userActivities: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated UserRelevantData user_activities */ 1:
                    message.userActivities.push(UserRelevantData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                case /* User user */ 3:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated UserRelevantData user_activities = 1; */
        for (let i = 0; i < message.userActivities.length; i++)
            UserRelevantData.internalBinaryWrite(message.userActivities[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        /* User user = 3; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SearchUserByPhoneResponse
 */
export const SearchUserByPhoneResponse = new SearchUserByPhoneResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRelevantData$Type extends MessageType {
    constructor() {
        super("UserRelevantData", [
            { no: 1, name: "data_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "data_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "data_internal_reference", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "expiring_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "priority", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value) {
        const message = { dataId: 0, userId: 0, dataType: "", dataInternalReference: "", typeDescription: "", expiringDate: "", priority: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 data_id */ 1:
                    message.dataId = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* string data_type */ 3:
                    message.dataType = reader.string();
                    break;
                case /* string data_internal_reference */ 4:
                    message.dataInternalReference = reader.string();
                    break;
                case /* string type_description */ 5:
                    message.typeDescription = reader.string();
                    break;
                case /* string expiring_date */ 6:
                    message.expiringDate = reader.string();
                    break;
                case /* float priority */ 7:
                    message.priority = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 data_id = 1; */
        if (message.dataId !== 0)
            writer.tag(1, WireType.Varint).int32(message.dataId);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* string data_type = 3; */
        if (message.dataType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dataType);
        /* string data_internal_reference = 4; */
        if (message.dataInternalReference !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.dataInternalReference);
        /* string type_description = 5; */
        if (message.typeDescription !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.typeDescription);
        /* string expiring_date = 6; */
        if (message.expiringDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.expiringDate);
        /* float priority = 7; */
        if (message.priority !== 0)
            writer.tag(7, WireType.Bit32).float(message.priority);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserRelevantData
 */
export const UserRelevantData = new UserRelevantData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentType$Type extends MessageType {
    constructor() {
        super("PaymentType", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string field_mask */ 3:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 4:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string field_mask = 3; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 4; */
        if (message.pageNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaymentType
 */
export const PaymentType = new PaymentType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentTypeList$Type extends MessageType {
    constructor() {
        super("PaymentTypeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentType },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PaymentType results */ 1:
                    message.results.push(PaymentType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PaymentType results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PaymentType.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaymentTypeList
 */
export const PaymentTypeList = new PaymentTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServicesRenderedPaymentType$Type extends MessageType {
    constructor() {
        super("ServicesRenderedPaymentType", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_collected", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", isCollected: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 is_collected */ 3:
                    message.isCollected = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 is_collected = 3; */
        if (message.isCollected !== 0)
            writer.tag(3, WireType.Varint).int32(message.isCollected);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServicesRenderedPaymentType
 */
export const ServicesRenderedPaymentType = new ServicesRenderedPaymentType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServicesRenderedPaymentTypeList$Type extends MessageType {
    constructor() {
        super("ServicesRenderedPaymentTypeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServicesRenderedPaymentType },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ServicesRenderedPaymentType results */ 1:
                    message.results.push(ServicesRenderedPaymentType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ServicesRenderedPaymentType results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ServicesRenderedPaymentType.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServicesRenderedPaymentTypeList
 */
export const ServicesRenderedPaymentTypeList = new ServicesRenderedPaymentTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchProjectCostAndEstimateRequest$Type extends MessageType {
    constructor() {
        super("FetchProjectCostAndEstimateRequest", [
            { no: 1, name: "project_event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { projectEventId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 project_event_id */ 1:
                    message.projectEventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 project_event_id = 1; */
        if (message.projectEventId !== 0)
            writer.tag(1, WireType.Varint).int32(message.projectEventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FetchProjectCostAndEstimateRequest
 */
export const FetchProjectCostAndEstimateRequest = new FetchProjectCostAndEstimateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchProjectCostAndEstimateResponse$Type extends MessageType {
    constructor() {
        super("FetchProjectCostAndEstimateResponse", [
            { no: 1, name: "transaction_results", kind: "message", T: () => TransactionList },
            { no: 2, name: "timesheet_results", kind: "message", T: () => TimesheetLineList },
            { no: 3, name: "perdiem_results", kind: "message", T: () => PerDiemList },
            { no: 4, name: "trip_results", kind: "message", T: () => TripList },
            { no: 5, name: "estimates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Estimate },
            { no: 6, name: "expends", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Expended },
            { no: 7, name: "changeOrders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChangeOrder }
        ]);
    }
    create(value) {
        const message = { estimates: [], expends: [], changeOrders: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* TransactionList transaction_results */ 1:
                    message.transactionResults = TransactionList.internalBinaryRead(reader, reader.uint32(), options, message.transactionResults);
                    break;
                case /* TimesheetLineList timesheet_results */ 2:
                    message.timesheetResults = TimesheetLineList.internalBinaryRead(reader, reader.uint32(), options, message.timesheetResults);
                    break;
                case /* PerDiemList perdiem_results */ 3:
                    message.perdiemResults = PerDiemList.internalBinaryRead(reader, reader.uint32(), options, message.perdiemResults);
                    break;
                case /* TripList trip_results */ 4:
                    message.tripResults = TripList.internalBinaryRead(reader, reader.uint32(), options, message.tripResults);
                    break;
                case /* repeated Estimate estimates */ 5:
                    message.estimates.push(Estimate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Expended expends */ 6:
                    message.expends.push(Expended.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ChangeOrder changeOrders */ 7:
                    message.changeOrders.push(ChangeOrder.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* TransactionList transaction_results = 1; */
        if (message.transactionResults)
            TransactionList.internalBinaryWrite(message.transactionResults, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* TimesheetLineList timesheet_results = 2; */
        if (message.timesheetResults)
            TimesheetLineList.internalBinaryWrite(message.timesheetResults, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* PerDiemList perdiem_results = 3; */
        if (message.perdiemResults)
            PerDiemList.internalBinaryWrite(message.perdiemResults, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* TripList trip_results = 4; */
        if (message.tripResults)
            TripList.internalBinaryWrite(message.tripResults, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated Estimate estimates = 5; */
        for (let i = 0; i < message.estimates.length; i++)
            Estimate.internalBinaryWrite(message.estimates[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated Expended expends = 6; */
        for (let i = 0; i < message.expends.length; i++)
            Expended.internalBinaryWrite(message.expends[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated ChangeOrder changeOrders = 7; */
        for (let i = 0; i < message.changeOrders.length; i++)
            ChangeOrder.internalBinaryWrite(message.changeOrders[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FetchProjectCostAndEstimateResponse
 */
export const FetchProjectCostAndEstimateResponse = new FetchProjectCostAndEstimateResponse$Type();
/**
 * @generated ServiceType for protobuf service EventService
 */
export const EventService = new ServiceType("EventService", [
    { name: "Create", options: {}, I: Event, O: Event },
    { name: "Get", options: {}, I: Event, O: Event },
    { name: "BatchGet", options: {}, I: Event, O: EventList },
    { name: "Update", options: {}, I: Event, O: Event },
    { name: "Delete", options: {}, I: Event, O: Event },
    { name: "GetCalendarData", options: {}, I: Event, O: CalendarData },
    { name: "WriteQuote", options: {}, I: Quotable, O: Quotable },
    { name: "ReadQuotes", options: {}, I: QuotableRead, O: QuotableList },
    { name: "GetProjectTasks", options: {}, I: ProjectTask, O: ProjectTaskList },
    { name: "GetLaborHoursByEventID", options: {}, I: Int32, O: Double },
    { name: "BatchGetCostReportData", options: {}, I: CostReportReq, O: CostReportData },
    { name: "CreateJobAdmin", options: {}, I: JobAdmin, O: JobAdmin },
    { name: "GetJobAdmin", options: {}, I: JobAdmin, O: JobAdmin },
    { name: "BatchGetJobAdmin", options: {}, I: JobAdmin, O: JobAdminList },
    { name: "UpdateJobAdmin", options: {}, I: JobAdmin, O: JobAdmin },
    { name: "DeleteJobAdmin", options: {}, I: JobAdmin, O: JobAdmin },
    { name: "GetClosestProperty", options: {}, I: ClosestPropertyRequest, O: ClosestPropertyResult },
    { name: "GeolocationCheckout", options: {}, I: Int32, O: Bool },
    { name: "CreateEventAssignment", options: {}, I: EventAssignment, O: EventAssignment },
    { name: "GetEventAssignment", options: {}, I: EventAssignment, O: EventAssignment },
    { name: "BatchGetEventAssignment", options: {}, I: EventAssignment, O: EventAssignmentList },
    { name: "UpdateEventAssignment", options: {}, I: EventAssignment, O: EventAssignment },
    { name: "DeleteEventAssignment", options: {}, I: EventAssignment, O: EventAssignment },
    { name: "CreateQuickBooksTrackingCode", options: {}, I: QuickBooksTrackingCode, O: QuickBooksTrackingCode },
    { name: "GetQuickBooksTrackingCode", options: {}, I: QuickBooksTrackingCode, O: QuickBooksTrackingCode },
    { name: "BatchGetQuickBooksTrackingCode", options: {}, I: QuickBooksTrackingCode, O: QuickBooksTrackingCodeList },
    { name: "UpdateQuickBooksTrackingCode", options: {}, I: QuickBooksTrackingCode, O: QuickBooksTrackingCode },
    { name: "DeleteQuickBooksTrackingCode", options: {}, I: QuickBooksTrackingCode, O: QuickBooksTrackingCode },
    { name: "GetPendingBilling", options: {}, I: PendingBilling, O: PendingBilling },
    { name: "BatchGetPendingBilling", options: {}, I: PendingBilling, O: PendingBillingList },
    { name: "CreateContract", options: {}, I: Contract, O: Contract },
    { name: "GetContract", options: {}, I: Contract, O: Contract },
    { name: "BatchGetContract", options: {}, I: Contract, O: ContractList },
    { name: "UpdateContract", options: {}, I: Contract, O: Contract },
    { name: "DeleteContract", options: {}, I: Contract, O: Contract },
    { name: "GetMaterialsByContractID", options: {}, I: Contract, O: ContractMaterialList },
    { name: "CreateContractFrequency", options: {}, I: ContractFrequency, O: ContractFrequency },
    { name: "GetContractFrequency", options: {}, I: ContractFrequency, O: ContractFrequency },
    { name: "BatchGetContractFrequency", options: {}, I: ContractFrequency, O: ContractFrequencyList },
    { name: "UpdateContractFrequency", options: {}, I: ContractFrequency, O: ContractFrequency },
    { name: "DeleteContractFrequency", options: {}, I: ContractFrequency, O: ContractFrequency },
    { name: "CreateInvoice", options: {}, I: Invoice, O: Invoice },
    { name: "GetInvoice", options: {}, I: Invoice, O: Invoice },
    { name: "BatchGetInvoice", options: {}, I: Invoice, O: InvoiceList },
    { name: "UpdateInvoice", options: {}, I: Invoice, O: Invoice },
    { name: "DeleteInvoice", options: {}, I: Invoice, O: Invoice },
    { name: "SendSQSInvoiceEmail", options: {}, I: SQSEmailAndDocument, O: Bool },
    { name: "GetInvoiceBody", options: {}, I: InvoiceBodyRequest, O: InvoiceBodyReturn },
    { name: "CreateSiLink", options: {}, I: SiLink, O: SiLink },
    { name: "GetSiLink", options: {}, I: SiLink, O: SiLink },
    { name: "BatchGetSiLink", options: {}, I: SiLink, O: SiLinkList },
    { name: "UpdateSiLink", options: {}, I: SiLink, O: SiLink },
    { name: "DeleteSiLink", options: {}, I: SiLink, O: SiLink },
    { name: "CreateMaintenanceQuestion", options: {}, I: MaintenanceQuestion, O: MaintenanceQuestion },
    { name: "GetMaintenanceQuestion", options: {}, I: MaintenanceQuestion, O: MaintenanceQuestion },
    { name: "BatchGetMaintenanceQuestion", options: {}, I: MaintenanceQuestion, O: MaintenanceQuestionList },
    { name: "UpdateMaintenanceQuestion", options: {}, I: MaintenanceQuestion, O: MaintenanceQuestion },
    { name: "DeleteMaintenanceQuestion", options: {}, I: MaintenanceQuestion, O: MaintenanceQuestion },
    { name: "CreatePayment", options: {}, I: Payment, O: Payment },
    { name: "GetPayment", options: {}, I: Payment, O: Payment },
    { name: "BatchGetPayment", options: {}, I: Payment, O: PaymentList },
    { name: "UpdatePayment", options: {}, I: Payment, O: Payment },
    { name: "DeletePayment", options: {}, I: Payment, O: Payment },
    { name: "GetPaymentsByEventID", options: {}, I: Int32, O: PaymentList },
    { name: "CreateServicesRendered", options: {}, I: ServicesRendered, O: ServicesRendered },
    { name: "GetServicesRendered", options: {}, I: ServicesRendered, O: ServicesRendered },
    { name: "BatchGetServicesRendered", options: {}, I: ServicesRendered, O: ServicesRenderedList },
    { name: "UpdateServicesRendered", options: {}, I: ServicesRendered, O: ServicesRendered },
    { name: "DeleteServicesRendered", options: {}, I: ServicesRendered, O: ServicesRendered },
    { name: "GetEventDetails", options: {}, I: JobNumberRequest, O: EventDetails },
    { name: "GeolocationCheckoutReturnTimesheets", options: {}, I: GeolocationCheckoutRequest, O: GeolocationTimesheetData },
    { name: "GenerateStripePaymentLink", options: {}, I: GenerateStripePaymentLinkRequest, O: GenerateStripePaymentLinkResponse },
    { name: "BatchGetPurchaseOrder", options: {}, I: PurchaseOrderBatchGetRequest, O: PurchaseOrderList },
    { name: "GetPurchaseOrder", options: {}, I: IDInt32, O: PurchaseOrder },
    { name: "CreatePurchaseOrder", options: {}, I: PurchaseOrder, O: IDInt32 },
    { name: "UpdatePurchaseOrder", options: {}, I: PurchaseOrderUpdateRequest, O: Empty },
    { name: "DeletePurchaseOrder", options: {}, I: IDInt32, O: Empty },
    { name: "BatchGetPurchaseOrderRequisitionItem", options: {}, I: PurchaseOrderRequisitionItemBatchGetRequest, O: PurchaseOrderRequisitionItemList },
    { name: "GetPurchaseOrderRequisitionItem", options: {}, I: IDInt32, O: PurchaseOrderRequisitionItem },
    { name: "CreatePurchaseOrderRequisitionItem", options: {}, I: PurchaseOrderRequisitionItem, O: IDInt32 },
    { name: "UpdatePurchaseOrderRequisitionItem", options: {}, I: PurchaseOrderRequisitionItemUpdateRequest, O: Empty },
    { name: "DeletePurchaseOrderRequisitionItem", options: {}, I: IDInt32, O: Empty },
    { name: "FetchUpcomingSubAccountEvents", options: {}, I: SubAccountEventRequest, O: EventList },
    { name: "FetchSubAccountEventHistory", options: {}, I: SubAccountEventRequest, O: EventList },
    { name: "MostLikelyJob", options: {}, I: MostLikelyJobRequest, O: EventList },
    { name: "GetProjectEvent", options: {}, I: ProjectEvent, O: ProjectEvent },
    { name: "BatchGetProjectEvent", options: {}, I: ProjectEvent, O: ProjectEventList },
    { name: "CreateProjectEvent", options: {}, I: ProjectEvent, O: ProjectEvent },
    { name: "UpdateProjectEvent", options: {}, I: ProjectEvent, O: ProjectEvent },
    { name: "DeleteProjectEvent", options: {}, I: ProjectEvent, O: ProjectEvent },
    { name: "GetInvoicePayment", options: {}, I: InvoicePayment, O: InvoicePayment },
    { name: "BatchGetInvoicePayment", options: {}, I: InvoicePayment, O: InvoicePaymentList },
    { name: "CreateInvoicePayment", options: {}, I: InvoicePayment, O: InvoicePayment },
    { name: "UpdateInvoicePayment", options: {}, I: InvoicePayment, O: InvoicePayment },
    { name: "DeleteInvoicePayment", options: {}, I: InvoicePayment, O: InvoicePayment },
    { name: "GetInvoicePaymentLine", options: {}, I: InvoicePaymentLine, O: InvoicePaymentLine },
    { name: "BatchGetInvoicePaymentLine", options: {}, I: InvoicePaymentLine, O: InvoicePaymentLineList },
    { name: "CreateInvoicePaymentLine", options: {}, I: InvoicePaymentLine, O: InvoicePaymentLine },
    { name: "UpdateInvoicePaymentLine", options: {}, I: InvoicePaymentLine, O: InvoicePaymentLine },
    { name: "DeleteInvoicePaymentLine", options: {}, I: InvoicePaymentLine, O: InvoicePaymentLine },
    { name: "GetCallCountForTimeRange", options: {}, I: CallCountRequest, O: CallCountResponse },
    { name: "SendInvoiceDocumentLinkWithStripe", options: {}, I: PropertyDocumentRequest, O: Bool },
    { name: "SearchUserByPhone", options: {}, I: SearchUserByPhoneRequest, O: SearchUserByPhoneResponse },
    { name: "GetPaymentType", options: {}, I: PaymentType, O: PaymentType },
    { name: "BatchGetPaymentType", options: {}, I: PaymentType, O: PaymentTypeList },
    { name: "GetServicesRenderedPaymentType", options: {}, I: ServicesRenderedPaymentType, O: ServicesRenderedPaymentType },
    { name: "BatchGetServicesRenderedPaymentType", options: {}, I: ServicesRenderedPaymentType, O: ServicesRenderedPaymentTypeList },
    { name: "FetchProjectCostAndEstimate", options: {}, I: FetchProjectCostAndEstimateRequest, O: FetchProjectCostAndEstimateResponse }
]);
