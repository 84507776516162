import { createFileRoute } from '@tanstack/react-router';

import { SoftPhoneNewJobRoute } from '../../modules/Softphone/SoftPhoneNewJob/SoftPhoneNewJob';

export const Route = createFileRoute('/softphone/new-job')({
  component: RouteComponent,
});

function RouteComponent() {
  return <SoftPhoneNewJobRoute />;
}
