import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const toolFundSettingsSchema = z
  .object({
    id: z.number(),
    userId: z.number(),
    isEligible: z.boolean(),
    monthlyAllowance: z.number(),
    lastUpdated: z.string(),
    updatedBy: z.number(),
    tradeTypeId: z.number().refine((val) => val > 0, { message: 'Trade is required' }),
  })
  .superRefine((data, ctx) => {
    if (data.isEligible) {
      if (!data.tradeTypeId || data.tradeTypeId <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Trade is required when eligible for tool fund',
          path: ['tradeTypeId'],
        });
      }
    }
  });

export type ToolFundSettingsFormType = z.infer<typeof toolFundSettingsSchema>;

export const useToolFundSettingsForm = ({
  defaultValues,
  disabled = false,
}: {
  defaultValues?: Partial<ToolFundSettingsFormType>;
  disabled?: boolean;
}) => {
  return useForm<ToolFundSettingsFormType>({
    resolver: zodResolver(toolFundSettingsSchema),
    mode: 'onSubmit',
    defaultValues,
    disabled,
  });
};
