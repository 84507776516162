import { Event } from '@kalos/kalos-rpc';
import {
  Badge,
  Button,
  buttonVariants,
  cn,
  Dialog,
  DialogContent,
  Input,
  LoadingIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import {
  CheckCircledIcon,
  CrossCircledIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';
import { PopoverAnchor } from '@radix-ui/react-popover';
import { type ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  type MostLikelyJobRequestFilter,
  useEventQueryInline,
} from '../../hooks/react-query/useEventsQuery';
import { useEvent } from '../../hooks/useEvent';
import { AdvancedSearch } from '../../modules/ComponentsLibrary/AdvancedSearch';
import { type Kind } from '../../modules/ComponentsLibrary/AdvancedSearch/constants';
import { JobSelectorHintCombobox } from './JobSelectorHintCombobox';
const kindsSearch = ['serviceCalls'] satisfies Kind[];
export type JobCheckStatus = 'non-existing' | 'existing' | 'need-to-check' | 'loading' | 'none';
const staticEmptyFn = () => {};
/**
 * Component that allows to search for a job by its id.
 */
export const JobSelector = ({
  onChange,
  value: externalValue,
  getStatusTestId,
  inputTestId,
  checkBtnTestId,
  omitArchivedJobs = false,
  disabled,
  advancedSearchContainerTestId,
  onStatusChange = staticEmptyFn,
  showRecentServiceCallsForEmployee = false,
  inputClassName,
  onFocus,
  onBlur,
  hint,
  id,
}: {
  onChange: (value: number) => void;
  value: number | undefined;
  getStatusTestId?: (status: JobCheckStatus) => string;
  inputTestId?: string;
  onStatusChange?: (status: JobCheckStatus) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  checkBtnTestId?: string;
  omitArchivedJobs?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  advancedSearchContainerTestId?: string;
  id?: string;
  hint?: MostLikelyJobRequestFilter;
} & Pick<ComponentProps<typeof AdvancedSearch>, 'showRecentServiceCallsForEmployee'>) => {
  const [internalValue, setInternalValue] = useState<number | undefined>(externalValue);
  const [checkStatus, _setCheckStatus] = useState<JobCheckStatus>(
    internalValue ? 'loading' : 'none',
  );
  const { fetchEvent: fetchJob } = useEventQueryInline();
  const onChangeEvent = useEvent(onChange);
  const [isJobSearchOpen, setIsJobSearchOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [blockExternalSync, setBlockExternalSync] = useState(false);
  const onStatusChangeEvent = useEvent(onStatusChange);

  const [isApplyPopoverExistingValueOpen, setIsApplyPopoverExistingValueOpen] = useState(false);

  const filterCriteria = useMemo(
    () =>
      Event.create({
        isActive: 1,
        notEquals: omitArchivedJobs ? ['PropertyId', 'LogJobStatus'] : ['PropertyId'],
        logJobStatus: omitArchivedJobs ? 'Archived' : undefined,
      }),
    [omitArchivedJobs],
  );

  const setCheckStatus = useCallback(
    (status: JobCheckStatus) => {
      _setCheckStatus(status);
      onStatusChangeEvent(status);
    },
    [onStatusChangeEvent],
  );

  const handleCheck = useCallback(
    async (JobIdToCheck: number, { __callOnChange = true }: { __callOnChange?: boolean } = {}) => {
      setCheckStatus('loading');
      try {
        const res = await fetchJob({ filter: { ...filterCriteria, id: JobIdToCheck } });

        if (!res) {
          setCheckStatus('non-existing');
        } else {
          setCheckStatus('existing');
          if (__callOnChange) {
            onChangeEvent(JobIdToCheck);
          }
          setBlockExternalSync(false);
        }
      } catch (e) {
        console.log('[Job Selector]: Failed to check Job existence', e);
        setCheckStatus('non-existing');
      }
    },
    [fetchJob, onChangeEvent, setCheckStatus, filterCriteria],
  );

  useEffect(() => {
    // to sync outside value with internal state
    if (
      externalValue != undefined &&
      externalValue !== internalValue &&
      !blockExternalSync &&
      checkStatus !== 'non-existing'
    ) {
      setInternalValue(externalValue);
      if (externalValue === 0) setCheckStatus('none');
    }
  }, [externalValue, internalValue, handleCheck, blockExternalSync, checkStatus, setCheckStatus]);

  useEffect(() => {
    if (externalValue != undefined && externalValue != 0) {
      handleCheck(externalValue, { __callOnChange: false });
    }
    // run only once
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      'inputType' in e.nativeEvent &&
      typeof e.nativeEvent.inputType === 'string' &&
      e.nativeEvent.inputType === 'insertFromPaste'
    )
      return;

    const value = e.target.value;
    setBlockExternalSync(true);

    if (value === '' || Number(value) === 0) {
      setInternalValue(0);
      setCheckStatus('none');
      onChangeEvent(0);
      setBlockExternalSync(false);
    } else {
      const numberValue = Number(value);
      if (!isNaN(numberValue)) {
        setInternalValue(numberValue);
        setCheckStatus('need-to-check');
      }
    }
  };

  const onSearchBtnClick = () => {
    switch (checkStatus) {
      case 'none':
      case 'existing':
      case 'non-existing': {
        setIsJobSearchOpen(true);
        break;
      }
      case 'loading': {
        break;
      }
      case 'need-to-check': {
        if (internalValue) {
          handleCheck(internalValue);
        }
        break;
      }
    }
    inputRef.current?.focus();
  };

  const onModalClose = useCallback(() => setIsJobSearchOpen(false), []);

  const onEventSelect = useCallback(
    (e: Event) => {
      setInternalValue(e.id);
      setCheckStatus('existing');
      onChangeEvent(e.id);
      setIsJobSearchOpen(false);
    },
    [onChangeEvent, setCheckStatus],
  );

  const [isHintOpen, setIsHintOpen] = useState(false);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setBlockExternalSync(true);
    if (hint) {
      setIsHintOpen(true);
    }
    onFocus?.();
    e.preventDefault();
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLButtonElement> = async (e) => {
    if (
      e.relatedTarget?.id === 'check-job-btn' ||
      e.relatedTarget?.id === 'failed-check-state--apply-popover-trigger' ||
      // this is when user selects a job from the hint combobox. We should not check previous value in this case
      e.relatedTarget?.attributes.getNamedItem('cmdk-root')?.localName === 'cmdk-root'
    ) {
      return;
    }
    if (e.relatedTarget?.getAttribute('data-focus-marker') === 'job-selector-hint-input') return;
    setIsHintOpen(false);
    onBlur?.();
    if (internalValue && internalValue !== externalValue) {
      setBlockExternalSync(true);
      await handleCheck(internalValue);
    }
    setBlockExternalSync(false);
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      onSearchBtnClick();
      setIsHintOpen(false);
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.code === 'Escape') {
      setIsHintOpen(false);
    }
  };

  const onClick = () => {
    setIsHintOpen(true);
  };

  const onHintSelect = useCallback(
    (selected: Event) => {
      onEventSelect(selected);
      setIsHintOpen(false);
    },
    [onEventSelect],
  );

  const onHintBlur = useCallback<React.FocusEventHandler<HTMLDivElement>>((e) => {
    if (e.relatedTarget?.getAttribute('data-focus-marker') === 'job-selector-input') return;
    setIsHintOpen(false);
  }, []);

  const onPaste = async (e: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData;
    const pastedText = clipboardData.getData('text');
    const pastedNumber = Number(pastedText);
    if (!isNaN(pastedNumber) && pastedNumber !== 0 && pastedNumber !== internalValue) {
      setInternalValue(pastedNumber);
      setIsHintOpen(false);
      await handleCheck(pastedNumber);
    }
  };

  useEffect(() => {
    // when disabled is updated while hint is open - not hiding the hint might cause a focus issue (outside clicks do not close the hint)
    if (disabled) {
      setIsHintOpen(false);
    }
  }, [disabled]);

  const onFailedCheckApplyClick = (value: number) => {
    setInternalValue(value);
    if (value === 0) {
      setCheckStatus('none');
    } else {
      handleCheck(value, { __callOnChange: false });
    }
    setIsApplyPopoverExistingValueOpen(false);
  };

  const shouldProposeApplyBtn =
    checkStatus === 'non-existing' &&
    typeof externalValue === 'number' &&
    internalValue !== externalValue;

  return (
    <div className="relative h-9">
      <Popover
        // modal={true} prop is required because when used within Dialog component - there is an issue with scroll
        modal
        open={isHintOpen}
      >
        <PopoverAnchor asChild>
          <Input
            value={internalValue?.toString() || ''}
            ref={inputRef}
            min="0"
            onPaste={onPaste}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onInputChange}
            onKeyDown={onInputKeyDown}
            onClick={onClick}
            type="number"
            className={cn('pr-[4.5rem]', inputClassName)}
            disabled={disabled || checkStatus === 'loading'}
            inputMode="numeric"
            data-testid={inputTestId}
            id={id}
            data-focus-marker="job-selector-input"
          />
        </PopoverAnchor>
        {!!hint && (
          <PopoverContent
            className="z-[9999] p-0"
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
            onCloseAutoFocus={(e) => {
              e.preventDefault();
            }}
          >
            <JobSelectorHintCombobox onBlur={onHintBlur} hint={hint} onSelect={onHintSelect} />
          </PopoverContent>
        )}
      </Popover>
      <div className="absolute inset-y-0 right-0 flex items-center justify-center gap-1">
        {shouldProposeApplyBtn && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Badge className="text-[9px] leading-3" variant="success">
                  {externalValue}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>Current field value that is used</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        <Popover
          open={isApplyPopoverExistingValueOpen}
          onOpenChange={setIsApplyPopoverExistingValueOpen}
        >
          <PopoverTrigger asChild>
            <button
              id="failed-check-state--apply-popover-trigger"
              data-testid={getStatusTestId?.(checkStatus)}
              onClick={(e) => e.stopPropagation()}
              disabled={!shouldProposeApplyBtn}
              className={cn(
                shouldProposeApplyBtn
                  ? buttonVariants({ variant: 'outline', className: 'px-2' })
                  : 'scale-150 p-1',
              )}
            >
              <CheckStatusIndicator status={checkStatus} />
            </button>
          </PopoverTrigger>
          {typeof externalValue === 'number' && (
            <PopoverContent className="flex items-center justify-between gap-2 p-2 text-sm">
              <p className="min-w-max">Current applied value: {externalValue}</p>
              <Button
                data-testid="job-selector-apply-existing-value-btn"
                onClick={() => onFailedCheckApplyClick(externalValue)}
                size="sm"
              >
                Apply
              </Button>
            </PopoverContent>
          )}
        </Popover>
        <Button
          variant="outline"
          tabIndex={0}
          type="button"
          className="px-2"
          onBlur={handleBlur}
          disabled={checkStatus === 'loading' || disabled}
          onClick={onSearchBtnClick}
          data-testid={checkBtnTestId}
          id="check-job-btn"
        >
          <MagnifyingGlassIcon />
          <span className="sr-only">Check job</span>
        </Button>
      </div>

      <Dialog
        open={isJobSearchOpen}
        onOpenChange={(isOpen) => {
          setIsJobSearchOpen(isOpen);
          setBlockExternalSync(isOpen);
        }}
      >
        <DialogContent
          showClose={false}
          data-testid={advancedSearchContainerTestId}
          className="h-svh w-screen max-w-none overflow-auto p-0"
        >
          <AdvancedSearch
            title="Job Search"
            showRecentServiceCallsForEmployee={showRecentServiceCallsForEmployee}
            kinds={kindsSearch}
            omitArchivedJobs={omitArchivedJobs}
            onSelectEvent={onEventSelect}
            onClose={onModalClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const CheckStatusIndicator = ({ status }: { status: JobCheckStatus }) => {
  switch (status) {
    case 'none':
      return null;
    case 'existing':
      return <CheckCircledIcon className="text-green-500" />;
    case 'non-existing':
      return <CrossCircledIcon className="text-primary" />;
    case 'loading':
      return <LoadingIcon className="size-[15px] text-blue-500" />;
    case 'need-to-check':
      return <QuestionMarkCircledIcon className="text-gray-500" />;
  }
};
