import { createFileRoute } from '@tanstack/react-router';

import { CallWindow } from '../modules/ComponentsLibrary/CallWindow';

export const Route = createFileRoute('/callWindow')({
  component: CallWindow,
  head(ctx) {
    return {
      meta: [{ title: 'Call Window' }],
    };
  },
});
