import { Button, cn, Dialog, DialogContent, DialogTrigger } from '@kalos/ui';
import { FilterIcon } from 'lucide-react';
import React, { type RefObject } from 'react';
import ReactDOM from 'react-dom';

export const FilterDialog = ({
  children,
  triggerPortalRef,
}: {
  children: React.ReactNode;
  triggerPortalRef?: RefObject<HTMLDivElement | null>;
}) => {
  const trigger = (
    <DialogTrigger asChild>
      <Button size="sm" className="relative flex items-center justify-center gap-1 rounded-full">
        <span>Filter</span>
        <FilterIcon size={15} />
      </Button>
    </DialogTrigger>
  );

  return (
    <div
      className={cn({
        'fixed bottom-4 right-4 z-50': !!triggerPortalRef && !!triggerPortalRef?.current,
      })}
    >
      <Dialog>
        {!!triggerPortalRef && !!triggerPortalRef?.current
          ? ReactDOM.createPortal(trigger, triggerPortalRef.current)
          : trigger}
        <DialogContent className="top-1/3 ">{children}</DialogContent>
      </Dialog>
    </div>
  );
};
