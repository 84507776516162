import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { VehicleDashboard } from '../modules/VehicleDashboard';

const vehicles = 'vehicles';
const trailers = 'trailers';

export const Route = createFileRoute('/vehicles')({
  component: RouteComponent,
  validateSearch: z.object({
    vehicleDashboardTab: z.enum([vehicles, trailers]).default(vehicles),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'Vehicles' }],
    };
  },
});

function RouteComponent() {
  return <VehicleDashboard />;
}
