import { type FetchProjectCostAndEstimateRequest, ProjectEvent } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ProjectEventClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ProjectEventFilter = EntityFilter<ProjectEvent>;

export const useProjectEventsBatchGetQuery = ({ filter = {} }: { filter?: ProjectEventFilter }) =>
  useQuery({
    queryKey: [
      queryKeys.projectsEvents.root,
      queryKeys.projectsEvents.list,
      queryKeys.projectsEvents.getHash(filter),
    ],
    async queryFn() {
      return await ProjectEventClientService.BatchGet(ProjectEvent.create(filter));
    },
  });

export const getProjectEventQueryConfig = (filter: ProjectEventFilter) => ({
  queryKey: [queryKeys.projectsEvents.root, queryKeys.projectsEvents.getHash(filter)],
  async queryFn() {
    return await ProjectEventClientService.Get(ProjectEvent.create(filter));
  },
});

export const useProjectEventGetQuery = ({ filter = {} }: { filter?: ProjectEventFilter }) =>
  useQuery({ ...getProjectEventQueryConfig(filter) });

export const useCreateProjectEventMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (project: ProjectEvent) => await ProjectEventClientService.Create(project),
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.projectsEvents.root] });
    },
  });
};

export const useProjectEventUpdateMutation = ({
  onSuccess: propOnSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (project: ProjectEvent) => await ProjectEventClientService.Update(project),
    async onSuccess(data, variables, context) {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.projectsEvents.root],
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.events.root, queryKeys.events.list],
        }),
      ]);
      await propOnSuccess?.();
    },
  });
};

export const useProjectFinancialsQuery = ({ projectEventId }: { projectEventId: number }) =>
  useQuery({
    queryKey: [
      queryKeys.projectsEvents.root,
      ...queryKeys.projectsEvents.financials(projectEventId),
    ],
    async queryFn() {
      if (!projectEventId) {
        return null;
      }
      const req = { projectEventId } as FetchProjectCostAndEstimateRequest;
      return await ProjectEventClientService.FetchProjectFinancials(req);
    },
    enabled: true,
  });
