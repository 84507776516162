import { createFileRoute } from '@tanstack/react-router';

import { DriverVehicleDashboard } from '../modules/ComponentsLibrary/DriverDashboard';

export const Route = createFileRoute('/driverDashboard')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Driver Dashboard' }],
    };
  },
});

function RouteComponent() {
  return <DriverVehicleDashboard />;
}
