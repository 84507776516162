import {
  type MaintenanceQuestion,
  MaintenanceQuestionList,
  Reading,
  ReadingImageList,
  ReadingList,
} from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { MaintenanceQuestionClientService, ReadingClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ReadingFilter = EntityFilter<Reading>;
export type MaintenanceFilter = EntityFilter<MaintenanceQuestion>;

export const useReadingBatchGetQuery = ({ filter }: { filter: ReadingFilter }) =>
  useQuery({
    queryFn: () => {
      const request = Reading.create(filter);
      return ReadingClientService.BatchGet(request);
    },
    queryKey: [
      queryKeys.readings.root,
      queryKeys.readings.list,
      queryKeys.readings.getHash(filter),
    ],
  });

export const useReadingDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: Reading) => ReadingClientService.Delete(req),
    onSuccess: (data) => {
      queryClient.setQueriesData<ReadingList>(
        { queryKey: [queryKeys.readings.root, queryKeys.readings.list] },
        (cache) => {
          if (cache && data && cache.results.find((el) => el.id === data?.id)) {
            return ReadingList.create({
              totalCount: cache.totalCount - 1,
              results: cache.results.filter((el) => el.id !== data.id),
            });
          }
        },
      );
    },
  });
};

export const useReadingCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: Reading) => ReadingClientService.Create(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.readings.root],
      });
    },
  });
};

export const useReadingUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: Reading) => ReadingClientService.Update(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.readings.root],
      });
    },
  });
};

export const useMaintenanceQuestionDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: MaintenanceQuestion) => MaintenanceQuestionClientService.Delete(req),
    onSuccess: (data) => {
      queryClient.setQueriesData<MaintenanceQuestionList>(
        { queryKey: [queryKeys.maintenanceQuestion.root, queryKeys.maintenanceQuestion.list] },
        (cache) => {
          if (cache && data && cache.results.find((el) => el.id === data?.id)) {
            return MaintenanceQuestionList.create({
              totalCount: cache.totalCount - 1,
              results: cache.results.filter((el) => el.id !== data.id),
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.maintenanceQuestion.root],
      });
    },
  });
};

export const useMaintenanceQuestionCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: MaintenanceQuestion) => MaintenanceQuestionClientService.Create(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.maintenanceQuestion.root],
      });
    },
  });
};

export const useMaintenanceQuestionUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: MaintenanceQuestion) => MaintenanceQuestionClientService.Update(req),
    onSuccess: (data) => {
      queryClient.setQueriesData<MaintenanceQuestionList>(
        { queryKey: [queryKeys.maintenanceQuestion.root, queryKeys.maintenanceQuestion.list] },
        (cache) => {
          if (cache && data) {
            const changedIdx = cache.results.findIndex((item) => item.id === data.id);
            cache.results[changedIdx] = data;
            return MaintenanceQuestionList.create({
              totalCount: cache.totalCount + 1,
              results: cache.results,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.maintenanceQuestion.root],
      });
    },
  });
};

export const useReadingImageCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (arg: Parameters<typeof ReadingClientService.CreateReadingImage>[0]) =>
      ReadingClientService.CreateReadingImage(arg),
    onSuccess: (data) => {
      queryClient.setQueriesData<ReadingList>(
        { queryKey: [queryKeys.readings.root, queryKeys.readings.list] },
        (cache) => {
          if (cache) {
            const modifiedReading = cache.results.find((el) => el.id === data.readingId);
            if (!modifiedReading) return cache;
            if (!modifiedReading.readingImages) return cache;

            return ReadingList.create({
              totalCount: cache.totalCount + 1,
              results: cache.results.map((el) =>
                el.id === data.readingId
                  ? Reading.create({
                      ...modifiedReading,
                      readingImages: ReadingImageList.create({
                        results: [...(modifiedReading.readingImages?.results ?? []), data],
                        totalCount: el.readingImages?.totalCount ?? 0 + 1,
                      }),
                    })
                  : el,
              ),
            });
          }
        },
      );
    },
  });
};

export const useReadingImageDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (arg: Parameters<typeof ReadingClientService.DeleteReadingImage>[0]) =>
      ReadingClientService.DeleteReadingImage(arg),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.readings.root],
      });
    },
  });
};
