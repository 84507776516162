import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { Link } from '@tanstack/react-router';
import { Mic, MicOff } from 'lucide-react';
import React, { useCallback } from 'react';
import { useStore } from 'zustand';

import { softPhoneNewJobRoute } from '../../../SideMenu/constants';
import { messageBus } from '../../context/SoftphoneMessageBus';
import { useProtectedSoftPhoneStore } from '../../context/SoftPhoneStore.context';
import { useCallStore } from './CallStore';
import { useToggleMuteMutation } from './useToggleMuteMutation';
import { softphoneLog } from './utils';

export const Call = React.memo(() => {
  const store = useProtectedSoftPhoneStore();
  const session = useStore(store, (store) => store.session);

  const currentCall = useCallStore.use.currentCall();
  const isCallActive = useCallStore((store) => store.computed.isCallActive);
  const peerConnection = useCallStore.use.peerConnection();
  const handleEndCall = useCallStore.use.handleCallEnd();
  const isMuted = useCallStore((store) => store.computed.isMuted);
  const caller = useCallStore.use.currentCaller();

  const toggleMuteMutation = useToggleMuteMutation();

  const [selectedExtension, setSelectedExtension] = React.useState<string>('');

  const onMute = useCallback(async () => {
    if (!currentCall) throw new Error('No current call');
    if (!session) throw new Error('No session');
    try {
      await toggleMuteMutation.mutateAsync({
        callId: currentCall.id,
        sessionId: session.sessionId,
        sdp: '',
      });
    } catch (error) {
      console.error('onMute', error);
      toast({
        variant: 'destructive',
        title: 'Failed to mute',
        description: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  }, [currentCall, session, toggleMuteMutation]);

  const onUnmute = useCallback(async () => {
    if (!currentCall) throw new Error('No current call');
    if (!session) throw new Error('No session');
    try {
      await toggleMuteMutation.mutateAsync({
        callId: currentCall.id,
        sessionId: session.sessionId,
        sdp: '',
      });
    } catch (error) {
      console.error('onUnmute', error);
      toast({
        variant: 'destructive',
        title: 'Failed to unmute',
        description: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  }, [currentCall, session, toggleMuteMutation]);

  const handleTransfer = useCallback(async () => {
    if (!currentCall || !selectedExtension) return;

    try {
      await store.getState().client.TransferCall(currentCall.id, selectedExtension);

      // Broadcast transfer event before showing toast
      messageBus.broadcast({
        type: 'CALL_TRANSFERRED',
      });

      // Toast will show in the handler for CALL_TRANSFERRED
    } catch (error) {
      softphoneLog('Transfer failed:', error);
      toast({
        title: 'Transfer Failed',
        description: error instanceof Error ? error.message : 'Failed to transfer call',
        variant: 'destructive',
      });
    }
  }, [currentCall, selectedExtension, store]);

  const onEndCall = useCallback(async () => {
    if (!currentCall || !session) return;

    try {
      await handleEndCall(currentCall.id, session.sessionId, store.getState().client);
      messageBus.broadcast({
        type: 'CALL_ENDED',
      });
    } catch (error) {
      softphoneLog('Error ending call:', error);
      toast({
        title: 'Error Ending Call',
        description: error instanceof Error ? error.message : 'Failed to end call',
        variant: 'destructive',
      });
    }
  }, [currentCall, session, handleEndCall, store]);

  if (!session || !currentCall || !isCallActive) return null;

  return (
    <div className="flex flex-col gap-4">
      <Card>
        <CardHeader>
          <CardTitle>On Call</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Phone Number: {currentCall.number}</p>
          <p>Status: {currentCall.status}</p>
          <p>Name: {currentCall.name}</p>
        </CardContent>
        <CardFooter className="flex items-center gap-2">
          {peerConnection && (
            <>
              <Button size="sm" onClick={onEndCall}>
                End Call
              </Button>

              <div className="flex items-center gap-2">
                <Select value={selectedExtension} onValueChange={setSelectedExtension}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Transfer to..." />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={'1035'}>1035 - Robert Milejczak</SelectItem>
                    <SelectItem value={'1063'}>Back to Queue</SelectItem>
                  </SelectContent>
                </Select>

                <Button
                  size="sm"
                  variant="secondary"
                  onClick={handleTransfer}
                  disabled={!selectedExtension}
                >
                  Transfer
                </Button>
              </div>
            </>
          )}
          <TooltipProvider>
            {!isMuted && (
              <Tooltip>
                <TooltipContent>Turn off microphone</TooltipContent>
                <TooltipTrigger asChild>
                  <Button
                    size="icon"
                    disabled={toggleMuteMutation.isPending}
                    variant="secondary"
                    onClick={onMute}
                  >
                    <Mic size={15} />
                  </Button>
                </TooltipTrigger>
              </Tooltip>
            )}
            {isMuted && (
              <Tooltip>
                <TooltipContent>Turn on microphone</TooltipContent>
                <TooltipTrigger asChild>
                  <Button
                    size="icon"
                    disabled={toggleMuteMutation.isPending}
                    variant="destructive"
                    onClick={onUnmute}
                  >
                    <MicOff size={15} />
                  </Button>
                </TooltipTrigger>
              </Tooltip>
            )}
          </TooltipProvider>
        </CardFooter>
      </Card>

      {caller && (
        <Card variant="light">
          <CardHeader>
            <CardTitle>
              Caller: {caller.firstname} {caller.lastname}
            </CardTitle>
          </CardHeader>
          <CardFooter>
            <Button
              asChild
              className="flex !h-auto max-w-max items-center gap-2 whitespace-normal break-words"
            >
              {/* TODO fix */}
              <Link to={softPhoneNewJobRoute}>Add New Job</Link>
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  );
});
