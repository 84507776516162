'use no memo';
import './ServiceItems.module.less';

import {
  ActivityLog,
  Event,
  MaintenanceQuestion,
  Material,
  MaterialClient,
  type Property,
  Reading,
  ReadingClient,
  ServiceItem,
} from '@kalos/kalos-rpc';
import {
  Button,
  Checkbox,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Dialog,
  DialogContent,
  ScrollArea,
  Separator,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { default as IconButton } from '@mui/material/IconButton';
import { ImageIcon, MoveIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { format } from 'date-fns';
import { type FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { getLoadingColumns } from '../../../components/utils';
import { ENDPOINT, ROWS_PER_PAGE } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import { useEventQuery, useEventUpdateMutation } from '../../../hooks/react-query/useEventsQuery';
import {
  useMaintenanceQuestionDeleteMutation,
  useReadingDeleteMutation,
} from '../../../hooks/react-query/useReadingQuery';
import {
  useServiceItemBatchGetQuery,
  useServiceItemCreateMutation,
  useServiceItemDeleteMutation,
  useServiceItemUpdateMutation,
} from '../../../hooks/react-query/useServiceItemQuery';
import {
  ActivityLogClientService,
  makeFakeRows,
  ServiceItemClientService,
} from '../../../tools/helpers';
import { PropertiesSearch } from '../../ComponentsLibrary/AdvancedSearch/PropertiesSearch/PropertiesSearch';
import { Form, type Options, type Schema } from '../Form';
import { InfoTable } from '../InfoTable';
import { Modal } from '../Modal';
import { PlainForm } from '../PlainForm';
import Readings from '../ServiceItemReadings/main';
import { ServiceItemsImagesGallery } from './components/ServiceItemImageGallery';
import { ACTIONS, reducer } from './reducer';

const ReadingClientService = new ReadingClient(ENDPOINT);
const MaterialClientService = new MaterialClient(ENDPOINT);

const SYSTEM_READINGS_TYPE_OPTIONS: Options = [
  { label: 'Please Select an Option', value: 0 },
  { label: 'Straight-cool AC w/ heatstrips', value: 1 },
  { label: 'Heat-pump AC', value: 2 },
  { label: 'Furnace (Straight-Cool)', value: 3 },
  { label: 'Gas Pool Heater', value: 4 },
  { label: 'Heat Pump Pool Heater', value: 5 },
  { label: 'Furnace (Heat-pump)', value: 6 },
  { label: 'Cooler', value: 7 },
  { label: 'Freezer', value: 8 },
  { label: 'AC w/ Reheat', value: 9 },
  { label: 'Other', value: 10 },
];

const MATERIAL_SCHEMA: Schema<Material> = [
  [
    { label: 'Name', name: 'name' },
    { label: 'Quantity', name: 'quantity' },
    { label: 'Part #', name: 'partNumber' },
    { label: 'Vendor', name: 'vendor' },
    { name: 'id', type: 'hidden' },
  ],
];

export type Repair = {
  id: string;
  serviceItemId: number;
  diagnosis: string;
  description: string;
  price: number;
};

type Props = {
  propertyId: number;
  eventId?: number;
  selectForInvoice?: boolean;
  onSelectedUpdate?: (selected: string) => void;
  canMove?: boolean;
};

const sort = (a: ServiceItem, b: ServiceItem) => {
  if (a.sortOrder < b.sortOrder) return -1;
  if (a.sortOrder > b.sortOrder) return 1;
  return 0;
};

const staticServiceItems = Array.from({ length: 3 }).map((_, i) => ServiceItem.create());

const title = 'Service Items';
export const ServiceItems: FC<Props> = ({
  propertyId,
  eventId,
  selectForInvoice,
  onSelectedUpdate,
  canMove,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    materials: [],
    materialsIds: [],
    loadingMaterials: false,
    error: false,
    editing: undefined,
    openedReadings: null,
  });
  const loggedInUser = useAuth().user;
  const handleMaterialChange = useCallback(
    (idx: number) => (temp: Material) => {
      const newMaterials = [...state.materials];
      newMaterials[idx] = temp;
      dispatch({ type: ACTIONS.SET_MATERIALS, data: newMaterials });
    },
    [state.materials],
  );

  const handleAddMaterial = useCallback(() => {
    const newMaterial = Material.create();
    newMaterial.id = Date.now();
    const newMaterials = [...state.materials, newMaterial];
    dispatch({ type: ACTIONS.SET_MATERIALS, data: newMaterials });
  }, [state.materials]);

  const handleRemoveMaterial = useCallback(
    (idx: number) => () => {
      const newMaterials = state.materials.filter((_, idy) => idx !== idy);
      dispatch({ type: ACTIONS.SET_MATERIALS, data: newMaterials });
    },
    [state.materials],
  );

  const updateServiceItemMutation = useServiceItemUpdateMutation();
  const deleteServiceItemMutation = useServiceItemDeleteMutation();
  const createServiceItemMutation = useServiceItemCreateMutation();
  const isSaving = createServiceItemMutation.isPending;

  const MATERIALS_SCHEMA = state.materials
    .map(
      (_, idx): Schema<ServiceItem> => [
        [
          {
            label: `#${idx + 1}`,
            headline: true,
            actions: [
              {
                label: 'Remove',
                variant: 'outlined',
                onClick: handleRemoveMaterial(idx),
                compact: true,
                size: 'xsmall',
                disabled: isSaving,
              },
            ],
          },
        ],
        [
          {
            content: (
              <PlainForm<Material>
                key={state.materials[idx].id}
                schema={MATERIAL_SCHEMA}
                data={state.materials[idx]}
                onChange={handleMaterialChange(idx)}
                disabled={isSaving}
                readOnly={false}
              />
            ),
          },
        ],
      ],
    )
    .reduce((aggr, item) => [...aggr, ...item], []);

  const SCHEMA: Schema<ServiceItem> = [
    [
      {
        label: 'System Description',
        name: 'type',
        type: 'text',
        multiline: true,
        required: true,
        validationOnSave(value) {
          if (!value.trim().replaceAll(' ', '').length) {
            return 'This field is required';
          } else if (value.length > 2500) {
            return 'System Description must be less than 2500 characters';
          }
          return '';
        },
      },
    ],
    [
      { type: 'hidden', name: 'id' },
      {
        label: 'System Type',
        name: 'systemReadingsTypeId',
        type: 'number',
        required: true,
        options: SYSTEM_READINGS_TYPE_OPTIONS,
      },
    ],
    [
      { label: 'Date', name: 'startDate', type: 'date' },
      { label: 'Item Location', name: 'location' },
    ],
    [{ label: '#1', headline: true }],
    [
      { label: 'Item', name: 'item' },
      { label: 'Brand', name: 'brand' },
      { label: 'Model #', name: 'model' },
      { label: 'Serial #', name: 'serial' },
    ],
    [{ label: '#2', headline: true }],
    [
      { label: 'Item', name: 'item2' },
      { label: 'Brand', name: 'brand2' },
      { label: 'Model #', name: 'model2' },
      { label: 'Serial #', name: 'serial2' },
    ],
    [{ label: '#3', headline: true }],
    [
      { label: 'Item', name: 'item3' },
      { label: 'Brand', name: 'brand3' },
      { label: 'Model #', name: 'model3' },
      { label: 'Serial #', name: 'serial3' },
    ],
    [{ label: 'Filter', headline: true }],
    [
      { label: 'Width', name: 'filterWidth' },
      { label: 'Length', name: 'filterLength' },
      { label: 'Thickness', name: 'filterThickness' },
      { label: 'Quantity', name: 'filterQty' },
    ],
    [
      { label: 'Part #', name: 'filterPartNumber' },
      { label: 'Vendor', name: 'filterVendor' },
    ],

    [
      {
        label: 'Materials',
        headline: true,
        actions: [
          {
            label: 'Add',
            size: 'xsmall',
            variant: 'outlined',
            compact: true,
            onClick: handleAddMaterial,
            disabled: isSaving,
          },
        ],
      },
    ],
    ...(state.loadingMaterials
      ? [
          [
            {
              content: (
                <InfoTable
                  className="ServiceItemsLoadingMaterials"
                  data={makeFakeRows(4, 1)}
                  loading
                />
              ),
            },
          ],
        ]
      : MATERIALS_SCHEMA.length === 0
        ? [
            [
              {
                content: <InfoTable className="ServiceItemsNoMaterials" data={[]} />,
              },
            ],
          ]
        : MATERIALS_SCHEMA),
    [{ label: 'Notes', headline: true }],
    [{ label: 'Additional Notes', name: 'notes', multiline: true }],
  ];

  const handleMaterials = async (
    materials: Material[],
    materialsIds: number[],
    serviceItemId: number,
  ) => {
    console.log('handle materials');
    const ids = materials.map((id) => id.id);
    await Promise.all(
      materialsIds
        .filter((id) => !ids.includes(id))
        .map(async (id) => {
          const entry = Material.create();
          entry.id = id;
          return await MaterialClientService.Delete(entry);
        }),
    );
    console.log('finished deleting');
    const operations: {
      operation: 'Create' | 'Update';
      entry: Material;
    }[] = [];
    for (let i = 0; i < materials.length; i += 1) {
      const entry = materials[i];
      entry.serviceItemId = serviceItemId;
      if (materialsIds.includes(materials[i].id)) {
        entry.id = materials[i].id;

        operations.push({ operation: 'Update', entry });
      } else {
        operations.push({ operation: 'Create', entry });
      }
    }
    console.log('operations', operations);
    for (let i = 0; i < operations.length; i++) {
      if (operations[i].operation == 'Create') {
        console.log('create');
        const entry = Material.clone(operations[i].entry);
        entry.id = 0;
        entry.serviceItemId = serviceItemId;
        await MaterialClientService.Create(entry);
        console.log('Create', operations[i].entry);
      } else {
        const entry = Material.clone(operations[i].entry);
        console.log(entry);
        await MaterialClientService.Update(entry);
      }
    }
  };
  const [serviceItemViewGallery, setServiceItemViewedGallery] = useState<ServiceItem>();
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const jobQuery = useEventQuery({
    filter: {
      id: eventId,
    },
  });
  const updateEventMutation = useEventUpdateMutation();

  const serviceItemsQuery = useServiceItemBatchGetQuery({
    filter: {
      propertyId,
      isActive: 1,
      pageNumber: page,
      orderBy: 'serviceItem_sortOrder',
    },
    select(data) {
      return {
        results: data.results.sort(sort),
        totalCount: data.totalCount,
      };
    },
  });

  const handleSave = useCallback(
    async (entry: ServiceItem) => {
      if (state.editing && serviceItemsQuery.isSuccess) {
        entry = ServiceItem.clone(entry);
        entry.id = state.editing.id;
        let result = state.editing;
        entry.propertyId = propertyId;
        if (entry.id == 0) {
          if (serviceItemsQuery.data.results.length > 0) {
            const sortOrder = Math.max(
              serviceItemsQuery.data.results.at(-1)!.sortOrder + 1,
              serviceItemsQuery.data.results.length,
            );
            entry.sortOrder = sortOrder;
            if (!entry.fieldMask) {
              entry.fieldMask = [];
            }
            entry.fieldMask.push('SortOrder');
          }
          console.log('WE  CREATE', entry);
          const response = await createServiceItemMutation.mutateAsync(entry);
          if (response) {
            result = response;
          }
        } else {
          if (entry.fieldMask.length) {
            console.log('WE  UPDATE', entry);
            await updateServiceItemMutation.mutateAsync(entry);
            result = entry;
          }
        }

        await handleMaterials(state.materials, state.materialsIds, result.id);

        dispatch({ type: ACTIONS.SET_EDITING, data: undefined });
      }
    },
    [
      state.editing,
      state.materials,
      state.materialsIds,
      serviceItemsQuery.isSuccess,
      serviceItemsQuery.data?.results,
      propertyId,
      createServiceItemMutation,
      updateServiceItemMutation,
    ],
  );

  useEffect(() => {
    if (serviceItemsQuery.isSuccess) {
      setTotalCount(serviceItemsQuery.data.totalCount);
    }
  }, [serviceItemsQuery.data?.totalCount, serviceItemsQuery.isSuccess]);

  const deleteReadingsMutation = useReadingDeleteMutation();
  const deleteMaintenanceQuestionMutation = useMaintenanceQuestionDeleteMutation();

  const isLoading =
    serviceItemsQuery.isPending ||
    jobQuery.isPending ||
    updateEventMutation.isPending ||
    updateServiceItemMutation.isPending ||
    deleteServiceItemMutation.isPending ||
    deleteReadingsMutation.isPending ||
    deleteMaintenanceQuestionMutation.isPending;

  const _selected = useMemo<number[]>(() => {
    if (jobQuery.isSuccess) {
      const ids = jobQuery.data.invoiceServiceItem.split(',');
      if (ids.length === 1 && ids[0] === '') {
        return [];
      }
      return ids.map((item) => parseInt(item));
    }

    return [];
  }, [jobQuery.data?.invoiceServiceItem, jobQuery.isSuccess]);

  const handleSelectedChange = useCallback(
    (entry: ServiceItem) => async () => {
      if (!eventId) return;

      const newSelected = _selected.includes(entry.id)
        ? _selected.filter((i) => i !== entry.id)
        : [..._selected, entry.id];
      let fullString = '';
      for (let i = 0; i < newSelected.length; i++) {
        fullString += `${newSelected[i]}`;
        if (i < newSelected.length - 1) {
          fullString += ',';
        }
      }
      if (onSelectedUpdate) {
        onSelectedUpdate(fullString);
      }
      updateEventMutation.mutate(
        Event.create({
          id: eventId,
          invoiceServiceItem: fullString,
          fieldMask: ['InvoiceServiceItem'],
        }),
      );
    },
    [_selected, eventId, updateEventMutation, onSelectedUpdate],
  );

  const [deletingId, setDeletingId] = useState<number | undefined>(undefined);
  const confirm = useConfirm();
  const handleDelete = useCallback(
    async (toDelete: ServiceItem) => {
      const ok = await confirm('Are you sure you want to delete this service item?');
      if (!ok) return;
      if (toDelete) {
        try {
          setDeletingId(toDelete.id);
          const reading = Reading.create();
          reading.serviceItemId = toDelete.id;
          const response = await ReadingClientService.BatchGet(reading);
          const readingIds = response!.results.map((res) => res.id);
          await Promise.all(
            readingIds.map(async (readingId: number) => {
              const maintenanceQuestion = MaintenanceQuestion.create();
              maintenanceQuestion.readingId = readingId;
              return await deleteMaintenanceQuestionMutation.mutateAsync(maintenanceQuestion);
            }),
          );
          await Promise.all(
            readingIds.map(async (id) => {
              const reading = Reading.create();
              reading.id = id;
              return await deleteReadingsMutation.mutateAsync(reading);
            }),
          );

          const entry = ServiceItem.create();

          if (eventId && _selected.includes(toDelete.id)) {
            // delete from invoice
            handleSelectedChange(toDelete)();
          }

          entry.id = toDelete.id;
          await deleteServiceItemMutation.mutateAsync(entry);
          toast({
            title: 'Service Item Deleted',
            variant: 'success',
          });
        } catch {
          toast({
            title: 'Error Deleting Service Item',
            variant: 'destructive',
          });
        } finally {
          setDeletingId(undefined);
        }
      }
    },
    [
      confirm,
      eventId,
      _selected,
      deleteServiceItemMutation,
      deleteMaintenanceQuestionMutation,
      deleteReadingsMutation,
      handleSelectedChange,
    ],
  );

  const handleReorder = useCallback(
    (idx: number, step: number) => async () => {
      if (serviceItemsQuery.isSuccess) {
        const currentItem = serviceItemsQuery.data.results[idx];
        const targetPage = step > 0 ? page + 1 : page - 1;

        if (
          (idx === 0 && step < 0) ||
          (idx === serviceItemsQuery.data.results.length - 1 && step > 0)
        ) {
          const filter = ServiceItem.create({
            propertyId,
            isActive: 1,
            pageNumber: targetPage,
            orderBy: 'serviceItem_sortOrder',
          });

          try {
            const adjacentPageData = await ServiceItemClientService.BatchGet(filter);

            if (adjacentPageData?.results.length) {
              const targetItem =
                step < 0
                  ? adjacentPageData.results[adjacentPageData.results.length - 1]
                  : adjacentPageData.results[0];

              let newCurrentSortOrder = targetItem.sortOrder;
              const newTargetSortOrder = currentItem.sortOrder;

              if (targetItem.sortOrder === currentItem.sortOrder) {
                if (step < 0) {
                  newCurrentSortOrder = targetItem.sortOrder - 1;
                } else {
                  newCurrentSortOrder = targetItem.sortOrder + 1;
                }
              }

              const entry = ServiceItem.create({
                id: currentItem.id,
                sortOrder: newCurrentSortOrder,
                fieldMask: ['SortOrder'],
              });

              const targetEntry = ServiceItem.create({
                id: targetItem.id,
                sortOrder: newTargetSortOrder,
                fieldMask: ['SortOrder'],
              });

              await Promise.all([
                updateServiceItemMutation.mutateAsync(entry),
                updateServiceItemMutation.mutateAsync(targetEntry),
              ]);

              setPage(targetPage);
            }
          } catch (error) {
            console.error('Error during reorder:', error);
          }
        } else {
          const nextItem = serviceItemsQuery.data.results[idx + step];

          let newCurrentSortOrder = nextItem.sortOrder;
          const newNextSortOrder = currentItem.sortOrder;

          if (nextItem.sortOrder === currentItem.sortOrder) {
            if (step < 0) {
              newCurrentSortOrder = nextItem.sortOrder - 1;
            } else {
              newCurrentSortOrder = nextItem.sortOrder + 1;
            }
          }

          const entry = ServiceItem.create({
            id: currentItem.id,
            sortOrder: newCurrentSortOrder,
            fieldMask: ['SortOrder'],
          });

          const nextEntry = ServiceItem.create({
            id: nextItem.id,
            sortOrder: newNextSortOrder,
            fieldMask: ['SortOrder'],
          });

          try {
            await Promise.all([
              updateServiceItemMutation.mutateAsync(entry),
              updateServiceItemMutation.mutateAsync(nextEntry),
            ]);
          } catch (error) {
            console.error('Error during reorder:', error);
          }
        }
      }
    },
    [
      updateServiceItemMutation,
      page,
      propertyId,
      serviceItemsQuery.data?.results,
      serviceItemsQuery.isSuccess,
    ],
  );

  const handleEditing = useCallback(
    (editing?: ServiceItem) => async () => {
      console.log(editing);
      if (editing === undefined) {
        dispatch({ type: ACTIONS.SET_EDITING, data: undefined });
      }
      if (editing) {
        dispatch({ type: ACTIONS.SET_EDITING, data: editing });
      }
      if (editing && editing.id != 0) {
        const entry = Material.create();
        entry.serviceItemId = editing.id;
        dispatch({ type: ACTIONS.SET_LOADING_MATERIALS, data: true });
        const results = (await MaterialClientService.BatchGet(entry))!.results;
        dispatch({ type: ACTIONS.SET_MATERIALS, data: results });
        const ids = results.map((id) => id.id);
        dispatch({ type: ACTIONS.SET_MATERIALS_IDS, data: ids });

        dispatch({ type: ACTIONS.SET_LOADING_MATERIALS, data: false });
      } else {
        dispatch({ type: ACTIONS.SET_MATERIALS, data: [] });
        dispatch({ type: ACTIONS.SET_MATERIALS_IDS, data: [] });
      }
    },
    [],
  );

  const [movingServiceItem, setMovingServiceItem] = useState<ServiceItem | undefined>(undefined);

  const handleMoveServiceItem = useCallback(
    (serviceItem: ServiceItem) => () => {
      setMovingServiceItem(serviceItem);
    },
    [],
  );

  const handleMoveToProperty = useCallback(
    async (newProperty: Property) => {
      if (movingServiceItem) {
        const updatedServiceItem = ServiceItem.create({
          ...movingServiceItem,
          propertyId: newProperty.id,
          fieldMask: ['PropertyId'],
        });

        await updateServiceItemMutation.mutateAsync(updatedServiceItem);
        setMovingServiceItem(undefined);
        const activityReqForOrigin = ActivityLog.create({
          propertyId: propertyId,
          activityName:
            'Service Item Moved by user from ' +
            movingServiceItem.propertyId +
            ' to ' +
            newProperty.id,
          userId: loggedInUser.id,
        });
        await ActivityLogClientService.Create(activityReqForOrigin);

        const activityReqForDestination = ActivityLog.create({
          propertyId: newProperty.id,
          activityName:
            'Service Item Moved by user from ' +
            movingServiceItem.propertyId +
            ' to ' +
            newProperty.id,
          userId: loggedInUser.id,
        });
        await ActivityLogClientService.Create(activityReqForDestination);
        serviceItemsQuery.refetch();
      }
    },
    [movingServiceItem, updateServiceItemMutation, serviceItemsQuery, loggedInUser, propertyId],
  );

  const tableColumns = useMemo<ColumnDef<ServiceItem>[]>(() => {
    return [
      {
        id: 'isSelected',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Include in Invoice?" />;
        },
        meta: {
          className: 'flex items-center gap-4',
        },
        cell(props) {
          const entry = props.row.original;
          const isLastPage = (page + 1) * ROWS_PER_PAGE >= totalCount;
          const isFirstPage = page === 0;
          const isLastItemOnPage = props.row.index + 1 === props.table.getRowCount();
          const isFirstItemOnPage = props.row.index === 0;

          return (
            <div className={props.column.columnDef.meta?.className}>
              <Checkbox
                checked={!!_selected.find((selectedID) => selectedID === entry.id)}
                onCheckedChange={handleSelectedChange(entry)}
                disabled={isLoading}
              />
              <IconButton
                className="mr-2"
                size="small"
                disabled={(isFirstPage && isFirstItemOnPage) || isLoading}
                onClick={handleReorder(props.row.index, -1)}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                className="mr-2"
                size="small"
                disabled={(isLastPage && isLastItemOnPage) || isLoading}
                onClick={handleReorder(props.row.index, 1)}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </div>
          );
        },
      },
      {
        accessorKey: 'type',
        meta: {
          className: 'flex-1',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <span>{props.row.original.type}</span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        meta: {
          className: 'flex justify-end items-center gap-2',
        },
        cell(props) {
          const entry = props.row.original;
          const isDeleting = deletingId === entry.id;
          const isPerformingAction = isDeleting;
          return (
            <div className={props.column.columnDef.meta?.className}>
              <Button
                disabled={isPerformingAction}
                onClick={() => {
                  dispatch({
                    type: ACTIONS.SET_OPENED_READINGS,
                    data: entry.id,
                  });
                }}
                size="sm"
                variant="outline"
              >
                Readings
              </Button>
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipContent>View Images</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      disabled={isPerformingAction}
                      size="icon"
                      variant="outline"
                      onClick={() => setServiceItemViewedGallery(entry)}
                    >
                      <ImageIcon />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>

                <Tooltip>
                  <TooltipContent>Edit</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      disabled={isPerformingAction}
                      size="icon"
                      variant="outline"
                      onClick={handleEditing(entry)}
                    >
                      <Pencil1Icon />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>

                <Tooltip>
                  <TooltipContent>Delete</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      disabled={deletingId === entry.id}
                      isLoading={isDeleting}
                      size="icon"
                      variant="outline"
                      onClick={() => handleDelete(entry)}
                    >
                      <TrashIcon />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>
                {canMove && (
                  <Tooltip>
                    <TooltipContent>Move</TooltipContent>
                    <TooltipTrigger asChild>
                      <Button
                        disabled={isPerformingAction}
                        size="icon"
                        variant="outline"
                        onClick={handleMoveServiceItem(entry)}
                      >
                        <MoveIcon />
                      </Button>
                    </TooltipTrigger>
                  </Tooltip>
                )}
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
  }, [
    page,
    totalCount,
    _selected,
    handleSelectedChange,
    isLoading,
    handleReorder,
    deletingId,
    handleEditing,
    canMove,
    handleMoveServiceItem,
    handleDelete,
  ]);

  const loadingColumns = useMemo(() => getLoadingColumns(tableColumns), [tableColumns]);

  const table = useReactTable({
    data: serviceItemsQuery.isSuccess ? serviceItemsQuery.data?.results : staticServiceItems,
    columns: serviceItemsQuery.isSuccess ? tableColumns : loadingColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const pageCount = Math.ceil(totalCount / ROWS_PER_PAGE);
  return (
    <div className="pb-10">
      <div className="flex items-center justify-between bg-gray-200 p-2">
        <div className="flex items-center gap-2">
          <h2 className="text-2xl font-medium">{title}</h2>
          {pageCount > 1 && (
            <DataTablePagination currentPage={page} pageCount={pageCount} setPage={setPage} />
          )}
        </div>
        <Button
          size="sm"
          disabled={isLoading}
          onClick={handleEditing(
            ServiceItem.create({
              startDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
            }),
          )}
        >
          Add
        </Button>
      </div>
      <Separator decorative />
      <div className="w-full overflow-auto">
        <DataTable table={table} />
      </div>
      {state.editing && (
        <Modal open onClose={handleEditing(undefined)}>
          <div className="ServiceItemsModal">
            <Form<ServiceItem>
              title={`${state.editing.id ? 'Edit' : 'Add'} Service Item`}
              key={JSON.stringify(state.editing)}
              schema={SCHEMA}
              data={state.editing}
              onSave={handleSave}
              onClose={handleEditing(undefined)}
              disabled={isSaving}
              className="ServiceItemsForm"
            />
          </div>
        </Modal>
      )}
      {serviceItemViewGallery && (
        <Dialog
          onOpenChange={(isOpen) => {
            if (!isOpen) setServiceItemViewedGallery(undefined);
          }}
          open={true}
        >
          <DialogContent className="h-[80vh] max-w-2xl overflow-auto p-0">
            <ScrollArea className="px-3 pt-6 lg:px-6">
              <ServiceItemsImagesGallery serviceItemId={serviceItemViewGallery.id} />
            </ScrollArea>
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        open={!!state.openedReadings}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            dispatch({
              type: ACTIONS.SET_OPENED_READINGS,
              data: null,
            });
          }
        }}
      >
        <DialogContent className="max-h-svh min-h-[40svh] max-w-xl overflow-auto px-0 pt-8">
          {!!state.openedReadings && (
            <Readings serviceItemId={state.openedReadings} eventId={eventId} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!movingServiceItem}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setMovingServiceItem(undefined);
          }
        }}
      >
        <DialogContent className="h-full max-w-xl overflow-auto px-0 pt-8">
          <ScrollArea className="px-3 pt-6 lg:px-6">
            <h2 className="mb-4 text-xl font-semibold">Select Property To Move</h2>
            <PropertiesSearch exceptionId={propertyId} onSelectProperty={handleMoveToProperty} />
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </div>
  );
};
