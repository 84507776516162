import './PlainForm.module.less';

import { cn } from '@kalos/ui';
import useTheme from '@mui/material/styles/useTheme';
import type TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type DesktopDatePicker } from '@mui/x-date-pickers';
import { clsx } from 'clsx';
import {
  type ComponentProps,
  Fragment,
  type JSXElementConstructor,
  type ReactElement,
  type ReactNode,
  type Ref,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { type Props as ButtonProps } from '../Button';
import {
  Field,
  type FieldType,
  type Option as OptionType,
  type Options as OptionsType,
  type Value as ValueType,
} from '../Field';
import { getDefaultValueByType } from '../Field/Field.utils';

export type Value = ValueType;
export type Option = OptionType;
export type Options = OptionsType;

type MUIDatesConstraints = Pick<
  ComponentProps<typeof DesktopDatePicker>,
  'disableFuture' | 'disablePast'
> & {
  minDate?: Date;
  maxDate?: Date;
};

export type SchemaProps<T> = {
  label?: ReactNode;
  name?: keyof T;
  headline?: boolean;
  description?: string;
  options?: Options;
  required?: boolean;
  helperText?: string;
  onChange?: (value: Value) => void;
  onBlur?: (value: Value) => void;
  onFileLoad?: (file: string | ArrayBuffer | null, filename?: string) => void;
  onClose?: () => void;
  actions?: ButtonProps[];
  actionsInLabel?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  content?: ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  technicianAsEmployee?: boolean;
  jobAdminTechnicianFilter?: boolean;
  removeExcessCheckboxArea?: boolean;
  minutesStep?: number;
  disableMinutesStep?: boolean;
  filter?(a: any): boolean;
  invisible?: boolean;
  defaultValue?: T[keyof T];
  displayEmpty?: boolean;
  forceShrinkLabel?: boolean;
  defaultLabel?: string;
  minRows?: number;
  maxRows?: number;
  technicianFilters?: number[];
  omitArchivedJobs?: boolean;
  className?: string;
  validationOnUpdate?: (value: any) => string | Promise<string>;
  validationOnSave?: (value: any) => string | Promise<string>;
  performUpdateValidationOnSave?: boolean; // Requires validationOnUpdate to be populated to work
} & (
  | {
      type?: Exclude<FieldType, 'action-group' | 'mui-date' | 'mui-datemonth'>;
    }
  | {
      type: 'action-group';
      actions: ButtonProps[];
    }
  | ({
      type: 'mui-date';
      dateFormat?: string;
    } & MUIDatesConstraints)
  | ({
      type: 'mui-datemonth';
    } & Omit<MUIDatesConstraints, 'minDate' | 'maxDate'>)
  | {
      type: 'text';
      multiline?: ComponentProps<typeof TextField>['multiline'];
    }
  | {
      type: 'file';
      withDeleteButton?: boolean;
      onDeleteClick?: () => void;
    }
);

export type Schema<T> = SchemaProps<T>[][];

export type Validation = { [key: string]: string };

type Style = {
  compact?: boolean;
  fullWidth?: boolean;
  className?: string;
  groupClassName?: string;
  errorClassName?: string;
};

export interface PlainFormProps<T> extends Style {
  schema: Schema<T>;
  data: T;
  disabled?: boolean;
  readOnly?: boolean;
  error?: ReactNode;
  warning?: ReactNode;
  useMatches?: boolean;
  checkForDataPropChanges?: boolean; //Just in case you have multiple forms handling the same piece of data, this will enable the useEffect to check for changes
  children?: ReactNode;
  ref?: RefObject<T | null> | Ref<T>;
  inputFieldRefs?: T[];
}

type Props<T> = PlainFormProps<T> & {
  onSubmit?: () => void;

  onClear?: (name: string) => void;
  onEnter?: boolean;
  validations?: Validation;
} & (
    | {
        attachFieldMask: true;
        onChange: (data: T & { fieldMask?: string[] }) => void;
      }
    | {
        attachFieldMask?: false;
        onChange: (data: T) => void;
      }
  );

export const PlainForm: <T>(
  props: Props<T>,
) => ReactElement<any, string | JSXElementConstructor<any>> | null = ({
  schema,
  data,
  onChange,
  onSubmit,
  onClear,
  disabled = false,
  readOnly = false,
  compact = false,
  fullWidth = false,
  checkForDataPropChanges = false,
  error,
  warning,
  onEnter,
  validations = {},
  className = '',
  errorClassName,
  children,
  groupClassName,
  useMatches = true,
  inputFieldRefs,
  attachFieldMask = true,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const getInitialFormData = <T,>(data: any, schema: Schema<T>) => {
    return schema.reduce(
      (aggr, fields) => {
        const fieldObj = fields.reduce((aggr, field) => {
          if (field.name !== undefined) {
            return {
              ...aggr,
              [field.name]:
                data[field.name] ||
                (field.type === 'hidden' || field.type === undefined
                  ? getDefaultValueByType(typeof data![field.name] as FieldType)
                  : getDefaultValueByType(field.type!)),
            };
          } else {
            return aggr;
          }
        }, {});
        return {
          ...aggr,
          ...fieldObj,
        };
      },
      {} as typeof data,
    );
  };

  const [formData, setFormData] = useState(() => getInitialFormData(data, schema));

  const handleSetFormData = useCallback(
    (data: any) => {
      setFormData(() => getInitialFormData(data, schema));
    },
    [schema],
  );
  useEffect(() => {
    if (checkForDataPropChanges) {
      handleSetFormData(data);
    }
  }, [data, schema, checkForDataPropChanges, handleSetFormData]);

  const handleFieldMask = (value: string, fieldMask: string[]) => {
    const tempValue = value.charAt(0).toUpperCase() + value.slice(1);
    const check = fieldMask.findIndex((el) => el == tempValue);
    if (check == -1) {
      return fieldMask.concat(tempValue);
    }
    return fieldMask;
  };

  const handleChange = useCallback(
    (name: string) => (value: Value) => {
      const data = { ...formData, [name]: value };
      setFormData(data);
      const field = schema
        .reduce((aggr, fields) => [...aggr, ...fields], [])
        .find((field) => field.name === name);
      if (field && field.onChange) {
        field.onChange(value);
      }
      if (field && field.name && attachFieldMask) {
        console.log('form field', { field });
        if ('fieldMask' in data) {
          Object.assign(data, {
            fieldMask: handleFieldMask(field.name as string, data.fieldMask as unknown as string[]),
          });
        } else {
          Object.assign(data, {
            fieldMask: handleFieldMask(field.name as string, []),
          });
        }
      }
      onChange(data);
    },
    [formData, schema, attachFieldMask, onChange],
  );

  if (formRef.current) {
    if (!formRef.current.onkeyup) {
      formRef.current.onkeyup = (event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          event.preventDefault();
          if (onSubmit && onEnter) {
            onSubmit();
          }
        }
      };
    }
    if (!formRef.current.onsubmit) {
      formRef.current.onsubmit = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (onSubmit) {
          onSubmit();
        }
      };
    }
  }
  let indexOfInputField = 0;
  return (
    <form
      ref={formRef}
      className={clsx(className, 'PlainForm report', {
        compact,
        fullWidth,
        disabled,
      })}
    >
      {error && (
        <Typography className={clsx(errorClassName, 'PlainFormError')} component="div">
          {error}
        </Typography>
      )}
      {warning && (
        <Typography className="PlainFormWarning" component="div">
          {warning}
        </Typography>
      )}
      {Object.keys(validations).length > 0 && (
        <Typography className="PlainFormError" sx={{ marginBottom: '16px' }}>
          Please correct the following validation errors and try again.
          <span className="PlainFormErrorFields">
            {Object.keys(validations).map((fieldName) => (
              <span key={fieldName} className="PlainFormErrorField">
                <strong>
                  {
                    schema
                      .reduce((aggr, fields) => [...aggr, ...fields], [])
                      .find(({ name }) => name === fieldName)?.label
                  }
                  :{' '}
                </strong>
                {validations[fieldName]}
              </span>
            ))}
          </span>
        </Typography>
      )}
      {schema.map((fields, idx) => (
        <div key={idx} className={cn('PlainFormGroup', groupClassName)}>
          {fields.map((props, idx2) => {
            if (props.invisible) return <Fragment key={idx2}></Fragment>;
            const { name } = props;
            return (
              <Field
                key={`${idx2}-${String(name)}`}
                ref={(functionRef: any) => {
                  if (functionRef && inputFieldRefs && !inputFieldRefs?.includes(functionRef)) {
                    if (inputFieldRefs[indexOfInputField]) {
                      inputFieldRefs[indexOfInputField] = functionRef;
                    } else {
                      inputFieldRefs?.push(functionRef);
                    }
                  }
                  indexOfInputField++;
                }}
                {...props}
                value={name ? formData[name] : undefined}
                filter={props.filter}
                onChange={handleChange(name?.toString() || '')}
                onClear={name ? () => onClear?.(name.toString()) : undefined}
                disabled={disabled || props.disabled}
                validation={validations[name as string]}
                readOnly={readOnly || props.readOnly}
                style={
                  useMatches && matches
                    ? {
                        width: `calc((100% - ${(fields.length - 1) * 16}px) / ${fields.length})`,
                      }
                    : {}
                }
              />
            );
          })}
        </div>
      ))}
      {children}
      <button type="submit" className="PlainFormSubmit">
        Submit
      </button>
    </form>
  );
};
