import { zodResolver } from '@hookform/resolvers/zod';
import { type QuoteLine, type TimesheetDepartmentList } from '@kalos/kalos-rpc';
import {
  Button,
  Form as FormContext,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MoneyInput,
  MultipleSelect,
  Textarea,
} from '@kalos/ui';
import { type FC } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useTimesheetDepartmentListQuery } from '../../../../hooks/react-query/useTimesheetDepartmentQuery';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';

// Schema and types
type FlatRateCreateFormFields = Pick<QuoteLine, 'description' | 'departments' | 'adjustment'>;

const flatRateCreateFormSchema = z.object({
  description: z
    .string()
    .max(255, 'Description is too long (max 255 characters)')
    .min(1, 'Description is required'),
  departments: z.array(z.number()).min(1, 'At least one department is required'),
  adjustment: z.coerce
    .number()
    .min(0, 'Cost must be greater than or equal to 0')
    .refine((val) => !isNaN(val), 'Cost must be a valid number')
    .transform((val) => Number(val.toFixed(2))),
}) satisfies ZodObjectWithModelKeys<FlatRateCreateFormFields>;

type FlatRateCreateFormSchemaType = z.infer<typeof flatRateCreateFormSchema>;

// Form component
export const FlatRateCreateForm: FC<{
  onSave: (arg: { data: FlatRateCreateFormSchemaType }) => void;
  isLoading?: boolean;
  disabled?: boolean;
}> = ({ onSave, disabled, isLoading }) => {
  const form = useForm<FlatRateCreateFormSchemaType>({
    resolver: zodResolver(flatRateCreateFormSchema),
    mode: 'onBlur',
    defaultValues: {
      description: '',
      departments: [],
      adjustment: 0,
    },
    disabled,
  });

  const handleSave: SubmitHandler<FlatRateCreateFormSchemaType> = async (data) => {
    onSave({ data });
  };

  const departmentsQuery = useTimesheetDepartmentListQuery({
    select: (data: TimesheetDepartmentList) => {
      return data.results.map((item) => ({
        value: item.id.toString(),
        label: `${item.value} - ${item.description}`,
      }));
    },
  });

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(handleSave)} className="flex items-end gap-4">
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Description</FormLabel>
              <FormMessage />
              <FormControl>
                <Textarea {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="departments"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Department(s)</FormLabel>
              <FormMessage />
              <FormControl>
                <MultipleSelect
                  options={departmentsQuery.data || []}
                  selected={field.value ? field.value.map(String) : []}
                  onChange={(values) => field.onChange(values.map(Number))}
                  placeholder="Select departments..."
                  loading={departmentsQuery.isLoading}
                  disabled={field.disabled}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="adjustment"
          render={({ field }) => (
            <FormItem className="w-32">
              <FormLabel>Cost</FormLabel>
              <FormMessage />
              <FormControl>
                <MoneyInput
                  {...field}
                  type="number"
                  step="0.01"
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value);
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button type="submit" isLoading={isLoading} disabled={disabled} className="h-10">
          Save
        </Button>
      </form>
    </FormContext>
  );
};
