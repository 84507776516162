'use client';

import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { ChevronDownIcon, ChevronUpIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';
import { type ComponentProps, useMemo, useState } from 'react';
import { type DateRange, type Matcher } from 'react-day-picker';

import { Button } from '../../components/button';
import { Calendar } from '../../components/calendar';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem } from '../../components/dropdownMenu';
import { Popover, PopoverContent, PopoverTrigger } from '../../components/popover';
import { cn } from '../../utils';
import { modifySelectRange } from './utils';

export const inputDateFormat = 'MM/dd/y';
const placeHolder = format(new Date(), inputDateFormat);

const formatDate = (date: Date, locale = 'en-us'): string => {
  return date.toLocaleDateString(locale, {
    month: 'short',
    weekday: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const DateRangePicker = ({
  value,
  onChange,
  placeholder = 'Select date range',
  triggerClassName,
  presets = [
    {
      label: 'next Monday',
      value: {
        from: new Date(new Date().getTime() + DAY_IN_MS * (8 - new Date().getDay())),
        to: new Date(new Date().getTime() + DAY_IN_MS * (8 - new Date().getDay())),
      },
    },
    {
      label: 'next Tuesday',
      value: {
        from: new Date(new Date().getTime() + DAY_IN_MS * (9 - new Date().getDay())),
        to: new Date(new Date().getTime() + DAY_IN_MS * (9 - new Date().getDay())),
      },
    },
    {
      label: 'next Wednesday',
      value: {
        from: new Date(new Date().getTime() + DAY_IN_MS * (10 - new Date().getDay())),
        to: new Date(new Date().getTime() + DAY_IN_MS * (10 - new Date().getDay())),
      },
    },
    {
      label: 'next Thursday',
      value: {
        from: new Date(new Date().getTime() + DAY_IN_MS * (11 - new Date().getDay())),
        to: new Date(new Date().getTime() + DAY_IN_MS * (11 - new Date().getDay())),
      },
    },
    {
      label: 'next Friday',
      value: {
        from: new Date(new Date().getTime() + DAY_IN_MS * (12 - new Date().getDay())),
        to: new Date(new Date().getTime() + DAY_IN_MS * (12 - new Date().getDay())),
      },
    },
  ],
  disabled,
  validDateRange,
  ...props
}: {
  disabled?: boolean;
  value: DateRange | undefined;
  onChange: (date?: DateRange) => void;
  placeholder?: React.ReactNode;
  triggerClassName?: string;
  presets?: { label: string; value: DateRange }[];
  validDateRange?: { from: Date; to: Date };
} & Pick<ComponentProps<typeof Calendar>, 'fromDate' | 'toDate' | 'weekStartsOn'>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(() => {
    if (value) {
      return value.from;
    } else if (validDateRange) {
      return validDateRange.from;
    }
    return undefined;
  });

  const disableProp = useMemo<Matcher[] | undefined>(() => {
    if (disabled) {
      return [true];
    }
    if (validDateRange && !disabled) {
      return [
        {
          after: validDateRange.to,
          before: validDateRange.from,
        },
      ];
    }
    return undefined;
  }, [disabled, validDateRange]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          disabled={disabled}
          className={cn(triggerClassName, 'flex h-auto justify-between gap-1')}
        >
          {value && (value.from || value.to) ? (
            <div className="flex flex-wrap gap-1">
              <span>{value.from ? formatDate(value.from) : '-/-/-'} </span>
              <span>-</span>
              <span>{value.to ? formatDate(value.to) : '-/-/-'}</span>
            </div>
          ) : (
            placeHolder
          )}
          <div className="-mr-2 scale-125 pl-1 opacity-60">
            {isOpen ? <ChevronUpIcon width={24} /> : <ChevronDownIcon width={24} />}
          </div>
        </Button>
      </PopoverTrigger>

      <PopoverContent className="flex flex-col p-1">
        <div className="flex px-4">
          {presets && presets.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Select preset</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {presets.map((preset, idx) => (
                  <DropdownMenuItem
                    key={idx}
                    onClick={() => {
                      onChange(preset.value);
                      setSelectedMonth(preset.value.from);
                    }}
                  >
                    {preset.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
          <Popover>
            <PopoverTrigger asChild>
              <Button size="icon" className="absolute right-2 top-2 z-50" variant="outline">
                <QuestionMarkCircledIcon />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="text-xs">
              <b>Double click</b> on the non-start date to put both <b>end</b> and <b>start</b> on
              the date
            </PopoverContent>
          </Popover>
        </div>

        <Calendar
          mode="range"
          classNames={{
            nav: 'absolute top-16 flex inset-x-4 items-center justify-between',
          }}
          selected={value}
          onMonthChange={setSelectedMonth}
          month={selectedMonth}
          disabled={disableProp}
          onSelect={modifySelectRange(onChange, value)}
          {...props}
        />
      </PopoverContent>
    </Popover>
  );
};
