import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ServiceCalendar } from '../../modules/ServiceCalendar/main';

export const Route = createFileRoute('/calendar/$initialCustomerId')({
  component: RouteComponent,
  params: z.object({
    initialCustomerId: z.string(),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'Service Calendar' }],
    };
  },
});

function RouteComponent() {
  const { initialCustomerId } = Route.useParams();
  return <ServiceCalendar initialCustomer={initialCustomerId} />;
}
