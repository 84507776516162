import { createFileRoute } from '@tanstack/react-router';

import { AdvancedSearch } from '../../modules/ComponentsLibrary/AdvancedSearch';
import { type Kind } from '../../modules/ComponentsLibrary/AdvancedSearch/constants';
import { customerSearchScheme } from '../../modules/ComponentsLibrary/AdvancedSearch/CustomerSearch/utils';

const customers = ['customers'] satisfies Kind[];
export const Route = createFileRoute('/customers/')({
  component: RouteComponent,
  validateSearch: customerSearchScheme,
  head: () => ({
    meta: [
      {
        title: 'Customer Search',
      },
    ],
  }),
});

function RouteComponent() {
  return <AdvancedSearch title="Customer Search" kinds={customers} />;
}
