import { createFileRoute } from '@tanstack/react-router';

import { FirstCallDashboard } from '../modules/ComponentsLibrary/FirstCalls';

export const Route = createFileRoute('/firstcalls')({
  component: RouteComponent,
});

function RouteComponent() {
  return <FirstCallDashboard />;
}
