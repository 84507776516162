import { Paper, Typography } from '@mui/material';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/tools')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Tools' }],
    };
  },
});

function RouteComponent() {
  return (
    <Paper>
      <Typography>Tools</Typography>
      <Outlet />
    </Paper>
  );
}
