import { createFileRoute } from '@tanstack/react-router';

import { CreditCardsPage } from '../modules/CreditCards/CreditCardsPage';

export const Route = createFileRoute('/creditCards')({
  component: RouteComponent,
});

function RouteComponent() {
  return <CreditCardsPage />;
}
