'use client';

import { Cross2Icon } from '@radix-ui/react-icons';
import { Command as CommandPrimitive } from 'cmdk';
import * as React from 'react';

import { Badge } from '../../components/badge';
import { Button } from '../../components/button';
import { Command, CommandGroup, CommandItem, CommandList } from '../../components/command';
import { LoadingIcon } from '../../components/loadingIcon';
import { useEvent, useLatest } from '../../utils/hooks';

type SelectItem = Record<'value' | 'label', string>;
interface Props {
  options: SelectItem[];
  onChange?: (val: string[]) => void;
  placeholder?: string;
  selected?: string[];
  loading?: boolean;
  disabled?: boolean;
  id?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const staticFn = () => {};

export const MultipleSelect = ({
  options,
  placeholder = '',
  loading,
  selected: selectedProp,
  onChange = staticFn,
  disabled,
  id,
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);

  const [selected, _setSelected] = React.useState<SelectItem[]>(() => {
    return options.filter((o) => selectedProp?.includes(o.value));
  });
  const selectedValue = useLatest(selected);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    if (
      selectedProp &&
      selectedValue.current.map((s) => s.value).join(',') !== selectedProp.join(',')
    ) {
      _setSelected(options.filter((o) => selectedProp.includes(o.value)));
    }
  }, [options, selectedProp, selectedValue]);

  const onChangeEvent = useEvent(onChange);

  const setSelected: React.Dispatch<React.SetStateAction<SelectItem[]>> = React.useCallback(
    (updater) => {
      _setSelected((prev) => {
        const newValue = typeof updater === 'function' ? updater(prev) : updater;
        onChangeEvent?.(newValue.map((s) => s.value));
        return newValue;
      });
    },
    [onChangeEvent],
  );

  const handleUnselect = React.useCallback(
    (selectedItem: SelectItem) => {
      setSelected((prev) => prev.filter((s) => s.value !== selectedItem.value));
    },
    [setSelected],
  );

  const selectables = (options || []).filter((item) => !selected?.includes(item));

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            setSelected((prev) => {
              const newSelected = [...prev];
              newSelected.pop();
              return newSelected;
            });
          }
        }
        if (e.key === 'Escape') {
          input.blur();
          e.stopPropagation();
        }
      }
    },
    [setSelected],
  );

  return (
    <Command id={id} onKeyDown={handleKeyDown} className="overflow-visible bg-transparent">
      <div className="border-input ring-offset-background focus-within:ring-ring group rounded-md border px-3 py-2 text-sm focus-within:ring-2 focus-within:ring-offset-2">
        <div className="flex flex-wrap gap-1">
          {selected.map((framework) => {
            return (
              <Badge key={framework.value} variant="secondary">
                {framework.label}
                <button
                  className="ring-offset-background focus:ring-ring ml-1 rounded-full outline-none focus:ring-2 focus:ring-offset-2"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(framework);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  disabled={disabled}
                  onClick={() => handleUnselect(framework)}
                >
                  {!disabled && <Cross2Icon />}
                </button>
              </Badge>
            );
          })}
          {loading && <LoadingIcon />}
          <CommandPrimitive.Input
            disabled={disabled || selectables.length === 0}
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder={selectables.length > 0 ? placeholder : undefined}
            className="placeholder:text-muted-foreground ml-2 flex-1 bg-transparent outline-none"
          />
          <Button
            type="button"
            disabled={disabled}
            onClick={() => {
              setSelected([]);
            }}
            variant="outline"
            size="sm"
            className="h-6"
          >
            Clear all
          </Button>
        </div>
      </div>
      {open && selectables.length > 0 && (
        <div className="relative mt-2">
          <div className="bg-popover text-popover-foreground animate-in absolute top-0 z-10 max-h-40 w-full overflow-auto rounded-md border shadow-md outline-none">
            <CommandList>
              <CommandGroup className="h-full overflow-auto">
                {selectables.map((selectable) => {
                  return (
                    <CommandItem
                      key={selectable.value}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={(value) => {
                        setInputValue('');
                        setSelected((prev) => [...prev, selectable]);
                      }}
                      className={'cursor-pointer'}
                    >
                      {selectable.label}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </div>
        </div>
      )}
    </Command>
  );
};
