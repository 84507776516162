import { toast } from '@kalos/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { ProjectSelect } from '../modules/Estimates/main';

export const Route = createFileRoute('/projects/estimates')({
  component: RouteComponent,
  loader(ctx) {
    const requiredPermissionId = 70;
    const userPermissions = ctx.context.authContext.user.permissionGroups.map((p) => p.id);
    const hasAllPermissions = userPermissions.includes(requiredPermissionId);
    if (!hasAllPermissions) {
      toast({
        title: 'You do not have permission to view this page',
        description: 'Please contact your administrator if you believe this is an error',
        variant: 'destructive',
      });
      throw redirect({ to: '/' });
    }
  },
  head(ctx) {
    return {
      meta: [{ title: 'Project Estimates' }],
    };
  },
});

function RouteComponent() {
  return <ProjectSelect />;
}
