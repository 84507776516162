import { createFileRoute } from '@tanstack/react-router';

import { EmployeeItemOptionsPage } from '../../modules/EmployeeItems/EmployeeItemOptions/EmployeeItemOptionsPage';

export const Route = createFileRoute('/employees/items/options')({
  component: RouteComponent,
});

function RouteComponent() {
  return <EmployeeItemOptionsPage />;
}
