import { createRouter } from '@tanstack/react-router';

import { Loader } from './modules/Loader/main';
import { routeTree } from './routeTree.gen';
import { queryClient } from './tools/queryClient';

// Create a new router instance
export const router = createRouter({
  routeTree,
  defaultNotFoundComponent(props) {
    return (
      <div className="flex h-screen">
        <div className="m-auto text-center">
          <h1>404</h1>
          <p>Page not found</p>
        </div>
      </div>
    );
  },
  defaultErrorComponent: ({ error }) => {
    console.error(error);
    return (
      // Uncaught ReferenceError: path is not defined
      <div>
        <div>We had an error, check the console for information</div>
        <p>{error.message}</p>
      </div>
    );
  },
  defaultPendingComponent: Loader,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  context: {
    queryClient,
    authContext: undefined!,
  },
  defaultViewTransition: true,
});
