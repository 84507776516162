import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@kalos/ui';
import { Headset, Phone } from 'lucide-react';
import { type ComponentProps, useCallback, useEffect, useRef } from 'react';
import React from 'react';
import { useStore } from 'zustand';

import {
  useProtectedSoftPhoneStore,
  useSoftPhoneStoreContext,
} from './context/SoftPhoneStore.context';
import { useSoftphoneSession } from './hooks/useSoftphoneSession';
import { useCallStore, useCallStoreBase } from './SoftPhoneWidget/components/CallStore';
import { softphoneLog } from './SoftPhoneWidget/components/utils';

const POPUP_WIDTH = 500;
const POPUP_HEIGHT = 700;
const POPUP_ROUTE = '/softphone/popup';

export const Softphone = () => {
  const { store, requiresAuth, isPending } = useSoftPhoneStoreContext();
  const popupRef = useRef<Window | null>(null);
  const remoteStream = useCallStore.use.remoteStream();
  const currentCall = useCallStore.use.currentCall();
  const setCurrentCall = useCallStore.use.setCurrentCall();
  useSoftphoneSession();

  // Listen for call state updates
  useEffect(() => {
    if (window.name === 'KalosSoftphone') return;

    const channel = new BroadcastChannel('kalos-softphone-state');

    const handleStateUpdate = (event: MessageEvent) => {
      if (event.data.type === 'CALL_STATE_UPDATE') {
        softphoneLog('Main window received call state update:', event.data);
        const { currentCall } = event.data.data;

        // Update the store state
        if (currentCall !== undefined) {
          setCurrentCall(currentCall);
        }
      }
    };

    channel.addEventListener('message', handleStateUpdate);
    return () => {
      channel.removeEventListener('message', handleStateUpdate);
      channel.close();
    };
  }, [setCurrentCall]);

  // Handle subscription reconnection
  useEffect(() => {
    if (!store || requiresAuth || isPending || window.name === 'KalosSoftphone') return;

    let visibilityTimeout: NodeJS.Timeout | null = null;
    let lastReconnectAttempt = 0;
    const RECONNECT_DEBOUNCE = 5000; // 5 seconds between reconnection attempts

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const now = Date.now();

        // Prevent rapid reconnection attempts
        if (now - lastReconnectAttempt < RECONNECT_DEBOUNCE) {
          softphoneLog('Skipping reconnection attempt - too soon since last attempt');
          return;
        }

        // Clear any existing timeout
        if (visibilityTimeout) {
          clearTimeout(visibilityTimeout);
          visibilityTimeout = null;
        }

        // Wait a short delay before attempting to reconnect to allow for browser stabilization
        visibilityTimeout = setTimeout(async () => {
          try {
            const session = store.getState().session;
            if (session?.sessionId) {
              softphoneLog('Tab became visible, ensuring event stream is active');
              lastReconnectAttempt = Date.now();
              await store.getState().ensureEventStream();
            }
          } catch (error) {
            softphoneLog('Error reconnecting event stream:', error);
          }
        }, 1000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Also check connection when the window regains focus
    const handleFocus = () => {
      if (document.visibilityState === 'visible') {
        handleVisibilityChange();
      }
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
      if (visibilityTimeout) {
        clearTimeout(visibilityTimeout);
      }
    };
  }, [store, requiresAuth, isPending]);

  const handleOpenSoftphone = useCallback(() => {
    // If auth is required, open the OAuth popup instead
    if (requiresAuth) {
      // Calculate center position for the popup
      const left = window.screen.width / 2 - POPUP_WIDTH / 2;
      const top = window.screen.height / 2 - POPUP_HEIGHT / 2;

      // Store return URL if needed
      sessionStorage.setItem('softphone_return_url', window.location.pathname);

      // Open OAuth popup
      const popup = window.open(
        `${window.location.origin}/softphone/oauth-callback`,
        'KalosSoftphoneAuth',
        `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},left=${left},top=${top},resizable=yes,scrollbars=yes`,
      );

      if (popup) {
        popupRef.current = popup;

        // Handle popup close
        const checkClosed = setInterval(() => {
          if (popup.closed) {
            clearInterval(checkClosed);
            popupRef.current = null;
          }
        }, 1000);
      }

      return;
    }

    // If popup exists, focus it
    if (popupRef.current && !popupRef.current.closed) {
      popupRef.current.focus();
      return;
    }

    // Calculate center position for the popup
    const left = window.screen.width / 2 - POPUP_WIDTH / 2;
    const top = window.screen.height / 2 - POPUP_HEIGHT / 2;

    // Open new popup
    const popup = window.open(
      `${window.location.origin}${POPUP_ROUTE}`,
      'KalosSoftphone',
      `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},left=${left},top=${top},resizable=yes,scrollbars=yes`,
    );

    if (popup) {
      popupRef.current = popup;

      // Handle popup close
      const checkClosed = setInterval(() => {
        if (popup.closed) {
          clearInterval(checkClosed);
          popupRef.current = null;
        }
      }, 1000);
    }
  }, [requiresAuth]);

  // Set up notification channel for incoming calls
  useEffect(() => {
    if (window.name === 'KalosSoftphone') return; // Don't set up in popup window

    const channel = new BroadcastChannel('kalos-softphone-notifications');

    const handleMessage = async (event: MessageEvent) => {
      if (event.data.type === 'INCOMING_CALL') {
        softphoneLog('Main window received incoming call notification:', event.data);

        // Open/focus softphone popup
        handleOpenSoftphone();

        // Wait for popup to initialize
        await new Promise((resolve) => setTimeout(resolve, 1500));

        // Clear the incoming call from the main window
        useCallStore.getState().setIncomingCall(null);
      }
    };

    channel.addEventListener('message', handleMessage);

    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, [handleOpenSoftphone]);

  // Handle popup window cleanup
  useEffect(() => {
    if (window.name === 'KalosSoftphone') {
      // When popup closes, clean up its state
      const handleUnload = () => {
        useCallStore.getState().handleReset({ reason: 'popup_closed' });
      };

      window.addEventListener('unload', handleUnload);
      return () => {
        window.removeEventListener('unload', handleUnload);
      };
    }
  }, []);

  const isConnecting = useCallStoreBase((state) => state.computed.isCallPendingVoice);

  let tooltipContent: React.ReactNode = 'SoftPhone';
  if (currentCall) {
    tooltipContent = (
      <div className="flex flex-col gap-2 text-sm">
        <p>Name: {currentCall.name}</p>
        <p>Phone: {currentCall.number}</p>
      </div>
    );
  }
  if (isConnecting) {
    tooltipContent = 'Connecting...';
  }

  // Don't render in popup window
  if (window.name === 'KalosSoftphone') return null;

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <SoftPhoneButton onClick={handleOpenSoftphone} />
          </TooltipTrigger>
          <TooltipContent className="rounded-full" sideOffset={10}>
            {tooltipContent}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {remoteStream && (
        <audio
          autoPlay
          ref={(ref) => {
            ref && (ref.srcObject = remoteStream);
          }}
        />
      )}
    </>
  );
};

const SoftPhoneButton = React.forwardRef<HTMLButtonElement, { onClick: () => void }>(
  ({ onClick }, ref) => {
    // Always show the button, regardless of auth status
    return (
      <Button
        size="icon"
        variant="secondary"
        className="fixed bottom-4 right-4 z-50 rounded-full"
        onClick={onClick}
        ref={ref}
      >
        <Headset size={15} />
      </Button>
    );
  },
);
SoftPhoneButton.displayName = 'SoftPhoneButton';

const ActivatedSoftPhoneButton = React.forwardRef<HTMLButtonElement, { onClick: () => void }>(
  ({ onClick }, ref) => {
    const store = useProtectedSoftPhoneStore();
    const session = useStore(store, (store) => store.session);

    const isPendingConnection = useCallStore((state) => state.computed.isCallPendingVoice);
    const isCallActive = useCallStore((state) => state.computed.isCallActive);

    let variant: ComponentProps<typeof Button>['variant'] = 'secondary';
    if (session) variant = 'default';
    if (isPendingConnection) variant = 'pulsating';
    if (isCallActive) variant = 'success';

    return (
      <Button
        variant={variant}
        size="icon"
        className="fixed bottom-4 right-4 z-50 rounded-full"
        onClick={onClick}
        ref={ref}
      >
        {isCallActive ? <Phone size={15} /> : <Headset size={15} />}
      </Button>
    );
  },
);
ActivatedSoftPhoneButton.displayName = 'ActivatedSoftPhoneButton';

export { Call } from './SoftPhoneWidget/components/Call';
