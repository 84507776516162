import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { CustomerDetailsPage } from '../../modules/CustomerDetails/main';

export const Route = createFileRoute('/customers/$customerId')({
  component: CustomerDetailsPage,
  params: z.object({
    customerId: z.coerce.number().positive(),
  }),
  validateSearch: z.object({
    showCreateProperty: z.boolean().optional(),
  }),
  head: () => ({
    meta: [
      {
        title: 'Customer Details',
      },
    ],
  }),
});
