import { type User } from '@kalos/kalos-rpc';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  useGetDefaultSearchFormValues,
  useSearchParamsForm,
} from '../../../../hooks/useFormWithSearchParams';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';

type EmployeeSearchFields = Pick<
  User,
  | 'firstname'
  | 'lastname'
  | 'employeeDepartmentID'
  | 'empTitle'
  | 'email'
  | 'cellphone'
  | 'phone'
  | 'id'
  | 'employeeItemName'
>;

export const employeesSearchScheme = z.object({
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  employeeDepartmentID: z.coerce.number().optional(),
  empTitle: z.string().optional(),
  email: z.string().optional(),
  cellphone: z.string().optional(),
  phone: z.string().optional(),
  id: z.coerce.number().optional(),
  employeeItemName: z.string().optional(),
}) satisfies ZodObjectWithModelKeys<EmployeeSearchFields>;

export type EmployeeSearchScheme = z.infer<typeof employeesSearchScheme>;
export const defaultEmployeeSearchValues: EmployeeSearchScheme = {
  firstname: '',
  lastname: '',
  cellphone: '',
  employeeDepartmentID: 0,
  empTitle: '',
  id: 0,
  email: '',
  phone: '',
  employeeItemName: '',
};

export const useEmployeeSearchForm = () => {
  return useSearchParamsForm({
    schema: employeesSearchScheme,
    defaultValues: useGetDefaultSearchFormValues(
      employeesSearchScheme,
      defaultEmployeeSearchValues,
    ),
    defaultResetValues: defaultEmployeeSearchValues,
  });
};

export const useEmployeeSearchFormContext = () => {
  return useFormContext<EmployeeSearchScheme>();
};
