import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { MemoList } from '../../modules/ComponentsLibrary/Memo';
export const Route = createFileRoute('/notes/$notesIds')({
  component: RouteComponent,
  params: z.object({
    notesIds: z.string(),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'Notes' }],
    };
  },
});

function RouteComponent() {
  const { notesIds } = Route.useParams();
  return <MemoList reportIds={notesIds} />;
}
