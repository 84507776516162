import { toast } from '@kalos/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { getUserQueryConfig } from '../../hooks/react-query/useUserQuery';
import { TimeOffReportPage } from '../../modules/ComponentsLibrary/Reports/pages/TimeoffReport';

export const Route = createFileRoute('/reports/time-off')({
  component: TimeOffReportPage,
  validateSearch: z.object({
    technicianId: z.coerce.number().positive(),
    year: z.coerce.number().positive(),
  }),
  loaderDeps(opts) {
    return {
      technicianId: opts.search.technicianId,
    };
  },
  async loader(ctx) {
    try {
      await ctx.context.queryClient.ensureQueryData(
        getUserQueryConfig({
          id: ctx.deps.technicianId,
          isActive: 1,
        }),
      );
    } catch {
      toast({
        title: 'Error',
        description: 'Failed to load technician data',
        variant: 'destructive',
      });
      throw redirect({
        to: '/reports',
      });
    }
  },
});
