import { toast } from '@kalos/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { PerDiemManager } from '../../modules/ComponentsLibrary/PerDiemManager';

export const Route = createFileRoute('/payroll/manage-perdiem')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Manage Per Diem' }],
    };
  },
  loader(ctx) {
    const isManager = ctx.context.authContext.user.permissionGroups.find(
      (group) => group.name === 'Manager',
    );
    if (!isManager) {
      toast({
        title: 'You are not authorized to access this page',
        description: 'Please contact your administrator if you think this is an error',
        variant: 'destructive',
      });
      return redirect({ to: '/payroll' });
    }
  },
});

function RouteComponent() {
  return <PerDiemManager />;
}
