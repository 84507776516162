import { createFileRoute } from '@tanstack/react-router';

import { Reports } from '../../modules/ComponentsLibrary/Reports';

export const Route = createFileRoute('/reports/')({
  component: Reports,
  head(ctx) {
    return {
      meta: [{ title: 'Reports' }],
    };
  },
});
