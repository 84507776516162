'use no memo';
import { SpiffToolAdminAction, Task } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { type ComponentProps, type FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useGetLoadingColumns } from '../../../components/utils';
import { useAuth } from '../../../context/AuthContext';
import {
  useSpiffAdminActionDeleteMutation,
  useTaskUpdateMutation,
} from '../../../hooks/react-query/useTaskClientServiceQuery';
import { formatDate, timestamp } from '../../../tools/helpers';
import { getFieldMaskFromDirtyField } from '../EmployeeDepartments/utils';
import { Tooltip as TooltipOld } from '../Tooltip';
import { SpiffExtendedForm } from './SpiffExtendedForm';
import { SPIFF_TOOL_STATUSES } from './SpiffToolAdminActionForm/constants';
import { SpiffToolAdminActionCreateFormDialog } from './SpiffToolAdminActionForm/SpiffToolAdminActionCreateFormDialog';
import { SpiffToolAdminActionEditFormDialog } from './SpiffToolAdminActionForm/SpiffToolAdminActionEditFormDialog';
import { ToolExtendedForm } from './ToolExtendedForm';

const staticActions = Array.from({ length: 3 }, (_, idx) =>
  SpiffToolAdminAction.create({ id: idx }),
);
interface Props {
  type: 'Spiff' | 'Tool';
  onClose: () => void;
  onSave: () => void;
  onStatusChange: () => void;
  data: Task;
  loading: boolean;
  cancelLabel?: string;
  statusEditing?: SpiffToolAdminAction;
  disableForm?: boolean;
}

export const SpiffActionsList: FC<{
  actions: SpiffToolAdminAction[];
}> = ({ actions }) => {
  if (actions.length === 0) return null;
  //const { status, reason, reviewedBy } = actions[0];
  const status = actions[0].status;
  const reason = actions[0].reason;
  const reviewedBy = actions[0].reviewedBy;
  const config = SPIFF_TOOL_STATUSES.find((el) => el.value === status.toString());
  return (
    <TooltipOld
      content={
        <>
          <strong>Status:</strong> {config?.label} <br />
          <strong>Reviewed By:</strong> {reviewedBy} <br />
          <strong>Reason:</strong> {reason}
        </>
      }
    >
      <div>{config?.label}</div>
    </TooltipOld>
  );
};

export const SpiffToolLogEdit: FC<Props> = ({
  type,
  data: spiffOrTool,
  onSave,
  onClose,
  cancelLabel,
  onStatusChange,
  loading: loadingInitial,
  statusEditing: statusEditingInitial,
  disableForm,
}) => {
  const auth = useAuth();
  const loggedUser = auth.user;
  const managerPermission = useMemo(
    () => loggedUser.permissionGroups.find((el) => el.name === 'Manager'),
    [loggedUser],
  );

  const [saving, setSaving] = useState<boolean>(false);

  const taskUpdateMutation = useTaskUpdateMutation();

  const handleSaveSpiff = useCallback<ComponentProps<typeof SpiffExtendedForm>['onSubmit']>(
    async ({ data, dirtyFields }) => {
      if (Object.keys(dirtyFields).length) {
        setSaving(true);
        const updateReq = Task.create({
          id: spiffOrTool.id,
          ...data,
          timeDue: timestamp(true, data.timeDue),
          datePerformed: timestamp(true, data.datePerformed),
          spiffJobNumber: data.spiffJobNumber.toString(),
          fieldMask: getFieldMaskFromDirtyField(dirtyFields),
        });
        try {
          await taskUpdateMutation.mutateAsync(updateReq);
          onSave();
        } catch {
          toast({
            variant: 'destructive',
            title: 'Error updating Spiff',
          });
        } finally {
          setSaving(false);
        }
      }
    },
    [onSave, spiffOrTool.id, taskUpdateMutation],
  );

  const handleSaveTool = useCallback<ComponentProps<typeof ToolExtendedForm>['onSubmit']>(
    async ({ data, dirtyFields }) => {
      if (Object.keys(dirtyFields).length) {
        setSaving(true);
        const updateReq = Task.create({
          id: spiffOrTool.id,
          ...data,
          toolpurchaseDate: timestamp(true, data.toolpurchaseDate),
          fieldMask: getFieldMaskFromDirtyField(dirtyFields),
        });
        try {
          await taskUpdateMutation.mutateAsync(updateReq);
          onSave();
        } catch {
          toast({
            variant: 'destructive',
            title: 'Error updating Spiff',
          });
        } finally {
          setSaving(false);
        }
      }
    },
    [onSave, spiffOrTool.id, taskUpdateMutation],
  );

  const confirm = useConfirm();
  const deleteAdminTaskActionMutation = useSpiffAdminActionDeleteMutation();
  const handleDeleteStatus = useCallback(
    async (statusToDelete: SpiffToolAdminAction) => {
      if (!(await confirm('Are you sure you want to delete this status?'))) {
        return;
      }

      await deleteAdminTaskActionMutation.mutateAsync(statusToDelete);
      onStatusChange();
    },
    [confirm, deleteAdminTaskActionMutation, onStatusChange],
  );

  const trimJobNUmber = useCallback((s: string) => {
    if (s.includes('-')) {
      return s.split('-')[1];
    } else {
      return s;
    }
  }, []);

  const columns = useMemo<ColumnDef<SpiffToolAdminAction>[]>(
    () => [
      {
        accessorKey: 'dateProcessed',
        header(props) {
          return <DataTableColumnHeader title="Date" column={props.column} />;
        },
        meta: {
          className: 'w-24',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {formatDate(props.row.original.decisionDate)}
            </div>
          );
        },
      },
      {
        accessorKey: 'reviewedBy',
        header(props) {
          return <DataTableColumnHeader title="Reviewed By" column={props.column} />;
        },
        meta: {
          className: 'w-36',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.reviewedBy}
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header(props) {
          return <DataTableColumnHeader title="Status" column={props.column} />;
        },
        meta: {
          className: 'w-32',
        },
        cell(props) {
          const config = SPIFF_TOOL_STATUSES.find(
            (el) => el.value === props.row.original.status.toString(),
          );
          return <div className={props.column.columnDef.meta?.className}>{config?.label}</div>;
        },
      },
      {
        accessorKey: 'reason',
        header(props) {
          return <DataTableColumnHeader title="Reason" column={props.column} />;
        },
        meta: {
          className: 'w-52',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.reason}
            </div>
          );
        },
      },
      {
        accessorKey: 'actions',
        header(props) {
          return <DataTableColumnHeader title="Actions" column={props.column} />;
        },
        meta: {
          className: 'w-24 flex items-center gap-2',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <TooltipProvider>
                <Tooltip>
                  <SpiffToolAdminActionEditFormDialog
                    original={props.row.original}
                    parentTask={spiffOrTool}
                    trigger={
                      <TooltipTrigger asChild>
                        <Button size="icon" variant="outline">
                          <Pencil1Icon />
                        </Button>
                      </TooltipTrigger>
                    }
                  />
                  <TooltipContent>Edit</TooltipContent>
                </Tooltip>

                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={() => handleDeleteStatus(props.row.original)}
                      size="icon"
                      variant="outline"
                    >
                      <TrashIcon />
                    </Button>
                  </TooltipTrigger>

                  <TooltipContent>Delete</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        },
      },
    ],
    [spiffOrTool, handleDeleteStatus],
  );

  const loadingColumns = useGetLoadingColumns(columns);

  const statusTable = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    columns: loadingInitial ? loadingColumns : columns,
    data: loadingInitial ? staticActions : spiffOrTool.actions,
    enableSorting: false,
    getRowId: (row) => row.id.toString(),
  });

  const spiffExtendedFormDefaultValues = useMemo<
    ComponentProps<typeof SpiffExtendedForm>['defaultValues']
  >(
    () => ({
      briefDescription: spiffOrTool.briefDescription,
      datePerformed: new Date(spiffOrTool.datePerformed),
      spiffTypeId: spiffOrTool.spiffTypeId,
      spiffToolId: spiffOrTool.spiffToolId,
      referenceUrl: spiffOrTool.referenceUrl,
      referenceNumber: spiffOrTool.referenceNumber,
      timeDue: new Date(spiffOrTool.timeDue),
      spiffAmount: spiffOrTool.spiffAmount,
      spiffJobNumber: Number(trimJobNUmber(spiffOrTool.spiffJobNumber)),
    }),
    [
      spiffOrTool.briefDescription,
      spiffOrTool.datePerformed,
      spiffOrTool.referenceNumber,
      spiffOrTool.referenceUrl,
      spiffOrTool.spiffAmount,
      spiffOrTool.spiffJobNumber,
      spiffOrTool.spiffToolId,
      spiffOrTool.spiffTypeId,
      spiffOrTool.timeDue,
      trimJobNUmber,
    ],
  );

  const toolExtendedFormDefaultValues = useMemo<
    ComponentProps<typeof ToolExtendedForm>['defaultValues']
  >(
    () => ({
      briefDescription: spiffOrTool.briefDescription,
      spiffToolId: spiffOrTool.spiffToolId,
      toolpurchaseCost: spiffOrTool.toolpurchaseCost,
      toolpurchaseDate: new Date(spiffOrTool.toolpurchaseDate),
    }),
    [
      spiffOrTool.briefDescription,
      spiffOrTool.spiffToolId,
      spiffOrTool.toolpurchaseCost,
      spiffOrTool.toolpurchaseDate,
    ],
  );

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  useEffect(() => {
    if (statusEditingInitial) {
      setIsCreateDialogOpen(true);
    }
  }, [statusEditingInitial]);

  const handleCreateDialogSave = useCallback(() => {
    setIsCreateDialogOpen(false);
    onStatusChange();
  }, [onStatusChange]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between bg-gray-200 p-4">
        <h2 className="text-xl font-bold">{`${type === 'Spiff' ? 'Spiff' : 'Tool Purchase'} Request`}</h2>
        <div className="flex items-center gap-2">
          {onClose && (
            <Button variant="outline" onClick={onClose}>
              {cancelLabel ? cancelLabel : 'Close'}
            </Button>
          )}
          <Button
            type="submit"
            disabled={saving || disableForm}
            form="spiffToolLogEditForm"
            isLoading={saving}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="px-4">
        {type === 'Spiff' && (
          <SpiffExtendedForm
            formId="spiffToolLogEditForm"
            defaultValues={spiffExtendedFormDefaultValues}
            disableSpiffAmountField={spiffOrTool.payrollProcessed === true ? true : false}
            onSubmit={handleSaveSpiff}
            disabled={saving || disableForm}
          />
        )}
        {type === 'Tool' && (
          <ToolExtendedForm
            formId="spiffToolLogEditForm"
            defaultValues={toolExtendedFormDefaultValues}
            disabled={saving}
            onSubmit={handleSaveTool}
          />
        )}
      </div>

      <div className="flex items-center justify-between bg-gray-200 p-4">
        <h2 className="text-xl font-bold">Status</h2>
        <div>
          <SpiffToolAdminActionCreateFormDialog
            parentTask={spiffOrTool}
            trigger={
              <Button
                disabled={saving || !managerPermission}
                onClick={() => setIsCreateDialogOpen(true)}
              >
                Approve/Reject/Revoke
              </Button>
            }
            onSave={handleCreateDialogSave}
            open={isCreateDialogOpen}
          />
        </div>
      </div>

      <div className="max-w-[calc(100vw-16px)] flex-1 overflow-auto pb-10 sm:pb-0">
        <DataTable table={statusTable} />
      </div>
    </div>
  );
};
