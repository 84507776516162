import { BaseClient } from '../../BaseClient';
import { Project, ProjectList } from '../../compiled-protos/projects_core';
import { ProjectsCoreServiceClient } from '../../compiled-protos/projects_core.client';

class ProjectClient extends BaseClient {
  self: ProjectsCoreServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ProjectsCoreServiceClient(this.transport);
  }

  public async Create(req: Project) {
    let res = Project.create();
    try {
      res = await this.self.createProject(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
    }
    return res;
  }

  public async Get(req: Project) {
    try {
      const res = await this.self.getProject(req, this.getMetaDataWithoutCache()).response;
      
      return res;
    } catch(err) {
      console.log(err)
      return null
    }
  }

  public async Update(req: Project) {
    let res = Project.create();
    try {
      res = await this.self.updateProject(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
    }
    return res;
  }

  public async BatchGet(req: Project) {
    let res = ProjectList.create();
    try {
      res = await this.self.batchGetProject(req, this.getMetaDataWithoutCache()).response;
    } catch (err) {
      console.log(err);
    }
    return res;
  }
}

export { Project, ProjectList, ProjectClient };
