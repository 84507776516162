import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { MemoPreview } from '../modules/ComponentsLibrary/Memo/components/MemoPreview';

export const Route = createFileRoute('/memoPreview/$memoId')({
  component: RouteComponent,
  params: z.object({
    memoId: z.coerce.number(),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'Field Note Preview' }],
    };
  },
});

function RouteComponent() {
  const { memoId } = Route.useParams();
  return <MemoPreview memoId={memoId} />;
}
