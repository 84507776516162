import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { AdvancedSearch } from '../modules/ComponentsLibrary/AdvancedSearch';
import { type Kind } from '../modules/ComponentsLibrary/AdvancedSearch/constants';
import { contractsSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/ContractsSearch/utils';
import { customerSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/CustomerSearch/utils';
import { propertiesSearchScheme } from '../modules/ComponentsLibrary/AdvancedSearch/PropertiesSearch/utils';

const searchKinds = ['customers', 'properties', 'contracts'] satisfies Kind[];

export const Route = createFileRoute('/search')({
  component: RouteComponent,
  validateSearch: z
    .discriminatedUnion('kind', [
      z.object({
        kind: z.literal('properties' satisfies Kind).catch('properties'),
        ...propertiesSearchScheme.shape,
      }),
      z.object({
        kind: z.literal('contracts' satisfies Kind).catch('contracts'),
        ...contractsSearchScheme.shape,
      }),
      z.object({
        kind: z.literal('customers' satisfies Kind).catch('customers'),
        ...customerSearchScheme.shape,
      }),
    ])
    .catch({ kind: searchKinds[0] }),
});

function RouteComponent() {
  return <AdvancedSearch title="Search" kinds={searchKinds} editableCustomers editableProperties />;
}
