import { createFileRoute } from '@tanstack/react-router';

import Transaction from '../modules/Transactions/main';

export const Route = createFileRoute('/transactionAdmin')({
  component: RouteComponent,
});

function RouteComponent() {
  return <Transaction />;
}
