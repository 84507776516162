import { zodResolver } from '@hookform/resolvers/zod';
import { type User } from '@kalos/kalos-rpc';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { SHIRT_SIZES } from '../../../../../constants';
import { useUserQuery } from '../../../../../hooks/react-query/useUserQuery';
import { requiredPhoneNumberSchema } from '../../../../../tools/schemas';

// Sector options: NA(0), Residential(1), Commercial(2)
export const EMPLOYEE_SECTORS = [
  { label: 'N/A', value: 0 },
  { label: 'Residential', value: 1 },
  { label: 'Commercial', value: 2 },
] as const;

export type EmployeeSector = (typeof EMPLOYEE_SECTORS)[number]['value'];

const hasW9OrCSRPermission = (user: User) => {
  const hasPermission = user.permissionGroups.some(
    (group) => group.id === 64 || group.id === 43, // W9 or CSR permission group IDs
  );
  console.log('Has W9 or CSR permission:', hasPermission);
  return hasPermission;
};

const employeeFormSchema = (employee: User) => {
  const hasW9OrCSR = hasW9OrCSRPermission(employee);
  return z.object({
    id: z.number().optional(),
    firstname: z.string().min(1, 'Required'),
    lastname: z.string().min(1, 'Required'),
    address: hasW9OrCSR ? z.string().optional() : z.string().min(1, 'Required'),
    city: z.string(),
    zip: hasW9OrCSR ? z.string().optional() : z.string().min(1, 'Required'),
    state: hasW9OrCSR ? z.string().optional() : z.string(),
    empTitle: z.string(),
    employeeDepartmentID: z.number().min(1, 'Please select segment'),
    managedBy: z.number(),
    empBadgeId: z.number(),
    phone: hasW9OrCSR ? z.string().optional() : requiredPhoneNumberSchema,
    cellphone: z.string(),
    ext: z.string().optional(),
    toolFund: z.number().nonnegative(),
    email: z.string().email(),
    phoneEmail: z.string().optional(),
    serviceCalls: z.number(),
    isAdmin: z.number(),
    paidServiceCallStatus: z.number(),
    showBilling: z.number(),
    isOfficeStaff: z.number(),
    isHvacTech: z.number(),
    techAssist: z.number(),
    isColorMute: z.number(),
    hireDate: z.date(),
    overrideMonth: z.coerce.number().optional(),
    pantSize: z.string(),
    shirtSize: z.enum(SHIRT_SIZES).optional(),
    ptoValue: z.number().optional(),
    image: z.any().optional(),
    // New fields
    userSector: z.number().default(0), // NA: 0, Residential: 1, Commercial: 2
    userJobType: z.string().default(''), // CSV list of job type IDs
  });
};

export type EmployeeFormType = z.infer<ReturnType<typeof employeeFormSchema>>;

const defaultFormValues: EmployeeFormType = {
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  zip: '',
  state: '',
  empTitle: '',
  employeeDepartmentID: 0,
  managedBy: 0,
  empBadgeId: 0,
  phone: '',
  cellphone: '',
  ext: '',
  toolFund: 0,
  email: '',
  phoneEmail: '',
  serviceCalls: 0,
  isAdmin: 0,
  paidServiceCallStatus: 0,
  showBilling: 0,
  isOfficeStaff: 0,
  isHvacTech: 0,
  techAssist: 0,
  isColorMute: 0,
  hireDate: new Date(),
  overrideMonth: undefined,
  ptoValue: 0,
  image: undefined,
  pantSize: '',
  shirtSize: undefined,
  // New fields with defaults
  userSector: 0, // Default to NA
  userJobType: '', // Default to empty CSV
};

export const getEmployeeFormDefaults = ({
  defaultValues,
}: {
  defaultValues?: Partial<EmployeeFormType>;
}): EmployeeFormType => {
  return {
    ...defaultFormValues,
    ...defaultValues,
  };
};

export const useEmployeeForm = ({
  defaultValues,
  disabled,
  employee,
}: {
  defaultValues: Partial<EmployeeFormType>;
  disabled?: boolean;
  employee: User;
}) => {
  const userQuery = useUserQuery({
    filters: {
      id: employee.id,
    },
    enabled: !!employee.id,
  });

  return useForm<EmployeeFormType>({
    resolver: zodResolver(employeeFormSchema(userQuery.data || employee)),
    mode: 'onSubmit',
    defaultValues: getEmployeeFormDefaults({ defaultValues }),
    disabled,
  });
};
