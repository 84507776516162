import { createFileRoute } from '@tanstack/react-router';

import { MemoList } from '../../modules/ComponentsLibrary/Memo';

export const Route = createFileRoute('/notes/')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Notes' }],
    };
  },
});

function RouteComponent() {
  return <MemoList />;
}
