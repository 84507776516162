import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { PermitGroupDetailsPage } from '../modules/Permits/PermitsAdminPage/PermitGroupDetails';

export const Route = createFileRoute('/permitAdmin/$permitGroupId')({
  component: PermitGroupDetailsPage,
  params: z.object({
    permitGroupId: z.coerce.number().min(1),
  }),
});
