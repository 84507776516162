import { type InternalResource } from '@kalos/kalos-rpc';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  useGetDefaultSearchFormValues,
  useSearchParamsForm,
} from '../../../hooks/useFormWithSearchParams';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';

type InternalResourceSearchFields = Pick<InternalResource, 'tag' | 'name' | 'description'>;

export const internalResourceSearchScheme = z.object({
  tag: z.coerce.number().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
}) satisfies ZodObjectWithModelKeys<InternalResourceSearchFields>;

export type InternalResourceSearchSchemeType = z.infer<typeof internalResourceSearchScheme>;
export const defaultInternalResourceSearchValues: InternalResourceSearchSchemeType = {
  tag: 0,
  name: '',
  description: '',
};

export const useInternalResourceSearchForm = () => {
  return useSearchParamsForm({
    schema: internalResourceSearchScheme,
    defaultValues: useGetDefaultSearchFormValues(
      internalResourceSearchScheme,
      defaultInternalResourceSearchValues,
    ),
    defaultResetValues: defaultInternalResourceSearchValues,
  });
};

export const useInternalResourceSearchFormContext = () => {
  return useFormContext<InternalResourceSearchSchemeType>();
};
