import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { DownloadInvoice } from '../modules/DownloadInvoice/main';

export const Route = createFileRoute('/documents/invoices/$invoiceId')({
  component: RouteComponent,
  params: z.object({
    invoiceId: z.coerce.number().positive(),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'Invoice' }],
    };
  },
});

function RouteComponent() {
  const params = Route.useParams();
  return <DownloadInvoice invoiceId={params.invoiceId} />;
}
