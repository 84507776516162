import { Event, type Payment, type ServicesRendered } from '@kalos/kalos-rpc';
import Typography from '@mui/material/Typography';
import { clsx } from 'clsx';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  PAYMENT_STATUS_LIST_OPTIONS,
  PAYMENT_TYPE_LIST_OPTIONS,
  SERVICE_STATUSES,
} from '../../../../constants';
import { formatDateTimeDay } from '../../../../tools/helpers';
import { Field } from '../../Field';
import { PlainForm, type Schema } from '../../PlainForm';
import { type RequestError } from '../reducer';

const { COMPLETED, INCOMPLETE } = SERVICE_STATUSES;

interface Props {
  disabled: boolean;
  event: Event;
  servicesRendered: ServicesRendered[];
  showBilling: boolean;
  payments: Payment[];
  onInitSchema: (fields: string[]) => void;
  onChangeServices: (serviceItem: Event) => void;
  onChangePayment: (serviceItem: Event) => void;
  formErrors?: RequestError[];
}
type FomrErrorsProps = {
  errors: RequestError[];
};

const FormErrors: FC<FomrErrorsProps> = ({ errors }) => {
  return errors.length ? (
    <div>
      <h3>Please correct the following validation errors and try again.</h3>
      {errors.map((item, index) => (
        <div key={index}>
          <strong>{item.label}: </strong>
          <span>{item.message}</span>
        </div>
      ))}
    </div>
  ) : null;
};

export const Invoice: FC<Props> = ({
  event,
  servicesRendered,
  onInitSchema,
  showBilling,
  onChangeServices,

  payments,
  onChangePayment,
  formErrors,
}) => {
  const [initSchemaCalled, setInitSchemaCalled] = useState<boolean>(false);
  const transformData = useCallback((data: Event) => {
    data = Event.clone(data);
    const totalamountrow1 = data.totalamountrow1 == '' ? '0' : data.totalamountrow1;
    const totalamountrow2 = data.totalamountrow2 == '' ? '0' : data.totalamountrow2;
    const totalamountrow3 = data.totalamountrow3 == '' ? '0' : data.totalamountrow3;
    const totalamountrow4 = data.totalamountrow4 == '' ? '0' : data.totalamountrow4;

    const defaultPaidStatus = 'Pending';
    if (data.logPaymentStatus == '' || data.logPaymentStatus == undefined) {
      data.logPaymentStatus = defaultPaidStatus;
    }
    const discount = data.discount == '' ? '0' : data.discount;
    const total1 = parseFloat(totalamountrow1);
    const total2 = parseFloat(totalamountrow2);
    const total3 = parseFloat(totalamountrow3);
    const total4 = parseFloat(totalamountrow4);
    const discountPercent = parseFloat(discount);
    console.log('discount percentage', discountPercent / 100);
    const discountAmount = (
      (total1 + total2 + total3 + total4 + data.materialTotal) *
      (discountPercent / 100)
    ).toFixed(2);

    data.totalamountrow1 = total1.toString();
    data.totalamountrow2 = total2.toString();
    data.totalamountrow3 = total3.toString();
    data.totalamountrow4 = total4.toString();
    data.discount = discountPercent.toString();
    data.discountcost = discountAmount;

    return data;
  }, []);
  const [formKey, setFormKey] = useState<number>(0);
  const data = useMemo(() => transformData(event), [event, transformData]);

  const handleChangeServices = useCallback(
    (data: Event) => {
      onChangeServices(transformData(data));
    },
    [onChangeServices, transformData],
  );
  const handleChangePayment = useCallback(
    (data: Event) => {
      onChangePayment(data);
    },
    [onChangePayment],
  );
  const totalPaid = payments.reduce(
    (accumulator, currentValue) => accumulator + currentValue.amountCollected,
    0,
  );

  const handleCopyFromServicesRendered = useCallback(() => {
    const servicesRenderedNotes: string = servicesRendered
      .filter((status) => [COMPLETED, INCOMPLETE].includes(status.status))
      .map((service) =>
        [formatDateTimeDay(service.timeStarted), service.name, '-', service.serviceRendered].join(
          ' ',
        ),
      )
      .join('\n');
    data.notes = servicesRenderedNotes;
    data.fieldMask.push('Notes');
    onChangePayment(data);
    setFormKey(formKey + 1);
  }, [data, onChangePayment, setFormKey, formKey, servicesRendered]);

  const totalRemaining = (
    parseFloat(data.totalamountrow1 || '0') +
    parseFloat(data.totalamountrow2 || '0') +
    parseFloat(data.totalamountrow3 || '0') +
    +data.materialTotal +
    parseFloat(data.totalamountrow4 || '0') -
    (totalPaid + parseFloat(data.discountcost))
  ).toFixed(2);

  const SCHEMA: Schema<Event> = useMemo(
    () => [
      [
        {
          name: 'dateStarted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'dateEnded',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'timeStarted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'timeEnded',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'isCallback',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'isResidential',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'isLmpc',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'departmentId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logJobStatus',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'description',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logVersion',
          type: 'hidden',
          invisible: true,
        },

        {
          name: 'logNotes',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'id',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'diagnosticQuoted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logJobNumber',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'name',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'propertyId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logTechnicianAssigned',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'jobTypeId',
          type: 'hidden',
          invisible: true,
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'contractId',
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'contractNumber',
        },
        {
          name: 'jobSubtypeId',
          type: 'hidden',
          invisible: true,
        },

        {
          name: 'logPaymentType',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'fieldMask',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'amountQuoted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'callbackOriginalId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'highPriority',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'notes',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'propertyBilling',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logPo',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logPaymentType',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logPaymentStatus',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logBillingDate',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'soldBy',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'leadGeneratedBy',
          type: 'hidden',
          invisible: true,
        },
      ],
      [
        {
          label: 'Services Performed (1)',
          name: 'servicesperformedrow1',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Total Amount (1)',
          name: 'totalamountrow1',
          type: 'number',
          startAdornment: '$',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Services Performed (2)',
          name: 'servicesperformedrow2',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Total Amount (2)',
          name: 'totalamountrow2',
          type: 'number',
          startAdornment: '$',
          disabled: event.logJobStatus === 'Archived',
        },
      ],

      [
        {
          label: 'Services Performed (3)',
          name: 'servicesperformedrow3',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Total Amount (3)',
          name: 'totalamountrow3',
          type: 'number',
          startAdornment: '$',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Services Performed (4)',
          name: 'servicesperformedrow4',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Total Amount (4)',
          name: 'totalamountrow4',
          type: 'number',
          startAdornment: '$',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Material Used',
          name: 'materialUsed',
          disabled: true,
          multiline: true,
        },
      ],
      [
        {
          label: 'Material Total',
          name: 'materialTotal',
          type: 'number',
          startAdornment: '$',
          disabled: true,
        },
      ],
      [
        {
          content: <Field label="Payment" name="payment" type="number" startAdornment="$" />,
        },
        {},
      ],
      [
        {
          label: 'Discount',
          name: 'discount',
          type: 'number',
          endAdornment: '%',
          disabled: event.logJobStatus === 'Archived',
        },

        {
          label: 'Discount Amount',
          type: 'number',
          name: 'discountcost',
          startAdornment: '$',
          disabled: true,
        },
      ],
      [
        {
          content: (
            <Field
              label="Grand Total"
              type="number"
              name="totalamounttotal"
              startAdornment="$"
              disabled={true}
              value={(
                parseFloat(data.totalamountrow1 == '' ? '0' : data.totalamountrow1) +
                parseFloat(data.totalamountrow2 == '' ? '0' : data.totalamountrow2) +
                parseFloat(data.totalamountrow3 == '' ? '0' : data.totalamountrow3) +
                parseFloat(data.totalamountrow4 == '' ? '0' : data.totalamountrow4) +
                data.materialTotal -
                parseFloat(data.discountcost)
              ).toFixed(2)}
            />
          ),
        },
      ],
      [
        {
          content: (
            <Field
              label="Payments"
              name="payments"
              type="number"
              disabled={true}
              value={totalPaid}
              startAdornment="$"
            />
          ),
        },
      ],
      [
        {
          content: (
            <Field
              label="Remaining due"
              type="number"
              name="remainingDue"
              value={totalRemaining}
              startAdornment="$"
            />
          ),
        },
        {},
      ],
    ],
    [
      data.totalamountrow1,
      data.totalamountrow2,
      data.totalamountrow3,
      data.totalamountrow4,
      totalPaid,
      event.logJobStatus,
      data.materialTotal,
      data.discountcost,
      totalRemaining,
    ],
  );
  const SCHEMA_PART_2: Schema<Event> = useMemo(
    () => [
      [{}],
      [
        {
          name: 'dateStarted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'dateEnded',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'timeStarted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'timeEnded',
          type: 'hidden',
          invisible: true,
        },
        { name: 'contractId', type: 'hidden', invisible: true },
        {
          name: 'contractNumber',
          type: 'hidden',
          invisible: true,
        },

        {
          name: 'isCallback',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'isResidential',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'isLmpc',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'departmentId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logJobStatus',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'description',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'soldBy',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'leadGeneratedBy',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logVersion',
          type: 'hidden',
          invisible: true,
        },

        {
          name: 'logNotes',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'id',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'diagnosticQuoted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logJobNumber',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'name',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'propertyId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logTechnicianAssigned',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'jobTypeId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'jobSubtypeId',
          type: 'hidden',
          invisible: true,
        },

        {
          name: 'logPaymentType',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'amountQuoted',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'callbackOriginalId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'fieldMask',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'highPriority',
          type: 'hidden',
          invisible: true,
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow1',
        },
        {
          name: 'totalamountrow1',
          type: 'hidden',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow2',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'totalamountrow2',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow3',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'totalamountrow3',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow4',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'totalamountrow4',
        },

        {
          name: 'materialUsed',
          type: 'hidden',
          invisible: true,
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'materialTotal',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'discount',
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'discountcost',
        },
      ],
      [
        {
          label: 'Billing Date',
          name: 'logBillingDate',
          type: 'date',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Payment Type',
          name: 'logPaymentType',
          options: PAYMENT_TYPE_LIST_OPTIONS,
          type: 'text',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Payment Status',
          name: 'logPaymentStatus',
          invisible: showBilling ? false : true,
          options: PAYMENT_STATUS_LIST_OPTIONS,
          type: 'text',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Tracking Number',
          name: 'logPo',
          type: 'text',
          validationOnSave(value) {
            if (value.length > 255) return 'The Tracking Number cannot exceed 255 characters';
            return '';
          },
          disabled: event.logJobStatus === 'Archived',
        },
      ],

      [
        {
          label: 'Use Property-level Billing?',
          name: 'propertyBilling',
          type: 'checkbox',
          disabled: event.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Invoice Notes',
          name: 'notes',
          disabled: event.logJobStatus === 'Archived',
          multiline: true,
          actions: [
            {
              label: 'Copy from Services Rendered',
              variant: 'text',
              onClick: handleCopyFromServicesRendered,
            },
          ],
          actionsInLabel: true,
        },
      ],
    ],
    [handleCopyFromServicesRendered, showBilling, event.logJobStatus],
  );
  useEffect(() => {
    if (!initSchemaCalled) {
      setInitSchemaCalled(true);
      const fields = SCHEMA.map((item) =>
        item.map(({ name }) => name).filter((name) => name),
      ).reduce((aggr, item) => [...aggr, ...item], []);
      onInitSchema(fields as string[]);
    }
  }, [initSchemaCalled, setInitSchemaCalled, onInitSchema, SCHEMA]);

  return (
    <div>
      {formErrors?.length ? (
        <Typography className={clsx('PlainFormError Invoice-Errors')} component="div">
          <FormErrors errors={formErrors} />
        </Typography>
      ) : undefined}
      <div
        style={{
          display: 'inline-block',
          width: '49%',
          verticalAlign: 'top',
        }}
      >
        <PlainForm
          key={'InvoicePart1'}
          schema={SCHEMA}
          data={data}
          onChange={handleChangeServices}
          checkForDataPropChanges={true}
        />
      </div>
      <div
        style={{
          display: 'inline-block',
          width: '49%',
          verticalAlign: 'top',
          whiteSpace: 'normal',
          maxWidth: '49%',
        }}
      >
        <PlainForm
          compact={true}
          key={'InvoicePart2'}
          schema={SCHEMA_PART_2}
          data={data}
          onChange={handleChangePayment}
          checkForDataPropChanges={true}
        />
      </div>
    </div>
  );
};
