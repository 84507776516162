import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ProjectResourcesPage } from '../modules/ProjectResources';
import { projectResourceTabs } from '../modules/ProjectResources/constants';

export const Route = createFileRoute('/projectResources')({
  validateSearch: z.object({
    projectResourceTab: z.enum(projectResourceTabs).catch(projectResourceTabs[0]),
  }),
  component: ProjectResourcesPage,
  head(ctx) {
    return {
      meta: [{ title: 'Project Resources' }],
    };
  },
});
