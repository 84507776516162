import { createFileRoute } from '@tanstack/react-router';

import { EmployeeDepartments } from '../modules/ComponentsLibrary/EmployeeDepartments';

export const Route = createFileRoute('/departments')({
  component: RouteComponent,
  head(ctx) {
    return {
      meta: [{ title: 'Employee Directory' }],
    };
  },
});

function RouteComponent() {
  return <EmployeeDepartments />;
}
