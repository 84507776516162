import { useEffect } from 'react';
import { createStore, useStore } from 'zustand';

import { messageBus } from '../context/SoftphoneMessageBus';
import { useSoftPhoneStoreContext } from '../context/SoftPhoneStore.context';
import { softphoneLog } from '../SoftPhoneWidget/components/utils';

// Dummy store for when the real store isn't available
const dummyStore = createStore(() => ({
  session: null,
  isSessionLoading: false,
}));

export const useSoftphoneSession = () => {
  const { store, requiresAuth, isPending } = useSoftPhoneStoreContext();

  // Use the real store or fall back to dummy store
  const activeStore = store || dummyStore;

  const session = useStore(activeStore, (state) => state.session);
  const isSessionLoading = useStore(activeStore, (state) => state.isSessionLoading);

  // Handle session initialization and activation
  useEffect(() => {
    if (!store || requiresAuth || isPending) return;

    const isSoftphonePopup = window.name === 'KalosSoftphone';
    const state = store.getState();

    if (isSoftphonePopup && !state.session) {
      // Request session state from main window
      messageBus.broadcast({
        type: 'STATE_UPDATE',
        data: { requestState: true },
      });
    }

    // Only initialize session in main window if we don't have one and aren't loading
    if (!isSoftphonePopup && !state.session && !state.isSessionLoading) {
      softphoneLog('Main window: Creating session');
      void state.createSession({
        userId: state.client.userID,
        clientInfo: {
          appVersion: '1.0.0',
          deviceId: `${state.client.userID}-web-softphone`,
          platform: 'browser',
          deviceType: 'web',
        },
      });
    }
  }, [store, requiresAuth, isPending]);

  return {
    session: store ? session : null,
    isSessionLoading: store ? isSessionLoading : false,
    isReady: !!store && !requiresAuth && !isPending,
  };
};
