import { createFileRoute } from '@tanstack/react-router';

import { PerDiemComponent } from '../modules/ComponentsLibrary/PerDiem';

export const Route = createFileRoute('/perdiem')({
  component: RouteComponent,
});

function RouteComponent() {
  return <PerDiemComponent />;
}
