import { type GoToCall, type SoftphoneClient, type User } from '@kalos/kalos-rpc';
import { create } from 'zustand';
import { persist, type PersistOptions } from 'zustand/middleware';

import { createSelectors } from '../../../../tools/zustand';
import { messageBus } from '../../context/SoftphoneMessageBus';
import { cleanupRTC, monitorAudioLevels, softphoneLog } from './utils';

// Add constants for audio detection
const AUDIO_DETECTION_TIMEOUT = 10000; // 10 seconds to detect audio

export type Caller = User & {
  isResidential: boolean;
  isCommercial: boolean;
};
export type CurrentCall = GoToCall & { callId: string };

// Separate the state into persisted and non-persisted parts
type PersistedState = {
  currentCall: CurrentCall | null;
  incomingCall: { callId: string; fromNumber: string; callerName: string } | null;
  isCalling: boolean;
  isPendingSound: boolean;
  outboundNumber: string;
  currentCaller: Caller | null;
  lastSubscriptionError: string | null;
  retryAttempts: number;
  isSubscriptionActive: boolean;
};

type NonPersistedState = {
  peerConnection: RTCPeerConnection | null;
  remoteStream: MediaStream | null;
  localStream: MediaStream | null;
  onCallEndListeners: (() => void)[];
  subscriptionRetryTimeout: number | null;
};

type CallStoreActions = {
  setIncomingCall: (
    incomingCall: { callId: string; fromNumber: string; callerName: string } | null,
  ) => void;
  setCurrentCall: React.Dispatch<React.SetStateAction<CurrentCall | null>>;
  setCurrentCaller: (currentCaller: Caller | null) => void;
  setUpCallStreams: (args: {
    peerConnection: RTCPeerConnection | null;
    localStream: MediaStream;
  }) => void;
  setIsCalling: (isCalling: boolean) => void;
  setIsPendingSound: (isPendingSound: boolean) => void;
  setRemoteStream: (remoteStream: MediaStream | null) => void;
  setLocalStream: (localStream: MediaStream | null) => void;
  addOnCallEndListener: (listener: () => void) => void;
  handleMuteLocalStream: () => void;
  handleUnmuteLocalStream: () => void;
  handleReset: (arg: { reason: string }) => void;
  setOutboundNumber: (number: string) => void;
  handleCallEnd: (callId: string, sessionId: string, client: SoftphoneClient) => void;
  handleAnswerCall: (args: {
    sessionId: string;
    callId: string;
    client: SoftphoneClient;
  }) => Promise<void>;
  handleRejectCall: (args: {
    sessionId: string;
    callId: string;
    client: SoftphoneClient;
  }) => Promise<void>;
  computed: {
    isCallPendingVoice: boolean;
    isCallActive: boolean;
    isMuted: boolean;
  };
};

type CallStore = PersistedState & NonPersistedState & CallStoreActions;

type CallStorePersist = Pick<CallStore, keyof PersistedState>;

export const useCallStoreBase = create<CallStore>()(
  persist(
    (set, get) => ({
      // Persisted State
      currentCall: null,
      incomingCall: null,
      isCalling: false,
      isPendingSound: false,
      outboundNumber: '',
      currentCaller: null,
      lastSubscriptionError: null,
      retryAttempts: 0,
      isSubscriptionActive: false,

      // Non-persisted State
      peerConnection: null,
      eventStream: null,
      remoteStream: null,
      localStream: null,
      onCallEndListeners: [],
      currentSubscription: null,
      currentSubscriptionSessionId: null,
      subscriptionRetryTimeout: null,

      // Actions
      setIncomingCall: (incomingCall) => set({ incomingCall }),
      setCurrentCall: (currentCall) =>
        set({
          currentCall:
            typeof currentCall === 'function' ? currentCall(get().currentCall) : currentCall,
        }),
      setUpCallStreams: ({ peerConnection, localStream }) => {
        set({
          peerConnection,
          localStream,
        });
      },
      setIsCalling: (isCalling) => set({ isCalling }),
      setIsPendingSound: (isPendingSound) => set({ isPendingSound }),
      setRemoteStream: (remoteStream) => set({ remoteStream }),
      setLocalStream: (localStream) => set({ localStream }),
      addOnCallEndListener: (listener) =>
        set((state) => ({ onCallEndListeners: [...state.onCallEndListeners, listener] })),
      setOutboundNumber: (outboundNumber) => set({ outboundNumber }),

      handleCallEnd: async (callId, sessionId, client) => {
        try {
          softphoneLog('call store: handleCallEnd');
          await client.EndCall(callId, sessionId);
          const peerConnection = get().peerConnection;
          if (peerConnection) {
            cleanupRTC({ peerConnection });
            get().onCallEndListeners.forEach((listener) => listener());
          }
          set({
            peerConnection: null,
            onCallEndListeners: [],
            isPendingSound: false,
            isCalling: false,
            currentCall: null,
          });
        } catch (error) {
          softphoneLog('Error ending call:', error);
          // Still cleanup local state even if the server call fails
          set({
            peerConnection: null,
            onCallEndListeners: [],
            isPendingSound: false,
            isCalling: false,
            currentCall: null,
          });
        }
      },

      handleMuteLocalStream: () => {
        get()
          .localStream?.getTracks()
          .forEach((track) => {
            track.enabled = false;
          });
      },

      handleUnmuteLocalStream: () => {
        get()
          .localStream?.getTracks()
          .forEach((track) => {
            track.enabled = true;
          });
      },

      handleAnswerCall: async ({ callId, sessionId, client }) => {
        try {
          const state = get();
          // Check if we're already in a call
          if (state.currentCall || state.peerConnection) {
            throw new Error('Already in a call');
          }

          // Focus the softphone window first, before any async operations
          messageBus.broadcast({
            type: 'WINDOW_FOCUS_REQUEST',
          });
          window.focus();

          softphoneLog('Setting up call answer', { callId });

          // Set initial call state
          set({
            isCalling: true,
            isPendingSound: true, // Set this early so UI shows we're waiting for audio
          });

          // Create peer connection for incoming call
          const peerConnection = new RTCPeerConnection();
          const remoteStream = new MediaStream();

          // Set up audio handling with timeout
          const setupAudioHandling = (soundStream: MediaStream) => {
            if (!soundStream) {
              softphoneLog('Warning: Empty sound stream received');
              return;
            }

            const audioTracks = soundStream.getAudioTracks();
            if (audioTracks.length === 0) {
              softphoneLog('Warning: No audio tracks in stream');
              return;
            }

            softphoneLog('Setting up audio monitoring', {
              tracks: audioTracks.length,
              trackSettings: audioTracks.map((track) => track.getSettings()),
            });

            const [promise, cleanupFn] = monitorAudioLevels(soundStream);
            state.addOnCallEndListener(cleanupFn);

            // Set up timeout for audio detection
            const timeoutId = setTimeout(() => {
              softphoneLog('Audio detection timeout reached');
              // If we haven't detected audio by now, mark the call as active anyway
              const currentState = get();
              if (currentState.isPendingSound) {
                set({
                  isPendingSound: false,
                  isCalling: false,
                });
              }
            }, AUDIO_DETECTION_TIMEOUT);

            // Clean up timeout on success or failure
            state.addOnCallEndListener(() => clearTimeout(timeoutId));

            promise
              .then(() => {
                softphoneLog('Audio detected in stream');
                clearTimeout(timeoutId);
                // Update store state to reflect active call
                set({
                  isPendingSound: false,
                  isCalling: false,
                });

                const incomingCall = state.incomingCall;
                if (incomingCall?.callerName) {
                  const isResidential = incomingCall.callerName
                    .toLowerCase()
                    .includes('residential');
                  const isCommercial = incomingCall.callerName.toLowerCase().includes('commercial');
                  set({
                    currentCaller: {
                      firstname: incomingCall.callerName,
                      lastname: '',
                      phone: incomingCall.fromNumber,
                      isResidential,
                      isCommercial,
                    } as Caller,
                  });
                }
              })
              .catch((error: unknown) => {
                softphoneLog('Error monitoring audio:', error);
                clearTimeout(timeoutId);
                // Even if audio monitoring fails, we should still mark the call as connected
                set({
                  isPendingSound: false,
                  isCalling: false,
                });
              });
          };

          // Handle incoming tracks
          peerConnection.ontrack = (event) => {
            softphoneLog('Incoming call track received:', event);
            remoteStream.addTrack(event.track);
            set({ remoteStream });

            // Monitor each stream that comes in
            event.streams.forEach((stream, index) => {
              softphoneLog('Processing stream:', { index, streamId: stream.id });
              setupAudioHandling(stream);
            });
          };

          // Set up local media
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
          set({ localStream: stream });

          // Create and set local description
          const offer = await peerConnection.createOffer();
          await peerConnection.setLocalDescription(offer);

          if (!offer.sdp) throw new Error('SDP offer not found');

          // Handle call answer
          softphoneLog('Answering call with SDP offer');
          const callAnswer = await client.AnswerCall(callId, sessionId, offer.sdp);
          softphoneLog('Call answer response:', callAnswer);

          // Set remote description
          const remoteDesc = new RTCSessionDescription({
            type: 'answer',
            sdp: callAnswer.sdp,
          });

          await peerConnection.setRemoteDescription(remoteDesc);

          // Update store state
          set({
            peerConnection,
            currentCall: { ...callAnswer, callId },
            incomingCall: null,
            // Don't set isPendingSound here, it's managed by audio detection
          });
        } catch (error) {
          softphoneLog('Error answering call:', error);
          const pc = get().peerConnection;
          if (pc) {
            cleanupRTC({ peerConnection: pc });
          }
          // Reset all call-related state on error
          set({
            peerConnection: null,
            localStream: null,
            remoteStream: null,
            incomingCall: null,
            currentCall: null,
            isCalling: false,
            isPendingSound: false,
          });
          throw error;
        }
      },

      handleRejectCall: async ({ callId, sessionId, client }) => {
        const cleanupState = () => {
          set({
            incomingCall: null,
            peerConnection: null,
            localStream: null,
            remoteStream: null,
            currentCall: null,
            isCalling: false,
            isPendingSound: false,
          });
        };

        try {
          softphoneLog('Rejecting call', { callId, sessionId });
          await client.RejectCall(callId, sessionId);
          cleanupState();
          softphoneLog('Call rejected successfully');
        } catch (error) {
          softphoneLog('Error rejecting call:', error);
          cleanupState();
          throw error;
        }
      },

      handleReset: ({ reason }) => {
        softphoneLog('call store: handleReset, reason:', reason);
        const state = get();

        // Clean up RTC connection
        if (state.peerConnection) {
          cleanupRTC({ peerConnection: state.peerConnection });
          state.onCallEndListeners.forEach((listener) => listener());
        }

        // Reset all state
        set({
          peerConnection: null,
          isCalling: false,
          isPendingSound: false,
          currentCall: null,
          remoteStream: null,
          localStream: null,
          onCallEndListeners: [],
          outboundNumber: '',
          currentCaller: null,
          lastSubscriptionError: null,
          retryAttempts: 0,
        });
      },

      setCurrentCaller: (currentCaller) => set({ currentCaller }),

      computed: {
        get isCallPendingVoice() {
          return !!get().peerConnection && (get().isCalling || get().isPendingSound);
        },
        get isCallActive() {
          return !!get().peerConnection && !(get().isCalling || get().isPendingSound);
        },
        get isMuted() {
          return !!get()
            .localStream?.getTracks()
            .some((track) => !track.enabled);
        },
      },
    }),
    {
      name: 'kalos-softphone-store',
      storage: {
        getItem: async (name) => {
          try {
            const value = localStorage.getItem(name);
            if (!value) return null;
            return JSON.parse(value);
          } catch (err) {
            console.error('Error getting item from storage:', err);
            return null;
          }
        },
        setItem: async (name, value) => {
          try {
            localStorage.setItem(name, JSON.stringify(value));
          } catch (err) {
            console.error('Error setting item in storage:', err);
          }
        },
        removeItem: async (name) => {
          try {
            localStorage.removeItem(name);
          } catch (err) {
            console.error('Error removing item from storage:', err);
          }
        },
      },
      partialize: (state): CallStorePersist => ({
        currentCall: state.currentCall,
        incomingCall: state.incomingCall,
        isCalling: state.isCalling,
        isPendingSound: state.isPendingSound,
        outboundNumber: state.outboundNumber,
        currentCaller: state.currentCaller,
        lastSubscriptionError: state.lastSubscriptionError,
        retryAttempts: state.retryAttempts,
        isSubscriptionActive: state.isSubscriptionActive,
      }),
    } as PersistOptions<CallStore, CallStorePersist>,
  ),
);

export const useCallStore = createSelectors(useCallStoreBase);

// Set up event listeners for the store
messageBus.subscribe((message) => {
  switch (message.type) {
    case 'SOFTPHONE_EVENT': {
      const { type, metadata, callId, fromNumber, callerName } = message.data;

      // Handle incoming call
      if (type === 0 && metadata?.type === 'incoming') {
        if (!callId || !fromNumber) {
          softphoneLog('Invalid incoming call data:', message.data);
          return;
        }

        useCallStoreBase.setState({
          incomingCall: {
            callId,
            fromNumber,
            callerName: callerName || fromNumber,
          },
        });
      }

      // Handle call ended
      if (type === 0 && metadata?.state === 'ENDED') {
        const state = useCallStoreBase.getState();
        if (state.currentCall?.callId === callId) {
          state.handleReset({ reason: 'call_ended' });
        }
      }
      break;
    }

    case 'STREAM_ERROR': {
      softphoneLog('Stream error received:', message.data.error);
      // Clean up call state on stream error
      useCallStoreBase.getState().handleReset({ reason: 'stream_error' });
      break;
    }
  }
});
