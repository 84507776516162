// this files ts-ignore lines have been checked

import {
  type Contract,
  LodgingRequestClient,
  PendingBillingClient,
  type PerDiemRow,
  PermitClient,
  type PromptPaymentDetailsResponse,
  type Transaction,
  type Trip,
} from '@kalos/kalos-rpc';
import {
  ActivityLog,
  ActivityLogClient,
  ApiKey,
  ApiKeyClient,
  ClassCodeClient,
  ContractClient,
  ContractFrequencyClient,
  CreditCardClient,
  DeviceClient,
  DispatchClient,
  DocumentClient,
  EmailClient,
  EmployeeFunctionClient,
  EmployeeProfileClient,
  Event,
  EventAssignmentClient,
  EventClient,
  ExpendedClient,
  File,
  FileClient,
  FileTranscriptClient,
  FirstCallClient,
  GeolocationLogClient,
  GoogleUserClient,
  GroupClient,
  InternalDocumentClient,
  InventoryClient,
  InvoiceClient,
  JobSubtypeClient,
  JobTypeClient,
  JobTypeSubtypeClient,
  KalosChatClient,
  MaintenanceQuestionClient,
  MapClient,
  MaterialClient,
  MemoClient,
  MertricReportDataRequest,
  MetricsClient,
  NULL_TIME,
  PaidTimeOffClient,
  PaymentClient,
  PDFClient,
  PendingInvoiceTransactionClient,
  PerDiemClient,
  PerformanceReviewClient,
  ProjectClient,
  ProjectEventClient,
  PromptPaymentOverrideClient,
  PromptPaymentReportLine,
  PromptPaymentSummaryRequest,
  Property,
  PropertyClient,
  QuickBooksTrackingCodeClient,
  QuoteDocumentClient,
  QuoteLineClient,
  QuoteLinePartClient,
  QuotePartClient,
  QuotePhotoClient,
  QuoteUsedClient,
  ReadingClient,
  ReimbursementClient,
  ReportClient,
  S3Client,
  SamsaraClient,
  ServiceItemClient,
  ServicesRenderedClient,
  SpiffReportLine,
  SpiffToolAdminActionClient,
  StoredQuoteClient,
  SUBJECT_TAGS,
  SUBJECT_TAGS_TRANSACTIONS,
  TaskAssignmentClient,
  TaskClient,
  TaskEventClient,
  TimeoffReportRequest,
  TimeoffRequestClient,
  TimesheetDepartmentClient,
  TimesheetLineClient,
  TransactionAccountClient,
  TransactionAccountsReceivableClient,
  TransactionActivityClient,
  TransactionClient,
  TransactionDocument,
  TransactionDocumentClient,
  TransactionStatusClient,
  User,
  UserClient,
  UserGroupLinkClient,
  VehicleDriverClient,
  VendorClient,
} from '@kalos/kalos-rpc';
import { addDays, addMonths, format, isValid, parseISO } from 'date-fns';
import heic2any from 'heic2any';

import {
  BASE_URL,
  ENDPOINT,
  IRS_SUGGESTED_MILE_FACTOR,
  MAX_PAGES,
  MONTHS,
  OPTION_ALL,
} from '../constants';
import { type Option } from '../modules/ComponentsLibrary/Field';

export type SimpleFile = {
  key: string;
  bucket: string;
};
export type TimeoffRequestTypes = {
  [key: number]: string;
};

const FIRST_NAMES = [
  'Mark',
  'Jeanne',
  'Steven',
  'Chris',
  'Peter',
  'Jacob',
  'Eve',
  'Agatha',
  'Jane',
  'Adam',
  'Hugh',
  'Keanu',
  'Robert',
  'George',
];

const LAST_NAME_POSTFIXES = ['sven', 'sten', 'sky', 'ski', 'son', 'ston', 'stein'];

const JOB_TITLES = [
  'Developer',
  'Web Developer',
  'Engineer',
  'Admin',
  'Technician',
  'Finanse',
  'Operational',
  'Director',
];

export const getRandomFirstName = () => FIRST_NAMES[Math.floor(Math.random() * FIRST_NAMES.length)];

export const getRandomLastName = () =>
  getRandomFirstName() +
  LAST_NAME_POSTFIXES[Math.floor(Math.random() * LAST_NAME_POSTFIXES.length)];

export const getRandomName = () => `${getRandomFirstName()} ${getRandomLastName()}`;

export const getRandomAge = () => 18 + Math.floor(Math.random() * 50);

export const getRandomJobTitle = () => JOB_TITLES[Math.floor(Math.random() * JOB_TITLES.length)];

export const getRandomDigit = () => Math.floor(Math.random() * 10);

export const getRandomPhone = () =>
  `${getRandomDigits(3)}-${getRandomDigits(3)}-${getRandomDigits(3)}`;

export const getRandomNumber = (min: number, max: number) =>
  min + Math.floor(Math.random() * (max - min + 1));

export const getRandomDigits = (digits: number) =>
  +[...Array(digits)].map(() => getRandomDigit()).join('');

export const randomize = (values: (string | number)[]) =>
  values[Math.floor(Math.random() * values.length)];

export const TransactionDocumentClientService = new TransactionDocumentClient(ENDPOINT);
export const TaskAssignmentClientService = new TaskAssignmentClient(ENDPOINT);
export const TaskEventClientService = new TaskEventClient(ENDPOINT);
export const TransactionClientService = new TransactionClient(ENDPOINT);
export const DocumentClientService = new DocumentClient(ENDPOINT);
export const ReportClientService = new ReportClient(ENDPOINT);
export const TaskClientService = new TaskClient(ENDPOINT);
export const PDFClientService = new PDFClient(ENDPOINT);
export const PendingInvoiceTransactionClientService = new PendingInvoiceTransactionClient(ENDPOINT);
export const UserClientService = new UserClient(ENDPOINT);
export const PropertyClientService = new PropertyClient(ENDPOINT);
export const ContractClientService = new ContractClient(ENDPOINT);
export const ProjectEventClientService = new ProjectEventClient(ENDPOINT);
export const ContractFrequencyClientService = new ContractFrequencyClient(ENDPOINT);
export const ReimbursementClientService = new ReimbursementClient(ENDPOINT);

export const CreditCardClientService = new CreditCardClient(ENDPOINT);
export const EventClientService = new EventClient(ENDPOINT);
export const JobTypeClientService = new JobTypeClient(ENDPOINT);
export const JobSubtypeClientService = new JobSubtypeClient(ENDPOINT);
export const JobTypeSubtypeClientService = new JobTypeSubtypeClient(ENDPOINT);
export const ActivityLogClientService = new ActivityLogClient(ENDPOINT);
export const PaidTimeOffClientService = new PaidTimeOffClient(ENDPOINT);
export const EmployeeFunctionClientService = new EmployeeFunctionClient(ENDPOINT);
export const PerDiemClientService = new PerDiemClient(ENDPOINT);
export const MapClientService = new MapClient(ENDPOINT);
export const ServicesRenderedClientService = new ServicesRenderedClient(ENDPOINT);
export const StoredQuoteClientService = new StoredQuoteClient(ENDPOINT);
export const QuotePartClientService = new QuotePartClient(ENDPOINT);
export const QuoteLinePartClientService = new QuoteLinePartClient(ENDPOINT);
export const QuoteLineClientService = new QuoteLineClient(ENDPOINT);
export const QuotePhotoClientService = new QuotePhotoClient(ENDPOINT);

export const TimesheetDepartmentClientService = new TimesheetDepartmentClient(ENDPOINT);
export const ClassCodeClientService = new ClassCodeClient(ENDPOINT);

export const MetricsClientService = new MetricsClient(ENDPOINT);
export const SpiffToolAdminActionClientService = new SpiffToolAdminActionClient(ENDPOINT);
export const GroupClientService = new GroupClient(ENDPOINT);
export const UserGroupLinkClientService = new UserGroupLinkClient(ENDPOINT);
export const InternalDocumentClientService = new InternalDocumentClient(ENDPOINT);
export const S3ClientService = new S3Client(ENDPOINT);
export const FileClientService = new FileClient(ENDPOINT);
export const TimeoffRequestClientService = new TimeoffRequestClient(ENDPOINT);
export const TimesheetLineClientService = new TimesheetLineClient(ENDPOINT);
export const TransactionAccountsReceivableClientService = new TransactionAccountsReceivableClient(
  ENDPOINT,
);
export const FileTranscriptClientService = new FileTranscriptClient(ENDPOINT);

export const PendingBillingClientService = new PendingBillingClient(ENDPOINT);

export const QuickBooksTrackingCodeClientService = new QuickBooksTrackingCodeClient(ENDPOINT);

export const ApiKeyClientService = new ApiKeyClient(ENDPOINT);
export const DispatchClientService = new DispatchClient(ENDPOINT);
export const TransactionActivityClientService = new TransactionActivityClient(ENDPOINT);
export const EmailClientService = new EmailClient(ENDPOINT);
export const TransactionAccountClientService = new TransactionAccountClient(ENDPOINT);
export const EventAssignmentClientService = new EventAssignmentClient(ENDPOINT);
export const PaymentClientService = new PaymentClient(ENDPOINT);

export const InvoiceClientService = new InvoiceClient(ENDPOINT);
export const FirstCallClientService = new FirstCallClient(ENDPOINT);
export const VendorClientService = new VendorClient(ENDPOINT);
export const PerformanceReviewClientService = new PerformanceReviewClient(ENDPOINT);
export const SamsaraClientService = new SamsaraClient(ENDPOINT);
export const KalosChatClientService = new KalosChatClient(ENDPOINT);
export const GoogleUserClientService = new GoogleUserClient(ENDPOINT);
export const ServiceItemClientService = new ServiceItemClient(ENDPOINT);
export const MaterialClientService = new MaterialClient(ENDPOINT);
export const MemoClientService = new MemoClient(ENDPOINT);
export const MaintenanceQuestionClientService = new MaintenanceQuestionClient(ENDPOINT);
export const ReadingClientService = new ReadingClient(ENDPOINT);
export const DeviceClientService = new DeviceClient(ENDPOINT);
export const VehicleDriverClientService = new VehicleDriverClient(ENDPOINT);
export const QuoteDocumentClientService = new QuoteDocumentClient(ENDPOINT);
export const TransactionStatusClientService = new TransactionStatusClient(ENDPOINT);
export const ExpendedClientService = new ExpendedClient(ENDPOINT);
export const GeoLocationLogClientService = new GeolocationLogClient(ENDPOINT);
export const ProjectClientService = new ProjectClient(ENDPOINT);
export const PromptPaymentOverrideClientService = new PromptPaymentOverrideClient(ENDPOINT);
export const QuoteUsedClientService = new QuoteUsedClient(ENDPOINT);
export const EmployeeProfileClientService = new EmployeeProfileClient(ENDPOINT);
export const LodgingClientService = new LodgingRequestClient(ENDPOINT);
export const PermitClientService = new PermitClient(ENDPOINT);

export const InventoryClientService = new InventoryClient(ENDPOINT);

export const getCFAppUrl = (action: string) => `${BASE_URL}?action=${action}`;

export const isMoneyValue = (value: number) => {
  return (value.toString().split('.')[1] || '').length <= 2;
};

function cfURL(action: string, qs = '') {
  return `${BASE_URL}?action=admin:${action}${qs}`;
}

/**
 *
 * @param number
 * @returns string as number with trailing zero, if number is less than 10
 */
function trailingZero(val: number) {
  return `${val < 10 ? 0 : ''}${val}`;
}

/**
 *
 * @param number
 * @returns string as usd amount, example 10.5 -> $10.50;
 */
export const usd = (val: number) => `$ ${val.toFixed(2)}`;

export const perDiemTripMilesToUsdAsNumber = (miles: number) => {
  return miles * IRS_SUGGESTED_MILE_FACTOR;
};

export const perDiemTripMilesToUsd = (miles: number) => {
  return usd(perDiemTripMilesToUsdAsNumber(miles));
};

/**
 *
 * @param dateOnly if true, returns only the date portion YYYY-MM-DD
 * @returns a timestamp in the format YYYY-MM-DD HH:MM:SS
 */
function timestamp(dateOnly = false, date?: Date) {
  const dateObj = date || new Date();
  let month = `${dateObj.getMonth() + 1}`;
  if (month.length === 1) {
    month = `0${month}`;
  }
  let day = `${dateObj.getDate()}`;
  if (day.length === 1) {
    day = `0${day}`;
  }
  let hour = `${dateObj.getHours()}`;
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  let minute = `${dateObj.getMinutes()}`;
  if (minute.length === 1) {
    minute = `0${minute}`;
  }
  let second = `${dateObj.getSeconds()}`;
  if (second.length === 1) {
    second = `0${second}`;
  }

  if (dateOnly) {
    return `${dateObj.getFullYear()}-${month}-${day}`;
  }
  return `${dateObj.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function checkPerDiemRowIsEarliestOrLatest(rows: PerDiemRow[], pdr: PerDiemRow) {
  if (rows.length <= 2) {
    return true;
  }

  const pdrTimestamp = new Date(pdr.dateString).getTime();
  let earliestTimestamp = Infinity;
  let latestTimestamp = -Infinity;

  for (const row of rows) {
    const timestamp = new Date(row.dateString).getTime();
    if (timestamp < earliestTimestamp) {
      earliestTimestamp = timestamp;
    }
    if (timestamp > latestTimestamp) {
      latestTimestamp = timestamp;
    }
  }

  return pdrTimestamp === earliestTimestamp || pdrTimestamp === latestTimestamp;
}

function getEditDistance(strOne: string, strTwo: string): number {
  const strOneLen = strOne.length;
  const strTwoLen = strTwo.length;
  const prevRow: number[] = [];
  const strTwoChar: number[] = [];
  let nextCol = 0;
  let curCol = 0;

  if (strOneLen === 0) {
    return strTwoLen;
  }
  if (strTwoLen === 0) {
    return strOneLen;
  }
  for (let i = 0; i < strTwoLen; ++i) {
    prevRow[i] = i;
    strTwoChar[i] = strTwo.charCodeAt(i);
  }
  prevRow[strTwoLen] = strTwoLen;

  let strComparison: boolean;
  let tmp: number;
  let j: number;
  for (let i = 0; i < strOneLen; ++i) {
    nextCol = i + 1;

    for (j = 0; j < strTwoLen; ++j) {
      curCol = nextCol;

      strComparison = strOne.charCodeAt(i) === strTwoChar[j];
      nextCol = prevRow[j] + (strComparison ? 0 : 1);
      tmp = curCol + 1;
      if (nextCol > tmp) {
        nextCol = tmp;
      }

      tmp = prevRow[j + 1] + 1;
      if (nextCol > tmp) {
        nextCol = tmp;
      }

      // copy current col value into previous (in preparation for next iteration)
      prevRow[j] = curCol;
    }

    // copy last col value into previous (in preparation for next iteration)
    prevRow[j] = nextCol;
  }
  return nextCol;
}

function getURLParams() {
  const params = new URLSearchParams(window.location.search);
  const res: { [key: string]: string } = {};
  params.forEach((val: string, key: string) => {
    res[key] = val;
  });
  return res;
}

function b64toBlob(b64Data: string, fileName: string) {
  const sliceSize = 512;
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];
  const contentType = getMimeType(fileName);

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const getFileExt = (fileName: string) => {
  const arr = fileName.toLowerCase().split('.');
  return arr[arr.length - 1];
};

export const getMimeType = (fileName: string) => {
  const ext = getFileExt(fileName);
  if (ext === 'pdf') {
    return 'application/pdf';
  } else if (ext === 'doc') {
    return 'application/msword';
  } else if (ext === 'docx') {
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  } else if (ext === 'png') {
    return 'image/png';
  } else if (ext === 'jpg' || ext === 'jpeg') {
    return 'image/jpeg';
  } else if (ext === 'mp3') {
    return 'audio/mpeg';
  } else if (ext === 'wav') {
    return 'audio/wav';
  } else if (ext === 'mp4') {
    return 'video/mp4';
  } else if (ext === 'mov') {
    return 'video/quicktime';
  }
  return 'application/octet-stream';
};

/**
 *
 * @param time time in format HH:MM (ie. 16:30)
 * @returns format h:MMa (ie. 4:30AM)
 */
function formatTime(time: string, forceMinutes: boolean = true) {
  const str = time.includes(' ') ? time.substr(11) : time;
  const [hourStr, minutes] = str.split(':');
  const hour = +hourStr;
  const minute = +minutes;
  return (
    (hour > 12 ? hour - 12 : hour || 12) +
    (forceMinutes || minute ? `:${minutes}` : '') +
    (hour < 12 ? ' AM' : ' PM')
  );
}

/**
 *
 * @param date date in format YYYY-MM-DD (ie. 2020-06-01)
 * @returns format M/D/YYYY (ie. 6/1/2020)
 */
function formatDate(date: string) {
  if (!date) return '';
  const [year, month, day] = date.substr(0, 10).split('-');
  if (+month + +day + +year === 0) return '';
  return [+month, +day, +year].join('/');
}

/**
 *
 * @param datetime date in format YYYY-MM-DD HH:MM:SS (ie. 2020-06-01 15:28:31)
 * @returns format Day (ie. Tue)
 */
function formatDay(datetime: string) {
  return (
    {
      0: 'Mon',
      1: 'Tue',
      2: 'Wed',
      3: 'Thu',
      4: 'Fri',
      5: 'Sat',
      6: 'Sun',
    } as { [key: number]: string }
  )[new Date(datetime.substring(0, 10)).getDay()];
}

/**
 *
 * @param datetime date in format YYYY-MM-DD HH:MM:SS (ie. 2020-06-01 15:28:31)
 * @returns format M/D/YYYY h:MMa (ie. 6/1/2020 3:28PM)
 */
function formatDateTime(datetime: string) {
  return formatDate(datetime) + ' ' + formatTime(datetime.substring(11));
}

/**
 *
 * @param datetime date in format YYYY-MM-DD HH:MM:SS (ie. 2020-06-01 15:28:31)
 * @returns format Day M/D/YYYY h:MMa (ie. Tue 6/1/2020 3:28PM)
 */
function formatDateTimeDay(datetime: string) {
  return formatDay(datetime) + ', ' + formatDate(datetime) + ' ' + formatTime(datetime.substr(11));
}

/**
 *
 * @param datetime date in format YYYY-MM-DD HH:MM:SS (ie. 2020-06-01 15:28:31)
 * @returns format Day M/D/YYYY h:MMa (ie. Tue 6/1/2020)
 */
function formatDateDay(datetime: string) {
  return formatDay(datetime) + ', ' + formatDate(datetime);
}
/**
 * Returns array of fake rows for InfoTable component
 * @param columns: number (default 1)
 * @param rows: number (default 3)
 * @returns fake rows with columns being { value: '' }
 */
function makeFakeRows(columns: number = 1, rows: number = 3) {
  return Array.from(Array(rows)).map(() => Array.from(Array(columns)).map(() => ({ value: '' })));
}

/**
 * Returns rpc fields
 * @param fieldName: field name, ie. jobNumber
 * @returns object { upperCaseProp: string, methodName: string }, ie. { upperCaseProp: 'JobNumber', setMethodName: 'setJobNumber',getMethodname: 'jobNumber'}
 */
/*
function getRPCFields(fieldName: string) {
  const upperCaseProp = `${fieldName[0].toUpperCase()}${fieldName.slice(1)}`;
  return {
    upperCaseProp,
    setMethodName: `set${upperCaseProp}`,
    getMethodName: `get${upperCaseProp}`,
  };
}
*/
export const formatWeek = (date: string) => {
  const d = parseISO(date);
  return `Week of ${format(d, 'yyyy')} ${format(d, 'MMMM')}, ${format(d, 'do')}`;
};

export const loadProjectTaskBillableTypes = async () => {
  //const { results } = (
  //  await TaskClientService.loadTaskBillableTypeList() // FIXME when available in rpc
  //).toObject();
  return ['Flat Rate', 'Hourly', 'Parts Run', 'Spiff', 'Tool Purchase'];
};

export const downloadCSV = (filename: string, csv: string) => {
  const link = document.createElement('a');
  link.setAttribute('download', `${filename}.csv`);
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
  link.click();
};

/**
 * Returns an array of numbers from start to end inclusive
 * @param start
 * @param end
 */
function range(start: number, end: number) {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
}

/**
 * Returns nicely formatted rounded number with 2 max fraction digits
 * if needed.
 */

function roundNumber(num: number) {
  return Math.round(num * 100) / 100;
}

/**
 * Returns options with weeks (starting Sunday) for the past year period
 */
function getWeekOptions(
  weeks = 52,
  offsetWeeks = 0,
  offsetDays = 0,
): { label: string; value: string }[] {
  const d = new Date();
  return Array.from(Array(weeks)).map((_, week) => {
    const w = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate() - d.getDay() - (week + offsetWeeks) * 7 + offsetDays,
    );
    return {
      label: `Week of ${MONTHS[w.getMonth()]} ${w.getDate()}, ${w.getFullYear()}`,
      value: `${w.getFullYear()}-${trailingZero(w.getMonth() + 1)}-${trailingZero(w.getDate())}`,
    };
  });
}

export type OrderDir = 'ASC' | 'DESC';

export type DateStartEndFilter = {
  dateStart: string;
  dateEnd: string;
};
export type DateStartEndSearchFilter = {
  dateStart: string;
  dateEnd: string;
  businessName?: string;
  lastname?: string;
};
export type LoadMetricsByFilter = {
  page: number;
  filter: DateStartEndFilter;
};
export type LoadMetricsBySearchFilter = {
  page: number;
  filter: DateStartEndSearchFilter;
  req: Event;
};

export const loadPerformanceMetricsByFilter = async ({
  page,
  filter: { dateStart, dateEnd },
}: LoadMetricsByFilter) => {
  console.log({ page, dateStart, dateEnd });
  const req = MertricReportDataRequest.create();
  req.endDate = dateEnd;
  req.startDate = dateStart;
  req.pageNumber = page;
  const results = await MetricsClientService.loadMetricsReportData(req);
  return results;
};

export const loadDeletedServiceCallsByFilter = async ({
  // FIXME move to event client
  page,
  filter: { dateStart, dateEnd, businessName },
  req,
}: LoadMetricsBySearchFilter) => {
  req.fieldMask = ['IsActive'];
  if (businessName && businessName != '') {
    const bReq = Property.create();
    bReq.businessname = businessName;
    const bResult = await PropertyClientService.Get(bReq);
    if (bResult) {
      req.propertyId = bResult.id;
      req.fieldMask.push('PropertyId');
    }
  }

  req.pageNumber = page === -1 ? 0 : page;
  req.orderBy = 'date_started';
  req.orderDir = 'ASC';
  req.isActive = 0;
  req.dateRange = ['>=', dateStart, '<=', dateEnd];
  req.dateTarget = ['date_started', 'date_started'];
  req.withoutLimit = true;
  const res = await EventClientService.BatchGet(req);
  return { results: res!.results, totalCount: res!.totalCount };
};

export type BillingAuditType = {
  date: string;
  name: string;
  businessname: string;
  jobNumber: number;
  payable: number;
  eventId: number;
  userId: number;
  propertyId: number;
  items: {
    id: number;
    date: string;
    payable: number;
    payed: number;
  }[];
};

export const loadBillingAuditReport = async (startDate: string) => {
  //FIXME make this load real data, move to client
  const [year, month] = startDate.split('-');
  return [...Array(160)].map(() => ({
    date: `${year}-${month}-${getRandomNumber(1, 31)}`,
    name: getRandomName(),
    businessname:
      getRandomDigit() < 4 ? `${getRandomLastName()} ${randomize(['Co.', 'and Son', 'SA'])}` : '',
    jobNumber: getRandomDigits(8),
    payable: getRandomDigits(4),
    eventId: 86246,
    userId: 2573,
    propertyId: 6552,
    items: [...Array(getRandomNumber(1, 5))].map((_, id) => {
      const payable = getRandomDigits(4);
      return {
        id,
        date: `2020-${trailingZero(getRandomNumber(1, 12))}-${trailingZero(
          getRandomNumber(1, 30),
        )}`,
        payable,
        payed: getRandomDigit() < 5 ? payable : 0,
      };
    }),
  }));
};

export const loadCharityReport = async (month: string) => {
  //FIXME make this load real data, move to client
  console.log('loadCharityReport', { month });
  return {
    residentialServiceTotal: getRandomDigits(6),
    residentialAorTotal: getRandomDigits(6),
    items: [...Array(30)].map(() => ({
      technician: getRandomName(),
      contribution: getRandomDigits(5),
      averageHourly: getRandomDigits(5) / 100,
    })),
  };
};

export const loadTimeoffSummaryReport = async (year: number) => {
  const req = TimeoffReportRequest.create();
  req.yearShift = year;
  const results = (await ReportClientService.GetTimeOffReport(req))!.data;
  console.log(results);
  const mappedResults = results.map((entry) => ({
    hireDate: entry.hireDate,
    employeeName: `${entry.userFirstname} ${entry.userLastname}`,
    annualPTOAllowance: entry.annualHoursPto,
    annualPTO: entry.annualPto,
    annualMandatory: entry.annualMandatory,
    annualDiscretionary: entry.annualDiscretionary,
    hireDateDiscretionary: entry.hireDateDiscretionary,
    hireDatePTO: entry.hireDatePto,
    hireDateMandatory: entry.hireDateMandatory,
    hireDatePaidDowntime: entry.hireDatePaidDowntime,
  }));
  return mappedResults;
};

export const loadWarrantyReport = async () => {
  //FIXME make this load real data, move to client
  return [...Array(130)].map(() => ({
    briefDdescription: randomize(['Broken', 'Not working', 'Noisy', 'Loud', 'Unpredictable']),
    externalId: getRandomDigits(7),
    referenceNumber: getRandomDigits(6),
    statusDesc: randomize(['Active', 'Inactive', 'Pending', 'Completed']),
    priorityDesc: randomize(['Blocker', 'Urgent', 'Major', 'Minor']),
    techName: getRandomName(),
  }));
};

export type LoadMetricsByWeekFilter = {
  filter: {
    week: string;
  };
};

export const loadServiceCallMetricsByFilter = async ({
  filter: { week },
}: LoadMetricsByWeekFilter) => {
  // //FIXME make this load real data, move to client
  return {
    serviceCallInformation: [...Array(5 + getRandomDigit())].map(() => ({
      averageCustomerAnnualValue: getRandomAge(),
      averageCustomerLifeTime: getRandomAge(),
      phoneCalls: getRandomAge(),
      serviceCalls: getRandomAge(),
      serviceCallDate: week,
    })),
    userInformation: [...Array(getRandomAge())].map(() => ({
      activeCustomers: getRandomAge(),
      contracts: getRandomAge(),
      installationTypeCalls: getRandomAge(),
      totalCustomers: getRandomAge(),
      users: getRandomAge(),
      serviceCallDate: week,
    })),
  };
};

export type PromptPaymentData = {
  customerId: number;
  customerName: string;
  payableAward: number;
  forfeitedAward: number;
  pendingAward: number;
  averageDaysToPay: number;
  daysToPay: number;
  paidInvoices: number;
  allInvoices: number;
  payableTotal: number;
  paidOnTime: number;
  possibleAwardTotal: number;
  entries: PromptPaymentReportLine[];
};

export const loadPromptPaymentDataSummary = async (month: string) => {
  //FIXME finish implementation, move to reports client

  const req = PromptPaymentSummaryRequest.create();

  const date = `${month.replace('%', '01')} 00:00:00`;
  console.log('formatted date', date);

  const startDate = format(addDays(new Date(parseISO(date)), -1), 'yyyy-MM-dd');
  const endDate = format(addMonths(new Date(parseISO(date)), 1), 'yyyy-MM-dd');
  req.startDate = startDate;
  req.endDate = endDate;
  req.customerId = [0];

  const res = await ReportClientService.GetPromptPaymentSummary(req);
  return res!.data;
};

export const loadPromptPaymentDataDetails = async (month: string, userIdList: number[]) => {
  //FIXME finish implementation, move to reports client

  console.log('loadPromptPaymentDataDetails', { month, userIdList });
  const req = PromptPaymentSummaryRequest.create();

  const date = `${month.replace('%', '01')} 00:00:00`;
  console.log('formatted date', date);

  const startDate = format(addDays(new Date(parseISO(date)), -1), 'yyyy-MM-dd');
  const endDate = format(addMonths(new Date(parseISO(date)), 1), 'yyyy-MM-dd');
  req.startDate = startDate;
  req.endDate = endDate;
  req.customerId = [0];

  const res = (await ReportClientService.GetPromptPaymentDetails(
    req,
  )) as PromptPaymentDetailsResponse;
  console.log(res);
  return res.data;
};

export const loadPromptPaymentData = async (month: string) => {
  //FIXME finish implementation, move to reports client

  const req = PromptPaymentReportLine.create();
  const date = `${month.replace('%', '01')} 00:00:00`;
  console.log('formatted date', date);

  const startDate = format(addDays(new Date(parseISO(date)), -1), 'yyyy-MM-dd');
  const endDate = format(addMonths(new Date(parseISO(date)), 1), 'yyyy-MM-dd');
  req.dateRange = ['>', startDate, '<', endDate];
  req.dateTarget = ['log_billingDate', 'reportUntil'];
  const res = await ReportClientService.GetPromptPaymentData(req);
  const data: {
    [key: string]: PromptPaymentData;
  } = {};
  res!.data.forEach((entry) => {
    const userId = entry.userId;
    const userBusinessName = entry.userBusinessName;
    const paymentTerms = entry.paymentTerms;
    const daysToPay = entry.daysToPay;
    const payable = entry.payable;
    const payed = entry.payed;
    const dueDate = entry.dueDate;
    const paymentDate = entry.paymentDate;
    const possibleAward = entry.possibleAward;
    if (!data[userBusinessName]) {
      data[userBusinessName] = {
        customerId: userId,
        customerName: userBusinessName,
        payableAward: 0,
        forfeitedAward: 0,
        pendingAward: 0,
        averageDaysToPay: 0,
        daysToPay: paymentTerms,
        paidInvoices: 0,
        allInvoices: 0,
        payableTotal: 0,
        paidOnTime: 0,
        possibleAwardTotal: 0,
        entries: [],
      };
    }
    data[userBusinessName].entries.push(entry);
    data[userBusinessName].averageDaysToPay += daysToPay;
    data[userBusinessName].allInvoices += 1;
    data[userBusinessName].payableTotal += payable;
    data[userBusinessName].paidInvoices += payable >= payed ? 1 : 0;
    data[userBusinessName].paidOnTime += payable >= payed && dueDate >= paymentDate ? 1 : 0;
    data[userBusinessName].possibleAwardTotal += possibleAward;
    // TODO calculate:
    // payableAward
    // forfeitedAward
    // pendingAward
  });

  const fn = (key: any) => {
    key.trimStart();
    return (a: PromptPaymentData, b: PromptPaymentData) => {
      // @ts-ignore
      a[key] = a[key].trimStart();
      // @ts-ignore
      b[key] = b[key].trimStart();
      // @ts-ignore
      return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
    };
  };
  const results = Object.values(data)
    .sort(fn('customerName'))
    .map(({ averageDaysToPay, ...item }) => ({
      ...item,
      averageDaysToPay:
        item.paidInvoices === 0 ? 0 : Math.round(averageDaysToPay / item.paidInvoices),
    }));
  return results;
};

export type LoadSpiffReportByFilter = {
  date: string;
  type: string;
  users: number[];
};
export const loadSpiffReportByFilter = async ({ date, type }: LoadSpiffReportByFilter) => {
  //FIXME finish this, move to report client
  const req = SpiffReportLine.create();
  req.isActive = true;
  req.orderBy = 'timestamp';
  if (type === 'Monthly') {
    const startDate = date.replace('%', '01');
    const endDate = format(addMonths(new Date(startDate), 1), 'yyyy-MM-dd');
    req.dateRange = ['>=', startDate, '<', endDate];
    req.dateTarget = ['timestamp', 'timestamp'];
  } else {
    const startDate = date;
    const endDate = format(addDays(new Date(startDate), 7), 'yyyy-MM-dd');
    req.dateRange = ['>=', startDate, '<', endDate];
    req.dateTarget = ['timestamp', 'timestamp'];
  }
  const res = await ReportClientService.GetSpiffReportData(req);
  const data: {
    [key: string]: {
      spiffBonusTotal: number;
      items: SpiffReportLine[];
    };
  } = {};
  res!.data.forEach((item) => {
    const employeeName = item.employeeName;
    if (!data[employeeName]) {
      data[employeeName] = {
        spiffBonusTotal: 0,
        items: [],
      };
    }
    data[employeeName].items.push(item);
    data[employeeName].spiffBonusTotal += item.amount;
  });
  return data;
};

export type ActivityLogsSort = {
  orderByField: keyof ActivityLog | keyof User;
  orderBy: string;
  orderDir: OrderDir;
};
export type LoadActivityLogsByFilter = {
  page: number;
  filter: ActivityLogsFilter;
  sort: ActivityLogsSort;
};
export type ActivityLogsFilter = {
  activityDateStart?: string;
  activityDateEnd?: string;
  activityName?: string;
  withUser?: boolean;
};
/**
 * Returns Activity Logs by filter
 * @param page number
 * @param searchBy string
 * @param searchPhrase string
 * @returns {results: ActivityLog[], totalCount: number}
 */
export const loadActivityLogsByFilter = async ({
  page,
  filter: { activityDateStart, activityDateEnd, activityName, withUser },
  sort,
}: LoadActivityLogsByFilter) => {
  // TODO make this call faster
  //FIXME move to activity log client
  const { orderBy, orderDir } = sort;
  const req = ActivityLog.create();
  const u = User.create();
  req.user = u;
  req.orderBy = cleanOrderByField(orderBy);
  req.orderDir = orderDir;
  req.pageNumber = page === -1 ? 0 : page;
  if (activityDateStart && activityDateEnd) {
    req.dateRange = ['>=', activityDateStart, '<=', activityDateEnd];
  }
  if (activityName) {
    req.activityName = `%${activityName}%`;
  }
  if (withUser) {
    req.withUser = true;
  }
  const results: ActivityLog[] = [];
  const res = await ActivityLogClientService.BatchGet(req);
  results.push(...res!.results);
  const totalCount = res!.totalCount;
  const len = res!.results.length;

  if (page === -1 && totalCount > len) {
    const batchesAmount = Math.min(MAX_PAGES, Math.ceil((totalCount - len) / len));
    const batchResults = await Promise.all(
      Array.from(Array(batchesAmount)).map(async (_, idx) => {
        req.pageNumber = idx + 1;
        return (await ActivityLogClientService.BatchGet(req))!.results;
      }),
    );
    results.push(...batchResults.reduce((aggr, item) => [...aggr, ...item], []));
  }
  return { results, totalCount };
};

export type PropertiesSort = {
  orderByField: keyof Property;
  orderBy: string;
  orderDir: OrderDir;
};
export type ContractsSort = {
  orderByField: keyof Contract;
  orderBy: string;
  orderDir: OrderDir;
};
export type TripsSort = {
  orderByField: keyof Trip;
  orderBy: string;
  orderDir: OrderDir;
};
export type LoadPropertiesByFilter = {
  page: number;
  filter: PropertiesFilter;
  sort: PropertiesSort;
  req: Property;
};

export type LoadTripsByFilter = {
  page: number;
  filter: TripsFilter;
  sort: TripsSort;
  req: Trip;
};
export type PropertiesFilter = {
  subdivision?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  userId?: number;
};
export type TripsFilter = {
  id?: number;
  userId?: number;
  lastName?: string;
  originAddress?: string;
  destinationAddress?: string;
  weekof?: number[];
  page: number;
  payrollProcessed: boolean | undefined;
  approved: boolean | undefined;
  role?: string;
  departmentId?: number;
  dateProcessed?: string | undefined;
  isActive?: boolean;
  adminActionDate?: string;
  dateRange?: string[];
};
/**
 * Returns Properties by filter
 * @param page number
 * @param searchBy string
 * @param searchPhrase string
 * @returns {results: Property[], totalCount: number}
 */
export const loadPropertiesByFilter = async ({
  page,
  filter,
  sort,
  req,
}: LoadPropertiesByFilter) => {
  // FIXME move to property client
  const { orderDir, orderByField } = sort;
  req.isActive = 1;
  req.pageNumber = page;
  // req.orderBy = orderBy;
  // req.orderDir = orderDir;
  for (const fieldName in filter) {
    const value = filter[fieldName as keyof PropertiesFilter];
    if (value) {
      const methodName = keyToMethodName('set', fieldName);
      //@ts-ignore
      req[methodName](typeof value === 'string' ? `%${value}%` : value);
    }
  }
  const response = await PropertyClientService.BatchGet(req);
  return {
    results: response!.results.sort((a, b) => {
      const A = (a[orderByField] || '').toString().toLowerCase();
      const B = (b[orderByField] || '').toString().toLowerCase();
      if (A < B) return orderDir === 'DESC' ? 1 : -1;
      if (A > B) return orderDir === 'DESC' ? -1 : 1;
      return 0;
    }),
    totalCount: response!.totalCount,
  };
};

export const loadTripsByFilter = async ({ page, filter, sort, req }: LoadTripsByFilter) => {
  // FIXME move to trips client
  const { orderDir, orderByField } = sort;
  req.page = page;
  req.notEquals = [];
  req.isActive = true;
  for (const fieldName in filter) {
    const value = filter[fieldName as keyof TripsFilter];

    const methodName = keyToMethodName('set', fieldName);

    // @ts-ignore
    if (!req[methodName]) continue;

    //@ts-ignore
    req[methodName](typeof value === 'string' ? `%${value}%` : value);
  }
  if (filter.dateRange) {
    req.dateRange = filter.dateRange;
    req.dateTarget = ['date'];
  }
  if (filter.payrollProcessed == true) {
    req.payrollProcessed = false;
    req.notEquals.push('PayrollProcessed');
    //req.addNotEquals('AdminActionDate');
    //req.adminActionDate = NULL_TIME;
  }
  if (filter.payrollProcessed === false) {
    req.payrollProcessed = true;
    req.notEquals.push('PayrollProcessed');
  }
  if (filter.approved === false) {
    req.notEquals.push('Approved');
    req.approved = true;
  }

  if (filter.approved === true) {
    req.notEquals.push('Approved');
    req.notEquals.push('AdminActionDate');
    req.adminActionDate = NULL_TIME;
    req.approved = false;
  }
  try {
    const response = await PerDiemClientService.BatchGetTrips(req);
    return {
      results: response.results.sort((a, b) => {
        const A = (a[orderByField] || '').toString().toLowerCase();
        const B = (b[orderByField] || '').toString().toLowerCase();
        if (A < B) return orderDir === 'DESC' ? 1 : -1;
        if (A > B) return orderDir === 'DESC' ? -1 : 1;
        return 0;
      }),
      totalCount: response.totalCount,
    };
  } catch (err) {
    throw new Error(`An error occurred while batch-getting trips: ${err}`); // To be caught at a higher call
  }
};

export type EventsSort = {
  orderByField: keyof Event | keyof Property | keyof User;
  orderBy: string;
  orderDir: OrderDir;
};
export type EventsFilter = {
  firstname?: string;
  lastname?: string;
  businessname?: string;
  logJobNumber?: string;
  logPo?: string;
  dateStarted?: string;
  dateStartedFrom?: string;
  dateStartedTo?: string;
  dateEnded?: string;
  address?: string;
  zip?: string;
  city?: string;
  name?: string;
  logNotes?: string;
  logDateCompleted?: string;
  isResidential?: number;
  jobTypeId?: number;
  jobSubtypeId?: number;
  logJobStatus?: string;
  logPaymentStatus?: string;
  departmentId?: number;
  logTechnicianAssigned?: string;
  notEquals?: string[];
  fieldMask?: string[];
  isActive?: boolean;
  description?: string;
  withoutLimit?: boolean;
  usePropertyLevelSearch?: boolean;
};
export type LoadEventsByFilter = {
  page: number;
  filter: EventsFilter;
  req: Event;
  sort: EventsSort;
  pendingBilling?: boolean;
};

/**
 * Returns Events by filter
 * @param page number
 * @param filter EventsFilter
 * @param sort Sort
 * @returns {results: Event[], totalCount: number}
 */
export const applyEventsFilter = ({
  page,
  filter,
  sort,
  pendingBilling = false,
  req,
}: LoadEventsByFilter) => {
  // FIXME, move to event client
  const {
    name,
    logNotes,
    logJobNumber,
    dateStarted,
    dateStartedFrom,
    dateStartedTo,
    dateEnded,
    address,
    zip,
    logDateCompleted,
    city,
    usePropertyLevelSearch,
    firstname,
    lastname,
    businessname,
    isResidential,
    jobTypeId,
    jobSubtypeId,
    logJobStatus,
    logPaymentStatus,
    departmentId,
    logTechnicianAssigned,
    logPo,
    notEquals,
    fieldMask,
    isActive,
    description,
  } = filter;
  const { orderBy, orderDir } = sort;
  const p = Property.create();
  const u = User.create();
  req.fieldMask = [];
  req.notEquals = [];
  req.orderBy = orderBy;
  req.orderDir = orderDir;
  if (fieldMask) {
    req.fieldMask = fieldMask;
  }
  if (isResidential != 0) {
    if (isResidential == 1) {
      req.notEquals = ['IsResidential'];
      req.isResidential = 0;
    }
    if (isResidential == 2) {
      console.log('Commercial');
      req.notEquals = ['IsResidential'];
      req.isResidential = 1;
    }
  }
  if (isActive) {
    req.isActive = 1;
    req.fieldMask.push('isActive');
  }
  if (!isActive) {
    req.isActive = 0;
    req.fieldMask.push('isActive');
  }
  p.isActive = 1;
  if (pendingBilling) {
    req.logJobStatus = 'Completed';
    req.logPaymentStatus = 'Pending';
  }
  if (notEquals) {
    req.notEquals = notEquals;
  }

  if (logJobNumber && logJobNumber !== '') {
    req.logJobNumber = `%${logJobNumber}%`;
  }
  if (jobTypeId && jobTypeId != 0) {
    req.jobTypeId = jobTypeId;
  }
  if (logPo && logPo !== '') {
    req.logPo = `%${logPo}%`;
  }
  if (jobSubtypeId && jobSubtypeId != 0) {
    req.jobSubtypeId = jobSubtypeId;
  }
  if (logJobStatus && logJobStatus !== '') {
    req.logJobStatus = logJobStatus;
  }
  if (logPaymentStatus && logPaymentStatus !== '') {
    req.logPaymentStatus = logPaymentStatus;
  }
  if (departmentId && departmentId != 0) {
    req.departmentId = departmentId;
  }
  if (logTechnicianAssigned && logTechnicianAssigned !== '') {
    req.logTechnicianAssigned = logTechnicianAssigned;
  }
  if (dateStarted && dateStarted !== '' && dateEnded && dateEnded !== '') {
    req.dateRange = ['>=', dateStarted, '<=', dateEnded];
    req.dateTarget = ['date_started', 'date_ended'];
  } else {
    if (dateStarted && dateStarted !== '') {
      req.dateStarted = `%${dateStarted}%`;
    }
    if (dateEnded && dateEnded !== '') {
      req.dateEnded = `%${dateEnded}%`;
    }
  }
  if ((dateStartedFrom && dateStartedFrom !== '') || (dateStartedTo && dateStartedTo !== '')) {
    if (dateStartedFrom && dateStartedFrom != '' && dateStartedTo && dateStartedTo != '') {
      req.dateRange = ['>=', dateStartedFrom, '<=', dateStartedTo];
      req.dateTarget = ['date_started', 'date_started'];
    } else if (dateStartedFrom && dateStartedFrom != '') {
      req.dateRange = ['>=', dateStartedFrom];
      req.dateTarget = ['date_started'];
    } else if (dateStartedTo && dateStartedTo != '') {
      req.dateRange = ['<=', dateStartedTo];
      req.dateTarget = ['date_started'];
    }
  }
  if (logDateCompleted && logDateCompleted !== '') {
    req.logDateCompleted = `${logDateCompleted}%`;
  }
  if (usePropertyLevelSearch) {
    p.city = city ? `%${city}%` : '';
    p.zip = zip ? `%${zip}%` : '';
    p.address = address ? `%${address}%` : '';
  } else {
    u.city = city ? `%${city}%` : '';
    u.zip = zip ? `%${zip}%` : '';
    u.address = address ? `%${address}%` : '';
  }
  if (firstname && firstname !== '') {
    u.firstname = `%${firstname}%`;
  }
  if (lastname && lastname !== '') {
    u.lastname = `%${lastname}%`;
  }
  if (businessname && businessname !== '') {
    u.businessname = `%${businessname}%`;
  }
  if (name && name !== '') {
    req.name = `%${name}%`;
  }
  if (logNotes && logNotes !== '') {
    req.logNotes = `%${logNotes}%`;
  }
  if (description && description !== '') {
    req.description = `%${description}%`;
  }
  req.isActive = 1;
  req.property = p;
  console.log('property', p);
  console.log('req', req);
  req.customer = u;
  if (page === -1) {
    req.withoutLimit = true;
  } else {
    req.pageNumber = page;
  }
  return req;
};

export const loadProjects = async () => {
  // FIXME move to event client
  const req = Event.create();
  req.notEquals = ['DepartmentId'];
  req.pageNumber = 0;
  req.orderBy = 'date_started';
  req.orderDir = 'ASC';
  req.withoutLimit = true;
  const response = await EventClientService.BatchGet(req);
  const results = response!.results;
  return results;
};

export const loadEventById = async (eventId: number) => {
  // FIXME move to event client
  return await EventClientService.loadEvent(eventId);
};

export const loadEventsByFilterDeleted = async ({
  page,
  filter,
  sort,
  pendingBilling = false,
  req,
}: LoadEventsByFilter) => {
  // FIXME move to event client
  const {
    isActive,
    logJobNumber,
    dateStarted,
    dateStartedFrom,
    dateStartedTo,
    dateEnded,
    address,
    zip,
    logDateCompleted,
    city,
    firstname,
    lastname,
    businessname,
    jobTypeId,
    jobSubtypeId,
    logJobStatus,
    logPaymentStatus,
    departmentId,
    logTechnicianAssigned,
    logPo,
    notEquals,
    fieldMask,
  } = filter;
  const { orderBy, orderDir, orderByField } = sort;
  const p = Property.create();
  const u = User.create();
  const fieldMaskEntries = new Set(fieldMask);
  if (orderByField === 'lastname') {
    u.orderBy = cleanOrderByField(orderBy);
    u.orderDir = orderDir;
  } else if (orderByField === 'address') {
    // FIXME - missing setOrderBy/setOrderDir in Property RPC
    // p.orderBy = orderBy;
    // p.orderDir = orderDir;
  } else {
    req.orderBy = cleanOrderByField(orderBy);
    req.orderDir = orderDir;
  }

  if (isActive) {
    req.isActive = 1;
  }
  if (!isActive) {
    //req.addNotEquals('IsActive')
    fieldMaskEntries.add('IsActive');
    req.isActive = 0;
  }

  req.pageNumber = page === -1 ? 0 : page;
  if (pendingBilling) {
    req.logJobStatus = 'Completed';
    req.logPaymentStatus = 'Pending';
  }
  if (notEquals) {
    req.notEquals = notEquals;
  }

  if (logJobNumber) {
    req.logJobNumber = `%${logJobNumber}%`;
  }
  if (jobTypeId) {
    req.jobTypeId = jobTypeId;
  }
  if (logPo) {
    req.logPo = logPo;
  }
  if (jobSubtypeId) {
    req.jobSubtypeId = jobSubtypeId;
  }
  if (logJobStatus) {
    req.logJobStatus = logJobStatus;
  }
  if (logPaymentStatus) {
    req.logPaymentStatus = logPaymentStatus;
  }
  if (departmentId) {
    req.departmentId = departmentId;
  }
  if (logTechnicianAssigned) {
    req.logTechnicianAssigned = logTechnicianAssigned;
  }
  if (dateStarted && dateEnded) {
    req.dateRange = ['>=', dateStarted, '<=', dateEnded];
    req.dateTarget = ['date_started', 'date_ended'];
  } else {
    if (dateStarted) {
      console.log('We set date started');
      req.dateStarted = `%${dateStarted}%`;
    }
    if (dateEnded) {
      console.log('We set date ended');
      req.dateEnded = `%${dateEnded}%`;
    }
  }
  if (dateStartedFrom || dateStartedTo) {
    if (dateStartedFrom && dateStartedTo) {
      req.dateRange = ['>=', dateStartedFrom, '<=', dateStartedTo];
      req.dateTarget = ['date_started', 'date_started'];
    } else if (dateStartedFrom) {
      req.dateRange = ['>=', dateStartedFrom];
      req.dateTarget = ['date_started'];
    } else if (dateStartedTo) {
      req.dateRange = ['<=', dateStartedTo];
      req.dateTarget = ['date_started'];
    }
  }
  if (address) {
    p.address = `%${address}%`;
  }
  if (zip) {
    p.zip = `%${zip}%`;
  }
  if (logDateCompleted) {
    req.logDateCompleted = `${logDateCompleted}%`;
  }
  if (city) {
    p.city = `%${city}%`;
  }
  if (firstname) {
    u.firstname = `%${firstname}%`;
  }
  if (lastname) {
    u.lastname = `%${lastname}%`;
  }
  if (businessname) {
    console.log('We got a buisiness name');
    u.businessname = `%${businessname}%`;
  }
  //console.log({ u });
  //console.log({ p });
  req.property = p;
  req.customer = u;
  req.fieldMask = Array.from(fieldMaskEntries);
  const results: Event[] = [];
  const response = await EventClientService.BatchGet(req);
  const totalCount = response!.totalCount;
  results.push(...response!.results);
  return {
    results,
    totalCount,
  };
};
/**
 * Returns escaped text with special characters, ie. &#x2f; -> /
 * @param encodedStr string
 * @returns string
 */

function escapeText(encodedStr: string) {
  const parser = new DOMParser();
  const dom = parser.parseFromString('<!doctype html><body>' + encodedStr, 'text/html');
  return dom.body.textContent || '';
}

// TODO probably shouldn't do it this way permanently, but creating a log image with the name itself specified is probably
// the easiest way to do it until Kalos RPC 3 is ready and all the issues are fixed
// I also made sure to set the tag as well so we can use that later on once we can modify the S3ClientService to actually use that
// information to grab stuff from S3
export const getS3LogImageFileName = (
  fileName: string,
  eventId: number | undefined,
  logId: number,
) => {
  const split = fileName.split('.');
  return `EventID-${eventId}-LogID-${logId}.${split[split.length - 1]}`;
};

/**
 * Upload file to S3 bucket
 * @param fileName string
 * @param fileData string (starting with ie. `data:image/png;base64,`)
 * @param bucketName string
 * @returns status string: "ok" | "nok"
 */

export const makeOptions = (options: string[], withAllOption = false): Option[] => [
  ...(withAllOption ? [{ label: OPTION_ALL, value: OPTION_ALL }] : []),
  ...options.map((label) => ({ label, value: label })),
];

export const getCurrDate = () => formatDate(new Date().toISOString()).replace(/\//g, '-');

const getComputedStyleCssText = (element: Element) => {
  const style = window.getComputedStyle(element, null);
  let cssText: string;
  if (style.cssText != '') {
    return style.cssText;
  }
  cssText = '';
  for (let i = 0; i < style.length; i++) {
    cssText += style[i] + ': ' + style.getPropertyValue(style[i]) + '; ';
  }
  return cssText;
};

export const setInlineStyles = (theElement: Element) => {
  const els = theElement.children;
  for (let i = 0, maxi = els.length; i < maxi; i++) {
    setInlineStyles(els[i]);
    const defaultElem = document.createElement(els[i].nodeName);
    const child = document.body.appendChild(defaultElem);
    const defaultsStyles = window.getComputedStyle(defaultElem, null);
    let computed = getComputedStyleCssText(els[i]);
    for (let j = 0, maxj = defaultsStyles.length; j < maxj; j++) {
      const defaultStyle =
        defaultsStyles[j] + ': ' + defaultsStyles.getPropertyValue('' + defaultsStyles[j]) + ';';
      if (computed.startsWith(defaultStyle)) {
        computed = computed.substring(defaultStyle.length);
      } else {
        computed = computed.replace(' ' + defaultStyle, '');
      }
    }
    child.remove();
    els[i].setAttribute('style', computed);
  }
};

export const makeLast12MonthsOptions = (withAllOption: boolean, monthOffset = 0) => {
  const today = new Date();
  const currMonth = today.getMonth() + 1 + monthOffset;
  return [
    ...(withAllOption ? [{ label: OPTION_ALL, value: OPTION_ALL }] : []),
    ...MONTHS.slice(currMonth).map((month, idx) => ({
      label: `${month}, ${today.getFullYear() - 1}`,
      value: `${today.getFullYear() - 1}-${trailingZero(currMonth + idx + 1)}-%`,
    })),
    ...MONTHS.slice(0, currMonth).map((month, idx) => ({
      label: `${month}, ${today.getFullYear()}`,
      value: `${today.getFullYear()}-${trailingZero(idx + 1)}-%`,
    })),
  ].reverse();
};

export const makeMonthsOptions = (withAllOption?: boolean) => {
  return [
    ...(withAllOption ? [{ label: OPTION_ALL, value: '0' }] : []),
    ...MONTHS.map((month, idx) => ({
      label: `${month} (${idx + 1})`,
      value: trailingZero(idx + 1),
    })),
  ];
};

// Returns an object with the key being the per diem id of the relevant per diem and the
// value being the cost for lodging

interface IBugReport {
  title: string;
  body?: string;
  labels?: string[];
}

const BUG_REPORT_LABEL = 'user submitted bug report';
async function newBugReport(data: IBugReport) {
  try {
    const req = ApiKey.create();
    req.textId = 'github_key';
    const key = await ApiKeyClientService.Get(req);
    data.labels = [BUG_REPORT_LABEL];
    const authString = `token ${key!.apiKey}`;
    const postData = {
      method: 'POST',
      headers: {
        Authorization: authString,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    await fetch(key!.apiEndpoint, postData);
  } catch (err) {
    console.log('error generating bug report', err);
  }
}

export type BugReportImage = {
  label: string;
  data: string;
  url?: string;
};

async function newBugReportImage(user: User, images: BugReportImage[]) {
  try {
    const timestamp = new Date().getTime();
    const client = new ApiKeyClient(ENDPOINT);
    const req = ApiKey.create();
    req.textId = 'github_file_key';
    const key = await client.Get(req);
    const common = {
      message: 'bug report image',
      committer: {
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
      },
    };
    const authString = `token ${key!.apiKey}`;
    const result: { filename: string; url: string }[] = [];
    for (const img of images) {
      const data = { ...common, content: img.data };
      const putData = {
        method: 'PUT',
        headers: {
          Authorization: authString,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      try {
        await fetch(`${key!.apiEndpoint}/images/${timestamp}/${img.label}`, putData);
        result.push({
          filename: img.label,
          url: encodeURI(
            `https://github.com/rmilejcz/kalos-frontend-issues/raw/master/images/${timestamp}/${img.label}`,
          ),
        });
      } catch (e) {
        console.log('error uploading image', e);
      }
    }
    return result;
  } catch (err) {
    console.log('error uploading image', err);
    const result: { filename: string; url: string }[] = [];
    return result;
  }
}

export const CustomEventsHandler = (() => {
  type EventName = 'AddProperty' | 'AddServiceCall' | 'ShowDocuments' | 'EditCustomer';
  const customEvents: { [key in EventName]: boolean } = {
    AddProperty: false,
    AddServiceCall: false,
    ShowDocuments: false,
    EditCustomer: false,
  };
  return {
    listen: (eventName: EventName, callback: () => void) => {
      if (customEvents[eventName]) return;
      customEvents[eventName] = true;
      window.addEventListener(eventName, callback);
    },
    emit: (eventName: EventName) => window.dispatchEvent(new CustomEvent(eventName)),
  };
})();

/**
 * Checks URL for http, and redirects to https appropriately
 */
function forceHTTPS() {
  if (window.location.hostname.startsWith('192.168.') || window.location.hostname.includes('local'))
    return;
  if (window.location.href.includes('http://')) {
    window.location.href = window.location.href.replace('http://', 'https://');
  }
}

/**
 * A redundant hardening feature that redirects non-employees from admin views
 * @param user
 */
function customerCheck(user: User) {
  if (window.location.href.includes('admin') && user.isEmployee === 0) {
    window.location.href =
      'https://app.kalosflorida.com/index.cfm?action=customer:account.dashboard';
  }
}

type dateRes = [number, number, number];
/**
 *
 * @param str A date string in the format YYYY-MM-DD
 */
function getDateArgs(str: string): dateRes {
  const splitTarget = str.includes('T') ? 'T' : ' ';
  const arr = str.split(splitTarget);
  const dateParts = arr[0].split('-');
  return [
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1, //month must be decremented
    parseInt(dateParts[2]),
  ];
}

type dateTimeRes = [number, number, number, number, number, number];
/**
 *
 * @param str A date string in the format YYYY-MM-DD HH:MM:SS
 */
function getDateTimeArgs(str: string): dateTimeRes {
  const splitTarget = str.includes('T') ? 'T' : ' ';
  const arr = str.split(splitTarget);
  const dateParts = arr[0].split('-');
  const timeParts = arr[1].split(':');
  return [
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1, //month must be decremented
    parseInt(dateParts[2]),
    parseInt(timeParts[0]),
    parseInt(timeParts[1]),
    parseInt(timeParts[2]),
  ];
}

const cleanOrderByField = (f: string) => {
  const parts = f.replace('get', '').split(/(?=[A-Z])/g);
  const lowerParts = parts.map((p) => p.toLowerCase());
  return lowerParts.join('_');
};

const cleanFieldMaskField = (f: string) => {
  console.log(f.slice(3));
  if (f.startsWith('get') || f.startsWith('set')) {
    return f.slice(3);
  } else {
    return f;
  }
};

const getNumberOfDocumentsForTransaction = async (transactionId: number): Promise<number> => {
  let initialDocumentLength = 0;
  // Get how many docs there are
  try {
    initialDocumentLength = (await TransactionDocumentClientService.byTransactionID(transactionId))!
      .length;
  } catch (err) {
    console.error(`An error occurred while getting the amount of items in the bucket: ${err}`);
    alert('An error occurred while double-checking that the file exists.');
  }

  return initialDocumentLength;
};

export const uploadPhotoToExistingTransaction = async (
  fileName: string,
  fileDescription: 'Receipt' | 'PickTicket' | 'Invoice' | undefined,
  fileData: string,
  existingTransaction: Transaction,
  loggedUserId: number, // loggedUserId included for logging purposes
  invoiceWaiverType?: number,
) => {
  const ext = getFileExt(fileName);
  const name = `${existingTransaction!.id}-${existingTransaction.description}-${Math.floor(
    Date.now() / 1000,
  )}.${ext}`;
  const nameWithoutId = `${existingTransaction.description}-${Math.floor(
    Date.now() / 1000,
  )}.${ext}`;

  // Getting initial amount of documents there are for this transaction to ensure that the proper number are present
  // at the end (checks success of the upload)

  await getNumberOfDocumentsForTransaction(existingTransaction.id);
  let tDocResult = TransactionDocument.create();
  let status;

  try {
    status = await S3ClientService.uploadFileToS3Bucket(
      name,
      fileData,
      'kalos-transactions',
      existingTransaction.vendorCategory,
    );
  } catch (err) {
    console.error(`An error occurred while uploading the file to S3: ${err}`);
    try {
      const log = ActivityLog.create();
      log.activityDate = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
      log.userId = loggedUserId;
      log.activityName = `ERROR : An error occurred while uploading a file to the S3 bucket ${'kalos-transactions'}. File name: ${name}. Error: ${err}`;
      await ActivityLogClientService.Create(log);
    } catch (err) {
      console.error(
        `An error occurred while uploading an activity log for an error in an S3 bucket: ${err}`,
      );
    }
  }

  if (status === 'ok') {
    const fReq = File.create();
    fReq.bucket = 'kalos-transactions';
    fReq.name = name;
    fReq.mimeType = name;
    fReq.ownerId = loggedUserId;
    let uploadFile;
    try {
      uploadFile = await FileClientService.Create(fReq);
    } catch (err) {
      console.error(`An error occurred while creating a file: ${err}`);
      try {
        const log = ActivityLog.create();
        log.activityDate = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
        log.userId = loggedUserId;
        log.activityName = `ERROR : An error occurred while creating a file. File name: ${name}. Error: ${err}`;
        await ActivityLogClientService.Create(log);
      } catch (err) {
        console.error(
          `An error occurred while uploading an activity log for a failed file upload: ${err}`,
        );
      }
    }

    if (!uploadFile) {
      console.error('No file was uploaded - returning.');
      return;
    }
    const tDoc = TransactionDocument.create();
    if (fileDescription) tDoc.description = fileDescription;
    if (invoiceWaiverType) tDoc.typeId = invoiceWaiverType;
    if (fileDescription != 'Invoice') {
      tDoc.typeId = 1;
    }
    if (existingTransaction) tDoc.transactionId = existingTransaction.id;
    tDoc.reference = nameWithoutId;
    tDoc.fileId = uploadFile.id;
    tDoc.uploaderId = loggedUserId;
    try {
      // @ts-ignore
      tDocResult = await TransactionDocumentClientService.Create(tDoc);
    } catch (err) {
      console.error(`An error occurred while creating a transaction document: ${err}`);
      try {
        const log = ActivityLog.create();
        log.activityDate = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
        log.userId = loggedUserId;
        log.activityName = `ERROR : An error occurred while creating a transaction document. File name: ${name}. Error: ${err}`;
        await ActivityLogClientService.Create(log);
      } catch (err) {
        console.error(
          `An error occurred while uploading an activity log for a failed transaction document upload: ${err}`,
        );
      }
    }
  } else {
    // status was not "ok"
    alert('An error occurred while uploading the file to the S3 bucket.');
    try {
      const log = ActivityLog.create();
      log.activityDate = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
      log.userId = loggedUserId;
      log.activityName = `ERROR : An error occurred while uploading a file to the S3 bucket ${'kalos-transactions'} (Status came back as ${status}). File name: ${name}`;
      await ActivityLogClientService.Create(log);
    } catch (err) {
      console.error(
        `An error occurred while uploading an activity log for an error in an S3 bucket: ${err}`,
      );
    }
  }

  try {
    /*
    // const docs = await getNumberOfDocumentsForTransaction(
    //   existingTransaction.id,
    // );
    //const docsRequest = TransactionDocument.create();
    //docsRequest.id = existingTransaction.id;
    //docsRequest.fieldMask = ['FileId', 'Reference'];
    const docs = (
      await TransactionDocumentClientService.BatchGet(docsRequest)
    ).getTotalCount();
    */
    // Just double checking that everything worked.
    if (tDocResult.id == 0) {
      alert('Upload was unsuccessful, please contact the webtech team if this issue persists.');
      return;
    }
    //console.log('DOC LENGTH WAS GOOD FROM CHECK: ', docs);
  } catch (err) {
    console.error(
      `An error occurred while double-checking that the file which was just uploaded exists: ${err}`,
    );
    alert(
      'An error occurred while double-checking that the file exists. Please retry the upload, and if the problem persists, please contact the webtech team.',
    );
  }
};

/* MIGRATION HELPERS */

/**
 *
 * @param data the output object of a Form component (see components library)
 * @param result a proper request object compatible with kalos-rpc (instantiate with new)
 * @returns the provided request object with all the form data applied
 *
 * note this may need debugging
 */
const makeSafeFormObject_DEPRECATED = function makeSafeFormObject_DEPRECATED<T>(
  data: T,
  result: T,
) {
  // @ts-ignore
  const keys = Object.keys(data);
  for (const key of keys) {
    if (key.startsWith('get')) {
      try {
        if (typeof (data as any)[key] != 'function') {
          (result as any)[key.replace('get', 'set')]((data as any)[key]);
        }
      } catch (err) {
        console.log('failed to set value on request object', err);
      }
    }
  }
  return result;
};

/**
 *
 * @param type
 * @param key any Proto.AsObject key
 * @returns method name for a setter or getter compatible with protos
 */
const keyToMethodName = function keyToMethodName(type: 'get' | 'set', key: string): string {
  return `${type}${key[0].toUpperCase()}${key.slice(1)}`;
};

const sortUserByLastname = function sortUserByLastname(a: User, b: User): number {
  const nameA = a.lastname.toLocaleLowerCase();
  const nameB = b.lastname.toLocaleLowerCase();
  if (nameA > nameB) return 1;
  if (nameB > nameA) return -1;
  return 0;
};

const cloneProtoObject_DEPRECATED = function cloneProtoObject<T>(input: T, output: T): T {
  // @ts-ignore
  for (const key of Object.keys(input)) {
    if (key.startsWith('set')) {
      const getKey = key.replace('set', 'get');
      // @ts-ignore
      output[key](input[getKey]());
    }
  }
  return output;
};

const loadScript = (source: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = resolve;
    script.onerror = reject;
    script.async = true;
    script.src = source;
    document.body.appendChild(script);
  });
};

function debounce(func: any, wait: any, options?: any) {
  let lastArgs: any, lastThis: any, maxWait: any, result: any, timerId: any, lastCallTime: any;

  let lastInvokeTime = 0;
  let leading = false;
  let maxing = false;
  let trailing = true;

  // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
  const useRAF = !wait && wait !== 0 && typeof window.requestAnimationFrame === 'function';
  // const useRAF = false

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }
  wait = +wait || 0;
  const type = typeof options;
  if (options != null && (type === 'object' || type === 'function')) {
    leading = !!options.leading;
    maxing = 'maxWait' in options;
    maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }

  function invokeFunc(time: any) {
    const args = lastArgs;
    const thisArg = lastThis;

    lastArgs = lastThis = undefined;
    lastInvokeTime = time;
    result = func.apply(thisArg, args);
    return result;
  }

  function startTimer(pendingFunc: any, wait: any) {
    if (useRAF) {
      window.cancelAnimationFrame(timerId);
      return window.requestAnimationFrame(pendingFunc);
    }
    return setTimeout(pendingFunc, wait);
  }

  function cancelTimer(id: any) {
    if (useRAF) {
      return window.cancelAnimationFrame(id);
    }
    clearTimeout(id);
  }

  function leadingEdge(time: any) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time;
    // Start the timer for the trailing edge.
    timerId = startTimer(timerExpired, wait);
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time: any) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;
    const timeWaiting = wait - timeSinceLastCall;

    return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;
  }

  function shouldInvoke(time: any) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (
      lastCallTime === undefined ||
      timeSinceLastCall >= wait ||
      timeSinceLastCall < 0 ||
      (maxing && timeSinceLastInvoke >= maxWait)
    );
  }

  function timerExpired() {
    const time = Date.now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    // Restart the timer.
    timerId = startTimer(timerExpired, remainingWait(time));
  }

  function trailingEdge(time: any) {
    timerId = undefined;

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = lastThis = undefined;
    return result;
  }

  function cancel() {
    if (timerId !== undefined) {
      cancelTimer(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = lastThis = timerId = undefined;
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now());
  }

  function pending() {
    return timerId !== undefined;
  }

  function debounced(...args: any) {
    const time = Date.now();
    const isInvoking = shouldInvoke(time);

    lastArgs = args;
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    lastThis = this;
    lastCallTime = time;

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = startTimer(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = startTimer(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  debounced.pending = pending;
  return debounced;
}

function navigateNewTab(route: string) {
  console.log(' NAVIGATING TO A NEW TAB');
  console.log('ROUTE INFORMATION: ', { location: window.location, route });
  console.log('ORIGIN: ', window.location.origin);
  const url = `${window.location.origin}${route}`;
  console.log('ORIGIN URL: ', url);
  return window.open(url, '_blank');
}
function navigateToNewPage(route: string) {
  const url = `${window.location.origin}${route}`;
  window.location.href = url;
}

const zipCodeValidation = (value: string) => {
  const checkValid = (val: string) => {
    const numsRegex = /^\d+$/;
    let error = '';
    if (!val) error = 'This field is required';
    else if (!numsRegex.test(val)) error = 'Please use only numbers';
    else if (val.length > 10) error = 'Zipcode cannot contain more than 10 numbers';
    return error;
  };
  return checkValid(value);
};

const convertFieldNameIntoFieldMaskEntry = (fieldName: string) =>
  fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

const formatBackendDate = (date: string) => {
  const dateObj = new Date(parseISO(date));

  if (date != NULL_TIME && date != '0000-00-00 00:00:00' && isValid(dateObj)) {
    return format(dateObj, 'yyyy-MM-dd');
  }

  return '-';
};

export type DirtyModelFields<T extends Record<string, unknown>> = Partial<
  Record<keyof T, boolean | boolean[]>
>;

export type SaveHandler<T extends Record<string, unknown>> = (arg: {
  data: T;
  dirtyFields: DirtyModelFields<T>;
}) => void;

const generateDateRangeAndDateTargetFromDates = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  {
    endDateFieldName,
    startDateFieldName,
  }: {
    startDateFieldName: string;
    endDateFieldName: string;
  },
) => {
  const formatConfig = 'yyyy-MM-dd HH-mm-ss';

  let dateRange: string[] | undefined = undefined;
  let dateTarget: string[] | undefined = undefined;
  if (startDate && endDate) {
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 999);
    dateRange = ['>=', format(startDate, formatConfig), '<=', format(endDate, formatConfig)];
    dateTarget = [startDateFieldName, endDateFieldName];
  } else if (startDate) {
    dateRange = [
      '>=',
      format(startDate.setHours(0, 0, 0, 0), formatConfig),
      '<=',
      format(startDate.setHours(23, 59, 59, 999), formatConfig),
    ];
    dateTarget = [startDateFieldName, startDateFieldName];
  } else if (endDate) {
    dateRange = [
      '>=',
      format(endDate.setHours(0, 0, 0, 0), formatConfig),
      '<=',
      format(endDate.setHours(23, 59, 59, 999), formatConfig),
    ];
    dateTarget = [endDateFieldName, endDateFieldName];
  }
  return { dateRange, dateTarget };
};

const hasAccessToVehicleAssetCreation = (user: User) => {
  return user.permissionGroups.find((el) => el.name === 'FleetAdmin' || el.name === 'Manager');
};

const tableStaticData = <T>(length: number = 3) => Array.from({ length }, () => ({}) as T);

function digitCeil(value: number, digits: number) {
  const multiplier = Math.pow(10, digits);
  return Math.ceil(value * multiplier) / multiplier;
}

export function prettyMoney(amount: number): string {
  const [dollars, cents] = amount.toString().split('.');
  if (!cents) {
    return `${dollars}.00`;
  } else if (cents.length === 1) {
    return `${dollars}.${cents}0`;
  } else {
    return `${dollars}.${cents}`;
  }
}

export {
  digitCeil,
  generateDateRangeAndDateTargetFromDates,
  SUBJECT_TAGS,
  convertFieldNameIntoFieldMaskEntry,
  SUBJECT_TAGS_TRANSACTIONS,
  getDateArgs,
  getDateTimeArgs,
  cfURL,
  BASE_URL,
  timestamp,
  getEditDistance,
  getURLParams,
  b64toBlob,
  formatTime,
  formatDate,
  formatDay,
  formatDateDay,
  formatDateTimeDay,
  makeFakeRows,
  formatDateTime,
  range,
  trailingZero,
  roundNumber,
  getWeekOptions,
  escapeText,
  newBugReport,
  newBugReportImage,
  forceHTTPS,
  customerCheck,
  cleanOrderByField,
  cleanFieldMaskField,
  makeSafeFormObject_DEPRECATED,
  keyToMethodName,
  sortUserByLastname,
  cloneProtoObject_DEPRECATED,
  loadScript,
  debounce,
  navigateNewTab,
  navigateToNewPage,
  zipCodeValidation,
  formatBackendDate,
  tableStaticData,
  hasAccessToVehicleAssetCreation,
};

/**
 * Converts an HEIC image to JPEG format with optimized memory handling
 * @param data The binary data of the HEIC image
 * @param fileName The name of the file (used to check if it's HEIC)
 * @param mimeType Optional mime type of the file
 * @param options Optional configuration for conversion
 * @returns A Promise that resolves to a Blob of the converted image (or original if not HEIC)
 */
export const convertHeicToJpeg = async (
  data: Uint8Array | ArrayBuffer,
  fileName: string,
  mimeType?: string,
  options: {
    quality?: number;
    signal?: AbortSignal;
  } = {},
): Promise<Blob> => {
  const { quality = 0.8, signal } = options;
  const isHeic =
    fileName.toLowerCase().endsWith('.heic') || mimeType?.toLowerCase().includes('image/heic');

  if (!isHeic) {
    // Return original data as blob if not HEIC
    return new Blob([data], {
      type: mimeType || getMimeType(fileName) || 'application/octet-stream',
    });
  }

  try {
    // Check if conversion should be aborted
    if (signal?.aborted) {
      throw new Error('Conversion aborted');
    }

    // Create a blob URL for the HEIC image
    const heicBlob = new Blob([data], { type: 'image/heic' });

    // Convert HEIC to JPEG with quality setting to manage file size
    const convertedBlob = (await heic2any({
      blob: heicBlob,
      toType: 'image/jpeg',
      quality,
    })) as Blob;

    // Clean up the original blob
    if (heicBlob instanceof Blob) {
      // In some browsers, we can explicitly release the memory
      if ('close' in heicBlob) {
        (heicBlob as any).close();
      }
    }

    return convertedBlob;
  } catch (error) {
    console.error('Error converting HEIC:', error);

    // If conversion was aborted, throw the error
    if (error instanceof Error && error.message === 'Conversion aborted') {
      throw error;
    }

    // Fallback to original blob if conversion fails
    return new Blob([data], { type: 'image/heic' });
  }
};

// Helper to create an abortable HEIC conversion
export const createAbortableHeicConversion = () => {
  const controller = new AbortController();
  return {
    convert: (data: Uint8Array | ArrayBuffer, fileName: string, mimeType?: string) =>
      convertHeicToJpeg(data, fileName, mimeType, { signal: controller.signal }),
    abort: () => controller.abort(),
  };
};
