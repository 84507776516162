import { Document, File } from '@kalos/kalos-rpc';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import {
  DocumentClientService,
  FileClientService,
  PDFClientService,
  timestamp,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { Alert } from '../Alert';
import { Button } from '../Button';

type StringDimension = {
  value1: string;
  value2: string;
};
export interface Props {
  propertyId: number;
  jobNumber: number;
  onCreateDoc?: () => void;
}

type SellSheetType = {
  jobNumber: number;
  customerName: string;
  propertyAddress: string;
  systemType: string;
  manufacturer: StringDimension[];
  modelNumber: StringDimension[];
  serialNumber: StringDimension[];
  heaterLocation: string;
  heaterGallons: string;
  sameUnit: string;
  sameUnitReplace: string;
  breakerType: string;
  heaterBreaker: StringDimension[];
  breakerDimensions: StringDimension[];
  wireSize: StringDimension[];
  timeFrame: string;
  email: string;
  notes: string;
};

type Errors = {
  jobNumber?: string;
  customerName?: string;
  propertyAddress?: string;
  systemType?: string;
  manufacturer?: string;
  modelNumber?: string;
  serialNumber?: string;
  heaterLocation?: string;
  heaterGallons?: string;
  sameUnit?: string;
  sameUnitReplace?: string;
  breakerType?: string;
  breakerDimensions?: string;
  heaterBreaker?: string;
  wireSize?: string;
  timeFrame?: string;
  email?: string;
  notes?: string;
};

const checkFormIsValid = (form: SellSheetType): Errors => {
  const errors: Errors = {};

  if (form.jobNumber === 0) errors.jobNumber = 'This field is required';

  if (form.customerName.length === 0) errors.customerName = 'This field is required';

  if (form.propertyAddress.length === 0) errors.propertyAddress = 'This field is required';

  if (form.systemType === 'Choose One') errors.systemType = 'This field is required';

  if (form.manufacturer.length === 0) errors.manufacturer = 'This field is required';

  if (form.modelNumber.length === 0) errors.modelNumber = 'This field is required';

  if (form.serialNumber.length === 0) errors.serialNumber = 'This field is required';

  if (form.heaterLocation === 'Choose One') errors.heaterLocation = 'This field is required';

  if (form.heaterGallons === 'Choose One') errors.heaterGallons = 'This field is required';

  if (form.sameUnit === 'Choose One') errors.sameUnit = 'This field is required';

  if (form.breakerType === 'Choose One') errors.breakerType = 'This field is required';

  if (form.heaterBreaker.length === 0) errors.heaterBreaker = 'This field is required';

  if (form.breakerDimensions.length === 0) errors.breakerDimensions = 'This field is required';

  if (form.wireSize.length === 0) errors.wireSize = 'This field is required';

  if (form.timeFrame.length === 0) errors.timeFrame = 'This field is required';

  if (form.email.length === 0) errors.email = 'This field is required';

  if (form.notes.length === 0) errors.notes = 'This field is required';

  return errors;
};

export const WaterHeaterSellSheet: React.FC<Props> = ({ propertyId, jobNumber, onCreateDoc }) => {
  const [form, setForm] = useState<SellSheetType>({
    jobNumber: 0,
    customerName: '',
    propertyAddress: '',
    systemType: '',
    manufacturer: [{ value1: '', value2: '' }],
    modelNumber: [{ value1: '', value2: '' }],
    serialNumber: [{ value1: '', value2: '' }],
    heaterLocation: '',
    heaterGallons: '',
    sameUnit: '',
    sameUnitReplace: '',
    breakerType: 'Choose One',
    heaterBreaker: [{ value1: '', value2: '' }],
    breakerDimensions: [{ value1: '', value2: '' }],
    wireSize: [{ value1: '', value2: '' }],
    timeFrame: '',
    email: '',
    notes: '',
  });
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});

  const createMyDocument = async (formData: SellSheetType) => {
    const content = `<div style="font-family: Arial, sans-serif; margin: 0 auto; width: 600px;">
    <h1 style="text-align: center;">AC Quote</h1>
    <table style="width: 100%; border-collapse: collapse;">
    <h1>Water Heater Sell Sheet</h1>
        <table>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Job Number</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.jobNumber}</td>
            </tr>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Customer Name</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.customerName}</td>
            </tr>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Property Address</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.propertyAddress}</td>
            </tr>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">System Type</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.systemType}</td>
            </tr>
            ${formData.manufacturer
              .map(
                (m, index) => `<tr>
                <td style="border: 1px solid #000; padding: 10px;">Manufacturer ${index + 1}</td>
                <td style="border: 1px solid #000; padding: 10px;">${m.value1} / ${m.value2}</td>
            </tr>`,
              )
              .join('')}
            ${formData.modelNumber
              .map(
                (m, index) => `<tr>
                <td style="border: 1px solid #000; padding: 10px;">Model Number ${index + 1}</td>
                <td style="border: 1px solid #000; padding: 10px;">${m.value1} / ${m.value2}</td>
            </tr>`,
              )
              .join('')}
            ${formData.serialNumber
              .map(
                (s, index) => `<tr>
                <td style="border: 1px solid #000; padding: 10px;">Serial Number ${index + 1}</td>
                <td style="border: 1px solid #000; padding: 10px;">${s.value1} / ${s.value2}</td>
            </tr>`,
              )
              .join('')}
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Heater Location</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.heaterLocation}</td>
            </tr>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Heater Gallons</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.heaterGallons}</td>
            </tr>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Same Unit</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.sameUnit}</td>
            </tr>
            ${
              formData.sameUnit === 'no'
                ? `<tr>
                <td style="border: 1px solid #000; padding: 10px;">Unit Replacement Choice</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.sameUnitReplace}</td>
            </tr>`
                : ''
            }
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Breaker Type</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.breakerType}</td>
            </tr>
            
            ${formData.heaterBreaker
              .map(
                (h, index) => `<tr>
                <td style="border: 1px solid #000; padding: 10px;">Heater Breaker ${index + 1}</td>
                <td style="border: 1px solid #000; padding: 10px;">${h.value1} / ${h.value2}</td>
            </tr>`,
              )
              .join('')}
            ${formData.wireSize
              .map(
                (w, index) => `<tr>
                <td style="border: 1px solid #000; padding: 10px;">Wire Size ${index + 1}</td>
                <td style="border: 1px solid #000; padding: 10px;">${w.value1} / ${w.value2}</td>
            </tr>`,
              )
              .join('')}
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Time Frame</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.timeFrame}</td>
            </tr>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Email</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.email}</td>
            </tr>
            <tr>
                <td style="border: 1px solid #000; padding: 10px;">Notes</td>
                <td style="border: 1px solid #000; padding: 10px;">${formData.notes}</td>
            </tr>
        </table>
    </div>
  
  `;

    const html = `
<!DOCTYPE html>
<html>
  
            <meta charset="UTF-8">
    <title>Water Heater Sell Sheet</title>
  </head>
  <body>
   ${content}
  </body>
</html>
`;

    // Use the PDFClientService to convert the HTML to a PDF
    const url = await PDFClientService.getUploadedHTMLUrl(
      html,
      `WaterHeaterSellSheet-${jobNumber}-${timestamp()}.pdf`,
      'kalosdocs-prod',
    );

    return url;
  };

  const uploadDocument = async () => {
    const errors = checkFormIsValid(form);

    setErrors(errors);
    setLoading(true);
    try {
      if (!Object.keys(errors).length) {
        setLoading(true);
        const url = await createMyDocument(form);
        if (url) {
          //we have to fetch the file first
          const fileRes = await FileClientService.Get(File.create({ name: url.key }));
          if (fileRes) {
            // the file was created, so we can create the document record
            await DocumentClientService.Create(
              Document.create({
                fileId: fileRes.id,
                propertyId: propertyId,
                description: fileRes.name,
                filename: fileRes.name,
              }),
            );
          }
          onCreateDoc?.();
          setMessage(`Document Successfully Created`);
        }
      }
    } catch (error) {
      console.error('Failed to create or upload document:', error);
      setLoading(false);
      setMessage(`Error with Generating document: ${error}`);
      return;
    }
    setLoading(false);
  };

  const handleAddManufacturerDimension = () => {
    setForm({
      ...form,
      manufacturer: [...form.manufacturer, { value1: '', value2: '' }],
    });
  };

  const handleRemoveManufacturerDimension = (index: number) => {
    setForm({
      ...form,

      manufacturer: form.manufacturer.filter((_, i) => i !== index),
    });
  };

  const handleAddModelNumberDimension = () => {
    setForm({
      ...form,
      modelNumber: [...form.modelNumber, { value1: '', value2: '' }],
    });
  };

  const handleRemoveModelNumberDimension = (index: number) => {
    setForm({
      ...form,

      modelNumber: form.modelNumber.filter((_, i) => i !== index),
    });
  };

  const handleAddSerialNumberDimension = () => {
    setForm({
      ...form,
      serialNumber: [...form.serialNumber, { value1: '', value2: '' }],
    });
  };

  const handleRemoveSerialNumberDimension = (index: number) => {
    setForm({
      ...form,

      serialNumber: form.serialNumber.filter((_, i) => i !== index),
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleAddHeaterBreakerDimension = () => {
    setForm({
      ...form,
      heaterBreaker: [...form.heaterBreaker, { value1: '', value2: '' }],
    });
  };

  const handleRemoveHeaterBreakerDimension = (index: number) => {
    setForm({
      ...form,

      heaterBreaker: form.heaterBreaker.filter((_, i) => i !== index),
    });
  };

  const handleAddWireSizeDimension = () => {
    setForm({
      ...form,
      wireSize: [...form.wireSize, { value1: '', value2: '' }],
    });
  };

  const handleRemoveWireSizeDimension = (index: number) => {
    setForm({
      ...form,

      wireSize: form.wireSize.filter((_, i) => i !== index),
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name as string]: value as string,
    }));
  };

  const handleStringDimensionChange =
    <
      K extends keyof SellSheetType, // K is a key of SellSheetType
      F extends keyof StringDimension, // F is a key of StringDimension
    >(
      arrayName: K,
      index: number,
      field: F,
    ) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // Ensuring the target field is an array of StringDimension
      const targetArray = form[arrayName] as StringDimension[];

      // Creating a new copy of the array with the updated value
      const updatedArray = targetArray.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: event.target.value };
        }
        return item;
      });

      // Updating the state
      setForm((prevForm) => ({
        ...prevForm,
        [arrayName]: updatedArray,
      }));
    };

  return (
    <div>
      <form className="pt-4">
        <Grid container direction="column" alignItems="flex-start" spacing={3}>
          <Grid item>
            <TextField
              label="Job Number"
              name="jobNumber"
              value={form.jobNumber}
              onChange={handleInputChange}
              error={!!errors.jobNumber}
            />
            <p className="pt-1 text-sm text-red-500 ">{errors.jobNumber}</p>
          </Grid>
          <Grid item>
            <InputLabel
              id="customerName-label"
              className="mb-[10px] w-full font-bold"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Customer Name?
            </InputLabel>
            <TextField
              name="customerName"
              value={form.customerName}
              onChange={handleInputChange}
              error={!!errors.customerName}
            ></TextField>
            <Grid container spacing={-5}>
              <InputLabel
                id="propertyAddress-label"
                className="mb-[10px] mt-[15px] w-full font-bold"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                Property Address
              </InputLabel>
              <TextField
                name="propertyAddress"
                value={form.propertyAddress}
                onChange={handleInputChange}
                error={!!errors.propertyAddress}
              ></TextField>
            </Grid>
            <Grid container spacing={-5}>
              <InputLabel
                id="systemType-label"
                className="mb-[10px] mt-[15px]  w-full font-bold"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                System Type?
              </InputLabel>
              <Select
                label="systemType-label"
                name="systemType"
                value={form.systemType}
                onChange={handleSelectChange}
                error={!!errors.systemType}
              >
                <MenuItem value="Natural Gas">Natural Gas</MenuItem>
                <MenuItem value="Electric">Electric</MenuItem>
                <MenuItem value="Tankless">Tankless</MenuItem>
                <MenuItem value="heatPumpHybrid">Heat Pump/Hybrid</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <InputLabel
                id="manufacturer-label"
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                  paddingTop: '10px',
                }}
              >
                Manufacturer
              </InputLabel>
              {form.manufacturer.map((dim, index) => (
                <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                  <Grid item xs={4}>
                    <TextField
                      label={`Manufacturer ${index + 1} `}
                      value={dim.value1}
                      onChange={handleStringDimensionChange('manufacturer', index, 'value1')}
                      error={!!errors.manufacturer}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={`Manufacturer ${index + 1}`}
                      value={dim.value2}
                      onChange={handleStringDimensionChange('manufacturer', index, 'value2')}
                      error={!!errors.manufacturer}
                    />
                  </Grid>
                  <Grid item xs={3} container alignItems="center">
                    {form.manufacturer.length > 1 && (
                      <IconButton onClick={() => handleRemoveManufacturerDimension(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleAddManufacturerDimension}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item>
              <InputLabel
                id="modelNumber-label"
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                }}
              >
                Model Number
              </InputLabel>
              {form.modelNumber.map((dim, index) => (
                <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                  <Grid item xs={4}>
                    <TextField
                      label={`Model Number ${index + 1} `}
                      value={dim.value1}
                      onChange={handleStringDimensionChange('modelNumber', index, 'value1')}
                      error={!!errors.modelNumber}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={`Model Number ${index + 1}`}
                      value={dim.value2}
                      onChange={handleStringDimensionChange('modelNumber', index, 'value2')}
                      error={!!errors.modelNumber}
                    />
                  </Grid>
                  <Grid item xs={3} container alignItems="center">
                    {form.modelNumber.length > 1 && (
                      <IconButton onClick={() => handleRemoveModelNumberDimension(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleAddModelNumberDimension}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item>
              <InputLabel
                id="serialNumber-label"
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                }}
              >
                Serial Number
              </InputLabel>
              {form.serialNumber.map((dim, index) => (
                <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                  <Grid item xs={4}>
                    <TextField
                      label={`Serial Number ${index + 1} `}
                      value={dim.value1}
                      onChange={handleStringDimensionChange('serialNumber', index, 'value1')}
                      error={!!errors.serialNumber}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={`Serial Number ${index + 1}`}
                      value={dim.value2}
                      onChange={handleStringDimensionChange('serialNumber', index, 'value2')}
                      error={!!errors.serialNumber}
                    />
                  </Grid>
                  <Grid item xs={3} container alignItems="center">
                    {form.serialNumber.length > 1 && (
                      <IconButton onClick={() => handleRemoveSerialNumberDimension(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleAddSerialNumberDimension}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item>
              <InputLabel
                id="heaterLocation-label"
                style={{
                  fontWeight: 'bold',
                  borderBottom: '1px solid black',
                }}
              >
                Water Heater Location
              </InputLabel>

              <Select
                className="min-w-[190px]"
                name="heaterLocation"
                value={form.heaterLocation}
                onChange={handleSelectChange}
              >
                <MenuItem value="Choose One">Choose One</MenuItem>

                <MenuItem value="In Garage On Ground">In Garage on Ground</MenuItem>
                <MenuItem value="In Garage On Wood Platform">In Garage on Wooden Platform</MenuItem>
                <MenuItem value="Interior Closet">Interior Closet</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <InputLabel
                id="heaterGallons-label"
                style={{
                  fontWeight: 'bold',
                  borderBottom: '1px solid black',
                }}
              >
                Water Heater Gallons
              </InputLabel>

              <Select
                className="min-w-[190px]"
                name="heaterGallons"
                value={form.heaterGallons}
                onChange={handleSelectChange}
              >
                <MenuItem value="Choose One">Choose One</MenuItem>

                <MenuItem value="30">30</MenuItem>
                <MenuItem value="40">40</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="65">65</MenuItem>
                <MenuItem value="80">80</MenuItem>
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="Not Sure">Not Sure</MenuItem>
              </Select>
            </Grid>
            <Grid container spacing={-5}>
              <InputLabel
                id="sameUnit"
                className="w-full font-bold underline"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                When replacing, Do you want to use the same kind of unit?
              </InputLabel>
              <Grid item xs={12} sm={6}>
                <Select
                  label="sameUnit"
                  name="sameUnit"
                  value={form.sameUnit}
                  onChange={handleSelectChange}
                  error={!!errors.sameUnit}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {form.sameUnit === 'no' && (
              <Grid item xs={12}>
                <Grid item>
                  <InputLabel
                    id="sameUnitReplace"
                    className="w-full font-bold underline"
                    style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                  >
                    Unit Selection
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <Select
                      label="sameUnitReplace"
                      name="sameUnitReplace"
                      error={!!errors.sameUnitReplace}
                      value={form.sameUnitReplace}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="Larger Size">Larger Size</MenuItem>
                      <MenuItem value="Smaller Size">Smaller Size</MenuItem>
                      <MenuItem value="High Efficiency Unit">High Efficiency Unit</MenuItem>
                      <MenuItem value="Tankless Water Heater">Tankless Water Heater</MenuItem>
                      <MenuItem value="Not Sure">Not Sure</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <InputLabel
                id="breakerType-label"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black', marginTop: '10px' }}
              >
                Breaker Type
              </InputLabel>

              <Select
                className="min-w-[190px]"
                name="breakerType"
                value={form.breakerType}
                onChange={handleSelectChange}
              >
                <MenuItem value="Choose One">Choose One</MenuItem>

                <MenuItem value="Siemens">Siemens</MenuItem>
                <MenuItem value="Square D QO">Square D QO</MenuItem>
                <MenuItem value="Square D Homeline">Square D Homeline</MenuItem>
                <MenuItem value="GE Slimeline">GE Slimeline</MenuItem>
                <MenuItem value="GE regular">GE regular</MenuItem>
                <MenuItem value="Cutler Hammer Black">Cutler Hammer Black</MenuItem>
                <MenuItem value="Cutler Hammer Brown">Cutler Hammer Brown</MenuItem>
                <MenuItem value="WestingHouse">WestingHouse</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </Grid>
            Breaker Sizes
            <Grid item>
              <InputLabel
                id="heaterBreaker-label"
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                }}
              >
                Heater Breaker
              </InputLabel>
              {form.heaterBreaker.map((dim, index) => (
                <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                  <Grid item xs={4}>
                    <TextField
                      label={`Heater Breaker ${index + 1} `}
                      value={dim.value1}
                      onChange={handleStringDimensionChange('heaterBreaker', index, 'value1')}
                      error={!!errors.heaterBreaker}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={`Heater Breaker ${index + 1}`}
                      value={dim.value2}
                      onChange={handleStringDimensionChange('heaterBreaker', index, 'value2')}
                      error={!!errors.heaterBreaker}
                    />
                  </Grid>
                  <Grid item xs={3} container alignItems="center">
                    {form.heaterBreaker.length > 1 && (
                      <IconButton onClick={() => handleRemoveHeaterBreakerDimension(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleAddHeaterBreakerDimension}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item>
              <InputLabel
                id="wireSize-label"
                style={{
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                }}
              >
                Wire Size
              </InputLabel>
              {form.wireSize.map((dim, index) => (
                <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                  <Grid item xs={4}>
                    <TextField
                      label={`Wire Size ${index + 1} `}
                      value={dim.value1}
                      onChange={handleStringDimensionChange('wireSize', index, 'value1')}
                      error={!!errors.wireSize}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={`Wire Size ${index + 1}`}
                      value={dim.value2}
                      onChange={handleStringDimensionChange('wireSize', index, 'value2')}
                      error={!!errors.wireSize}
                    />
                  </Grid>
                  <Grid item xs={3} container alignItems="center">
                    {form.wireSize.length > 1 && (
                      <IconButton onClick={() => handleRemoveWireSizeDimension(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleAddWireSizeDimension}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              <Grid container spacing={-5}>
                <InputLabel
                  id="timeFrame-label"
                  className="w-full font-bold underline"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Time Frame
                </InputLabel>
                <Select
                  label="timeFrame"
                  name="timeFrame"
                  value={form.timeFrame}
                  onChange={handleSelectChange}
                  error={!!errors.timeFrame}
                >
                  <MenuItem value="Choose One">Choose One</MenuItem>
                  <MenuItem value="Immediately Unit Operational">
                    Immediately - Operational Unit
                  </MenuItem>
                  <MenuItem value="First Date Available">First Date Available</MenuItem>
                  <MenuItem value="Flexible Best Pricing">Flexible - Best Pricing</MenuItem>
                  <MenuItem value="Just Curious">Just Curious</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={-5}>
              <InputLabel
                id="email-label"
                className="w-full font-bold underline"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                Customer Email Address
              </InputLabel>
              <TextField
                name="email"
                value={form.email}
                onChange={handleInputChange}
                error={!!errors.email}
              ></TextField>
            </Grid>
            <Grid container spacing={-5}>
              <InputLabel
                id="notes-label"
                className="w-full font-bold underline"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                Additional Notes
              </InputLabel>
              <TextField
                name="notes"
                value={form.notes}
                onChange={handleInputChange}
                error={!!errors.notes}
                multiline
                rows={4}
                fullWidth
                placeholder="Enter any additional notes or context that would be helpful for the estimation team or technician"
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Button className="!mt-[20px]" label="Create" onClick={() => uploadDocument()}></Button>
      {message != '' && (
        <Alert
          className="CenteredModal"
          title="Water Heater Sell Sheet/Quote"
          open
          onClose={() => setMessage('')}
        >
          {message}
        </Alert>
      )}
      {loading && <Loader className="SellSheet-Loader" />}
    </div>
  );
};
