import React from 'react';

// Define props for the devtools component
export type DevtoolsProps = {
  router?: any;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  initialIsOpen?: boolean;
  hideToggleButton?: boolean;
  toggleButtonStyle?: React.CSSProperties;
};

const TanStackRouterDevtoolsLazy = React.lazy(() =>
  // Lazy load in development
  import('@tanstack/router-devtools').then((res) => ({
    default: res.TanStackRouterDevtools,
    // For Embedded Mode
    // default: res.TanStackRouterDevtoolsPanel
  })),
);

// Forward the props to the lazy component
export const DevToolsWrapper = (props: DevtoolsProps) => {
  // Extract toggleButtonStyle for special handling
  const { toggleButtonStyle, ...otherProps } = props;

  // Create toggleButtonProps with the style
  const toggleButtonProps = toggleButtonStyle
    ? {
        style: toggleButtonStyle,
        className: 'router-devtools-toggle',
      }
    : undefined;

  return <TanStackRouterDevtoolsLazy {...otherProps} toggleButtonProps={toggleButtonProps} />;
};
