import {
  type ClassCode,
  type JobSubtype,
  type TimesheetDepartment,
  type TransactionAccount,
  type User,
} from '@kalos/kalos-rpc';
import { CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { type UseQueryResult } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { useClassCodeListQuery } from '../../../../hooks/react-query/useClassCodeQuery';
import { useJobSubTypesQuery } from '../../../../hooks/react-query/useJobSubTypesQuery';
import { useTimesheetDepartmentListQuery } from '../../../../hooks/react-query/useTimesheetDepartmentQuery';
import { useTransactionAccountBatchGet } from '../../../../hooks/react-query/useTransactionAccountsQuery';
import { useBatchUserQuery } from '../../../../hooks/react-query/useUserQuery';

type QueryBatchGetResult<T> =
  | {
      results: T[];
      totalCount: number;
    }
  | undefined;

type QueryPickerProps<T, QueryArgs> = {
  className?: string;
  required?: boolean;
  showAlternativeLabel?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  query: (args: QueryArgs) => UseQueryResult<QueryBatchGetResult<T>>;
  queryArgs: QueryArgs;
  label?: React.ReactNode;
  selected: number;
  loading?: boolean;
  onSelect?(id: number): void;
  filter?: (item: T) => boolean;
  sort?: (a: T, b: T) => number;
  renderItem(item: T): React.ReactNode;
};

export const QueryPicker = <T, QueryArgs>({
  label,
  showAlternativeLabel = true,
  filter,
  queryArgs,
  sort,
  onSelect,
  renderItem,
  selected,
  className,
  disabled,
  fullWidth,
  required,
  query,
  loading: propLoading,
}: QueryPickerProps<T, QueryArgs>) => {
  const queryResult = query(queryArgs);

  const _disabled = disabled || !queryResult.isSuccess;

  const loading = queryResult.isPending || queryResult.isFetching || propLoading;

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const id = parseInt(e.currentTarget.value as string);
    onSelect?.(id);
  };

  const formattedData = useMemo(() => {
    const data = queryResult.data?.results ?? [];
    if (data.length && sort && filter) {
      return data.filter(filter).sort(sort);
    } else if (data.length && sort) {
      return data.sort(sort);
    } else if (data.length && filter) {
      return data.filter(filter);
    }
    return data;
  }, [queryResult.data?.results, filter, sort]);

  return (
    <FormControl
      className={className}
      required={required}
      disabled={_disabled}
      fullWidth={fullWidth}
    >
      {loading ? (
        <div className="t-0 absolute z-10 h-4 w-[120%]">
          <CircularProgress />
        </div>
      ) : (
        <>
          <InputLabel htmlFor={`${label}-picker`}>{showAlternativeLabel ? label : ''}</InputLabel>
          <NativeSelect
            value={selected}
            onChange={handleSelect}
            IconComponent={undefined}
            inputProps={{ id: `${label}-picker` }}
          >
            <option value={0}>
              {label?.toString().includes('Select') ? '' : 'Select'} {label}
            </option>
            {formattedData.map(renderItem)}
          </NativeSelect>
        </>
      )}
    </FormControl>
  );
};

type TransactionAccountPickerProps = Omit<
  QueryPickerProps<TransactionAccount, Parameters<typeof useTransactionAccountBatchGet>['0']>,
  'query' | 'queryArgs'
>;

export const TransactionAccountPicker = (props: TransactionAccountPickerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { onSelect } = props;
  return (
    <QueryPicker
      // eslint-disable-next-line react-compiler/react-compiler
      query={useTransactionAccountBatchGet}
      queryArgs={{
        filter: {
          isActive: 1,
          orderBy: 'description',
          orderDir: 'ASC',
        },
      }}
      {...props}
      loading={loading}
      onSelect={(id) => {
        setLoading(true);
        Promise.all([onSelect?.(id)]).then(() => {
          setLoading(false);
        });
      }}
    />
  );
};

type TimsheetDepartmentPickerProps = Omit<
  QueryPickerProps<TimesheetDepartment, Parameters<typeof useTransactionAccountBatchGet>['0']>,
  'query' | 'queryArgs'
>;

export const TimsheetDepartmentPicker = (props: TimsheetDepartmentPickerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { onSelect } = props;
  return (
    <QueryPicker
      // eslint-disable-next-line react-compiler/react-compiler
      query={useTimesheetDepartmentListQuery}
      queryArgs={{
        filter: { isActive: 1 },
      }}
      {...props}
      loading={loading}
      onSelect={(id) => {
        setLoading(true);
        Promise.all([onSelect?.(id)]).then(() => {
          setLoading(false);
        });
      }}
    />
  );
};

type EmployeePickerProps = Omit<
  QueryPickerProps<User, Parameters<typeof useBatchUserQuery>['0']>,
  'query' | 'queryArgs'
>;
export const EmployeePicker = (props: EmployeePickerProps) => {
  return (
    <QueryPicker
      // eslint-disable-next-line react-compiler/react-compiler
      query={useBatchUserQuery}
      queryArgs={{
        filters: {
          isEmployee: 1,
          orderBy: 'user_lastname',
          orderDir: 'ASC',
          overrideLimit: true,
        },
      }}
      {...props}
    />
  );
};

type JobSubTypePickerProps = Omit<
  QueryPickerProps<JobSubtype, Parameters<typeof useBatchUserQuery>['0']>,
  'query' | 'queryArgs'
>;

export const JobSubTypePicker = (props: JobSubTypePickerProps) => {
  // eslint-disable-next-line react-compiler/react-compiler
  return <QueryPicker query={useJobSubTypesQuery} queryArgs={{}} {...props} />;
};

type ClassCodePickerProps = Omit<
  QueryPickerProps<ClassCode, Parameters<typeof useClassCodeListQuery>['0']>,
  'query' | 'queryArgs'
>;

export const ClassCodePicker = (props: ClassCodePickerProps) => {
  return (
    <QueryPicker
      // eslint-disable-next-line react-compiler/react-compiler
      query={useClassCodeListQuery}
      queryArgs={{ filters: { isActive: true } }}
      {...props}
    />
  );
};
