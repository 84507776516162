import { Requisition } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { getFileQueryConfig } from './useFileQuery';
import { type EntityFilter } from './utils';

export type RequisitionFilter = EntityFilter<Requisition>;

export const getRequisitionQueryKeys = (filter: RequisitionFilter) => [
  queryKeys.requisition.root,
  queryKeys.requisition.getHash(filter),
];

export const useRequisitionQuery = ({
  filters = {},
  enabled = true,
}: {
  filters?: RequisitionFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: getRequisitionQueryKeys(filters),
    queryFn: () => {
      const req = Requisition.create(filters);
      return InventoryClientService.GetRequisition(req);
    },
    enabled,
  });
};

export const useBatchGetRequisitionQuery = ({
  filters = {},
  enabled = true,
}: {
  filters?: RequisitionFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.requisition.root,
      queryKeys.requisition.list,
      queryKeys.requisition.getHash(filters),
    ],
    queryFn: async () => {
      const response = await InventoryClientService.BatchGetRequisition(
        Requisition.create(filters),
      );
      return response;
    },
    enabled,
  });
};

export const useRequisitionCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (requisition: Requisition) => {
      return await InventoryClientService.CreateRequisition(requisition);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.requisition.root] });
    },
  });
};

export const useRequisitionUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (requisition: Requisition) => {
      return await InventoryClientService.UpdateRequisition(requisition);
    },
    onSuccess: async (_, updateRequest) => {
      const updatePromises: Promise<void>[] = [];
      updatePromises.push(
        queryClient.invalidateQueries({
          queryKey: [queryKeys.requisition.root, queryKeys.requisition.list],
        }),
      );
      if (updateRequest.fieldMask.includes('FileId') && updateRequest.fileId) {
        updatePromises.push(
          queryClient.invalidateQueries({
            queryKey: getFileQueryConfig({ filter: { id: updateRequest.fileId } }).queryKey,
          }),
        );
      }

      await Promise.allSettled(updatePromises);
    },
  });
};

export const useRequisitionDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (requisition: Requisition) => {
      return await InventoryClientService.DeleteRequisition(requisition);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.requisition.root] });
    },
  });
};

export const useToolFundTransferMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: { assetId: number; newUserId: number }) => {
      const req = Requisition.create({
        id: params.assetId,
        userId: params.newUserId,
        updatedAt: new Date().toISOString(),
        fieldMask: ['user_id', 'updated_at'],
      });
      return await InventoryClientService.UpdateRequisition(req);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.requisition.root] });
    },
  });
};
