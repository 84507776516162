import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { PermitDetails } from '../modules/Permits/PermitsAdminPage/PermitGroupDetails/PermitDetails/PermitDetails';

export const Route = createFileRoute('/permitAdmin/$permitGroupId/permits/$permitId')({
  component: RouteComponent,
  params: z.object({
    permitId: z.coerce.number().positive(),
  }),
});

function RouteComponent() {
  const { permitId } = Route.useParams();
  return (
    <div className="flex h-full flex-col overflow-hidden pb-10 pt-4">
      <PermitDetails permitId={permitId} />
    </div>
  );
}
