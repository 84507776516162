import { type Transaction } from '@kalos/kalos-rpc';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  useGetDefaultSearchFormValues,
  useSearchParamsForm,
} from '../../../../../hooks/useFormWithSearchParams';
import { type ZodObjectWithModelKeys } from '../../../../../tools/typeguargs';

type TransactionSearchFields = Pick<
  Transaction,
  | 'searchPhrase'
  | 'departmentId'
  | 'assignedEmployeeId'
  | 'statusId'
  | 'jobId'
  | 'amount'
  | 'costCenterId'
>;

export const transactionSearchScheme = z.object({
  searchPhrase: z.string().optional(),
  departmentId: z.coerce.number().optional(),
  assignedEmployeeId: z.coerce.number().optional(),
  statusId: z.coerce.number().optional(),
  jobId: z.coerce.number().optional(),
  amount: z.coerce.number().optional(),
  dateFrom: z.coerce.date().optional(),
  dateTo: z.coerce.date().optional(),
  costCenterId: z.coerce.number().optional(),
}) satisfies ZodObjectWithModelKeys<TransactionSearchFields>;

export type TransactionSearchSchemeType = z.infer<typeof transactionSearchScheme>;
export const defaultTransactionSearchValues: TransactionSearchSchemeType = {
  amount: 0,
  assignedEmployeeId: 0,
  departmentId: 0,
  jobId: 0,
  searchPhrase: '',
  statusId: 0,
  costCenterId: 0,
  dateFrom: undefined,
  dateTo: undefined,
};

export const useTransactionSearchForm = ({
  disableParamsSync,
  defaultStatusId,
}: { disableParamsSync?: boolean; defaultStatusId?: number } = {}) => {
  const form = useSearchParamsForm({
    schema: transactionSearchScheme,
    disableParamsSync: disableParamsSync,
    defaultValues: useGetDefaultSearchFormValues(
      transactionSearchScheme,
      useMemo(
        () => ({
          ...defaultTransactionSearchValues,
          statusId: defaultStatusId ?? 0,
        }),
        [defaultStatusId],
      ),
    ),
    defaultResetValues: defaultTransactionSearchValues,
  });

  return form;
};

export const useTransactionSearchFormContext = () => {
  return useFormContext<TransactionSearchSchemeType>();
};
