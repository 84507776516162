import { Document, File } from '@kalos/kalos-rpc';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import {
  DocumentClientService,
  FileClientService,
  PDFClientService,
  timestamp,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { Alert } from '../Alert';
import { Button } from '../Button';

type StringDimension = {
  value1: string;
  value2: string;
};
export interface Props {
  propertyId: number;
  jobNumber: number;
  onCreateDoc?: () => void;
}

type SellSheetType = {
  breakerType: string;
  breakerDimensions: StringDimension[];

  jobNumber: number;
  systemType: string;
  dataTag: string;
  poolLength: string;
  poolWidth: string;
  poolDepth: string;
  modelNumber: string;
  pipingSize: string;
  concretePad: string;
  padSize: string;
  reusePad: string;
  notes: string;
};

type Errors = {
  systemDescription?: string;
  jobNumber?: string;
  systemType?: string;
  dataTag?: string;
  poolLength?: string;
  poolWidth?: string;
  poolDepth?: string;
  modelNumber?: string;
  pipingSize?: string;
  concretePad?: string;
  padSize?: string;
  reusePad?: string;
  notes?: string;
  breakerType?: string;
};

const checkFormIsValid = (form: SellSheetType): Errors => {
  const errors: Errors = {};

  if (form.jobNumber <= 0) {
    errors.jobNumber = 'Valid job number is required';
  }

  if (form.systemType === '' || form.systemType === 'Choose One') {
    errors.systemType = 'System type is required';
  }

  if (form.dataTag === '') {
    errors.dataTag = 'Please indicate if the data tag was readable';
  }

  if (form.dataTag === 'no') {
    if (!form.poolLength.length) {
      errors.poolLength = 'Pool length is required';
    }
    if (!form.poolWidth.length) {
      errors.poolWidth = 'Pool width is required';
    }
    if (!form.poolDepth.length) {
      errors.poolDepth = 'Pool depth is required';
    }
  }

  if (form.dataTag === 'yes' && !form.modelNumber.trim()) {
    errors.modelNumber = 'Model number is required';
  }

  if (form.pipingSize === '' || form.pipingSize === 'Choose One') {
    errors.pipingSize = 'Piping size is required';
  }

  if (form.concretePad === '' || form.concretePad === 'Choose One') {
    errors.concretePad = 'Please indicate if a new concrete pad is needed';
  }

  if (form.concretePad === 'no' && form.reusePad === 'yes' && !form.padSize.trim()) {
    errors.padSize = 'Existing pad size is required';
  }

  if (form.breakerType === '' || form.breakerType === 'Choose One') {
    errors.breakerType = 'Breaker type is required';
  }

  return errors;
};

export const PoolHeaterSellSheet: React.FC<Props> = ({ propertyId, jobNumber, onCreateDoc }) => {
  const [form, setForm] = useState<SellSheetType>({
    breakerType: 'Choose One',
    breakerDimensions: [{ value1: '', value2: '' }],

    jobNumber: 0,
    systemType: '',
    dataTag: '',
    poolLength: '',
    poolWidth: '',
    poolDepth: '',
    modelNumber: '',
    pipingSize: '',
    concretePad: '',
    padSize: '',
    reusePad: '',
    notes: '',
  });
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});

  const createMyDocument = async (formData: SellSheetType) => {
    const content = `
    <div style="font-family: Arial, sans-serif; margin: 0 auto; width: 600px;">
      <h1 style="text-align: center;">AC Quote</h1>
      <table style="width: 100%; border-collapse: collapse;">
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Job Number</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.jobNumber}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">System Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.systemType}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Data Tag Readable</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.dataTag}</td>
        </tr>
        ${
          formData.dataTag === 'no'
            ? `
            <tr>
              <td colspan="2" style="border: 1px solid #000; padding: 10px;">
                <strong>Pool Dimensions (L x W x Avg. Depth):</strong><br>
                Length: ${formData.poolLength}, Width: ${formData.poolWidth}, Depth: ${formData.poolDepth}
              </td>
            </tr>
          `
            : `
            <tr>
              <td style="border: 1px solid #000; padding: 10px;">Model Number</td>
              <td style="border: 1px solid #000; padding: 10px;">${formData.modelNumber}</td>
            </tr>
          `
        }
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Piping Size</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.pipingSize}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">New Concrete Pad Needed</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.concretePad}</td>
        </tr>
        ${
          formData.concretePad === 'no' && formData.reusePad === 'yes'
            ? `
            <tr>
              <td style="border: 1px solid #000; padding: 10px;">Existing Pad Size</td>
              <td style="border: 1px solid #000; padding: 10px;">${formData.padSize}</td>
            </tr>
          `
            : ''
        }
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Breaker Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.breakerType}</td>
        </tr>
        ${formData.breakerDimensions
          .map(
            (dim, index) => `
            <tr>
              <td colspan="2" style="border: 1px solid #000; padding: 10px;">
                <strong>Breaker Dimension ${index + 1}:</strong><br>
                AH/Furnace Breaker: ${dim.value1}, Condenser Breaker: ${dim.value2}
              </td>
            </tr>
          `,
          )
          .join('')}
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Additional Notes</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.notes}</td>
        </tr>
      </table>
    </div>
  `;

    const html = `
<!DOCTYPE html>
<html>
            <meta charset="UTF-8">
    <title>Pool Heater Sell Sheet</title>
  </head>
  <body>
   ${content}
  </body>
</html>
`;

    // Use the PDFClientService to convert the HTML to a PDF
    const url = await PDFClientService.getUploadedHTMLUrl(
      html,
      `PoolHeaterSellSheet-${jobNumber}-${timestamp()}.pdf`,
      'kalosdocs-prod',
    );

    return url;
  };

  const uploadDocument = async () => {
    const errors = checkFormIsValid(form);

    setErrors(errors);
    setLoading(true);
    try {
      if (!Object.keys(errors).length) {
        setLoading(true);
        const url = await createMyDocument(form);
        if (url) {
          //we have to fetch the file first
          const fileRes = await FileClientService.Get(File.create({ name: url.key }));
          if (fileRes) {
            // the file was created, so we can create the document record
            await DocumentClientService.Create(
              Document.create({
                fileId: fileRes.id,
                propertyId: propertyId,
                description: fileRes.name,
                filename: fileRes.name,
              }),
            );
          }
          onCreateDoc?.();
          setMessage(`Document Successfully Created`);
        }
      }
    } catch (error) {
      console.error('Failed to create or upload document:', error);
      setLoading(false);
      setMessage(`Error with Generating document: ${error}`);
      return;
    }
    setLoading(false);
  };

  const handleAddBreakerDimension = () => {
    setForm({
      ...form,
      breakerDimensions: [...form.breakerDimensions, { value1: '', value2: '' }],
    });
  };
  const handleRemoveBreakerDimension = (index: number) => {
    setForm({
      ...form,
      breakerDimensions: form.breakerDimensions.filter((_, i) => i !== index),
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name as string]: value as string,
    }));
  };

  const handleBreakerChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newBreaker = [...form.breakerDimensions];
      newBreaker[index][key] = String(event.target.value);
      setForm({
        ...form,
        breakerDimensions: newBreaker,
      });
    };

  return (
    <div>
      <form className="pt-4">
        <Grid container direction="column" spacing={3}>
          {/* Job Number */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Job Number"
              name="jobNumber"
              value={form.jobNumber}
              onChange={handleInputChange}
              error={!!errors.jobNumber}
            />
            <p className="pt-1 text-sm text-red-500">{errors.jobNumber}</p>
          </Grid>

          {/* System Type */}
          <Grid item xs={12}>
            <p className="mb-[10px]">System Type</p>
            <Select
              fullWidth
              label="System Type"
              name="systemType"
              value={form.systemType}
              onChange={handleSelectChange}
              error={!!errors.systemType}
            >
              <MenuItem value="natural gas">Natural Gas</MenuItem>
              <MenuItem value="propane">Propane</MenuItem>
              <MenuItem value="heat pump">Heat Pump</MenuItem>
            </Select>
            <p className="pt-1 text-sm text-red-500">{errors.systemType}</p>
          </Grid>

          {/* Data Tag Readable */}
          <Grid item xs={12}>
            <InputLabel
              className="mb-[10px] w-full font-bold"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Was the data tag readable?
            </InputLabel>
            <Select
              fullWidth
              name="dataTag"
              value={form.dataTag}
              onChange={handleSelectChange}
              error={!!errors.dataTag}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </Grid>

          {/* Conditional Pool Dimensions */}
          {form.dataTag === 'no' && (
            <Grid container direction="column" className="!pt-2 pl-6">
              <Grid item xs={12}>
                <InputLabel
                  className="mb-[10px] w-full font-bold"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Please Enter Pool Dimensions (L x W x Avg. Depth)
                </InputLabel>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Pool L"
                      name="poolLength"
                      value={form.poolLength}
                      onChange={handleInputChange}
                      error={!!errors.poolLength}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Pool W"
                      name="poolWidth"
                      value={form.poolWidth}
                      onChange={handleInputChange}
                      error={!!errors.poolWidth}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Pool D"
                      name="poolDepth"
                      value={form.poolDepth}
                      onChange={handleInputChange}
                      error={!!errors.poolDepth}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Model Number if Data Tag is Readable */}
          {form.dataTag === 'yes' && (
            <Grid container direction="column" className="pl-6 pt-2">
              <Grid item xs={12}>
                <InputLabel
                  className="mb-[10px] w-full font-bold"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Please Enter Model Number
                </InputLabel>
                <TextField
                  fullWidth
                  label="Model Number"
                  name="modelNumber"
                  value={form.modelNumber}
                  onChange={handleInputChange}
                  error={!!errors.modelNumber}
                />
              </Grid>
            </Grid>
          )}

          {/* Piping Size */}
          <Grid item xs={12}>
            <InputLabel
              className="mb-[10px] w-full font-bold"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Piping Size
            </InputLabel>
            <Select
              fullWidth
              name="pipingSize"
              value={form.pipingSize}
              onChange={handleSelectChange}
              error={!!errors.pipingSize}
            >
              <MenuItem value="1.5">1.5 Inch</MenuItem>
              <MenuItem value="2">2 Inch</MenuItem>
            </Select>
          </Grid>

          {/* Concrete Pad Needed */}
          <Grid item xs={12}>
            <InputLabel
              className="mb-[10px] w-full font-bold"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              New concrete pad needed?
            </InputLabel>
            <Select
              fullWidth
              name="concretePad"
              value={form.concretePad}
              onChange={handleSelectChange}
              error={!!errors.concretePad}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </Grid>

          {/* Re-using Existing Pad */}
          {form.concretePad === 'no' && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <InputLabel
                  className="mb-[10px] w-full font-bold"
                  style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                >
                  Are you re-using the existing pad?
                </InputLabel>
                <Select
                  fullWidth
                  name="reusePad"
                  value={form.reusePad}
                  onChange={handleSelectChange}
                  error={!!errors.reusePad}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </Grid>
              {form.reusePad === 'yes' && (
                <Grid item xs={12}>
                  <InputLabel
                    className="mb-[10px] mt-[10px] w-full font-bold"
                    style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
                  >
                    What is the existing pad size?
                  </InputLabel>
                  <TextField
                    fullWidth
                    label="Pad Size"
                    name="padSize"
                    value={form.padSize}
                    onChange={handleInputChange}
                    error={!!errors.padSize}
                  />
                </Grid>
              )}
            </Grid>
          )}

          {/* Breaker Type */}
          <Grid item xs={12}>
            <InputLabel
              className="mb-[10px] w-full font-bold"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Breaker Type
            </InputLabel>
            <Select
              fullWidth
              name="breakerType"
              value={form.breakerType}
              onChange={handleSelectChange}
              error={!!errors.breakerType}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="Siemens">Siemens</MenuItem>
              <MenuItem value="Square D QO">Square D QO</MenuItem>
              <MenuItem value="Square D Homeline">Square D Homeline</MenuItem>
              <MenuItem value="GE Slimeline">GE Slimeline</MenuItem>
              <MenuItem value="GE regular">GE regular</MenuItem>
              <MenuItem value="Cutler Hammer Black">Cutler Hammer Black</MenuItem>
              <MenuItem value="Cutler Hammer Brown">Cutler Hammer Brown</MenuItem>
              <MenuItem value="WestingHouse">WestingHouse</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Grid>

          <Grid item>
            <InputLabel
              id="breakerSizes-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Breaker Sizes
            </InputLabel>
            {form.breakerDimensions.map((dim, index) => (
              <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={4}>
                  <TextField
                    label={`AH/Furnace Breaker ${index + 1} `}
                    value={dim.value1}
                    onChange={handleBreakerChange(index, 'value1')}
                    error={!!errors.breakerType}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Condenser Breaker ${index + 1}`}
                    value={dim.value2}
                    onChange={handleBreakerChange(index, 'value2')}
                    error={!!errors.breakerType}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.breakerDimensions.length > 1 && (
                    <IconButton onClick={() => handleRemoveBreakerDimension(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddBreakerDimension}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          {/* Additional Notes */}
          <Grid item xs={12}>
            <InputLabel
              className="mb-[10px] w-full  font-bold"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Additional Notes
            </InputLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Notes"
              name="notes"
              value={form.notes}
              onChange={handleInputChange}
              placeholder="Enter any additional notes or context that would be helpful for the estimation team or technician"
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button label="Create" onClick={uploadDocument} />
            {message != '' && (
              <Alert
                className="CenteredModal"
                title="Sell Sheet/AC Quote"
                open
                onClose={() => setMessage('')}
              >
                {message}
              </Alert>
            )}
          </Grid>
        </Grid>
        {loading && <Loader className="SellSheet-Loader" />}
      </form>
    </div>
  );
};
