import { type ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import { EventEmitter } from 'events';

import { BaseClient } from '../BaseClient';
import { Empty } from '../compiled-protos/common';
import { type GetCallHistoryRequest } from '../compiled-protos/gotoadmin';
import {
  BlindTransferRequest,
  type ExchangeCodeRequest,
  type ExchangeCodeRequestWithRedirect,
  type GetAuthURLRequest,
  GetAuthURLResponse,
  type GetUserTokenRequest,
  type Notification,
} from '../compiled-protos/gotoconnect';
import {
  CallId,
  type ClientInfo,
  type DTMFRequest,
  type GenerateTestCallsRequest,
  InitializeClientRequest,
  StartCallRequest,
  SubscribeRequest,
  TerminateClientRequest,
} from '../compiled-protos/softphone';
import { SoftphoneServiceClient } from '../compiled-protos/softphone.client';
class SoftphoneClient extends BaseClient {
  self: SoftphoneServiceClient;
  stream: SoftphoneServiceClient;
  private subscription: ServerStreamingCall<SubscribeRequest, Notification> | null = null;
  private retryCount = 0;
  private maxRetries = 5;
  private retryTimeout: NodeJS.Timeout | null = null;
  private isRetrying = false;
  private eventEmitter: EventEmitter;
  private keepAliveInterval: NodeJS.Timeout | null = null;
  private readonly KEEP_ALIVE_INTERVAL = 60000; // 1 minute

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new SoftphoneServiceClient(this.transport);
    this.stream = new SoftphoneServiceClient(this.streamingTransport);
    this.eventEmitter = new EventEmitter();
  }

  private getSoftphoneMetaData() {
    const md = this.getMetaDataWithoutCache();
    md.meta['x-user-id'] = `${this.userID}`
    return md
  }

  public async InitializeClient(userId: number, clientInfo: ClientInfo) {
    console.log('[DEBUG] Initializing softphone client...');
    const req = InitializeClientRequest.create({ userId, clientInfo });
    
    return this.self.initializeClient(req, this.getSoftphoneMetaData()).response
  }

  public async TerminateClient(sessionId: string) {
    this.retryCount = this.maxRetries; // Prevent further retries
    this.cleanupSubscription();
    
    const req = TerminateClientRequest.create({ sessionId });
    try {
      await this.self.terminateClient(req, this.getSoftphoneMetaData()).response;
    } catch (err) {
      console.error('Error terminating client:', err);
      throw err;
    }
  }

  public SubscribeToEvents(sessionId: string) {
    return this.subscribeWithRetry(sessionId);
  }

  private setupKeepAlive() {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
    }
    
    this.keepAliveInterval = setInterval(() => {
      if (this.subscription) {
        // Send an empty message through metadata to keep the connection alive
        try {
          const md = this.getSoftphoneMetaData();
          // Use the transport's method to update headers
          this.stream.subscribeToEvents(SubscribeRequest.create({}), {
            ...md,
            meta: { ...md.meta, 'keep-alive': Date.now().toString() }
          });
        } catch (error) {
          console.error('Keep-alive failed:', error);
        }
      }
    }, this.KEEP_ALIVE_INTERVAL);
  }

  public subscribeWithRetry(sessionId: string) {
    // If we're already retrying, don't start another retry attempt
    if (this.isRetrying) {
      console.log('Already retrying subscription, skipping new attempt');
      return this.subscription;
    }

    const req = SubscribeRequest.create({ sessionId });
    
    try {
      // Clean up existing subscription
      this.cleanupSubscription();

      // Reset retry count for new subscription
      this.retryCount = 0;
      this.isRetrying = false;

      // Create new subscription with timeout slightly less than Envoy's stream_idle_timeout
      this.subscription = this.stream.subscribeToEvents(req, {
        ...this.getSoftphoneMetaData(),
      });

      // Setup keep-alive mechanism
      this.setupKeepAlive();

      // Handle stream completion (normal termination)
      this.subscription.responses.onComplete(() => {
        console.log('Stream completed normally');
        this.cleanupSubscription();
        // Only retry if we didn't intend to close the stream
        if (this.retryCount < this.maxRetries) {
          this.handleStreamError(sessionId, new Error('Stream completed unexpectedly'));
        }
      });

      // Handle stream errors
      this.subscription.responses.onError((error: any) => {
        console.error('Stream error:', error);
        this.cleanupSubscription();
        this.handleStreamError(sessionId, error);
      });

      // Handle messages
      this.subscription.responses.onMessage((message: any) => {
        // Reset retry count on successful message
        this.retryCount = 0;
        this.isRetrying = false;
        // Emit the message to listeners
        this.eventEmitter.emit('message', message);
      });
      
      return this.subscription;
    } catch (error) {
      console.error('Subscription error:', error);
      this.cleanupSubscription();
      this.handleStreamError(sessionId, error);
      throw error;
    }
  }

  private handleStreamError(sessionId: string, error: any) {
    // If we're already retrying, don't start another retry attempt
    if (this.isRetrying) {
      console.log('Already handling stream error, skipping new attempt');
      return;
    }

    // Clear any existing retry timeout
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }

    console.error('Stream error occurred:', error);

    // If we haven't exceeded max retries, try to reconnect
    if (this.retryCount < this.maxRetries) {
      const backoffMs = Math.min(1000 * Math.pow(2, this.retryCount), 30000);
      this.retryCount++;
      this.isRetrying = true;

      console.log(`Retrying subscription in ${backoffMs}ms (attempt ${this.retryCount})`);
      
      this.retryTimeout = setTimeout(() => {
        try {
          this.isRetrying = false; // Reset retry flag before attempting
          this.subscribeWithRetry(sessionId);
        } catch (error) {
          console.error('Retry failed:', error);
          this.isRetrying = false; // Reset retry flag on failure
        }
      }, backoffMs);
    } else {
      console.error('Max retry attempts reached');
      this.isRetrying = false;
      this.eventEmitter.emit('streamFailed', { sessionId, error });
    }
  }
  
  public async StartCall(sessionId: string, phoneNumber: string, sdp: string) {
    const req = StartCallRequest.create({
      sessionId,
      phoneNumber,
      sdp,
    });
    const res = await this.self.startCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async toggleMute(req: CallId) {
    const res = await this.self.toggleMute(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async AnswerCall(callId: string, sessionId: string, sdp: string) {
    const req = CallId.create({ callId, sessionId, sdp });
    const res = await this.self.answerCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async RejectCall(callId: string, sessionId: string) {
    const req = CallId.create({ callId, sessionId });
    const res = await this.self.rejectCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async EndCall(callId: string, sessionId: string) {
    const req = CallId.create({ callId, sessionId });
    const res = await this.self.endCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async ToggleMute(req: CallId) {
    const res = await this.self.toggleMute(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async ToggleHold(req: CallId) {
    const res = await this.self.toggleHold(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async SendDTMF(request: DTMFRequest) {
    const res = await this.self.sendDTMF(request, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async GetAuthURL(request: GetAuthURLRequest) {
    let res = GetAuthURLResponse.create();
    try {
      res = await this.self.getAuthURL(request, this.getSoftphoneMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async ExchangeCode(request: ExchangeCodeRequest) {
    const res = await this.self.exchangeCode(request, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async ExchangeCodeWithRedirect(request: ExchangeCodeRequestWithRedirect) {
    const res = await this.self.exchangeCodeWithRedirect(request, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async GetUserToken(request: GetUserTokenRequest) {
    const res = await this.self.getUserToken(request, this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GetCurrentCallQueue() {
    const res = await this.self.fetchCurrentCallQueue(Empty.create(), this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GetCallHistory(req: GetCallHistoryRequest) {
    const res = await this.self.getCallHistory(req, this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GetMe() {

    const res = await this.self.getMe(Empty.create(), this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GenerateTestCalls(req: GenerateTestCallsRequest) {
    const res = await this.self.generateTestCalls(req, this.getSoftphoneMetaData()).response;
    return res;
  }
  public async getCurrentCallQueue() {
    const res = await this.self.fetchCurrentCallQueue(Empty.create(), this.getSoftphoneMetaData()).response
    return res;
  }
  public async TransferCall(callId: string, extension: string) {
    const req = BlindTransferRequest.create({callId, dialString: extension});
    const res = await this.self.blindTransferCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public on(event: string, listener: (...args: any[]) => void): void {
    this.eventEmitter.on(event, listener);
  }

  public off(event: string, listener: (...args: any[]) => void): void {
    this.eventEmitter.off(event, listener);
  }

  public cleanupSubscription() {
    if (this.subscription) {
      try {
        // Close the response stream by ending it
        this.subscription.responses.onComplete(() => {
          console.log('Subscription stream closed');
        });
      } catch (err) {
        console.error('Error cleaning up subscription:', err);
      }
      this.subscription = null;
    }
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = null;
    }
    this.isRetrying = false;
  }
}

export { SoftphoneClient };
export { GetCallHistoryRequest, CallHistoryItem, Direction } from '../compiled-protos/gotoadmin';

export {
  ExchangeCodeRequest,

  ExchangeCodeRequestWithRedirect,
  GetAuthURLRequest,
  GetAuthURLResponse,
  GetUserTokenRequest,
  OAuthTokenResponse,
  GoToCall,
  Notification,
} from '../compiled-protos/gotoconnect';
export {
  CallId,
  ClientInfo,
  ClientSession,
  DTMFRequest,
  InitializeClientRequest,
  GenerateTestCallsRequest,
  StartCallRequest,
  SubscribeRequest,
  TerminateClientRequest,
} from '../compiled-protos/softphone';
