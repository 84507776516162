import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ServiceCall } from '../../modules/ComponentsLibrary/ServiceCall';

export const Route = createFileRoute('/jobs/$jobId')({
  component: RouteComponent,
  params: z.object({
    jobId: z.coerce.number().positive(),
  }),
  head(ctx) {
    return {
      meta: [{ title: `Job View - ${ctx.params.jobId}` }],
    };
  },
});

function RouteComponent() {
  const { jobId } = Route.useParams();

  return <ServiceCall serviceCallId={jobId} />;
}
