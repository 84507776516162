// @ts-ignore
import { deflate, ungzip } from 'pako';

import { BaseClient } from '../BaseClient';
import { getMimeType } from '../Common';
import {
  TransactionDocument,
  TransactionDocumentList,
} from '../compiled-protos/transaction';
import { TransactionServiceClient } from '../compiled-protos/transaction.client';
import { File, FileClient } from '../File';
import { FileObject, S3Client, URLObject } from '../S3File';



class TransactionDocumentClient extends BaseClient {
  S3: S3Client;
  FileClient: FileClient;
  self: TransactionServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.S3 = new S3Client(host, userID);
    this.FileClient = new FileClient(host, userID);
    this.deleteByName = this.deleteByName.bind(this);
    this.self = new TransactionServiceClient(this.transport);
  }

  public async Create(req: TransactionDocument) {
    const res = await this.self.createTransactionDocument(req, this.getMetaData())
      .response;
    return res;
  }

  public async Get(req: TransactionDocument) {
    let res = TransactionDocument.create();
    try {
      res = await this.self.getTransactionDocument(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: TransactionDocument) {
    let res = TransactionDocument.create();
    try {
      res = await this.self.updateTransactionDocument(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TransactionDocument) {
    let res = TransactionDocument.create();
    try {
      res = await this.self.deleteTransactionDocument(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TransactionDocument) {
    return await this.self.batchGetTransactionDocument(req, this.getMetaData())
      .response;
  }

  public async download(txnId: number, reference: string) {
    const urlObj = URLObject.create();
    const contentType = getMimeType(reference);
    urlObj.bucket = 'kalos-transactions';
    urlObj.key = `${reference}`;
    urlObj.contentType = contentType as string;
    const urlRes = await this.S3.GetDownloadURL(urlObj);
    const downloadRes = await fetch(urlRes!.url);
    const res = FileObject.create();
    res.key = `${reference}`;
    res.mimeType = contentType as string;
    if (downloadRes.status === 200) {
      const u8 = new Uint8Array(await downloadRes.arrayBuffer());
      if (u8.length > 0) {
        try {
          res.data = ungzip(u8);
        } catch (err) {
          res.data = u8;
        }
      }
    }
    return res;
  }

  public getFileExt = (fileName: string) => {
    const arr = fileName.toLowerCase().split('.');
    return arr[arr.length - 1];
  };

  public async upload(txnId: number, reference: string, fileData: Uint8Array) {
    const ext = this.getFileExt(reference);
    const uniqueId = Math.floor(Date.now() / 1000);
    let name: string;
    if (ext) {
      const baseName = reference.substring(0, reference.lastIndexOf('.'));
      name = `${baseName}-${uniqueId}.${ext}`;
    } else {
      name = `${reference}-${uniqueId}`;
    }
    const contentType = getMimeType(name);
    const fileKey = `${txnId}-${name}`;
    const urlObj = URLObject.create({
      bucket: 'kalos-transactions',
      key: fileKey,
      contentType: contentType,
    });
    const urlRes = await this.S3.GetUploadURL(urlObj);
    try {
      fileData = deflate(fileData);
    } catch (err) {
      console.error(err);
    }

    if (!urlRes) {
      throw new Error('Failed to get upload URL');
    }

    const uploadRes = await fetch(urlRes.url, {
      body: fileData,
      method: 'PUT',
    });

    if (uploadRes.status === 200) {
      const fileObj = File.create({
        bucket: 'kalos-transactions',
        name: fileKey,
        mimeType: contentType,
      });
      const fileRes = await this.FileClient.Create(fileObj);

      const req = TransactionDocument.create({
        fileId: fileRes!.id,
        transactionId: txnId,
        reference: name,
      });
      const docResult = await this.Create(req);
      return docResult;
    } else {
      return undefined
    }
  }

  public async byTransactionID(id: number) {
    const res = await this.BatchGet(
      TransactionDocument.create({ transactionId: id })
    );
    return res.results;
  }

  public DeleteTransactionDocument = ({ fileId, transactionFileId }: { fileId: number, transactionFileId: number }) => {
    return Promise.all([this.Delete(TransactionDocument.create({ id: transactionFileId })), this.FileClient.Delete(File.create({ id: fileId }))]);
  }

  public async deleteByName(fileName: string, bucket: string) {
    const fileReq = File.create({
      name: fileName,
      bucket,
    });
    const fileRes = await this.FileClient.Get(fileReq);
    const docReq = TransactionDocument.create({
      fileId: fileRes!.id,
    });
    const docRes = await this.Get(docReq);
    await this.DeleteTransactionDocument({ fileId: fileRes!.id, transactionFileId: docRes!.id });
  }
}

export {
  TransactionDocument,
  TransactionDocumentList,
  TransactionDocumentClient,
};

function diff(n: number) {
  return new Date().valueOf() - n;
}
