// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "dispatch.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "dispatch.proto" (syntax proto3)
// tslint:disable
import { Bool } from "./common";
import { Int32 } from "./common";
import { Empty } from "./common";
import { TimeoffRequestList } from "./timesheet_line";
import { TimeoffRequest } from "./timesheet_line";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DateRange } from "./common";
import { Event } from "./event";
// @generated message type with reflection information, may provide speed optimized methods
class DispatchableTech$Type extends MessageType {
    constructor() {
        super("DispatchableTech", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "techname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "user_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "property_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "property_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "geolocation_lat", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "geolocation_lng", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "activity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "activity_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "call_backs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchCallBack },
            { no: 15, name: "call_times", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchCallTime },
            { no: 16, name: "call_count", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchCallCount },
            { no: 17, name: "hours_worked", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "current_job", kind: "message", T: () => Event },
            { no: 19, name: "department_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "department_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "date_range", kind: "message", T: () => DateRange },
            { no: 22, name: "permission_group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "user_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "user_sector", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "user_job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, techname: "", jobType: "", userPhone: "", userEmail: "", eventId: 0, propertyUserId: 0, propertyId: 0, propertyCity: "", geolocationLat: 0, geolocationLng: 0, activity: "", activityDate: "", callBacks: [], callTimes: [], callCount: [], hoursWorked: 0, departmentList: "", departmentCode: 0, permissionGroupId: 0, userCity: "", userSector: 0, userJobType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string techname */ 2:
                    message.techname = reader.string();
                    break;
                case /* string job_type */ 3:
                    message.jobType = reader.string();
                    break;
                case /* string user_phone */ 4:
                    message.userPhone = reader.string();
                    break;
                case /* string user_email */ 5:
                    message.userEmail = reader.string();
                    break;
                case /* int32 event_id */ 6:
                    message.eventId = reader.int32();
                    break;
                case /* int32 property_user_id */ 7:
                    message.propertyUserId = reader.int32();
                    break;
                case /* int32 property_id */ 8:
                    message.propertyId = reader.int32();
                    break;
                case /* string property_city */ 9:
                    message.propertyCity = reader.string();
                    break;
                case /* double geolocation_lat */ 10:
                    message.geolocationLat = reader.double();
                    break;
                case /* double geolocation_lng */ 11:
                    message.geolocationLng = reader.double();
                    break;
                case /* string activity */ 12:
                    message.activity = reader.string();
                    break;
                case /* string activity_date */ 13:
                    message.activityDate = reader.string();
                    break;
                case /* repeated DispatchCallBack call_backs */ 14:
                    message.callBacks.push(DispatchCallBack.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated DispatchCallTime call_times */ 15:
                    message.callTimes.push(DispatchCallTime.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated DispatchCallCount call_count */ 16:
                    message.callCount.push(DispatchCallCount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 hours_worked */ 17:
                    message.hoursWorked = reader.int32();
                    break;
                case /* Event current_job */ 18:
                    message.currentJob = Event.internalBinaryRead(reader, reader.uint32(), options, message.currentJob);
                    break;
                case /* string department_list */ 19:
                    message.departmentList = reader.string();
                    break;
                case /* int32 department_code */ 20:
                    message.departmentCode = reader.int32();
                    break;
                case /* DateRange date_range */ 21:
                    message.dateRange = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.dateRange);
                    break;
                case /* int32 permission_group_id */ 22:
                    message.permissionGroupId = reader.int32();
                    break;
                case /* string user_city */ 23:
                    message.userCity = reader.string();
                    break;
                case /* int32 user_sector */ 24:
                    message.userSector = reader.int32();
                    break;
                case /* string user_job_type */ 25:
                    message.userJobType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string techname = 2; */
        if (message.techname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.techname);
        /* string job_type = 3; */
        if (message.jobType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.jobType);
        /* string user_phone = 4; */
        if (message.userPhone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.userPhone);
        /* string user_email = 5; */
        if (message.userEmail !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.userEmail);
        /* int32 event_id = 6; */
        if (message.eventId !== 0)
            writer.tag(6, WireType.Varint).int32(message.eventId);
        /* int32 property_user_id = 7; */
        if (message.propertyUserId !== 0)
            writer.tag(7, WireType.Varint).int32(message.propertyUserId);
        /* int32 property_id = 8; */
        if (message.propertyId !== 0)
            writer.tag(8, WireType.Varint).int32(message.propertyId);
        /* string property_city = 9; */
        if (message.propertyCity !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.propertyCity);
        /* double geolocation_lat = 10; */
        if (message.geolocationLat !== 0)
            writer.tag(10, WireType.Bit64).double(message.geolocationLat);
        /* double geolocation_lng = 11; */
        if (message.geolocationLng !== 0)
            writer.tag(11, WireType.Bit64).double(message.geolocationLng);
        /* string activity = 12; */
        if (message.activity !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.activity);
        /* string activity_date = 13; */
        if (message.activityDate !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.activityDate);
        /* repeated DispatchCallBack call_backs = 14; */
        for (let i = 0; i < message.callBacks.length; i++)
            DispatchCallBack.internalBinaryWrite(message.callBacks[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated DispatchCallTime call_times = 15; */
        for (let i = 0; i < message.callTimes.length; i++)
            DispatchCallTime.internalBinaryWrite(message.callTimes[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* repeated DispatchCallCount call_count = 16; */
        for (let i = 0; i < message.callCount.length; i++)
            DispatchCallCount.internalBinaryWrite(message.callCount[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* int32 hours_worked = 17; */
        if (message.hoursWorked !== 0)
            writer.tag(17, WireType.Varint).int32(message.hoursWorked);
        /* Event current_job = 18; */
        if (message.currentJob)
            Event.internalBinaryWrite(message.currentJob, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* string department_list = 19; */
        if (message.departmentList !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.departmentList);
        /* int32 department_code = 20; */
        if (message.departmentCode !== 0)
            writer.tag(20, WireType.Varint).int32(message.departmentCode);
        /* DateRange date_range = 21; */
        if (message.dateRange)
            DateRange.internalBinaryWrite(message.dateRange, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* int32 permission_group_id = 22; */
        if (message.permissionGroupId !== 0)
            writer.tag(22, WireType.Varint).int32(message.permissionGroupId);
        /* string user_city = 23; */
        if (message.userCity !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.userCity);
        /* int32 user_sector = 24; */
        if (message.userSector !== 0)
            writer.tag(24, WireType.Varint).int32(message.userSector);
        /* string user_job_type = 25; */
        if (message.userJobType !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.userJobType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchableTech
 */
export const DispatchableTech = new DispatchableTech$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchableTechList$Type extends MessageType {
    constructor() {
        super("DispatchableTechList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchableTech },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DispatchableTech results */ 1:
                    message.results.push(DispatchableTech.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DispatchableTech results = 1; */
        for (let i = 0; i < message.results.length; i++)
            DispatchableTech.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchableTechList
 */
export const DispatchableTechList = new DispatchableTechList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCall$Type extends MessageType {
    constructor() {
        super("DispatchCall", [
            { no: 1, name: "cust_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_businessname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_callback", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "property_is_residential", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "log_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "date_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "date_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "time_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "assigned", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "job_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "job_type_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "job_subtype_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "property_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "property_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "property_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "geolocation_lat", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 23, name: "geolocation_lng", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 24, name: "log_technician_assigned", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "is_residential", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 26, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 27, name: "sector_group", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 28, name: "sector_group_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 31, name: "job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "job_subtype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "user_phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "user_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 39, name: "log_jobStatus", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "log_jobStatus_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { custName: "", userBusinessname: "", id: 0, name: "", description: "", isCallback: 0, propertyIsResidential: 0, logNotes: "", dateStarted: "", dateEnded: "", timeStarted: "", timeEnded: "", assigned: "", jobTypeId: 0, jobTypeIdList: "", jobSubtypeId: 0, propertyId: 0, userId: 0, propertyAddress: "", propertyCity: "", propertyState: "", geolocationLat: 0, geolocationLng: 0, logTechnicianAssigned: "", isResidential: 0, departmentId: 0, sectorGroup: 0, sectorGroupList: "", fieldMask: [], pageNumber: 0, jobType: "", jobSubtype: "", notes: "", dateRange: [], dateTarget: [], notEquals: [], userPhone: "", userEmail: "", logJobStatus: "", logJobStatusList: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cust_name */ 1:
                    message.custName = reader.string();
                    break;
                case /* string user_businessname */ 2:
                    message.userBusinessname = reader.string();
                    break;
                case /* int32 id */ 3:
                    message.id = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* int32 is_callback */ 6:
                    message.isCallback = reader.int32();
                    break;
                case /* int32 property_is_residential */ 7:
                    message.propertyIsResidential = reader.int32();
                    break;
                case /* string log_notes */ 8:
                    message.logNotes = reader.string();
                    break;
                case /* string date_started */ 9:
                    message.dateStarted = reader.string();
                    break;
                case /* string date_ended */ 10:
                    message.dateEnded = reader.string();
                    break;
                case /* string time_started */ 11:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_ended */ 12:
                    message.timeEnded = reader.string();
                    break;
                case /* string assigned */ 13:
                    message.assigned = reader.string();
                    break;
                case /* int32 job_type_id */ 14:
                    message.jobTypeId = reader.int32();
                    break;
                case /* string job_type_id_list */ 15:
                    message.jobTypeIdList = reader.string();
                    break;
                case /* int32 job_subtype_id */ 16:
                    message.jobSubtypeId = reader.int32();
                    break;
                case /* int32 property_id */ 17:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 user_id */ 18:
                    message.userId = reader.int32();
                    break;
                case /* string property_address */ 19:
                    message.propertyAddress = reader.string();
                    break;
                case /* string property_city */ 20:
                    message.propertyCity = reader.string();
                    break;
                case /* string property_state */ 21:
                    message.propertyState = reader.string();
                    break;
                case /* double geolocation_lat */ 22:
                    message.geolocationLat = reader.double();
                    break;
                case /* double geolocation_lng */ 23:
                    message.geolocationLng = reader.double();
                    break;
                case /* string log_technician_assigned */ 24:
                    message.logTechnicianAssigned = reader.string();
                    break;
                case /* int32 is_residential */ 25:
                    message.isResidential = reader.int32();
                    break;
                case /* int32 department_id */ 26:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 sector_group */ 27:
                    message.sectorGroup = reader.int32();
                    break;
                case /* string sector_group_list */ 28:
                    message.sectorGroupList = reader.string();
                    break;
                case /* repeated string field_mask */ 29:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 30:
                    message.pageNumber = reader.int32();
                    break;
                case /* string job_type */ 31:
                    message.jobType = reader.string();
                    break;
                case /* string job_subtype */ 32:
                    message.jobSubtype = reader.string();
                    break;
                case /* string notes */ 33:
                    message.notes = reader.string();
                    break;
                case /* repeated string date_range */ 34:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 35:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string not_equals */ 36:
                    message.notEquals.push(reader.string());
                    break;
                case /* string user_phone */ 37:
                    message.userPhone = reader.string();
                    break;
                case /* string user_email */ 38:
                    message.userEmail = reader.string();
                    break;
                case /* string log_jobStatus */ 39:
                    message.logJobStatus = reader.string();
                    break;
                case /* string log_jobStatus_list */ 40:
                    message.logJobStatusList = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string cust_name = 1; */
        if (message.custName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.custName);
        /* string user_businessname = 2; */
        if (message.userBusinessname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userBusinessname);
        /* int32 id = 3; */
        if (message.id !== 0)
            writer.tag(3, WireType.Varint).int32(message.id);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* int32 is_callback = 6; */
        if (message.isCallback !== 0)
            writer.tag(6, WireType.Varint).int32(message.isCallback);
        /* int32 property_is_residential = 7; */
        if (message.propertyIsResidential !== 0)
            writer.tag(7, WireType.Varint).int32(message.propertyIsResidential);
        /* string log_notes = 8; */
        if (message.logNotes !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.logNotes);
        /* string date_started = 9; */
        if (message.dateStarted !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.dateStarted);
        /* string date_ended = 10; */
        if (message.dateEnded !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.dateEnded);
        /* string time_started = 11; */
        if (message.timeStarted !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_ended = 12; */
        if (message.timeEnded !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.timeEnded);
        /* string assigned = 13; */
        if (message.assigned !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.assigned);
        /* int32 job_type_id = 14; */
        if (message.jobTypeId !== 0)
            writer.tag(14, WireType.Varint).int32(message.jobTypeId);
        /* string job_type_id_list = 15; */
        if (message.jobTypeIdList !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.jobTypeIdList);
        /* int32 job_subtype_id = 16; */
        if (message.jobSubtypeId !== 0)
            writer.tag(16, WireType.Varint).int32(message.jobSubtypeId);
        /* int32 property_id = 17; */
        if (message.propertyId !== 0)
            writer.tag(17, WireType.Varint).int32(message.propertyId);
        /* int32 user_id = 18; */
        if (message.userId !== 0)
            writer.tag(18, WireType.Varint).int32(message.userId);
        /* string property_address = 19; */
        if (message.propertyAddress !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.propertyAddress);
        /* string property_city = 20; */
        if (message.propertyCity !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.propertyCity);
        /* string property_state = 21; */
        if (message.propertyState !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.propertyState);
        /* double geolocation_lat = 22; */
        if (message.geolocationLat !== 0)
            writer.tag(22, WireType.Bit64).double(message.geolocationLat);
        /* double geolocation_lng = 23; */
        if (message.geolocationLng !== 0)
            writer.tag(23, WireType.Bit64).double(message.geolocationLng);
        /* string log_technician_assigned = 24; */
        if (message.logTechnicianAssigned !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.logTechnicianAssigned);
        /* int32 is_residential = 25; */
        if (message.isResidential !== 0)
            writer.tag(25, WireType.Varint).int32(message.isResidential);
        /* int32 department_id = 26; */
        if (message.departmentId !== 0)
            writer.tag(26, WireType.Varint).int32(message.departmentId);
        /* int32 sector_group = 27; */
        if (message.sectorGroup !== 0)
            writer.tag(27, WireType.Varint).int32(message.sectorGroup);
        /* string sector_group_list = 28; */
        if (message.sectorGroupList !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.sectorGroupList);
        /* repeated string field_mask = 29; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(29, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 30; */
        if (message.pageNumber !== 0)
            writer.tag(30, WireType.Varint).int32(message.pageNumber);
        /* string job_type = 31; */
        if (message.jobType !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.jobType);
        /* string job_subtype = 32; */
        if (message.jobSubtype !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.jobSubtype);
        /* string notes = 33; */
        if (message.notes !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.notes);
        /* repeated string date_range = 34; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(34, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 35; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(35, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string not_equals = 36; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(36, WireType.LengthDelimited).string(message.notEquals[i]);
        /* string user_phone = 37; */
        if (message.userPhone !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.userPhone);
        /* string user_email = 38; */
        if (message.userEmail !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.userEmail);
        /* string log_jobStatus = 39; */
        if (message.logJobStatus !== "")
            writer.tag(39, WireType.LengthDelimited).string(message.logJobStatus);
        /* string log_jobStatus_list = 40; */
        if (message.logJobStatusList !== "")
            writer.tag(40, WireType.LengthDelimited).string(message.logJobStatusList);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCall
 */
export const DispatchCall = new DispatchCall$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCallList$Type extends MessageType {
    constructor() {
        super("DispatchCallList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchCall },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DispatchCall results */ 1:
                    message.results.push(DispatchCall.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DispatchCall results = 1; */
        for (let i = 0; i < message.results.length; i++)
            DispatchCall.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCallList
 */
export const DispatchCallList = new DispatchCallList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCallBack$Type extends MessageType {
    constructor() {
        super("DispatchCallBack", [
            { no: 1, name: "technician_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "service_callbacks", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "project_callbacks", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { technicianId: 0, serviceCallbacks: 0, projectCallbacks: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 technician_id */ 1:
                    message.technicianId = reader.int32();
                    break;
                case /* int32 service_callbacks */ 2:
                    message.serviceCallbacks = reader.int32();
                    break;
                case /* int32 project_callbacks */ 3:
                    message.projectCallbacks = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 technician_id = 1; */
        if (message.technicianId !== 0)
            writer.tag(1, WireType.Varint).int32(message.technicianId);
        /* int32 service_callbacks = 2; */
        if (message.serviceCallbacks !== 0)
            writer.tag(2, WireType.Varint).int32(message.serviceCallbacks);
        /* int32 project_callbacks = 3; */
        if (message.projectCallbacks !== 0)
            writer.tag(3, WireType.Varint).int32(message.projectCallbacks);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCallBack
 */
export const DispatchCallBack = new DispatchCallBack$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCallBacksList$Type extends MessageType {
    constructor() {
        super("DispatchCallBacksList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchCallBack },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DispatchCallBack results */ 1:
                    message.results.push(DispatchCallBack.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DispatchCallBack results = 1; */
        for (let i = 0; i < message.results.length; i++)
            DispatchCallBack.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCallBacksList
 */
export const DispatchCallBacksList = new DispatchCallBacksList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCallTime$Type extends MessageType {
    constructor() {
        super("DispatchCallTime", [
            { no: 1, name: "technician_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "avg_minutes_driving", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "avg_minutes_on_call", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value) {
        const message = { technicianId: 0, jobType: "", avgMinutesDriving: 0, avgMinutesOnCall: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 technician_id */ 1:
                    message.technicianId = reader.int32();
                    break;
                case /* string job_type */ 2:
                    message.jobType = reader.string();
                    break;
                case /* float avg_minutes_driving */ 3:
                    message.avgMinutesDriving = reader.float();
                    break;
                case /* float avg_minutes_on_call */ 4:
                    message.avgMinutesOnCall = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 technician_id = 1; */
        if (message.technicianId !== 0)
            writer.tag(1, WireType.Varint).int32(message.technicianId);
        /* string job_type = 2; */
        if (message.jobType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.jobType);
        /* float avg_minutes_driving = 3; */
        if (message.avgMinutesDriving !== 0)
            writer.tag(3, WireType.Bit32).float(message.avgMinutesDriving);
        /* float avg_minutes_on_call = 4; */
        if (message.avgMinutesOnCall !== 0)
            writer.tag(4, WireType.Bit32).float(message.avgMinutesOnCall);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCallTime
 */
export const DispatchCallTime = new DispatchCallTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCallTimeList$Type extends MessageType {
    constructor() {
        super("DispatchCallTimeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchCallTime },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DispatchCallTime results */ 1:
                    message.results.push(DispatchCallTime.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DispatchCallTime results = 1; */
        for (let i = 0; i < message.results.length; i++)
            DispatchCallTime.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCallTimeList
 */
export const DispatchCallTimeList = new DispatchCallTimeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCallCount$Type extends MessageType {
    constructor() {
        super("DispatchCallCount", [
            { no: 1, name: "technician_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "job_sub_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { technicianId: 0, jobType: "", jobSubType: "", count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 technician_id */ 1:
                    message.technicianId = reader.int32();
                    break;
                case /* string job_type */ 2:
                    message.jobType = reader.string();
                    break;
                case /* string job_sub_type */ 3:
                    message.jobSubType = reader.string();
                    break;
                case /* int32 count */ 4:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 technician_id = 1; */
        if (message.technicianId !== 0)
            writer.tag(1, WireType.Varint).int32(message.technicianId);
        /* string job_type = 2; */
        if (message.jobType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.jobType);
        /* string job_sub_type = 3; */
        if (message.jobSubType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.jobSubType);
        /* int32 count = 4; */
        if (message.count !== 0)
            writer.tag(4, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCallCount
 */
export const DispatchCallCount = new DispatchCallCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchCallCountList$Type extends MessageType {
    constructor() {
        super("DispatchCallCountList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchCallCount },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DispatchCallCount results */ 1:
                    message.results.push(DispatchCallCount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DispatchCallCount results = 1; */
        for (let i = 0; i < message.results.length; i++)
            DispatchCallCount.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchCallCountList
 */
export const DispatchCallCountList = new DispatchCallCountList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchFirstCall$Type extends MessageType {
    constructor() {
        super("DispatchFirstCall", [
            { no: 1, name: "cust_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_businessname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_callback", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "property_is_residential", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "log_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "date_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "date_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "time_ended", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "assigned", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "job_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "job_subtype_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "property_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "property_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "geolocation_lat", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 21, name: "geolocation_lng", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 22, name: "log_technician_assigned", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "group_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { custName: "", userBusinessname: "", id: 0, name: "", description: "", isCallback: 0, propertyIsResidential: 0, logNotes: "", dateStarted: "", dateEnded: "", timeStarted: "", timeEnded: "", assigned: "", jobTypeId: 0, jobSubtypeId: 0, propertyId: 0, userId: 0, propertyAddress: "", propertyCity: "", geolocationLat: 0, geolocationLng: 0, logTechnicianAssigned: "", groupId: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cust_name */ 1:
                    message.custName = reader.string();
                    break;
                case /* string user_businessname */ 2:
                    message.userBusinessname = reader.string();
                    break;
                case /* int32 id */ 3:
                    message.id = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* int32 is_callback */ 6:
                    message.isCallback = reader.int32();
                    break;
                case /* int32 property_is_residential */ 7:
                    message.propertyIsResidential = reader.int32();
                    break;
                case /* string log_notes */ 8:
                    message.logNotes = reader.string();
                    break;
                case /* string date_started */ 9:
                    message.dateStarted = reader.string();
                    break;
                case /* string date_ended */ 10:
                    message.dateEnded = reader.string();
                    break;
                case /* string time_started */ 11:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_ended */ 12:
                    message.timeEnded = reader.string();
                    break;
                case /* string assigned */ 13:
                    message.assigned = reader.string();
                    break;
                case /* int32 job_type_id */ 14:
                    message.jobTypeId = reader.int32();
                    break;
                case /* int32 job_subtype_id */ 15:
                    message.jobSubtypeId = reader.int32();
                    break;
                case /* int32 property_id */ 16:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 user_id */ 17:
                    message.userId = reader.int32();
                    break;
                case /* string property_address */ 18:
                    message.propertyAddress = reader.string();
                    break;
                case /* string property_city */ 19:
                    message.propertyCity = reader.string();
                    break;
                case /* double geolocation_lat */ 20:
                    message.geolocationLat = reader.double();
                    break;
                case /* double geolocation_lng */ 21:
                    message.geolocationLng = reader.double();
                    break;
                case /* string log_technician_assigned */ 22:
                    message.logTechnicianAssigned = reader.string();
                    break;
                case /* int32 group_id */ 23:
                    message.groupId = reader.int32();
                    break;
                case /* repeated string field_mask */ 24:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 25:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string cust_name = 1; */
        if (message.custName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.custName);
        /* string user_businessname = 2; */
        if (message.userBusinessname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userBusinessname);
        /* int32 id = 3; */
        if (message.id !== 0)
            writer.tag(3, WireType.Varint).int32(message.id);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* int32 is_callback = 6; */
        if (message.isCallback !== 0)
            writer.tag(6, WireType.Varint).int32(message.isCallback);
        /* int32 property_is_residential = 7; */
        if (message.propertyIsResidential !== 0)
            writer.tag(7, WireType.Varint).int32(message.propertyIsResidential);
        /* string log_notes = 8; */
        if (message.logNotes !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.logNotes);
        /* string date_started = 9; */
        if (message.dateStarted !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.dateStarted);
        /* string date_ended = 10; */
        if (message.dateEnded !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.dateEnded);
        /* string time_started = 11; */
        if (message.timeStarted !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_ended = 12; */
        if (message.timeEnded !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.timeEnded);
        /* string assigned = 13; */
        if (message.assigned !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.assigned);
        /* int32 job_type_id = 14; */
        if (message.jobTypeId !== 0)
            writer.tag(14, WireType.Varint).int32(message.jobTypeId);
        /* int32 job_subtype_id = 15; */
        if (message.jobSubtypeId !== 0)
            writer.tag(15, WireType.Varint).int32(message.jobSubtypeId);
        /* int32 property_id = 16; */
        if (message.propertyId !== 0)
            writer.tag(16, WireType.Varint).int32(message.propertyId);
        /* int32 user_id = 17; */
        if (message.userId !== 0)
            writer.tag(17, WireType.Varint).int32(message.userId);
        /* string property_address = 18; */
        if (message.propertyAddress !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.propertyAddress);
        /* string property_city = 19; */
        if (message.propertyCity !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.propertyCity);
        /* double geolocation_lat = 20; */
        if (message.geolocationLat !== 0)
            writer.tag(20, WireType.Bit64).double(message.geolocationLat);
        /* double geolocation_lng = 21; */
        if (message.geolocationLng !== 0)
            writer.tag(21, WireType.Bit64).double(message.geolocationLng);
        /* string log_technician_assigned = 22; */
        if (message.logTechnicianAssigned !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.logTechnicianAssigned);
        /* int32 group_id = 23; */
        if (message.groupId !== 0)
            writer.tag(23, WireType.Varint).int32(message.groupId);
        /* repeated string field_mask = 24; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(24, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 25; */
        if (message.pageNumber !== 0)
            writer.tag(25, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchFirstCall
 */
export const DispatchFirstCall = new DispatchFirstCall$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DismissalMsg$Type extends MessageType {
    constructor() {
        super("DismissalMsg", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "dismiss", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "dispatcher_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, dismiss: false, dispatcherId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* bool dismiss */ 2:
                    message.dismiss = reader.bool();
                    break;
                case /* int32 dispatcher_id */ 3:
                    message.dispatcherId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* bool dismiss = 2; */
        if (message.dismiss !== false)
            writer.tag(2, WireType.Varint).bool(message.dismiss);
        /* int32 dispatcher_id = 3; */
        if (message.dispatcherId !== 0)
            writer.tag(3, WireType.Varint).int32(message.dispatcherId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DismissalMsg
 */
export const DismissalMsg = new DismissalMsg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchFirstCallList$Type extends MessageType {
    constructor() {
        super("DispatchFirstCallList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchFirstCall },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DispatchFirstCall results */ 1:
                    message.results.push(DispatchFirstCall.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DispatchFirstCall results = 1; */
        for (let i = 0; i < message.results.length; i++)
            DispatchFirstCall.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DispatchFirstCallList
 */
export const DispatchFirstCallList = new DispatchFirstCallList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FirstCall$Type extends MessageType {
    constructor() {
        super("FirstCall", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "json", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sector", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "first_call_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_night_shift", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "channel_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, dateCreated: "", json: "", sector: 0, fieldMask: [], pageNumber: 0, firstCallDate: "", isNightShift: false, channelCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string date_created */ 2:
                    message.dateCreated = reader.string();
                    break;
                case /* string json */ 3:
                    message.json = reader.string();
                    break;
                case /* int32 sector */ 4:
                    message.sector = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                case /* string first_call_date */ 7:
                    message.firstCallDate = reader.string();
                    break;
                case /* bool is_night_shift */ 8:
                    message.isNightShift = reader.bool();
                    break;
                case /* string channel_code */ 9:
                    message.channelCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string date_created = 2; */
        if (message.dateCreated !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dateCreated);
        /* string json = 3; */
        if (message.json !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.json);
        /* int32 sector = 4; */
        if (message.sector !== 0)
            writer.tag(4, WireType.Varint).int32(message.sector);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        /* string first_call_date = 7; */
        if (message.firstCallDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.firstCallDate);
        /* bool is_night_shift = 8; */
        if (message.isNightShift !== false)
            writer.tag(8, WireType.Varint).bool(message.isNightShift);
        /* string channel_code = 9; */
        if (message.channelCode !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.channelCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FirstCall
 */
export const FirstCall = new FirstCall$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FirstCallList$Type extends MessageType {
    constructor() {
        super("FirstCallList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FirstCall },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated FirstCall results */ 1:
                    message.results.push(FirstCall.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated FirstCall results = 1; */
        for (let i = 0; i < message.results.length; i++)
            FirstCall.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FirstCallList
 */
export const FirstCallList = new FirstCallList$Type();
/**
 * @generated ServiceType for protobuf service DispatchService
 */
export const DispatchService = new ServiceType("DispatchService", [
    { name: "GetDispatchableTechnicians", options: {}, I: DispatchableTech, O: DispatchableTechList },
    { name: "GetDispatchCalls", options: {}, I: DispatchCall, O: DispatchCallList },
    { name: "GetTimeoffData", options: {}, I: TimeoffRequest, O: TimeoffRequestList },
    { name: "GetDispatchCallBacks", options: {}, I: DateRange, O: DispatchCallBacksList },
    { name: "GetDispatchCallTimes", options: {}, I: DateRange, O: DispatchCallTimeList },
    { name: "GetDispatchCallCount", options: {}, I: DateRange, O: DispatchCallCountList },
    { name: "GetDispatchFirstCall", options: {}, I: Empty, O: DispatchFirstCallList },
    { name: "DispatchMessage", options: {}, I: Int32, O: Bool },
    { name: "DismissalMessage", options: {}, I: DismissalMsg, O: Empty },
    { name: "CreateFirstCall", options: {}, I: FirstCall, O: FirstCall },
    { name: "GetFirstCall", options: {}, I: FirstCall, O: FirstCall },
    { name: "BatchGetFirstCall", options: {}, I: FirstCall, O: FirstCallList },
    { name: "UpdateFirstCall", options: {}, I: FirstCall, O: FirstCall },
    { name: "DeleteFirstCall", options: {}, I: FirstCall, O: FirstCall },
    { name: "FirstCallMessage", options: {}, I: FirstCall, O: Bool }
]);
