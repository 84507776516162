import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { NewHireEdit } from '../../modules/NewHire/components/NewHireEdit';

export const Route = createFileRoute('/hrDashboard/newHire/edit/$newHireId')({
  component: RouteComponent,
  params: z.object({
    newHireId: z.coerce.number().positive(),
  }),
  head(ctx) {
    return {
      meta: [{ title: 'HR Dashboard - New Hire Edit' }],
    };
  },
});

function RouteComponent() {
  const params = Route.useParams();
  return <NewHireEdit newHireId={params.newHireId} />;
}
