import { type User } from '@kalos/kalos-rpc';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  useGetDefaultSearchFormValues,
  useSearchParamsForm,
} from '../../../../hooks/useFormWithSearchParams';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';

type UserSearchFields = Pick<User, 'firstname' | 'lastname' | 'businessname' | 'email' | 'phone'>;

export const customerSearchScheme = z.object({
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  businessname: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
}) satisfies ZodObjectWithModelKeys<UserSearchFields>;

export type UserSearchSchemeType = z.infer<typeof customerSearchScheme>;
export const defaultUserSearchValues: UserSearchSchemeType = {
  firstname: '',
  lastname: '',
  businessname: '',
  email: '',
  phone: '',
};

export const useUserSearchForm = () => {
  return useSearchParamsForm({
    schema: customerSearchScheme,
    defaultValues: useGetDefaultSearchFormValues(customerSearchScheme, defaultUserSearchValues),
    defaultResetValues: defaultUserSearchValues,
  });
};

export const useUserSearchFormContext = () => {
  return useFormContext<UserSearchSchemeType>();
};
