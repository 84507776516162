'use no memo';

import { Event, getPropertyAddress, type Property } from '@kalos/kalos-rpc';
import {
  Button,
  cn,
  DataTable,
  DataTableColumnHeader,
  Form,
  Skeleton,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import { BarChartIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { Link } from '@tanstack/react-router';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  type OnChangeFn,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import compact from 'lodash/compact';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME, SEARCH_DATE_FORMAT } from '../../../../constants';
import { useAuth } from '../../../../context/AuthContext';
import {
  useEventDeleteMutation,
  useEventsQuery,
} from '../../../../hooks/react-query/useEventsQuery';
import { useUserQuery } from '../../../../hooks/react-query/useUserQuery';
import { useBuildLocation } from '../../../../hooks/useBuildLocation';
import {
  debounce,
  type EventsFilter,
  type EventsSort,
  formatDate,
  type LoadEventsByFilter,
  navigateNewTab,
  UserClientService,
} from '../../../../tools/helpers';
import { PropertyInfo } from '../../../PropertyInformation/components/PropertyInfo';
import { CostReportCSV } from '../../CostReportCSV';
import { CustomerInformation } from '../../CustomerInformation';
import { Modal } from '../../Modal';
import { type AdvancedSearchProps } from '..';
import { useAdvancedSearchPaginationStore } from '../paginationStore';
import { ServiceCallsSearchForm } from './ServiceCallsSearchForm';
import { type ServiceCallsSearchScheme, useServiceCallsSearchForm } from './utils';

type ServiceCallsSearchProps = {
  accounting: boolean;
  showJob?: boolean;
  omitArchivedJobs?: boolean;
} & Pick<
  AdvancedSearchProps,
  'onSelectEvent' | 'onClose' | 'deletableEvents' | 'showRecentServiceCallsForEmployee'
>;

const loadingServiceCalls = Array.from({ length: 5 }, (_, i) => Event.create());
const staticArray: Event[] = [];
export const ServiceCallsSearch = ({
  showRecentServiceCallsForEmployee,
  deletableEvents,
  accounting,
  showJob,
  onSelectEvent,
  omitArchivedJobs,
  onClose,
}: ServiceCallsSearchProps) => {
  const auth = useAuth();
  const loggedUserId = auth.user.id;

  const canDeleteJobs = useMemo(() => {
    return auth.user.permissionGroups.some(
      (permission) =>
        (permission.type === 'role' && permission.name === 'Manager') ||
        (permission.type === 'permission' && permission.name === 'CanDeleteJobs'),
    );
  }, [auth.user.permissionGroups]);

  const searchForm = useServiceCallsSearchForm({ isSelector: !!onSelectEvent });

  const page = useAdvancedSearchPaginationStore((state) => state.page);
  const setPage = useAdvancedSearchPaginationStore((state) => state.setPage);
  const setTotalPageCount = useAdvancedSearchPaginationStore((state) => state.setTotalEntriesCount);

  const [eventsFilter, setEventsFilter] = useState<EventsFilter>(() => {
    const defaultValues = searchForm.getValues();
    return {
      ...defaultValues,
      logTechnicianAssigned: defaultValues.logTechnicianAssigned?.toString(),
      dateStartedFrom: defaultValues.dateStartedFrom
        ? format(defaultValues.dateStartedFrom, SEARCH_DATE_FORMAT)
        : undefined,
      dateStartedTo: defaultValues.dateStartedTo
        ? format(defaultValues.dateStartedTo, SEARCH_DATE_FORMAT)
        : undefined,
    };
  });

  const [eventsSort, setEventsSort] = useState<EventsSort>({
    orderByField: 'dateStarted',
    orderBy: 'date_started',
    orderDir: 'DESC',
  });

  const userQuery = useUserQuery({
    filters: {
      id: loggedUserId || 0,
    },
    enabled: typeof loggedUserId === 'number',
    select(data) {
      return {
        ...data,
        canViewCostReport: !!data?.permissionGroups.find((el) => el.name == 'CostReportAccess'),
      };
    },
  });

  const serviceCallsFilterCriteria = useMemo<LoadEventsByFilter>(() => {
    const criteria: LoadEventsByFilter = {
      page,
      filter: eventsFilter,
      sort: eventsSort,
      req: Event.create(),
    };
    return criteria;
  }, [eventsSort, eventsFilter, page]);
  const serviceCallsEventsQuery = useEventsQuery({
    filters: serviceCallsFilterCriteria,
  });

  useEffect(() => {
    if (serviceCallsEventsQuery.isSuccess) {
      setTotalPageCount(serviceCallsEventsQuery.data?.totalCount || 0);
    }
  }, [
    serviceCallsEventsQuery.data?.totalCount,
    serviceCallsEventsQuery.isSuccess,
    setTotalPageCount,
  ]);

  const [pendingPropertyViewing, setPendingPropertyViewing] = useState<Property | null>(null);
  const handlePendingPropertyViewingToggle = useCallback(
    (pendingPropertyViewing: Property | null) => () =>
      setPendingPropertyViewing(pendingPropertyViewing),
    [setPendingPropertyViewing],
  );

  const [costReportJobNumber, setCostReportJobNumber] = useState<number | null>(null);

  const buildLocation = useBuildLocation();
  const onSelectEventCallback = useCallback(
    (event: Event, nativeEvent?: React.MouseEvent<HTMLTableRowElement>) => {
      if (nativeEvent) {
        const target = nativeEvent.target as HTMLElement;
        const columnId = target.getAttribute('data-column-id');
        if (!onSelectEvent && columnId && columnId.includes('customer') && event.customer?.id) {
          const location = buildLocation({
            to: '/customers/$customerId',
            params: {
              customerId: event.customer!.id,
            },
          });
          navigateNewTab(location.href);
        }
        if (!onSelectEvent && columnId && columnId.includes('property')) {
          const location = buildLocation({
            to: '/properties/$propertyId',
            params: {
              propertyId: event.propertyId,
            },
          });
          navigateNewTab(location.href);
        } else {
          console.log('We did not provide an onclick events');
          if (accounting) {
            const location = buildLocation({
              to: '/jobs/$jobId',
              params: {
                jobId: event.id,
              },
            });
            navigateNewTab(location.href);
            return;
          }
          if (onSelectEvent) {
            if (omitArchivedJobs) {
              if (event.logJobStatus === 'Archived') {
                return;
              }
            }
            onSelectEvent(event);
          } else if (showJob) {
            const location = buildLocation({
              to: '/jobs/$jobId',
              params: {
                jobId: event.id,
              },
            });
            const newWindow = navigateNewTab(location.href);
            newWindow?.focus();
          }
        }
      }
      if (onClose) {
        onClose();
      }
    },
    [onClose, onSelectEvent, buildLocation, accounting, showJob, omitArchivedJobs],
  );

  useEffect(() => {
    const debouncedUpdate = debounce((data: ServiceCallsSearchScheme) => {
      console.log('search', { data });
      setEventsFilter({
        ...data,
        logTechnicianAssigned:
          data.logTechnicianAssigned && data.logTechnicianAssigned != 0
            ? `%${data.logTechnicianAssigned.toString()}%`
            : undefined,
        dateStartedFrom: data.dateStartedFrom
          ? format(data.dateStartedFrom, SEARCH_DATE_FORMAT)
          : undefined,
        dateStartedTo: data.dateStartedTo
          ? format(data.dateStartedTo, SEARCH_DATE_FORMAT)
          : undefined,
        description: `%${data.description}%`,
      });
      setPage(0);
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);
    const updateSubscription = searchForm.watch(debouncedUpdate);
    return () => updateSubscription.unsubscribe();
  }, [searchForm, setPage]);

  const serviceCallsColumnsDef = useMemo<ColumnDef<Event>[]>(() => {
    const customerNameAndBusinessNameColumns: ColumnDef<Event>[] = accounting
      ? [
          {
            id: 'customerName',
            accessorFn: (entry) => {
              if (!entry.customer) return '';
              return UserClientService.getCustomerName(entry.customer);
            },
            meta: {
              className: 'w-24',
            },
            header: ({ column }) => (
              <DataTableColumnHeader hideVisibilityToggle title="Customer Name" column={column} />
            ),
            cell({ row, column }) {
              const value = row.getValue('customerName');
              return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
            },
            enableSorting: false,
          },
          {
            id: 'businessName',
            accessorFn: (entry) => {
              if (!entry.customer) return '';
              return UserClientService.getBusinessName(entry.customer);
            },
            header: ({ column }) => (
              <DataTableColumnHeader hideVisibilityToggle title="Business Name" column={column} />
            ),
            meta: {
              className: 'w-32',
            },
            cell({ row, column }) {
              const value = row.getValue('businessName');
              return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
            },
            enableSorting: false,
          },
        ]
      : [
          {
            id: 'customer',
            accessorFn: (entry) => {
              const customer = entry.customer;
              if (!customer) {
                return '';
              }
              return accounting
                ? UserClientService.getCustomerName(customer)
                : UserClientService.getCustomerNameAndBusinessName(customer);
            },
            header: ({ column }) => (
              <DataTableColumnHeader
                hideVisibilityToggle
                title="Customer Name - Business Name"
                column={column}
              />
            ),
            meta: {
              className: 'w-32',
            },
            cell({ row, column }) {
              const value = row.getValue('customer');
              return (
                <div data-column-id="customer" className={column.columnDef.meta?.className}>
                  {String(value)}
                </div>
              );
            },
            enableSorting: false,
          },
        ];

    const addressAndContractsColumns: ColumnDef<Event>[] = accounting
      ? [
          {
            id: 'address',
            accessorFn: (entry) => {
              if (!entry.property) return '';
              return getPropertyAddress(entry.property);
            },
            header: ({ column }) => (
              <DataTableColumnHeader hideVisibilityToggle title="Address" column={column} />
            ),
            meta: {
              className: 'w-32',
            },
            cell({ row, column }) {
              const value = row.getValue('address');
              return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
            },
            enableSorting: false,
          },
          {
            id: 'city',
            accessorFn: (entry) => {
              if (!entry.property) return '';
              return entry.property.city;
            },
            header: ({ column }) => (
              <DataTableColumnHeader hideVisibilityToggle title="City" column={column} />
            ),
            meta: { className: 'w-16' },
            cell({ row, column }) {
              const value = row.getValue('city');
              return <div className={cn(column.columnDef.meta?.className)}>{String(value)}</div>;
            },
            enableSorting: false,
          },
          {
            id: 'zip',
            accessorFn: (entry) => {
              if (!entry.property) return '';
              return entry.property.zip;
            },
            header: ({ column }) => (
              <DataTableColumnHeader hideVisibilityToggle title="Zip" column={column} />
            ),
            meta: { className: 'w-10' },
            cell({ row, column }) {
              const value = row.getValue('zip');
              return <div className={cn(column.columnDef.meta?.className)}>{String(value)}</div>;
            },
            enableSorting: false,
          },
          {
            id: 'phone',
            accessorFn: (entry) => {
              if (!entry.property) return '';
              return entry.property.phone;
            },
            header: ({ column }) => {
              return <DataTableColumnHeader hideVisibilityToggle title="Phone" column={column} />;
            },
            meta: { className: 'w-32' },
            cell({ row, column }) {
              const value = row.getValue('phone');
              return <div className={cn(column.columnDef.meta?.className)}>{String(value)}</div>;
            },
            enableSorting: false,
          },
        ]
      : [
          {
            id: 'addressWithPhone',
            accessorFn: (entry) => {
              const property = entry.property;
              const customer = entry.customer;
              return `${property ? getPropertyAddress(property) : ''} ${
                customer ? UserClientService.getCustomerPhone(customer) : ''
              }`;
            },
            header: ({ column }) => (
              <DataTableColumnHeader
                hideVisibilityToggle
                title="Address City Zip Phone"
                column={column}
              />
            ),
            enableSorting: false,
            meta: {
              className: 'w-32',
            },
            cell({ row, column }) {
              const value = row.getValue('addressWithPhone');
              return (
                <div data-column-id="property" className={column.columnDef.meta?.className}>
                  {String(value)}
                </div>
              );
            },
          },
        ];

    const jobStatusColumn: ColumnDef<Event>[] = accounting
      ? []
      : [
          {
            id: 'log_jobStatus',
            accessorKey: 'logJobStatus',
            header: ({ column }) => (
              <DataTableColumnHeader hideVisibilityToggle title="Job Status" column={column} />
            ),
            meta: {
              className: 'w-32',
            },
            cell({ row, column }) {
              const value = row.original.logJobStatus;
              return (
                <div
                  className={cn(
                    column.columnDef.meta?.className,
                    value === 'Canceled' && 'text-red-500',
                  )}
                >
                  {String(value)}
                </div>
              );
            },
          },
        ];
    const servicesNeededColumn: ColumnDef<Event>[] = accounting
      ? []
      : [
          {
            id: 'description',
            accessorKey: 'servicesNeeded',
            header: ({ column }) => (
              <DataTableColumnHeader hideVisibilityToggle title="Services Needed" column={column} />
            ),
            meta: {
              className: 'w-32',
            },
            cell({ row, column }) {
              const value = row.original.description;
              return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
            },
          },
        ];
    const actionsColumn: ColumnDef<Event>[] = onSelectEvent
      ? []
      : [
          {
            id: 'actions',
            header: ({ column }) => (
              <DataTableColumnHeader
                hideVisibilityToggle
                className="mx-auto text-center"
                title="Actions"
                column={column}
              />
            ),
            meta: {
              className: 'mx-auto flex w-40 flex-wrap items-center justify-center gap-1',
            },
            cell({ row, column }) {
              const entry = row.original;
              return (
                <div className={column.columnDef.meta?.className}>
                  <TooltipProvider delayDuration={150}>
                    {userQuery.data?.canViewCostReport && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCostReportJobNumber(entry.id);
                            }}
                          >
                            <BarChartIcon />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="px-2 py-1">Job Cost Report</TooltipContent>
                      </Tooltip>
                    )}

                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          size="icon"
                          variant="outline"
                          asChild
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Link to="/jobs/$jobId" target="_blank" params={{ jobId: entry.id }}>
                            <Pencil1Icon />
                          </Link>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent className="px-2 py-1">Edit Job</TooltipContent>
                    </Tooltip>
                    {deletableEvents && canDeleteJobs && <DeleteJobAction job={entry} />}
                  </TooltipProvider>
                </div>
              );
            },
          },
        ];

    const selectColumns: ColumnDef<Event>[] = onSelectEvent
      ? [
          {
            meta: {
              className: 'w-32',
            },
            id: 'select',
            cell({ row, column }) {
              const isDisabled = row.original.logJobStatus === 'Archived' && omitArchivedJobs;
              return (
                <div className={column.columnDef.meta?.className}>
                  <Button disabled={isDisabled}>{`${isDisabled ? 'Archived' : 'Select'}`}</Button>
                </div>
              );
            },
          },
        ]
      : [];

    const columns: ColumnDef<Event>[] = [
      ...selectColumns,
      {
        id: 'date_started',
        accessorKey: 'dateStarted',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Date Started" column={column} />
        ),
        meta: {
          className: 'w-20',
        },
        cell({ row, column }) {
          const value = formatDate(row.original.dateStarted);
          return <div className={column.columnDef.meta?.className}>{value}</div>;
        },
        sortingFn: () => 0,
      },
      ...customerNameAndBusinessNameColumns,
      ...addressAndContractsColumns,
      {
        accessorFn: (entry) => {
          const logJobNumber = entry.logJobNumber;
          const logPo = entry.logPo;
          return `${
            accounting
              ? compact(logJobNumber.replace(/[A-Za-z]/g, '').split('-'))
                  .join('-')
                  .replace(/~/g, '')
              : logJobNumber
          } / ${logPo || '-'}`;
        },
        id: 'log_jobNumber',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Job # / PO" column={column} />
        ),
        meta: {
          className: 'w-32',
        },
        cell({ row, column }) {
          const value = row.getValue('log_jobNumber');
          return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        id: 'job_type_id',
        accessorFn: (entry) => {
          const jt = entry.jobType;
          const jst = entry.jobSubtype;
          return `${jt} / ${jst}`;
        },
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Job Type / Subtype" column={column} />
        ),
        meta: {
          className: 'w-32',
        },
        cell({ row, column }) {
          const value = row.getValue('job_type_id');
          return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
        },
      },
      ...jobStatusColumn,
      ...servicesNeededColumn,
      ...actionsColumn,
    ];
    return columns;
  }, [
    accounting,
    onSelectEvent,
    userQuery.data?.canViewCostReport,
    deletableEvents,
    canDeleteJobs,
    omitArchivedJobs,
  ]);

  const skeletonColumns = useMemo<ColumnDef<Event>[]>(() => {
    return serviceCallsColumnsDef.map((column) => ({
      ...column,
      cell: () => <Skeleton className="bg-foreground/30 h-4 w-32" />,
    }));
  }, [serviceCallsColumnsDef]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const onSortingChange: OnChangeFn<SortingState> = (updater) => {
    setSorting((data) => {
      const newSorting = typeof updater === 'function' ? updater(data) : updater;

      setEventsSort({
        orderByField: newSorting[0].id as keyof Event,
        orderBy: newSorting[0].id,
        orderDir: newSorting[0].desc ? 'DESC' : 'ASC',
      });
      setPage(0);
      return newSorting;
    });
  };

  const serviceCallsTable = useReactTable({
    data: serviceCallsEventsQuery.isPending
      ? loadingServiceCalls
      : serviceCallsEventsQuery.data?.results ?? staticArray,
    columns: serviceCallsEventsQuery.isPending ? skeletonColumns : serviceCallsColumnsDef,
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    enableRowSelection: true,
    onSortingChange: onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <>
      <Form {...searchForm}>
        <div className="p-4">
          <ServiceCallsSearchForm />
        </div>
      </Form>
      <div className="max-w-[100vw] overflow-x-auto lg:max-w-full">
        <DataTable
          onRowClick={(event, nativeEvent) => {
            if (serviceCallsEventsQuery.data?.results.length) {
              onSelectEventCallback(event, nativeEvent);
            }
          }}
          table={serviceCallsTable}
        />
      </div>

      {pendingPropertyViewing && (
        <Modal open onClose={handlePendingPropertyViewingToggle(null)} fullScreen>
          <CustomerInformation
            userID={pendingPropertyViewing.userId}
            propertyId={pendingPropertyViewing.id}
            onClose={handlePendingPropertyViewingToggle(null)}
          />
          <PropertyInfo
            propertyId={pendingPropertyViewing.id}
            onClose={handlePendingPropertyViewingToggle(null)}
          />
        </Modal>
      )}
      {costReportJobNumber && (
        <Modal
          className="Modal-Remove-Scroll"
          fullScreen
          open
          onClose={() => setCostReportJobNumber(null)}
        >
          <CostReportCSV
            title="Job Cost Report"
            serviceCallId={costReportJobNumber}
            onClose={() => setCostReportJobNumber(null)}
          />
        </Modal>
      )}
    </>
  );
};

function DeleteJobAction({ job }: { job: Event }) {
  const confirm = useConfirm();
  const deleteEventMutation = useEventDeleteMutation();
  const handleDeleteServiceCall = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const ok = await confirm({
        title: 'Delete Job',
        body: `Are you sure you want to delete job ${job.logJobNumber}?`,
      });
      if (ok) {
        try {
          await deleteEventMutation.mutateAsync(job);
          toast({
            title: 'Job deleted',
            description: 'Job deleted successfully',
            variant: 'success',
          });
        } catch {
          toast({
            title: 'Error',
            description: 'Failed to delete job',
            variant: 'destructive',
          });
        }
      }
    },
    [confirm, deleteEventMutation, job],
  );

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          isLoading={deleteEventMutation.isPending}
          disabled={deleteEventMutation.isPending}
          size="icon"
          variant="outline"
          onClick={handleDeleteServiceCall}
        >
          <TrashIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="px-2 py-1">Delete Job</TooltipContent>
    </Tooltip>
  );
}
